import { Injectable, SecurityContext } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { ApiLoginService } from '../services/api-login.service';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private _apiLoginService: ApiLoginService,
    private cookieService: CookieService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot) {

    var currentPath = route.url[0].path;

/*    console.log(route.queryParams.dc);
    console.log(route.queryParams.dc_timestamp);
    console.log(route.url[0].path);*/
    
    if(route.queryParams.dc!=undefined && route.queryParams.dc_timestamp!=undefined) { /* Create Cookie Login */
        this.cookieService.set(route.url[0].path, route.url[0].path);
//        console.log("Create cookie " + route.url[0].path);
    }
    if(route.url[0].path == "therapie") {
//        console.log(this.cookieService.get('therapie'));
        if(this.cookieService.get('therapie')!="") {
//            console.log("Logged");
            return true;
        } else {
//            console.log("Not Logged");
        }
    }
    if(route.url[0].path == "infocenter") {
//        console.log(this.cookieService.get('infocenter'));
        if(this.cookieService.get('infocenter')!="") {
            return true;
        } 
    }
    if(route.url[0].path == "education") {
//        console.log(this.cookieService.get('education'));
        if(this.cookieService.get('education')!="") {
            return true;
        }
    }
    this._router.navigate([`/${currentPath}-login`]); // IF LOGIN NOT OK
    
    /* OLD LOGIN CHECKUP */
/*    if(this._apiLoginService.isAuthenticated()){
        return true;
    } else {
//        this._router.navigate([`/${currentPath}-login`]);
      return false;
    }*/
  }

  



}
