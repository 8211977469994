<div id="main-login" class="page-wrapper">

  <app-menu [activeMenuIndex]="1">
  </app-menu>

  <!--<div class="subheader-bar-wrapper">
    <div class="subheader-content">
      <a href="https://www.lsd-diagnostik-partner.de/" target="_blank">
        <div class="subheader-content-text">
          Ihr kostenfreies Testset!
        </div>
      </a>
    </div>
  </div>-->

  <app-login>
  </app-login>

</div>