<div id="therapie" class="page-wrapper">

  <app-menu [activeMenuIndex]="3">
  </app-menu>

  <!--<div class="subheader-bar-wrapper">
    <div class="subheader-content">
      <a href="https://www.lsd-diagnostik-partner.de/" target="_blank">
        <div class="subheader-content-text">
          Ihr kostenfreies Testset!
        </div>
      </a>
    </div>
  </div>-->

  <div class="floating-chameleon">
  </div>

  <span class="icon-care ico page-intro green"></span>

  <div class="title-wrapper">

    <h1>
      Therapie
    </h1>

  </div>

<!--  <app-login zone="therapie">-->
<!--  <app-login zone="2000000014925"> <!-- Development -->
<!--  <app-login zone="2000000016671"> <!-- Takeda Server -->
  <app-login zone="2000000014983"> <!-- Production -->
  </app-login>

<!--<iframe align="left" frameborder="0" scrolling="no" width="424" height="215" name="dc_login_iframe" id="dc_login_iframe" src="https://login.doccheck.com/code/de/2000000014925/login_l/" ><a href="https://login.doccheck.com/code/de/2000000014925/login_l/" target="_blank">LOGIN</a></iframe>-->

  <app-footer>
  </app-footer>

</div>