import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-center-login',
  templateUrl: './info-center-login.component.html',
  styleUrls: ['./info-center-login.component.scss']
})
export class InfoCenterLoginComponent implements OnInit {

    currentPanelId = ""; 
  constructor() { }

  ngOnInit(): void {

    window.scrollTo(0, 0);

    const cityButtons = document.querySelectorAll('.st2');

    for (var i = 0; i < cityButtons.length; i++) {
      cityButtons[i].addEventListener('click', this.switchAddressPanel.bind(this));
    }

  }

  switchAddressPanel(event): void {

    if(this.currentPanelId) {
      document.querySelectorAll(this.currentPanelId)[0].classList.add("hidden");
    }

    var newPanelId = "#center_" + event.srcElement['id'].split("_")[1];


    if(newPanelId) {
      document.querySelectorAll(newPanelId)[0].classList.remove("hidden");
    }

    this.currentPanelId = newPanelId;
  }

}
