import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { ImageDialogComponent } from '../partials/image-dialog/image-dialog.component';

@Component({
  selector: 'app-diagnose',
  templateUrl: './diagnose.component.html',
  styleUrls: ['./diagnose.component.scss']
})
export class DiagnoseComponent implements OnInit {

  constructor(
    private _router: Router,
    public _matDialog: MatDialog
    ) { }

  ngOnInit(): void {

    window.scrollTo(0, 0);

    const supTags = document.querySelectorAll('sup');

    for (var i = 0; i < supTags.length; i++) {
      supTags[i].addEventListener('click', this.goToReferences.bind(this));
      supTags[i].setAttribute('style', 'cursor: pointer;');
    }
    
  }

  goToReferences(event): void {
    this.navigateTo('/referenzen');
  }

  navigateTo(url){
    this._router.navigate([url], { fragment: 'diagnose' });
    // document.body.scrollTop = 0;
  }

  openDialog(id) {
    this._matDialog.open(ImageDialogComponent, {
      data: {
        identifier: id
      },
      panelClass: 'image-dialog-container'
    });

  }

}
