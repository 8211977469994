import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class ApiLoginService {

  private authToken: any;
  private authInfo: any;

  constructor(
  ) {

  }

  tryLogin(authToken){
    return new Promise((resolve, reject) => {
      this.authToken = authToken;
      resolve(true);
    });
  }


  tryLogout(){
    return new Promise((resolve, reject) => {
      this.authToken = null;

      resolve(true);
    });
  }

  isAuthenticated(){
      return true;
//    return this.authToken && this.authToken.dc_timestamp.length > 0; /* OLD CHECK UP */
  }

}