<div id="menu" class="header-bar"
  [ngClass]="{ 'sticky-header' : isSticky }"
  >

  <div class="rectangle rectangle-1"
    [class.rectangle-1-active]="activeMenuIndex===1"
    (click)="navigateTo('morbus-fabry')"
    >
    Morbus
    <br>
    Fabry
  </div>
  <div class="rectangle rectangle-2"
    [class.rectangle-2-active]="activeMenuIndex===2"
    (click)="navigateTo('diagnose')"
    >
    Diagnose
  </div>
  <div class="rectangle rectangle-3"
    [class.rectangle-3-active]="activeMenuIndex===3"
    (click)="navigateTo('therapie')"
    >
    Therapie
  </div>
  <div class="rectangle rectangle-4"
    [class.rectangle-4-active]="activeMenuIndex===4"
    (click)="navigateTo('infocenter')"
    >
    Infocenter
  </div>
  <div class="rectangle rectangle-5"
    [class.rectangle-5-active]="activeMenuIndex===5"
    (click)="navigateTo('education')"
    >
    Education
  </div>
  <div class="rectangle rectangle-6"
    [class.rectangle-6-active]="activeMenuIndex===6"
    (click)="navigateTo('fachgebiete')"
    >
    Fachgebiete
  </div>

  <div class="mobile-menu-wrapper">

    <aside *ngIf="isMenuOpen" 
      class="off-canvas position-top is-transition-push is-open"
      id="offCanvas"
      data-off-canvas=""
      aria-hidden="false"
      data-i="kf14o6-i"
    >

      <!-- <div class="grid-x grid-padding-x grid-padding-y"> -->
      <div class="close-button-box">
        <button class="close-button" aria-label="Close menu" 
          type="button"
          aria-label="Close menu"
          (click)="closeMenu()"
          >
          <span aria-hidden="true"> 
            <span class="badge close-icon"><span class="icon-close ico"></span></span>
          </span>
        </button>
      </div>
      <!-- </div> -->
      
      <ul class="grid-x menu off-canvas-navi">
        <!--<li class="cell medium-auto red"><a class="menu-item" href="#"><span>DocCheck Login</span></a></li>-->
        <li class="cell medium-auto ocean">
          <a class="menu-item is-current" [routerLink]="" (click)="navigateTo('')">
            <span>Startseite</span>
          </a>
        </li>
        <li class="cell medium-auto pink">
          <a class="menu-item is-current" [routerLink]="" (click)="navigateTo('morbus-fabry')">
            <span>Morbus Fabry</span>
          </a>
        </li>
        <li class="cell medium-auto purple">
          <a class="menu-item" [routerLink]="" (click)="navigateTo('diagnose')">
            <span>Diagnose</span>
          </a>
        </li>
        <li class="cell medium-auto cyan">
          <a class="menu-item" [routerLink]="" (click)="navigateTo('therapie')">
            <span>Therapie</span>
          </a>
        </li>
        <li class="cell medium-auto green">
          <a class="menu-item" [routerLink]="" (click)="navigateTo('infocenter')">
            <span>Infocenter</span>
          </a>
        </li>
        <li class="cell medium-auto apple">
          <a class="menu-item" [routerLink]="" (click)="navigateTo('education')">
            <span>Education</span>
          </a>
        </li>
        <li class="cell medium-auto blue">
          <a class="menu-item" [routerLink]="" (click)="navigateTo('fachgebiete')">
            <span>Fachgebiete</span>
          </a>
        </li>
      </ul>
    </aside>

    <div *ngIf="!isMenuOpen" class="cell auto text-center">
      <button class="burger-icon btn-control" 
        type="button" 
        aria-label="Open menu" 
        (click)="openMenu()"
        >
        <span class="strk one"></span>
        <span class="strk two"></span>
        <span class="strk three"></span>
      </button>
    </div>
  </div>

  

</div>
  <div class="subheader-bar-wrapper">
    <div class="subheader-content">
      <a href="https://www.lsd-diagnostik-partner.de/" target="_blank">
        <div class="subheader-content-text">
          Ihr kostenfreies Testset!
        </div>
      </a>
    </div>
  </div>

