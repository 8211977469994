<div id="fachgebiete" class="page-wrapper">

  <app-menu [activeMenuIndex]="6">
  </app-menu>

  <!--<div class="subheader-bar-wrapper">
    <div class="subheader-content">
      <a href="https://www.lsd-diagnostik-partner.de/" target="_blank">
        <div class="subheader-content-text">
          Ihr kostenfreies Testset!
        </div>
      </a>
    </div>
  </div>-->

  <div class="title-wrapper">
    <img src="/assets/img/template/under_construction.png" />
  </div>

  <app-footer>
  </app-footer>

</div>