<div id="image-dialog">

  <ng-container *ngIf="currentIdentifier === 'morbus-fabry-chart-1'">
    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 903 368" style="enable-background:new 0 0 903 368;" xml:space="preserve">
      <style type="text/css">
        .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#E3E1D9;}
        .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#F5C4DF;}
        .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#D8137D;}
        .st3{fill:none;stroke:#E3E1D9;stroke-width:4;}
        .st4{enable-background:new    ;}
        .st5{fill:#FFFFFF;}
        .st6{fill:none;stroke:#E3E1D9;stroke-width:5;}
      </style>
      <g>
        <g>
          <g>
            <g>
              <path class="st0" d="M874,286V5L518,286H874z"/>
              <path class="st1" d="M874,286V126L285,286H874z"/>
              <path class="st2" d="M184,286h690v-68L184,286z"/>
              <path class="st3" d="M895,286H38V5"/>
            </g>
            <g class="st4">
              <path d="M461.6,319h-7.9v-1l6.1-9.2h-5.9v-1.2h7.5v1l-6.1,9.2h6.2L461.6,319L461.6,319z"/>
              <path d="M467.2,319.2c-1.3,0-2.3-0.4-3-1.2s-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2s1.9,0.4,2.5,1.1
                s0.9,1.6,0.9,2.8v0.8h-5.9c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                S467.8,319.2,467.2,319.2z M466.9,311.4c-0.7,0-1.2,0.2-1.6,0.7s-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                S467.5,311.4,466.9,311.4z"/>
              <path d="M472.5,308.1c0-0.3,0.1-0.5,0.2-0.7s0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2s0.2,0.4,0.2,0.6s-0.1,0.5-0.2,0.7
                s-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2S472.5,308.4,472.5,308.1z M473.9,319h-1.3v-8.6h1.3V319z"/>
              <path d="M479.4,318.1c0.2,0,0.5,0,0.7-0.1s0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2s-0.5,0.1-0.7,0.1c-1.7,0-2.5-0.9-2.5-2.6
                v-5.1h-1.2v-0.6l1.2-0.5l0.5-1.8h0.8v2h2.5v1H478v5c0,0.5,0.1,0.9,0.4,1.2S479,318.1,479.4,318.1z"/>
            </g>
            <g class="st4">
              <path d="M798.3,94c0,1.7-0.4,2.9-1.2,3.8s-2,1.3-3.5,1.3s-2.7-0.4-3.5-1.3s-1.2-2.2-1.2-3.8s0.4-2.9,1.2-3.8s2-1.3,3.5-1.3
                s2.7,0.4,3.5,1.3C797.9,91.1,798.3,92.3,798.3,94z M791,94c0,1.1,0.2,2,0.6,2.5s1.1,0.8,1.9,0.8c1.7,0,2.5-1.1,2.5-3.4
                s-0.8-3.4-2.5-3.4c-0.8,0-1.5,0.3-1.9,0.9C791.2,92,791,92.9,791,94z"/>
              <path d="M804.5,91.2c0.3,0,0.5,0,0.7,0.1l-0.2,2c-0.2,0-0.4-0.1-0.6-0.1c-0.7,0-1.2,0.2-1.6,0.5c-0.4,0.3-0.6,0.8-0.6,1.4V99
                h-2.1v-7.6h1.6l0.3,1.3h0.1c0.2-0.4,0.6-0.8,1-1C803.6,91.3,804,91.2,804.5,91.2z"/>
              <path d="M813.2,91.4v1.1l-1.2,0.3c0.2,0.3,0.3,0.7,0.3,1.1c0,0.8-0.3,1.5-0.9,1.9c-0.6,0.5-1.4,0.7-2.4,0.7h-0.4h-0.3
                c-0.2,0.2-0.3,0.3-0.3,0.5c0,0.3,0.4,0.5,1.1,0.5h1.3c0.8,0,1.5,0.2,1.9,0.5c0.4,0.4,0.7,0.9,0.7,1.6c0,0.9-0.4,1.6-1.1,2.1
                c-0.7,0.5-1.8,0.7-3.2,0.7c-1.1,0-1.9-0.2-2.4-0.6c-0.6-0.4-0.8-0.9-0.8-1.6c0-0.5,0.1-0.8,0.4-1.2s0.7-0.5,1.3-0.7
                c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.3,0.1-0.5,0.3-0.7s0.4-0.4,0.7-0.6c-0.4-0.2-0.7-0.5-1-0.8
                c-0.2-0.4-0.4-0.8-0.4-1.4c0-0.8,0.3-1.5,0.8-1.9s1.3-0.7,2.3-0.7c0.2,0,0.5,0,0.8,0.1c0.3,0,0.5,0.1,0.6,0.1L813.2,91.4
                L813.2,91.4z M807.3,100.1c0,0.3,0.1,0.5,0.4,0.7c0.3,0.2,0.7,0.2,1.2,0.2c0.7,0,1.3-0.1,1.8-0.3s0.6-0.5,0.6-0.8
                s-0.1-0.5-0.4-0.6s-0.6-0.2-1.2-0.2h-1.1c-0.4,0-0.7,0.1-1,0.3C807.5,99.5,807.3,99.8,807.3,100.1z M808.1,93.9
                c0,0.4,0.1,0.7,0.3,1c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.4s0.3-0.6,0.3-1c0-0.9-0.4-1.4-1.1-1.4
                C808.5,92.5,808.1,92.9,808.1,93.9z"/>
              <path d="M819.4,99l-0.4-1h-0.1c-0.4,0.4-0.7,0.7-1.1,0.9c-0.4,0.2-0.9,0.3-1.5,0.3c-0.7,0-1.3-0.2-1.7-0.6s-0.6-1-0.6-1.8
                s0.3-1.4,0.9-1.8c0.6-0.4,1.4-0.6,2.6-0.6h1.3v-0.3c0-0.8-0.4-1.2-1.2-1.2c-0.6,0-1.3,0.2-2.2,0.6l-0.7-1.4
                c0.9-0.5,1.9-0.7,2.9-0.7s1.8,0.2,2.4,0.7s0.8,1.1,0.8,2V99H819.4z M818.7,95.5h-0.8c-0.6,0-1.1,0.1-1.4,0.3s-0.4,0.5-0.4,0.9
                c0,0.6,0.3,0.9,1,0.9c0.5,0,0.9-0.1,1.2-0.4s0.4-0.6,0.4-1.1V95.5z"/>
              <path d="M830,99h-2.1v-4.5c0-0.6-0.1-1-0.3-1.2c-0.2-0.3-0.5-0.4-0.9-0.4c-0.6,0-1,0.2-1.3,0.6s-0.4,1-0.4,1.9V99h-2v-7.6h1.6
                l0.3,1h0.1c0.2-0.4,0.6-0.6,1-0.8c0.4-0.2,0.9-0.3,1.4-0.3c0.9,0,1.6,0.2,2,0.7c0.5,0.5,0.7,1.2,0.7,2.1L830,99L830,99z"/>
              <path d="M831.5,96.1v-1.7h3.7v1.7H831.5z"/>
            </g>
            <g class="st4">
              <path d="M790.9,118l-2.9-7.6h2.2l1.5,4.4c0.2,0.6,0.3,1.1,0.3,1.6l0,0c0-0.4,0.1-1,0.3-1.6l1.5-4.4h2.2l-2.9,7.6H790.9z"/>
              <path d="M800.5,118.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9s0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1c1.1,0,1.9,0.3,2.5,0.9
                s0.9,1.5,0.9,2.5v1h-4.9c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                c-0.4,0.2-0.8,0.3-1.2,0.4C801.6,118.1,801.1,118.1,800.5,118.1z M800.2,111.7c-0.4,0-0.8,0.1-1,0.4s-0.4,0.7-0.4,1.2h2.9
                c0-0.5-0.1-0.9-0.4-1.2C801,111.8,800.7,111.7,800.2,111.7z"/>
              <path d="M809.6,110.2c0.3,0,0.5,0,0.7,0.1l-0.2,2c-0.2,0-0.4-0.1-0.6-0.1c-0.7,0-1.2,0.2-1.6,0.5c-0.4,0.3-0.6,0.8-0.6,1.4v3.9
                h-2.1v-7.6h1.6l0.3,1.3h0.1c0.2-0.4,0.6-0.8,1-1C808.7,110.3,809.1,110.2,809.6,110.2z"/>
              <path d="M817,115.7c0,0.8-0.3,1.4-0.8,1.8s-1.4,0.6-2.4,0.6c-0.6,0-1,0-1.4-0.1s-0.8-0.2-1.1-0.3V116c0.4,0.2,0.8,0.3,1.3,0.5
                c0.5,0.1,0.9,0.2,1.3,0.2c0.8,0,1.1-0.2,1.1-0.7c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.6-0.3-1-0.5
                c-0.6-0.2-1-0.5-1.3-0.7s-0.5-0.5-0.6-0.7c-0.1-0.3-0.2-0.6-0.2-1c0-0.7,0.3-1.2,0.8-1.6s1.3-0.6,2.2-0.6s1.8,0.2,2.7,0.6
                l-0.6,1.5c-0.4-0.2-0.7-0.3-1.1-0.4s-0.7-0.2-1-0.2c-0.6,0-0.9,0.2-0.9,0.5c0,0.2,0.1,0.3,0.3,0.5c0.2,0.1,0.6,0.3,1.3,0.6
                c0.6,0.2,1,0.5,1.3,0.7s0.5,0.5,0.6,0.7S817,115.4,817,115.7z"/>
              <path d="M823.5,118l-0.4-1H823c-0.4,0.4-0.7,0.7-1.1,0.9c-0.4,0.2-0.9,0.3-1.5,0.3c-0.7,0-1.3-0.2-1.7-0.6s-0.6-1-0.6-1.8
                s0.3-1.4,0.9-1.8c0.6-0.4,1.4-0.6,2.6-0.6h1.3v-0.3c0-0.8-0.4-1.2-1.2-1.2c-0.6,0-1.3,0.2-2.2,0.6l-0.7-1.4
                c0.9-0.5,1.9-0.7,2.9-0.7s1.8,0.2,2.4,0.7s0.8,1.1,0.8,2v5.1h-1.4V118z M822.9,114.5h-0.8c-0.6,0-1.1,0.1-1.4,0.3
                s-0.4,0.5-0.4,0.9c0,0.6,0.3,0.9,1,0.9c0.5,0,0.9-0.1,1.2-0.4s0.4-0.6,0.4-1.1V114.5z"/>
              <path d="M833.8,110.4v1.1l-1.2,0.3c0.2,0.3,0.3,0.7,0.3,1.1c0,0.8-0.3,1.5-0.9,1.9c-0.6,0.5-1.4,0.7-2.4,0.7h-0.4h-0.3
                c-0.2,0.2-0.3,0.3-0.3,0.5c0,0.3,0.4,0.5,1.1,0.5h1.3c0.8,0,1.5,0.2,1.9,0.5c0.4,0.4,0.7,0.9,0.7,1.6c0,0.9-0.4,1.6-1.1,2.1
                c-0.7,0.5-1.8,0.7-3.2,0.7c-1.1,0-1.9-0.2-2.4-0.6c-0.6-0.4-0.8-0.9-0.8-1.6c0-0.5,0.1-0.8,0.4-1.2s0.7-0.5,1.3-0.7
                c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.3,0.1-0.5,0.3-0.7s0.4-0.4,0.7-0.6c-0.4-0.2-0.7-0.5-1-0.8
                c-0.2-0.4-0.4-0.8-0.4-1.4c0-0.8,0.3-1.5,0.8-1.9s1.3-0.7,2.3-0.7c0.2,0,0.5,0,0.8,0.1c0.3,0,0.5,0.1,0.6,0.1L833.8,110.4
                L833.8,110.4z M827.9,119.1c0,0.3,0.1,0.5,0.4,0.7c0.3,0.2,0.7,0.2,1.2,0.2c0.7,0,1.3-0.1,1.8-0.3s0.6-0.5,0.6-0.8
                s-0.1-0.5-0.4-0.6s-0.6-0.2-1.2-0.2h-1.1c-0.4,0-0.7,0.1-1,0.3C828,118.5,827.9,118.8,827.9,119.1z M828.6,112.9
                c0,0.4,0.1,0.7,0.3,1c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.4s0.3-0.6,0.3-1c0-0.9-0.4-1.4-1.1-1.4
                C829,111.5,828.6,111.9,828.6,112.9z"/>
              <path d="M838.5,118.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9s0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1c1.1,0,1.9,0.3,2.5,0.9
                s0.9,1.5,0.9,2.5v1h-4.9c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                c-0.4,0.2-0.8,0.3-1.2,0.4C839.6,118.1,839.1,118.1,838.5,118.1z M838.2,111.7c-0.4,0-0.8,0.1-1,0.4s-0.4,0.7-0.4,1.2h2.9
                c0-0.5-0.1-0.9-0.4-1.2C839,111.8,838.6,111.7,838.2,111.7z"/>
              <path d="M850.3,118h-2.1v-4.5c0-0.6-0.1-1-0.3-1.2c-0.2-0.3-0.5-0.4-0.9-0.4c-0.6,0-1,0.2-1.3,0.6s-0.4,1-0.4,1.9v3.6h-2.1v-7.6
                h1.6l0.3,1h0.1c0.2-0.4,0.6-0.6,1-0.8c0.4-0.2,0.9-0.3,1.4-0.3c0.9,0,1.6,0.2,2,0.7c0.5,0.5,0.7,1.2,0.7,2.1V118z"/>
            </g>
            <g class="st4">
              <path class="st5" d="M735.1,191.4h4v5.2c-0.6,0.2-1.2,0.4-1.8,0.4c-0.6,0.1-1.1,0.1-1.7,0.1c-1.5,0-2.7-0.4-3.5-1.3
                c-0.8-0.9-1.2-2.2-1.2-3.8s0.5-2.9,1.4-3.8c0.9-0.9,2.2-1.4,3.8-1.4c1,0,2,0.2,3,0.6l-0.7,1.7c-0.7-0.4-1.5-0.5-2.3-0.5
                c-0.9,0-1.6,0.3-2.2,0.9S733,191,733,192c0,1.1,0.2,1.9,0.7,2.5c0.4,0.6,1.1,0.9,1.9,0.9c0.4,0,0.9,0,1.3-0.1v-2.1H735
                L735.1,191.4L735.1,191.4z"/>
              <path class="st5" d="M744.7,197.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9c0-1.3,0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1
                c1.1,0,1.9,0.3,2.5,0.9s0.9,1.5,0.9,2.5v1H743c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                c-0.4,0.2-0.8,0.3-1.2,0.4C745.8,197.1,745.3,197.1,744.7,197.1z M744.4,190.7c-0.4,0-0.8,0.1-1,0.4c-0.2,0.3-0.4,0.7-0.4,1.2
                h2.9c0-0.5-0.1-0.9-0.4-1.2C745.2,190.8,744.8,190.7,744.4,190.7z"/>
              <path class="st5" d="M755.8,197l-0.6-2.7l-0.8-3.4l0,0l-1.4,6h-2.2l-2.2-7.6h2.1l0.9,3.4c0.1,0.6,0.3,1.4,0.4,2.5l0,0
                c0-0.3,0.1-0.9,0.2-1.6l0.1-0.6l0.9-3.7h2.3l0.9,3.7c0,0.1,0,0.2,0.1,0.4c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.1,0.6
                c0,0.2,0,0.4,0.1,0.5l0,0c0-0.3,0.1-0.8,0.2-1.3c0.1-0.6,0.2-1,0.2-1.2l0.9-3.4h2l-2.2,7.6h-2.1V197z"/>
              <path class="st5" d="M764.9,197.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9c0-1.3,0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1
                c1.1,0,1.9,0.3,2.5,0.9s0.9,1.5,0.9,2.5v1h-4.9c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                c-0.4,0.2-0.8,0.3-1.2,0.4C766,197.1,765.5,197.1,764.9,197.1z M764.7,190.7c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.7-0.4,1.2h2.9
                c0-0.5-0.1-0.9-0.4-1.2C765.4,190.8,765.1,190.7,764.7,190.7z"/>
              <path class="st5" d="M774,189.2c0.9,0,1.6,0.4,2.1,1.1c0.5,0.7,0.8,1.7,0.8,2.9c0,1.3-0.3,2.2-0.8,2.9s-1.2,1-2.2,1
                c-0.9,0-1.6-0.3-2.1-1h-0.1l-0.3,0.8h-1.6v-10.6h2.1v2.5c0,0.3,0,0.8-0.1,1.5h0.1C772.3,189.6,773.1,189.2,774,189.2z
                 M773.3,190.9c-0.5,0-0.9,0.2-1.1,0.5c-0.2,0.3-0.4,0.8-0.4,1.6v0.2c0,0.8,0.1,1.4,0.4,1.8c0.2,0.4,0.6,0.5,1.2,0.5
                c0.4,0,0.8-0.2,1-0.6c0.3-0.4,0.4-1,0.4-1.7s-0.1-1.3-0.4-1.7S773.8,190.9,773.3,190.9z"/>
              <path class="st5" d="M782.1,197.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9c0-1.3,0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1
                c1.1,0,1.9,0.3,2.5,0.9s0.9,1.5,0.9,2.5v1h-4.9c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                c-0.4,0.2-0.8,0.3-1.2,0.4C783.2,197.1,782.7,197.1,782.1,197.1z M781.8,190.7c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.7-0.4,1.2
                h2.9c0-0.5-0.1-0.9-0.4-1.2C782.6,190.8,782.2,190.7,781.8,190.7z"/>
              <path class="st5" d="M791.1,189.2c0.9,0,1.6,0.4,2.1,1.1c0.5,0.7,0.8,1.7,0.8,2.9c0,1.3-0.3,2.2-0.8,2.9s-1.2,1-2.2,1
                c-0.9,0-1.6-0.3-2.1-1h-0.1l-0.3,0.8h-1.6v-10.6h2.1v2.5c0,0.3,0,0.8-0.1,1.5h0.1C789.5,189.6,790.2,189.2,791.1,189.2z
                 M790.5,190.9c-0.5,0-0.9,0.2-1.1,0.5c-0.2,0.3-0.4,0.8-0.4,1.6v0.2c0,0.8,0.1,1.4,0.4,1.8c0.2,0.4,0.6,0.5,1.2,0.5
                c0.4,0,0.8-0.2,1-0.6c0.3-0.4,0.4-1,0.4-1.7s-0.1-1.3-0.4-1.7S790.9,190.9,790.5,190.9z"/>
              <path class="st5" d="M799.2,197.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9c0-1.3,0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1
                c1.1,0,1.9,0.3,2.5,0.9s0.9,1.5,0.9,2.5v1h-4.9c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                c-0.4,0.2-0.8,0.3-1.2,0.4C800.3,197.1,799.8,197.1,799.2,197.1z M798.9,190.7c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.7-0.4,1.2
                h2.9c0-0.5-0.1-0.9-0.4-1.2C799.7,190.8,799.4,190.7,798.9,190.7z"/>
              <path class="st5" d="M807.2,195.5c0.4,0,0.8-0.1,1.3-0.2v1.6c-0.5,0.2-1.2,0.3-1.9,0.3c-0.8,0-1.4-0.2-1.8-0.6
                c-0.4-0.4-0.6-1.1-0.6-1.9V191h-1v-1l1.1-0.7l0.6-1.6h1.3v1.6h2.1v1.6h-2.1v3.7c0,0.3,0.1,0.5,0.2,0.7
                C806.7,195.4,807,195.5,807.2,195.5z"/>
              <path class="st5" d="M813.6,197.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9c0-1.3,0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1
                c1.1,0,1.9,0.3,2.5,0.9s0.9,1.5,0.9,2.5v1h-4.9c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                c-0.4,0.2-0.8,0.3-1.2,0.4C814.7,197.1,814.2,197.1,813.6,197.1z M813.3,190.7c-0.4,0-0.8,0.1-1,0.4c-0.2,0.3-0.4,0.7-0.4,1.2
                h2.9c0-0.5-0.1-0.9-0.4-1.2C814.1,190.8,813.7,190.7,813.3,190.7z"/>
              <path class="st5" d="M818.3,187.4c0-0.7,0.4-1,1.1-1s1.1,0.3,1.1,1c0,0.3-0.1,0.6-0.3,0.8s-0.5,0.3-0.9,0.3
                C818.7,188.4,818.3,188.1,818.3,187.4z M820.5,197h-2.1v-7.6h2.1V197z"/>
              <path class="st5" d="M824.7,197h-2.1v-10.6h2.1V197z"/>
              <path class="st5" d="M826.8,187.4c0-0.7,0.4-1,1.1-1s1.1,0.3,1.1,1c0,0.3-0.1,0.6-0.3,0.8s-0.5,0.3-0.9,0.3
                C827.2,188.4,826.8,188.1,826.8,187.4z M829,197h-2.1v-7.6h2.1V197z"/>
              <path class="st5" d="M837.8,189.4v1.1l-1.2,0.3c0.2,0.3,0.3,0.7,0.3,1.1c0,0.8-0.3,1.5-0.9,1.9c-0.6,0.5-1.4,0.7-2.4,0.7h-0.4
                h-0.3c-0.2,0.2-0.3,0.3-0.3,0.5c0,0.3,0.4,0.5,1.1,0.5h1.3c0.8,0,1.5,0.2,1.9,0.5c0.4,0.4,0.7,0.9,0.7,1.6
                c0,0.9-0.4,1.6-1.1,2.1c-0.7,0.5-1.8,0.7-3.2,0.7c-1.1,0-1.9-0.2-2.4-0.6c-0.6-0.4-0.8-0.9-0.8-1.6c0-0.5,0.1-0.8,0.4-1.2
                s0.7-0.5,1.3-0.7c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.2-0.4-0.2-0.7s0.1-0.5,0.3-0.7s0.4-0.4,0.7-0.6c-0.4-0.2-0.7-0.5-1-0.8
                c-0.2-0.4-0.4-0.8-0.4-1.4c0-0.8,0.3-1.5,0.8-1.9s1.3-0.7,2.3-0.7c0.2,0,0.5,0,0.8,0.1c0.3,0,0.5,0.1,0.6,0.1L837.8,189.4
                L837.8,189.4z M831.9,198.1c0,0.3,0.1,0.5,0.4,0.7c0.3,0.2,0.7,0.2,1.2,0.2c0.7,0,1.3-0.1,1.8-0.3s0.6-0.5,0.6-0.8
                s-0.1-0.5-0.4-0.6s-0.6-0.2-1.2-0.2h-1.1c-0.4,0-0.7,0.1-1,0.3C832.1,197.5,831.9,197.8,831.9,198.1z M832.7,191.9
                c0,0.4,0.1,0.7,0.3,1c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.4s0.3-0.6,0.3-1c0-0.9-0.4-1.4-1.1-1.4
                C833.1,190.5,832.7,190.9,832.7,191.9z"/>
              <path class="st5" d="M844.5,197l-0.3-1h-0.1c-0.2,0.4-0.5,0.6-1,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.9,0-1.6-0.2-2-0.7
                c-0.5-0.5-0.7-1.2-0.7-2.1v-5h2.1v4.5c0,0.6,0.1,1,0.3,1.2c0.2,0.3,0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.6c0.3-0.4,0.4-1,0.4-1.9
                v-3.6h2.1v7.6h-1.6V197z"/>
              <path class="st5" d="M855.4,197h-2.1v-4.5c0-0.6-0.1-1-0.3-1.2c-0.2-0.3-0.5-0.4-0.9-0.4c-0.6,0-1,0.2-1.3,0.6
                c-0.3,0.4-0.4,1-0.4,1.9v3.6h-2.1v-7.6h1.6l0.3,1h0.1c0.2-0.4,0.6-0.6,1-0.8s0.9-0.3,1.4-0.3c0.9,0,1.6,0.2,2,0.7
                c0.5,0.5,0.7,1.2,0.7,2.1V197z"/>
              <path class="st5" d="M864.1,189.4v1.1l-1.2,0.3c0.2,0.3,0.3,0.7,0.3,1.1c0,0.8-0.3,1.5-0.9,1.9c-0.6,0.5-1.4,0.7-2.4,0.7h-0.4
                h-0.3c-0.2,0.2-0.3,0.3-0.3,0.5c0,0.3,0.4,0.5,1.1,0.5h1.3c0.8,0,1.5,0.2,1.9,0.5c0.4,0.4,0.7,0.9,0.7,1.6
                c0,0.9-0.4,1.6-1.1,2.1c-0.7,0.5-1.8,0.7-3.2,0.7c-1.1,0-1.9-0.2-2.4-0.6c-0.6-0.4-0.8-0.9-0.8-1.6c0-0.5,0.1-0.8,0.4-1.2
                s0.7-0.5,1.3-0.7c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.2-0.4-0.2-0.7s0.1-0.5,0.3-0.7s0.4-0.4,0.7-0.6c-0.4-0.2-0.7-0.5-1-0.8
                c-0.2-0.4-0.4-0.8-0.4-1.4c0-0.8,0.3-1.5,0.8-1.9s1.3-0.7,2.3-0.7c0.2,0,0.5,0,0.8,0.1c0.3,0,0.5,0.1,0.6,0.1L864.1,189.4
                L864.1,189.4z M858.2,198.1c0,0.3,0.1,0.5,0.4,0.7c0.3,0.2,0.7,0.2,1.2,0.2c0.7,0,1.3-0.1,1.8-0.3s0.6-0.5,0.6-0.8
                s-0.1-0.5-0.4-0.6s-0.6-0.2-1.2-0.2h-1.1c-0.4,0-0.7,0.1-1,0.3C858.4,197.5,858.2,197.8,858.2,198.1z M859,191.9
                c0,0.4,0.1,0.7,0.3,1c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.4s0.3-0.6,0.3-1c0-0.9-0.4-1.4-1.1-1.4
                C859.4,190.5,859,190.9,859,191.9z"/>
            </g>
            <g class="st4">
              <path class="st5" d="M691.8,264h-7.4v-1.4l4.8-6.9h-4.7V254h7.2v1.4l-4.8,6.9h4.9V264z"/>
              <path class="st5" d="M696.7,264.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9s0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1c1.1,0,1.9,0.3,2.5,0.9
                s0.9,1.5,0.9,2.5v1H695c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                c-0.4,0.2-0.8,0.3-1.2,0.4C697.8,264.1,697.3,264.1,696.7,264.1z M696.4,257.7c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.7-0.4,1.2
                h2.9c0-0.5-0.1-0.9-0.4-1.2C697.2,257.8,696.8,257.7,696.4,257.7z"/>
              <path class="st5" d="M703.6,264h-2.1v-10.6h2.1V264z"/>
              <path class="st5" d="M707.8,264h-2.1v-10.6h2.1V264z"/>
              <path class="st5" d="M715.4,264l-0.3-1H715c-0.2,0.4-0.5,0.6-1,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.9,0-1.6-0.2-2-0.7
                c-0.5-0.5-0.7-1.2-0.7-2.1v-5h2.1v4.5c0,0.6,0.1,1,0.3,1.2c0.2,0.3,0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.6s0.4-1,0.4-1.9v-3.6h2.1
                v7.6h-1.6V264z"/>
              <path class="st5" d="M721.3,264h-2.1v-10.6h2.1V264z"/>
              <path class="st5" d="M728.3,264l-0.4-1h-0.1c-0.4,0.4-0.7,0.7-1.1,0.9c-0.4,0.2-0.9,0.3-1.5,0.3c-0.7,0-1.3-0.2-1.7-0.6
                s-0.6-1-0.6-1.8s0.3-1.4,0.9-1.8c0.6-0.4,1.4-0.6,2.6-0.6h1.3v-0.3c0-0.8-0.4-1.2-1.2-1.2c-0.6,0-1.3,0.2-2.2,0.6l-0.7-1.4
                c0.9-0.5,1.9-0.7,2.9-0.7s1.8,0.2,2.4,0.7s0.8,1.1,0.8,2v5.1h-1.4V264z M724.3,254.4c0-0.3,0.1-0.5,0.3-0.7s0.4-0.2,0.7-0.2
                s0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7s-0.4,0.2-0.7,0.2s-0.5-0.1-0.7-0.2C724.4,254.9,724.3,254.7,724.3,254.4z
                 M727.7,260.5h-0.8c-0.6,0-1.1,0.1-1.4,0.3s-0.4,0.5-0.4,0.9c0,0.6,0.3,0.9,1,0.9c0.5,0,0.9-0.1,1.2-0.4s0.4-0.6,0.4-1.1V260.5z
                 M727.1,254.4c0-0.3,0.1-0.6,0.3-0.7c0.2-0.1,0.4-0.2,0.7-0.2s0.5,0.1,0.7,0.2c0.2,0.2,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7
                s-0.4,0.2-0.7,0.2s-0.5-0.1-0.7-0.2C727.2,254.9,727.1,254.7,727.1,254.4z"/>
              <path class="st5" d="M736.2,256.2c0.3,0,0.5,0,0.7,0.1l-0.2,2c-0.2,0-0.4-0.1-0.6-0.1c-0.7,0-1.2,0.2-1.6,0.5
                c-0.4,0.3-0.6,0.8-0.6,1.4v3.9h-2.1v-7.6h1.6l0.3,1.3h0.1c0.2-0.4,0.6-0.8,1-1C735.3,256.3,735.7,256.2,736.2,256.2z"/>
              <path class="st5" d="M741.8,264.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9s0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1c1.1,0,1.9,0.3,2.5,0.9
                s0.9,1.5,0.9,2.5v1h-5c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                c-0.4,0.2-0.8,0.3-1.2,0.4C742.9,264.1,742.4,264.1,741.8,264.1z M741.5,257.7c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.7-0.4,1.2
                h2.9c0-0.5-0.1-0.9-0.4-1.2C742.3,257.8,741.9,257.7,741.5,257.7z"/>
              <path class="st5" d="M754.2,258.4h4v5.2c-0.6,0.2-1.2,0.4-1.8,0.4c-0.6,0.1-1.1,0.1-1.7,0.1c-1.5,0-2.7-0.4-3.5-1.3
                s-1.2-2.2-1.2-3.8c0-1.6,0.5-2.9,1.4-3.8c0.9-0.9,2.2-1.4,3.8-1.4c1,0,2,0.2,3,0.6l-0.7,1.7c-0.7-0.4-1.5-0.5-2.3-0.5
                c-0.9,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.8,1.4-0.8,2.5s0.2,1.9,0.7,2.5c0.4,0.6,1.1,0.9,1.9,0.9c0.4,0,0.9,0,1.3-0.1v-2.1h-1.9
                V258.4z"/>
              <path class="st5" d="M764.6,256.2c0.9,0,1.6,0.4,2.1,1.1s0.8,1.7,0.8,2.9c0,1.3-0.3,2.2-0.8,2.9c-0.5,0.7-1.2,1-2.1,1
                c-0.9,0-1.6-0.3-2.1-1h-0.1l-0.3,0.8h-1.6v-10.6h2.1v2.5c0,0.3,0,0.8-0.1,1.5h0.1C762.9,256.6,763.6,256.2,764.6,256.2z
                 M763.9,257.9c-0.5,0-0.9,0.2-1.1,0.5c-0.2,0.3-0.4,0.8-0.4,1.6v0.2c0,0.8,0.1,1.4,0.4,1.8c0.2,0.4,0.6,0.5,1.2,0.5
                c0.4,0,0.8-0.2,1-0.6c0.3-0.4,0.4-1,0.4-1.7s-0.1-1.3-0.4-1.7C764.7,258.1,764.4,257.9,763.9,257.9z"/>
              <path class="st5" d="M775.3,256.2c0,0.6-0.2,1.2-0.6,1.6s-0.9,0.7-1.6,0.9l0,0c0.8,0.1,1.4,0.3,1.8,0.7c0.4,0.4,0.6,0.9,0.6,1.6
                c0,1-0.3,1.7-1,2.2s-1.7,0.8-3,0.8c-1.1,0-2-0.2-2.9-0.5v-1.8c0.4,0.2,0.8,0.4,1.3,0.5s0.9,0.2,1.4,0.2c0.7,0,1.2-0.1,1.5-0.4
                s0.5-0.6,0.5-1.1s-0.2-0.8-0.6-1s-1-0.3-1.8-0.3h-0.8V258h0.8c0.8,0,1.3-0.1,1.7-0.3c0.4-0.2,0.5-0.5,0.5-1
                c0-0.8-0.5-1.1-1.4-1.1c-0.3,0-0.7,0.1-1,0.2c-0.3,0.1-0.7,0.3-1.1,0.6l-1-1.5c0.9-0.7,2-1,3.3-1c1,0,1.9,0.2,2.5,0.6
                C775,254.9,775.3,255.5,775.3,256.2z"/>
              <path class="st5" d="M776.5,261.1v-1.7h3.7v1.7H776.5z"/>
              <path class="st5" d="M788,264l-0.7-2.4h-3.6L783,264h-2.3l3.5-10h2.6l3.5,10H788z M786.8,259.8c-0.7-2.2-1-3.4-1.1-3.7
                c-0.1-0.3-0.1-0.5-0.2-0.7c-0.2,0.6-0.6,2-1.3,4.3h2.6V259.8z"/>
              <path class="st5" d="M795.6,256.2c0.9,0,1.6,0.4,2.1,1.1s0.8,1.7,0.8,2.9c0,1.3-0.3,2.2-0.8,2.9c-0.5,0.7-1.2,1-2.2,1
                c-0.9,0-1.6-0.3-2.1-1h-0.1L793,264h-1.6v-10.6h2.1v2.5c0,0.3,0,0.8-0.1,1.5h0.1C793.9,256.6,794.7,256.2,795.6,256.2z
                 M794.9,257.9c-0.5,0-0.9,0.2-1.1,0.5c-0.2,0.3-0.4,0.8-0.4,1.6v0.2c0,0.8,0.1,1.4,0.4,1.8c0.2,0.4,0.6,0.5,1.2,0.5
                c0.4,0,0.8-0.2,1-0.6c0.3-0.4,0.4-1,0.4-1.7s-0.1-1.3-0.4-1.7C795.7,258.1,795.4,257.9,794.9,257.9z"/>
              <path class="st5" d="M802.3,264h-2.1v-10.6h2.1V264z"/>
              <path class="st5" d="M809.4,264l-0.4-1h-0.1c-0.4,0.4-0.7,0.7-1.1,0.9c-0.4,0.2-0.9,0.3-1.5,0.3c-0.7,0-1.3-0.2-1.7-0.6
                s-0.6-1-0.6-1.8s0.3-1.4,0.9-1.8c0.6-0.4,1.4-0.6,2.6-0.6h1.3v-0.3c0-0.8-0.4-1.2-1.2-1.2c-0.6,0-1.3,0.2-2.2,0.6l-0.7-1.4
                c0.9-0.5,1.9-0.7,2.9-0.7s1.8,0.2,2.4,0.7s0.8,1.1,0.8,2v5.1h-1.4V264z M808.7,260.5h-0.8c-0.6,0-1.1,0.1-1.4,0.3
                s-0.4,0.5-0.4,0.9c0,0.6,0.3,0.9,1,0.9c0.5,0,0.9-0.1,1.2-0.4s0.4-0.6,0.4-1.1V260.5z"/>
              <path class="st5" d="M819.6,256.4v1.1l-1.2,0.3c0.2,0.3,0.3,0.7,0.3,1.1c0,0.8-0.3,1.5-0.9,1.9c-0.6,0.5-1.4,0.7-2.4,0.7H815
                h-0.3c-0.2,0.2-0.3,0.3-0.3,0.5c0,0.3,0.4,0.5,1.1,0.5h1.3c0.8,0,1.5,0.2,1.9,0.5c0.4,0.4,0.7,0.9,0.7,1.6
                c0,0.9-0.4,1.6-1.1,2.1c-0.7,0.5-1.8,0.7-3.2,0.7c-1.1,0-1.9-0.2-2.4-0.6c-0.6-0.4-0.8-0.9-0.8-1.6c0-0.5,0.1-0.8,0.4-1.2
                s0.7-0.5,1.3-0.7c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.3,0.1-0.5,0.3-0.7s0.4-0.4,0.7-0.6
                c-0.4-0.2-0.7-0.5-1-0.8c-0.2-0.4-0.4-0.8-0.4-1.4c0-0.8,0.3-1.5,0.8-1.9s1.3-0.7,2.3-0.7c0.2,0,0.5,0,0.8,0.1
                c0.3,0,0.5,0.1,0.6,0.1h2.7V256.4z M813.7,265.1c0,0.3,0.1,0.5,0.4,0.7c0.3,0.2,0.7,0.2,1.2,0.2c0.7,0,1.3-0.1,1.8-0.3
                s0.6-0.5,0.6-0.8s-0.1-0.5-0.4-0.6s-0.6-0.2-1.2-0.2H815c-0.4,0-0.7,0.1-1,0.3C813.8,264.5,813.7,264.8,813.7,265.1z
                 M814.5,258.9c0,0.4,0.1,0.7,0.3,1c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.4s0.3-0.6,0.3-1c0-0.9-0.4-1.4-1.1-1.4
                C814.8,257.5,814.5,257.9,814.5,258.9z"/>
              <path class="st5" d="M824.3,264.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9s0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1c1.1,0,1.9,0.3,2.5,0.9
                s0.9,1.5,0.9,2.5v1h-4.9c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                c-0.4,0.2-0.8,0.3-1.2,0.4C825.4,264.1,824.9,264.1,824.3,264.1z M824,257.7c-0.4,0-0.8,0.1-1,0.4s-0.4,0.7-0.4,1.2h2.9
                c0-0.5-0.1-0.9-0.4-1.2C824.8,257.8,824.5,257.7,824,257.7z"/>
              <path class="st5" d="M833.4,256.2c0.3,0,0.5,0,0.7,0.1l-0.2,2c-0.2,0-0.4-0.1-0.6-0.1c-0.7,0-1.2,0.2-1.6,0.5
                c-0.4,0.3-0.6,0.8-0.6,1.4v3.9H829v-7.6h1.6l0.3,1.3h0.1c0.2-0.4,0.6-0.8,1-1C832.5,256.3,832.9,256.2,833.4,256.2z"/>
              <path class="st5" d="M840.9,264l-0.3-1h-0.1c-0.2,0.4-0.5,0.6-1,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.9,0-1.6-0.2-2-0.7
                c-0.5-0.5-0.7-1.2-0.7-2.1v-5h2.1v4.5c0,0.6,0.1,1,0.3,1.2c0.2,0.3,0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.6s0.4-1,0.4-1.9v-3.6h2.1
                v7.6h-1.6V264z"/>
              <path class="st5" d="M851.7,264h-2.1v-4.5c0-0.6-0.1-1-0.3-1.2c-0.2-0.3-0.5-0.4-0.9-0.4c-0.6,0-1,0.2-1.3,0.6s-0.4,1-0.4,1.9
                v3.6h-2.1v-7.6h1.6l0.3,1h0.1c0.2-0.4,0.6-0.6,1-0.8c0.4-0.2,0.9-0.3,1.4-0.3c0.9,0,1.6,0.2,2,0.7c0.5,0.5,0.7,1.2,0.7,2.1V264z
                "/>
              <path class="st5" d="M860.5,256.4v1.1l-1.2,0.3c0.2,0.3,0.3,0.7,0.3,1.1c0,0.8-0.3,1.5-0.9,1.9c-0.6,0.5-1.4,0.7-2.4,0.7h-0.4
                h-0.3c-0.2,0.2-0.3,0.3-0.3,0.5c0,0.3,0.4,0.5,1.1,0.5h1.3c0.8,0,1.5,0.2,1.9,0.5c0.4,0.4,0.7,0.9,0.7,1.6
                c0,0.9-0.4,1.6-1.1,2.1c-0.7,0.5-1.8,0.7-3.2,0.7c-1.1,0-1.9-0.2-2.4-0.6c-0.6-0.4-0.8-0.9-0.8-1.6c0-0.5,0.1-0.8,0.4-1.2
                s0.7-0.5,1.3-0.7c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.3,0.1-0.5,0.3-0.7s0.4-0.4,0.7-0.6
                c-0.4-0.2-0.7-0.5-1-0.8c-0.2-0.4-0.4-0.8-0.4-1.4c0-0.8,0.3-1.5,0.8-1.9s1.3-0.7,2.3-0.7c0.2,0,0.5,0,0.8,0.1
                c0.3,0,0.5,0.1,0.6,0.1h2.7V256.4z M854.6,265.1c0,0.3,0.1,0.5,0.4,0.7c0.3,0.2,0.7,0.2,1.2,0.2c0.7,0,1.3-0.1,1.8-0.3
                s0.6-0.5,0.6-0.8s-0.1-0.5-0.4-0.6s-0.6-0.2-1.2-0.2h-1c-0.4,0-0.7,0.1-1,0.3C854.8,264.5,854.6,264.8,854.6,265.1z
                 M855.4,258.9c0,0.4,0.1,0.7,0.3,1c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.4s0.3-0.6,0.3-1c0-0.9-0.4-1.4-1.1-1.4
                C855.8,257.5,855.4,257.9,855.4,258.9z"/>
            </g>
            <g class="st4">
              <path d="M730.9,310.3c0,0.7-0.2,1.3-0.6,1.8c-0.4,0.5-1,0.8-1.7,0.9v0.1c0.9,0.1,1.6,0.4,2,0.9s0.7,1.1,0.7,1.8
                c0,1.1-0.4,1.9-1.1,2.5c-0.8,0.6-1.8,0.9-3.2,0.9c-0.6,0-1.2,0-1.7-0.1s-1-0.3-1.5-0.5v-1.2c0.5,0.2,1,0.4,1.6,0.6
                c0.6,0.1,1.1,0.2,1.6,0.2c2,0,3-0.8,3-2.3c0-1.4-1.1-2.1-3.3-2.1h-1.1v-1.1h1.1c0.9,0,1.6-0.2,2.1-0.6s0.8-0.9,0.8-1.6
                c0-0.6-0.2-1-0.6-1.3s-0.9-0.5-1.6-0.5c-0.5,0-1,0.1-1.4,0.2s-0.9,0.4-1.5,0.8l-0.7-0.9c0.5-0.4,1-0.7,1.6-0.9
                c0.6-0.2,1.3-0.3,1.9-0.3c1.1,0,2,0.3,2.6,0.8C730.6,308.7,730.9,309.4,730.9,310.3z"/>
              <path d="M733.3,318.2c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.8
                c0,0.3-0.1,0.6-0.3,0.8s-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.2C733.4,318.8,733.3,318.5,733.3,318.2z"/>
              <path d="M741.2,315.3v-1.2h3.8v1.2H741.2z"/>
              <path d="M758.7,316.4H757v2.6h-1.2v-2.6h-5.6v-1.1l5.4-7.7h1.4v7.7h1.7L758.7,316.4L758.7,316.4z M755.8,315.2v-3.8
                c0-0.7,0-1.6,0.1-2.5h-0.1c-0.2,0.5-0.5,0.9-0.7,1.2l-3.6,5.1L755.8,315.2L755.8,315.2z"/>
              <path d="M760.2,318.2c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.8
                c0,0.3-0.1,0.6-0.3,0.8s-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.2C760.3,318.8,760.2,318.5,760.2,318.2z"/>
              <path d="M769,319v-11.4h1.3v10.2h5v1.2L769,319L769,319z"/>
              <path d="M780.7,319.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2
                c1.1,0,1.9,0.4,2.5,1.1s0.9,1.6,0.9,2.8v0.8H778c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2
                c-0.5,0.2-0.9,0.3-1.3,0.4C781.8,319.1,781.3,319.2,780.7,319.2z M780.4,311.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9
                h4.5c0-0.8-0.2-1.4-0.5-1.9C781.6,311.6,781.1,311.4,780.4,311.4z"/>
              <path d="M790.1,310.3c1.1,0,2,0.4,2.6,1.2c0.6,0.8,0.9,1.9,0.9,3.3s-0.3,2.5-0.9,3.3c-0.6,0.8-1.5,1.2-2.6,1.2
                c-0.6,0-1.1-0.1-1.5-0.3s-0.8-0.5-1.2-0.9h-0.1L787,319h-0.9v-12.2h1.3v3c0,0.7,0,1.3-0.1,1.8h0.1
                C788,310.7,788.9,310.3,790.1,310.3z M789.9,311.4c-0.9,0-1.5,0.3-1.9,0.8s-0.6,1.4-0.6,2.6s0.2,2.1,0.6,2.6
                c0.4,0.5,1,0.8,1.9,0.8c0.8,0,1.4-0.3,1.8-0.9s0.6-1.4,0.6-2.5s-0.2-1.9-0.6-2.5S790.7,311.4,789.9,311.4z"/>
              <path d="M799.5,319.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2
                c1.1,0,1.9,0.4,2.5,1.1s0.9,1.6,0.9,2.8v0.8h-5.9c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2
                c-0.5,0.2-0.9,0.3-1.3,0.4C800.6,319.1,800.1,319.2,799.5,319.2z M799.2,311.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9
                h4.5c0-0.8-0.2-1.4-0.5-1.9C800.4,311.6,799.8,311.4,799.2,311.4z"/>
              <path d="M810.7,319v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7s-0.6,1.3-0.6,2.4v4.5h-1.3v-8.6
                h1.1l0.2,1.2h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7s0.8,1.3,0.8,2.4v5.6L810.7,319L810.7,319z"
                />
              <path d="M820.2,316.7c0,0.8-0.3,1.4-0.9,1.8s-1.4,0.6-2.5,0.6s-2-0.2-2.7-0.5v-1.2c0.4,0.2,0.9,0.4,1.3,0.5
                c0.5,0.1,0.9,0.2,1.4,0.2c0.7,0,1.2-0.1,1.6-0.3c0.4-0.2,0.5-0.5,0.5-1c0-0.3-0.1-0.6-0.4-0.9s-0.9-0.5-1.7-0.8
                s-1.4-0.6-1.7-0.8s-0.6-0.5-0.8-0.8s-0.2-0.6-0.2-1c0-0.7,0.3-1.2,0.9-1.7c0.6-0.4,1.3-0.6,2.3-0.6c0.9,0,1.8,0.2,2.7,0.6
                l-0.5,1.1c-0.9-0.4-1.6-0.5-2.3-0.5c-0.6,0-1.1,0.1-1.4,0.3s-0.5,0.5-0.5,0.8c0,0.2,0.1,0.4,0.2,0.6s0.3,0.3,0.6,0.5
                c0.3,0.1,0.8,0.4,1.5,0.6c1,0.4,1.7,0.7,2.1,1.1C820,315.6,820.2,316.1,820.2,316.7z"/>
              <path d="M828.1,317.9L828.1,317.9c-0.7,0.9-1.6,1.3-2.8,1.3c-1.1,0-2-0.4-2.6-1.1c-0.6-0.8-0.9-1.9-0.9-3.3s0.3-2.5,0.9-3.3
                s1.5-1.2,2.6-1.2c1.2,0,2.1,0.4,2.7,1.3h0.1L828,311v-0.6v-3.5h1.3V319h-1.1L828.1,317.9z M825.6,318.1c0.9,0,1.5-0.2,1.9-0.7
                s0.6-1.3,0.6-2.3v-0.3c0-1.2-0.2-2.1-0.6-2.6c-0.4-0.5-1-0.8-1.9-0.8c-0.8,0-1.3,0.3-1.7,0.9s-0.6,1.4-0.6,2.5s0.2,1.9,0.6,2.5
                C824.2,317.8,824.8,318.1,825.6,318.1z"/>
              <path d="M835.7,319.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2
                c1.1,0,1.9,0.4,2.5,1.1s0.9,1.6,0.9,2.8v0.8H833c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2
                c-0.5,0.2-0.9,0.3-1.3,0.4C836.8,319.1,836.3,319.2,835.7,319.2z M835.4,311.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9
                h4.5c0-0.8-0.2-1.4-0.5-1.9C836.6,311.6,836.1,311.4,835.4,311.4z"/>
              <path d="M842.4,314.6c0.2-0.3,0.6-0.7,1-1.2l2.8-2.9h1.5l-3.5,3.6l3.7,4.9h-1.6l-3-4l-1,0.8v3.2H841v-12.2h1.3v6.4
                C842.4,313.6,842.4,314,842.4,314.6L842.4,314.6z"/>
              <path d="M854.8,319l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7
                c-0.5-0.4-0.7-1.1-0.7-1.9c0-1.7,1.4-2.6,4.1-2.7h1.5v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7
                l-0.4-1c0.4-0.2,0.9-0.4,1.4-0.5s1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2v5.8L854.8,319L854.8,319z M851.8,318.1
                c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1
                C850.9,318,851.3,318.1,851.8,318.1z"/>
              <path d="M864.2,317.9L864.2,317.9c-0.7,0.9-1.6,1.3-2.8,1.3c-1.1,0-2-0.4-2.6-1.1c-0.6-0.8-0.9-1.9-0.9-3.3s0.3-2.5,0.9-3.3
                s1.5-1.2,2.6-1.2c1.2,0,2.1,0.4,2.7,1.3h0.1l-0.1-0.6v-0.6v-3.5h1.3V319h-1.1L864.2,317.9z M861.6,318.1c0.9,0,1.5-0.2,1.9-0.7
                s0.6-1.3,0.6-2.3v-0.3c0-1.2-0.2-2.1-0.6-2.6c-0.4-0.5-1-0.8-1.9-0.8c-0.8,0-1.3,0.3-1.7,0.9s-0.6,1.4-0.6,2.5s0.2,1.9,0.6,2.5
                C860.3,317.8,860.9,318.1,861.6,318.1z"/>
              <path d="M871.8,319.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2
                c1.1,0,1.9,0.4,2.5,1.1s0.9,1.6,0.9,2.8v0.8h-5.9c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2
                c-0.5,0.2-0.9,0.3-1.3,0.4C872.9,319.1,872.4,319.2,871.8,319.2z M871.5,311.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9
                h4.5c0-0.8-0.2-1.4-0.5-1.9C872.7,311.6,872.1,311.4,871.5,311.4z"/>
            </g>
            <g class="st4">
              <path d="M17.5,191v1.6l-5.5,4.2l1.1,1.2h4.5v1.3H6.1V198h5.7l-5.7-5.2v-1.6l5,4.6L17.5,191z"/>
              <path d="M8.8,185.7c0-0.4,0-0.7,0.1-1l1.2,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0.7,0.3,1.3,0.8,1.8s1.3,0.7,2.1,0.7h4.6v1.3H8.9
                v-1.1l1.6-0.1v-0.1c-0.6-0.3-1-0.7-1.3-1.1S8.8,186.2,8.8,185.7z"/>
              <path d="M17.5,177.8l-1.2,0.3v0.1c0.5,0.4,0.9,0.9,1.1,1.3c0.2,0.4,0.3,1,0.3,1.6c0,0.8-0.2,1.5-0.7,2s-1.1,0.7-1.9,0.7
                c-1.7,0-2.6-1.4-2.7-4.1v-1.5h-0.5c-0.7,0-1.2,0.1-1.5,0.4s-0.5,0.8-0.5,1.4c0,0.7,0.2,1.5,0.7,2.4l-1,0.4
                c-0.2-0.4-0.4-0.9-0.5-1.4s-0.2-1-0.2-1.5c0-1,0.2-1.8,0.7-2.3s1.2-0.7,2.2-0.7h5.8L17.5,177.8L17.5,177.8z M16.6,180.7
                c0-0.8-0.2-1.4-0.7-1.9s-1.1-0.7-1.9-0.7h-0.8l0.1,1.3c0,1,0.2,1.8,0.5,2.2s0.7,0.7,1.3,0.7c0.5,0,0.8-0.1,1.1-0.4
                S16.6,181.2,16.6,180.7z"/>
              <path d="M17.5,168.3H12c-0.7,0-1.2,0.2-1.6,0.5c-0.3,0.3-0.5,0.8-0.5,1.5c0,0.9,0.2,1.6,0.7,2s1.3,0.6,2.4,0.6h4.5v1.3H8.9v-1.1
                l1.2-0.2v-0.1c-0.4-0.3-0.7-0.6-1-1.1c-0.2-0.5-0.3-1-0.3-1.6c0-1,0.2-1.8,0.7-2.3s1.3-0.8,2.4-0.8h5.6V168.3z"/>
              <path d="M13.1,163.1c-0.3-0.2-0.7-0.6-1.2-1l-3-2.8v-1.5l3.6,3.5l4.9-3.7v1.6l-4,3l0.8,1h3.2v1.3H5.3v-1.3h6.4
                C12.1,163.1,12.5,163.1,13.1,163.1L13.1,163.1z"/>
              <path d="M17.5,150H12c-0.7,0-1.2,0.2-1.6,0.5c-0.3,0.3-0.5,0.8-0.5,1.5c0,0.9,0.2,1.6,0.7,2s1.3,0.6,2.4,0.6h4.5v1.3H5.3v-1.3H9
                c0.4,0,0.8,0,1.1,0.1v-0.1c-0.4-0.3-0.7-0.6-1-1.1c-0.2-0.5-0.4-1-0.4-1.6c0-1,0.2-1.8,0.7-2.4s1.3-0.8,2.4-0.8h5.6L17.5,150
                L17.5,150z"/>
              <path d="M17.7,142.4c0,1.3-0.4,2.3-1.2,3c-0.8,0.7-1.8,1.1-3.2,1.1s-2.5-0.3-3.3-1s-1.2-1.6-1.2-2.7c0-1.1,0.4-1.9,1.1-2.5
                c0.7-0.6,1.6-0.9,2.8-0.9h0.8v5.9c1,0,1.8-0.3,2.3-0.8s0.8-1.2,0.8-2c0-0.9-0.2-1.8-0.6-2.7h1.2c0.2,0.5,0.3,0.9,0.4,1.3
                C17.6,141.4,17.7,141.8,17.7,142.4z M9.9,142.8c0,0.7,0.2,1.2,0.7,1.6c0.4,0.4,1.1,0.6,1.9,0.7v-4.5c-0.8,0-1.4,0.2-1.9,0.5
                C10.1,141.6,9.9,142.1,9.9,142.8z"/>
              <path d="M6.6,137.2c-0.3,0-0.5-0.1-0.7-0.2c-0.1-0.1-0.2-0.3-0.2-0.5s0.1-0.4,0.2-0.5c0.1-0.2,0.4-0.2,0.6-0.2s0.5,0.1,0.7,0.2
                c0.1,0.2,0.2,0.3,0.2,0.5s-0.1,0.4-0.2,0.5C7.1,137.1,6.9,137.2,6.6,137.2z M17.5,135.8v1.3H8.9v-1.3H17.5z"/>
              <path d="M16.6,130.3c0-0.2,0-0.5-0.1-0.7c0-0.2-0.1-0.4-0.1-0.5h1c0.1,0.1,0.1,0.3,0.2,0.6c0.1,0.3,0.1,0.5,0.1,0.7
                c0,1.7-0.9,2.5-2.6,2.5H9.9v1.2H9.3l-0.5-1.2L7,132.4v-0.8h2v-2.5h1v2.5h5c0.5,0,0.9-0.1,1.2-0.4
                C16.4,131,16.6,130.7,16.6,130.3z"/>
              <path d="M15.2,121.8c0.8,0,1.4,0.3,1.8,0.9s0.6,1.4,0.6,2.5s-0.2,2-0.5,2.7h-1.2c0.2-0.4,0.4-0.9,0.5-1.3
                c0.1-0.5,0.2-0.9,0.2-1.4c0-0.7-0.1-1.2-0.3-1.6c-0.2-0.4-0.5-0.5-1-0.5c-0.3,0-0.6,0.1-0.9,0.4s-0.5,0.9-0.8,1.7
                c-0.3,0.8-0.6,1.4-0.8,1.7s-0.5,0.6-0.8,0.8s-0.6,0.2-1,0.2c-0.7,0-1.2-0.3-1.7-0.9c-0.4-0.6-0.6-1.3-0.6-2.3
                c0-0.9,0.2-1.8,0.6-2.7l1.1,0.5c-0.4,0.9-0.5,1.6-0.5,2.3c0,0.6,0.1,1.1,0.3,1.4s0.5,0.5,0.8,0.5c0.2,0,0.4-0.1,0.6-0.2
                s0.3-0.3,0.5-0.6c0.1-0.3,0.4-0.8,0.6-1.5c0.4-1,0.7-1.7,1.1-2.1C14.1,122,14.6,121.8,15.2,121.8z"/>
              <path d="M17.5,118.4v1.3H5.3v-1.3H17.5z"/>
              <path d="M17.5,110.4l-1.2,0.3v0.1c0.5,0.4,0.9,0.9,1.1,1.3s0.3,1,0.3,1.6c0,0.8-0.2,1.5-0.7,2c-0.4,0.5-1.1,0.7-1.9,0.7
                c-1.7,0-2.6-1.4-2.7-4.1v-1.5h-0.5c-0.7,0-1.2,0.1-1.5,0.4s-0.5,0.8-0.5,1.4c0,0.7,0.2,1.5,0.7,2.4l-1,0.4
                c-0.3-0.5-0.5-0.9-0.6-1.4s-0.2-1-0.2-1.5c0-1,0.2-1.8,0.7-2.3s1.2-0.7,2.2-0.7h5.8V110.4z M16.6,113.4c0-0.8-0.2-1.4-0.7-1.9
                s-1.1-0.7-1.9-0.7h-0.8l0.1,1.3c0,1,0.2,1.8,0.5,2.2c0.3,0.5,0.7,0.7,1.3,0.7c0.5,0,0.8-0.1,1.1-0.4
                C16.5,114.3,16.6,113.9,16.6,113.4z"/>
              <path d="M15.2,101.3c0.8,0,1.4,0.3,1.8,0.9s0.6,1.4,0.6,2.5s-0.2,2-0.5,2.7h-1.2c0.2-0.4,0.4-0.9,0.5-1.3
                c0.1-0.5,0.2-0.9,0.2-1.4c0-0.7-0.1-1.2-0.3-1.6c-0.2-0.4-0.5-0.5-1-0.5c-0.3,0-0.6,0.1-0.9,0.4s-0.5,0.9-0.8,1.7
                c-0.3,0.8-0.6,1.4-0.8,1.7s-0.5,0.6-0.8,0.8s-0.6,0.2-1,0.2c-0.7,0-1.2-0.3-1.7-0.9c-0.4-0.6-0.6-1.3-0.6-2.3
                c0-0.9,0.2-1.8,0.6-2.7l1.1,0.5c-0.4,0.9-0.5,1.6-0.5,2.3c0,0.6,0.1,1.1,0.3,1.4s0.5,0.5,0.8,0.5c0.2,0,0.4-0.1,0.6-0.2
                s0.3-0.3,0.5-0.6c0.1-0.3,0.4-0.8,0.6-1.5c0.4-1,0.7-1.7,1.1-2.1C14.1,101.4,14.6,101.3,15.2,101.3z"/>
              <path d="M16.6,96.4c0-0.2,0-0.5-0.1-0.7c0-0.2-0.1-0.4-0.1-0.5h1c0.1,0.1,0.1,0.3,0.2,0.6s0.1,0.5,0.1,0.7
                c0,1.7-0.9,2.5-2.6,2.5H9.9v1.2H9.3L8.8,99L7,98.5v-0.8h2v-2.5h1v2.5h5c0.5,0,0.9-0.1,1.2-0.4C16.4,97.2,16.6,96.8,16.6,96.4z"
                />
            </g>
            <path class="st6" d="M27,21L38.2,5L49,21"/>
            <path class="st6" d="M882,275l16,11.2L882,297"/>
            <g>
              <path d="M17.1,352.2l-1.4-3.6h-4.6l-1.4,3.6H8.3l4.5-11.5H14l4.5,11.5H17.1z M15.2,347.4l-1.3-3.5c-0.2-0.4-0.3-1-0.5-1.6
                c-0.1,0.5-0.3,1-0.5,1.6l-1.3,3.5H15.2z"/>
              <path d="M23.8,343.5c1.1,0,2,0.4,2.6,1.2c0.6,0.8,0.9,1.9,0.9,3.3s-0.3,2.5-0.9,3.3s-1.5,1.2-2.6,1.2c-0.6,0-1.1-0.1-1.5-0.3
                c-0.5-0.2-0.8-0.5-1.2-0.9H21l-0.3,1.1h-0.9v-12.2h1.3v3c0,0.7,0,1.3-0.1,1.8h0.1C21.7,344,22.6,343.5,23.8,343.5z M23.6,344.6
                c-0.9,0-1.5,0.3-1.9,0.8s-0.6,1.4-0.6,2.6s0.2,2.1,0.6,2.6s1,0.8,1.9,0.8c0.8,0,1.4-0.3,1.8-0.9s0.6-1.4,0.6-2.5
                s-0.2-1.9-0.6-2.5S24.4,344.6,23.6,344.6z"/>
              <path d="M33.6,343.5c1.1,0,2,0.4,2.6,1.2c0.6,0.8,0.9,1.9,0.9,3.3s-0.3,2.5-0.9,3.3c-0.6,0.8-1.5,1.2-2.6,1.2
                c-0.6,0-1.1-0.1-1.5-0.3c-0.5-0.2-0.8-0.5-1.2-0.9h-0.1l-0.3,1.1h-0.9v-12.2h1.3v3c0,0.7,0,1.3-0.1,1.8h0.1
                C31.5,344,32.4,343.5,33.6,343.5z M33.4,344.6c-0.9,0-1.5,0.3-1.9,0.8s-0.6,1.4-0.6,2.6s0.2,2.1,0.6,2.6s1,0.8,1.9,0.8
                c0.8,0,1.4-0.3,1.8-0.9c0.4-0.6,0.6-1.4,0.6-2.5s-0.2-1.9-0.6-2.5S34.2,344.6,33.4,344.6z"/>
              <path d="M39.3,351.4c0-0.3,0.1-0.6,0.2-0.8s0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8
                c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.2S39.3,351.8,39.3,351.4z"/>
              <path d="M58.8,352.2v-5.6c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.3-0.7-0.5-1.4-0.5c-0.8,0-1.4,0.2-1.8,0.7c-0.4,0.5-0.6,1.2-0.6,2.1v4.8
                h-1.3v-5.6c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.3-0.7-0.5-1.4-0.5c-0.8,0-1.4,0.2-1.8,0.7c-0.4,0.5-0.6,1.3-0.6,2.4v4.5h-1.3v-8.6h1.1
                l0.2,1.2h0.1c0.2-0.4,0.6-0.7,1-1s0.9-0.4,1.5-0.4c1.3,0,2.2,0.5,2.6,1.5h0.1c0.3-0.4,0.6-0.8,1.1-1.1c0.5-0.3,1-0.4,1.7-0.4
                c1,0,1.7,0.2,2.2,0.7s0.7,1.3,0.7,2.4v5.6L58.8,352.2L58.8,352.2z"/>
              <path d="M70.1,348c0,1.4-0.4,2.5-1.1,3.3s-1.7,1.2-2.9,1.2c-0.8,0-1.4-0.2-2-0.5s-1.1-0.9-1.4-1.5s-0.5-1.5-0.5-2.4
                c0-1.4,0.3-2.5,1-3.3s1.7-1.2,2.9-1.2c1.2,0,2.1,0.4,2.9,1.2S70.1,346.6,70.1,348z M63.6,348c0,1.1,0.2,1.9,0.7,2.5
                c0.4,0.6,1.1,0.9,1.9,0.9s1.5-0.3,1.9-0.9s0.7-1.4,0.7-2.5s-0.2-1.9-0.7-2.5c-0.4-0.6-1.1-0.8-1.9-0.8s-1.5,0.3-1.9,0.8
                C63.8,346,63.6,346.8,63.6,348z"/>
              <path d="M78.2,351.1L78.2,351.1c-0.7,0.9-1.6,1.3-2.8,1.3c-1.1,0-2-0.4-2.6-1.1s-0.9-1.9-0.9-3.3s0.3-2.5,0.9-3.3
                s1.5-1.2,2.6-1.2c1.2,0,2.1,0.4,2.7,1.3h0.1l-0.1-0.6v-0.6v-3.5h1.3v12.2h-1.1L78.2,351.1z M75.6,351.3c0.9,0,1.5-0.2,1.9-0.7
                s0.6-1.3,0.6-2.3V348c0-1.2-0.2-2.1-0.6-2.6s-1-0.8-1.9-0.8c-0.8,0-1.3,0.3-1.7,0.9c-0.4,0.6-0.6,1.4-0.6,2.5s0.2,1.9,0.6,2.5
                C74.3,351,74.9,351.3,75.6,351.3z"/>
              <path d="M82.1,341.4c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2s0.2,0.4,0.2,0.6c0,0.3-0.1,0.5-0.2,0.7
                c-0.2,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2S82.1,341.7,82.1,341.4z M83.5,352.2h-1.3v-8.6h1.3V352.2z"/>
              <path d="M90.1,344.7h-2.2v7.6h-1.3v-7.6h-1.5v-0.6l1.5-0.5v-0.5c0-2.1,0.9-3.2,2.8-3.2c0.5,0,1,0.1,1.6,0.3l-0.3,1
                c-0.5-0.2-0.9-0.2-1.3-0.2c-0.5,0-0.9,0.2-1.1,0.5s-0.4,0.8-0.4,1.6v0.6h2.2C90.1,343.7,90.1,344.7,90.1,344.7z M91.6,341.4
                c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2s0.2,0.4,0.2,0.6c0,0.3-0.1,0.5-0.2,0.7
                c-0.2,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2C91.6,341.9,91.6,341.7,91.6,341.4z M93,352.2h-1.3v-8.6H93V352.2z"/>
              <path d="M101.2,352.2H95v-0.9l4.7-6.7h-4.4v-1h5.8v1l-4.6,6.5h4.7V352.2z"/>
              <path d="M103.1,341.4c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2s0.2,0.4,0.2,0.6c0,0.3-0.1,0.5-0.2,0.7
                c-0.2,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2S103.1,341.7,103.1,341.4z M104.5,352.2h-1.3v-8.6h1.3V352.2z"/>
              <path d="M110.9,352.4c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2s1.9,0.4,2.5,1.1
                s0.9,1.6,0.9,2.8v0.8H108c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                S111.4,352.4,110.9,352.4z M110.5,344.6c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                C111.7,344.8,111.2,344.6,110.5,344.6z"/>
              <path d="M120.1,343.5c0.4,0,0.7,0,1,0.1l-0.2,1.2c-0.4-0.1-0.7-0.1-0.9-0.1c-0.7,0-1.3,0.3-1.8,0.8s-0.7,1.3-0.7,2.1v4.6h-1.3
                v-8.6h1.1l0.1,1.6h0.1c0.3-0.6,0.7-1,1.1-1.3C119.1,343.7,119.6,343.5,120.1,343.5z"/>
              <path d="M125.5,351.3c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2s-0.5,0.1-0.7,0.1
                c-1.7,0-2.5-0.9-2.5-2.6v-5.1h-1.2v-0.6l1.2-0.5l0.5-1.8h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2
                C124.8,351.2,125.1,351.3,125.5,351.3z"/>
              <path d="M138.4,352.2v-5.5c0-0.7-0.2-1.2-0.5-1.6s-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7s-0.6,1.3-0.6,2.4v4.5h-1.3v-8.6h1.1
                l0.2,1.2h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7s0.8,1.3,0.8,2.4v5.6L138.4,352.2L138.4,352.2z"
                />
              <path d="M147.7,352.2l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7s-0.7-1.1-0.7-1.9
                c0-1.7,1.4-2.6,4.1-2.7h1.5v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7l-0.4-1
                c0.4-0.2,0.9-0.4,1.4-0.5s1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2v5.8L147.7,352.2L147.7,352.2z M144.7,351.3
                c0.8,0,1.4-0.2,1.9-0.7c0.5-0.4,0.7-1.1,0.7-1.9V348l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3
                c0,0.5,0.1,0.8,0.4,1.1C143.8,351.2,144.2,351.3,144.7,351.3z"/>
              <path d="M154.7,352.4c-1.2,0-2.2-0.4-2.9-1.1c-0.7-0.8-1-1.8-1-3.2c0-1.4,0.3-2.5,1-3.3s1.7-1.2,2.9-1.2c0.4,0,0.8,0,1.2,0.1
                s0.7,0.2,1,0.3l-0.4,1.1c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.6-0.1-0.9-0.1c-1.7,0-2.6,1.1-2.6,3.3c0,1.1,0.2,1.9,0.6,2.4
                s1.1,0.8,1.9,0.8c0.7,0,1.4-0.2,2.2-0.5v1.1C156.3,352.2,155.6,352.4,154.7,352.4z"/>
              <path d="M164.8,352.2v-5.5c0-0.7-0.2-1.2-0.5-1.6s-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7s-0.6,1.3-0.6,2.4v4.5h-1.3V340h1.3v3.7
                c0,0.4,0,0.8-0.1,1.1h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.4,1.6-0.4c1,0,1.8,0.2,2.4,0.7c0.5,0.5,0.8,1.3,0.8,2.4v5.6
                L164.8,352.2L164.8,352.2z"/>
              <path d="M179.4,352.2H173v-11.4h6.4v1.2h-5v3.7h4.7v1.2h-4.7v4.2h5V352.2z"/>
              <path d="M190.9,352.2h-1.5l-6.2-9.6h-0.1c0.1,1.1,0.1,2.2,0.1,3.1v6.5H182v-11.4h1.5l6.2,9.5h0.1c0-0.1,0-0.6-0.1-1.4
                c0-0.8,0-1.3,0-1.6v-6.6h1.2V352.2z"/>
              <path d="M199.1,346.3h3.9v5.6c-0.6,0.2-1.2,0.3-1.8,0.4s-1.3,0.1-2.2,0.1c-1.7,0-3.1-0.5-4-1.5c-1-1-1.4-2.5-1.4-4.3
                c0-1.2,0.2-2.2,0.7-3.1s1.2-1.6,2.1-2c0.9-0.5,1.9-0.7,3.1-0.7c1.2,0,2.4,0.2,3.4,0.7l-0.5,1.2c-1-0.4-2-0.7-3-0.7
                c-1.4,0-2.5,0.4-3.3,1.2s-1.2,2-1.2,3.4c0,1.5,0.4,2.7,1.1,3.5s1.9,1.2,3.3,1.2c0.8,0,1.6-0.1,2.3-0.3v-3.5H199L199.1,346.3
                L199.1,346.3z"/>
              <path d="M206.8,350.4l0.1,0.2c-0.1,0.5-0.3,1.1-0.6,1.8c-0.3,0.7-0.5,1.3-0.8,1.9h-1c0.1-0.5,0.3-1.2,0.5-2s0.3-1.4,0.4-1.9
                H206.8z"/>
              <path d="M218.7,341.8c-1.3,0-2.2,0.4-3,1.3s-1.1,2-1.1,3.4c0,1.5,0.4,2.7,1.1,3.5c0.7,0.8,1.7,1.2,3,1.2c0.8,0,1.7-0.1,2.7-0.4
                v1.2c-0.8,0.3-1.8,0.4-2.9,0.4c-1.7,0-3-0.5-3.9-1.5s-1.4-2.5-1.4-4.4c0-1.2,0.2-2.2,0.7-3.1c0.4-0.9,1.1-1.6,1.9-2
                c0.8-0.5,1.8-0.7,2.9-0.7c1.2,0,2.2,0.2,3.1,0.7l-0.6,1.1C220.4,342,219.5,341.8,218.7,341.8z"/>
              <path d="M228.9,352.2l-3.9-10.1l0,0c0.1,0.8,0.1,1.8,0.1,2.9v7.3h-1.2v-11.4h2l3.6,9.4h0.1l3.6-9.4h2v11.4h-1.3v-7.4
                c0-0.8,0-1.8,0.1-2.8h-0.1l-3.9,10.1H228.9z"/>
              <path d="M237.9,351.4c0-0.3,0.1-0.6,0.2-0.8s0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8
                c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.2S237.9,351.8,237.9,351.4z"/>
              <path d="M250.1,352.4c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2
                c1.1,0,1.9,0.4,2.5,1.1s0.9,1.6,0.9,2.8v0.8h-5.9c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2
                c-0.5,0.2-0.9,0.3-1.3,0.4S250.7,352.4,250.1,352.4z M249.8,344.6c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.6,1.1-0.7,1.9h4.5
                c0-0.8-0.2-1.4-0.5-1.9C251,344.8,250.5,344.6,249.8,344.6z"/>
              <path d="M258.3,351.3c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2s-0.5,0.1-0.7,0.1
                c-1.7,0-2.5-0.9-2.5-2.6v-5.1h-1.2v-0.6l1.2-0.5l0.5-1.8h0.8v2h2.5v1H257v5c0,0.5,0.1,0.9,0.4,1.2
                C257.5,351.2,257.8,351.3,258.3,351.3z"/>
              <path d="M270.6,352.2l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1s-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7s-0.7-1.1-0.7-1.9
                c0-1.7,1.4-2.6,4.1-2.7h1.5v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7l-0.4-1
                c0.4-0.2,0.9-0.4,1.4-0.5s1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2v5.8L270.6,352.2L270.6,352.2z M267.6,351.3
                c0.8,0,1.4-0.2,1.9-0.7c0.5-0.4,0.7-1.1,0.7-1.9V348l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3
                c0,0.5,0.1,0.8,0.4,1.1C266.7,351.2,267.1,351.3,267.6,351.3z"/>
              <path d="M275.5,352.2h-1.3V340h1.3V352.2z"/>
              <path d="M278.1,351.4c0-0.3,0.1-0.6,0.2-0.8s0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8
                c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.2S278.1,351.8,278.1,351.4z"/>
              <path d="M293.6,352.2h-7.5v-1.1l3-3c0.9-0.9,1.5-1.6,1.8-2s0.5-0.8,0.7-1.2s0.2-0.8,0.2-1.2c0-0.6-0.2-1.1-0.6-1.4
                c-0.4-0.4-0.9-0.5-1.5-0.5c-0.5,0-0.9,0.1-1.3,0.2s-0.9,0.4-1.4,0.9l-0.7-0.9c1.1-0.9,2.2-1.3,3.4-1.3c1.1,0,1.9,0.3,2.5,0.8
                s0.9,1.3,0.9,2.2c0,0.7-0.2,1.4-0.6,2.1s-1.2,1.6-2.3,2.7l-2.5,2.4v0.1h5.9V352.2z"/>
              <path d="M302.8,346.5c0,2-0.3,3.4-0.9,4.4s-1.6,1.5-2.9,1.5c-1.2,0-2.2-0.5-2.8-1.5c-0.6-1-1-2.5-1-4.4c0-2,0.3-3.5,0.9-4.4
                c0.6-1,1.6-1.4,2.8-1.4c1.2,0,2.2,0.5,2.8,1.5C302.5,343.2,302.8,344.6,302.8,346.5z M296.5,346.5c0,1.7,0.2,2.9,0.6,3.6
                s1,1.1,1.9,1.1s1.5-0.4,1.9-1.2c0.4-0.8,0.6-2,0.6-3.6s-0.2-2.8-0.6-3.6c-0.4-0.8-1-1.1-1.9-1.1s-1.5,0.4-1.9,1.1
                C296.7,343.6,296.5,344.9,296.5,346.5z"/>
              <path d="M311.9,346.5c0,2-0.3,3.4-0.9,4.4s-1.6,1.5-2.9,1.5c-1.2,0-2.2-0.5-2.8-1.5c-0.6-1-1-2.5-1-4.4c0-2,0.3-3.5,0.9-4.4
                c0.6-1,1.6-1.4,2.8-1.4c1.2,0,2.2,0.5,2.8,1.5C311.6,343.2,311.9,344.6,311.9,346.5z M305.7,346.5c0,1.7,0.2,2.9,0.6,3.6
                s1,1.1,1.9,1.1s1.5-0.4,1.9-1.2c0.4-0.8,0.6-2,0.6-3.6s-0.2-2.8-0.6-3.6c-0.4-0.8-1-1.1-1.9-1.1s-1.5,0.4-1.9,1.1
                C305.9,343.6,305.7,344.9,305.7,346.5z"/>
              <path d="M315,352.2l4.7-10.2h-6.2v-1.2h7.6v1l-4.7,10.4L315,352.2L315,352.2z"/>
              <path d="M327,345.4h-1v1.5h-0.7v-1.5h-3.2v-0.7l3.2-4.5h0.8v4.5h1L327,345.4L327,345.4z M325.3,344.7v-2.2c0-0.4,0-0.9,0-1.5
                l0,0c-0.1,0.3-0.3,0.5-0.4,0.7l-2.1,3H325.3z"/>
              <path d="M329.9,340.2c0.6,0,1.1,0.1,1.4,0.4s0.5,0.7,0.5,1.2c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.5,0.5-1,0.7
                c0.5,0.3,0.9,0.5,1.2,0.8c0.2,0.3,0.3,0.6,0.3,1c0,0.6-0.2,1-0.6,1.3s-0.9,0.5-1.6,0.5s-1.3-0.2-1.6-0.5
                c-0.4-0.3-0.6-0.8-0.6-1.3c0-0.8,0.5-1.4,1.4-1.8c-0.4-0.2-0.7-0.5-0.9-0.8c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.5,0.2-0.9,0.5-1.2
                S329.3,340.2,329.9,340.2z M328.4,345.2c0,0.4,0.1,0.6,0.4,0.9c0.3,0.2,0.6,0.3,1.1,0.3s0.8-0.1,1.1-0.3
                c0.3-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.2-0.6-0.5-1.2-0.7c-0.5,0.2-0.8,0.4-1,0.6
                C328.5,344.6,328.4,344.9,328.4,345.2z M329.9,340.8c-0.4,0-0.7,0.1-0.9,0.3s-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7
                c0.2,0.2,0.5,0.4,1,0.6c0.4-0.2,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
                C330.6,340.9,330.2,340.8,329.9,340.8z"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </ng-container>

  <ng-container *ngIf="currentIdentifier === 'diagnose-chart-1'">
    <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 400 400">
      <g fill="none" fill-rule="evenodd">
        <g>
          <path stroke="#951C81" stroke-linecap="square" d="M31 12L35 12" transform="translate(22.5)"/>
          <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(22.5)">
              <tspan x=".985" y="15">100</tspan>
          </text>
          <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(22.5)">
              <tspan x="8.99" y="73">80</tspan>
          </text>
          <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(22.5)">
              <tspan x="8.99" y="131">60</tspan>
          </text>
          <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(22.5)">
              <tspan x="8.99" y="189">40</tspan>
          </text>
          <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(22.5)">
              <tspan x="8.99" y="248">20</tspan>
          </text>
          <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(22.5)">
              <tspan x="16.995" y="307">0</tspan>
          </text>
          <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(22.5)">
              <tspan x="30.998" y="323">0</tspan>
          </text>
          <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(22.5)">
              <tspan x="74.995" y="323">10</tspan>
          </text>
          <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(22.5)">
              <tspan x="121.995" y="323">20</tspan>
          </text>
          <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(22.5)">
              <tspan x="169.995" y="323">30</tspan>
          </text>
          <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(22.5)">
              <tspan x="217.995" y="323">40</tspan>
          </text>
          <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(22.5)">
              <tspan x="265.995" y="323">50</tspan>
          </text>
          <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(22.5)">
              <tspan x="311.995" y="323">60</tspan>
          </text>
          <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(22.5)">
              <tspan x="359.995" y="323">70</tspan>
          </text>
          <g stroke="#929897" stroke-linecap="square" stroke-width="2">
              <path d="M4.75.5v295m-4-62h4m-4-58.5h4m-4-58h4m-4-59h4m333 234.5L.5 292m28.25.5v2m23.5-2v2m24-2v2m24-2v2m47-2v2m47-2v2m-71-2v2m48-2v2m47-2v2m24-2v2m23-2v2m25-2v2m23-2v2m25-2v2" transform="translate(22.5) translate(30.25 11)"/>
          </g>
        </g>
        <path stroke="#007BB6" stroke-width="2" d="M57.5 9.5L110.216 9.5 114.037 13.701 126.005 13.701 129.846 15.76 172.89 15.76 177.132 18.658 188.991 18.658 204.368 32.014 220.913 32.014 225.763 41.738 232.914 52.577 242.378 61.041 244.926 66.48 248.74 83.945 254.429 83.945 254.429 87.623 258.707 96.391 263.843 96.391 268.758 105.581 271.282 120.037 272.191 129.964 277.945 129.964 282.798 140.102 292.384 151.039 296.379 166.226 301.563 166.226 303.112 169.206 309.881 178.64 316.486 178.64 320.854 196.692 325.618 231.62 330.288 231.62 335.307 267.01 344.576 267.01 349.08 284.046 382 284.046"/>
        <path stroke="#000" stroke-width="2" d="M57.5 9.5L217.646 9.5 223.946 13.048 250.188 13.048 293.159 21.214 343.127 44.67 377.792 94.585"/>
        <text fill="#000" font-family="OpenSans-SemiboldItalic, Open Sans" font-size="10" font-style="italic" font-weight="500">
            <tspan x="112.156" y="266">männliche Allgemeinbevölkerung</tspan>
        </text>
        <text fill="#000" font-family="OpenSans-SemiboldItalic, Open Sans" font-size="10" font-style="italic" font-weight="500">
            <tspan x="112.246" y="285">Männer mit M. Fabry</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="172.25" y="355">Alter (Jahre)</tspan>
        </text>
        <path stroke="#000" stroke-width="2" d="M81 264.5L108 264.5"/>
        <path stroke="#007BB6" stroke-width="2" d="M81 282.5L108 282.5"/>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="rotate(-90 9.5 144.5)">
            <tspan x="-78.502" y="150">Kumulatives Überleben (%)</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="15">
          <tspan x="20" y="392">Abb. modifiziert nach MACDERMONT, KD. et al. 2001</tspan> <tspan x="386" y="386" font-size="11">3</tspan>
        </text>
      </g>
    </svg>
  </ng-container>

  <ng-container *ngIf="currentIdentifier === 'diagnose-chart-2'">
    <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 400 400">
      <g fill="none" fill-rule="evenodd">
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="173.25" y="355">Alter (Jahre)</tspan>
        </text>
        <g>
            <path stroke="#951C81" stroke-linecap="square" d="M31 12L35 12" transform="translate(27)"/>
            <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(27)">
                <tspan x=".985" y="15">100</tspan>
            </text>
            <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(27)">
                <tspan x="8.99" y="73">80</tspan>
            </text>
            <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(27)">
                <tspan x="8.99" y="131">60</tspan>
            </text>
            <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(27)">
                <tspan x="8.99" y="189">40</tspan>
            </text>
            <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(27)">
                <tspan x="8.99" y="248">20</tspan>
            </text>
            <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(27)">
                <tspan x="16.995" y="307">0</tspan>
            </text>
            <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(27)">
                <tspan x="30.998" y="323">0</tspan>
            </text>
            <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(27)">
                <tspan x="67.495" y="323">10</tspan>
            </text>
            <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(27)">
                <tspan x="105.495" y="323">20</tspan>
            </text>
            <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(27)">
                <tspan x="145.495" y="323">30</tspan>
            </text>
            <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(27)">
                <tspan x="183.495" y="323">40</tspan>
            </text>
            <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(27)">
                <tspan x="223.495" y="323">50</tspan>
            </text>
            <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(27)">
                <tspan x="261.495" y="323">60</tspan>
            </text>
            <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(27)">
                <tspan x="299.495" y="323">70</tspan>
            </text>
            <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(27)">
                <tspan x="339.495" y="323">80</tspan>
            </text>
            <g stroke="#929897" stroke-linecap="square" stroke-width="2">
                <path d="M4.75.5v295m-4-62h4m-4-58.5h4m-4-58h4m-4-59h4m332.035 234.494L2.5 292.003m22.25.497v2m19.5-2v3m20-3v2m19-2v3m20-3v2m19-2v3m19-3v2m20-2v3m20-3v2m19-2v3m19-3v2m59-2v3m38.5-3v3m-18.5-3v2m38.5-2v2m-97.5-2v3m18.5-3v2" transform="translate(27) translate(30.25 11)"/>
            </g>
        </g>
        <text fill="#000" font-family="OpenSans-SemiboldItalic, Open Sans" font-size="10" font-style="italic" font-weight="500">
            <tspan x="111" y="266">weibliche Allgemeinbevölkerung (UK)</tspan>
        </text>
        <text fill="#000" font-family="OpenSans-SemiboldItalic, Open Sans" font-size="10" font-style="italic" font-weight="500">
            <tspan x="113.131" y="285">Frauen mit M. Fabry</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="rotate(-90 9.5 144.5)">
            <tspan x="-78.502" y="150">Kumulatives Überleben (%)</tspan>
        </text>
        <path stroke="#000" stroke-width="2" d="M80 264.5L107 264.5"/>
        <path stroke="#D8137D" stroke-width="2" d="M80 282.5L107 282.5M62.5 11.5L129.992 11.5 133.796 14.886 159.722 14.886 164.213 19 194.892 19 207.122 29.205 223.003 29.205 226.25 35.44 243.322 35.44 246.797 39.888 254.808 39.888 258.594 45.66 265.782 45.66 272.605 53.976 274.567 65.76 279.226 65.76 279.226 71.67 289.888 91.353 301.625 91.353 305.522 100.593 313.507 100.593 316.125 110.218 321.685 124.223 326.033 124.223 336.058 174.274 340.521 174.274 345.765 188.687 351.702 188.687 354.031 205.169 359.179 205.169 367.073 238.226 374 238.226 379.278 255 383 255 386.73 285.842 394.035 285.842"/>
        <path stroke="#000" stroke-width="2" d="M62.5 11L137.685 11 185.072 13.045 232.037 17.491 278.937 27.104 310.679 41.024 323.513 49.987 337.448 65.035 352.022 84.498 367.779 118.216 383 153.404 394.035 186.848"/>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="15">
            <tspan x="20" y="392">Abb. modifiziert nach MACDERMONT, KD. et al. 2001</tspan> <tspan x="386" y="386" font-size="11">4</tspan>
        </text>
      </g>
  </svg>
  </ng-container>

  <ng-container *ngIf="currentIdentifier === 'diagnose-chart-3'">
    <!-- <img src="/assets/img/template/diagnose-graphic-03@2x.png" /> -->
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="860" height="403" viewBox="0 0 860 403">
        <g fill="none" fill-rule="evenodd">
            <g>
                <g>
                    <g transform="translate(-290 -2562) translate(290 2562)">
                        <path stroke="#929897" stroke-width="2" d="M850 310L61 310 61 36"/>
                        <path stroke="#E3E1D9" stroke-linecap="square" d="M61.456 256.5L856.544 256.5M61.456 204.5L856.544 204.5M61.456 151.5L856.544 151.5M61.456 98.5L856.544 98.5M61.456 45.5L856.544 45.5"/>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="16" transform="rotate(-90 11 188.5)">
                            <tspan x="-58.07" y="194.5">QoL-Scores (SF-36)</tspan>
                        </text>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="16">
                            <tspan x="67.488" y="334">Körperliche</tspan> <tspan x="42" y="353">Funktionsfahigkeit</tspan>
                        </text>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="16">
                            <tspan x="192.469" y="334">Alltags-</tspan> <tspan x="190" y="353">aktivität</tspan>
                        </text>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="16">
                            <tspan x="277.5" y="334">Schmerzen</tspan>
                        </text>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="16">
                            <tspan x="376.766" y="334">Allgemeine</tspan> <tspan x="375" y="353">Gesundheit</tspan>
                        </text>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="16">
                            <tspan x="491.5" y="334">Vitalität</tspan>
                        </text>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="16">
                            <tspan x="591.5" y="334">Soziale</tspan> <tspan x="599.059" y="353">Rolle</tspan>
                        </text>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="16">
                            <tspan x="671.5" y="334">Emotionale</tspan> <tspan x="695.461" y="353">Rolle</tspan>
                        </text>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="16">
                            <tspan x="785.191" y="334">Mentale</tspan> <tspan x="772.5" y="353">Gesundheit</tspan>
                        </text>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
                            <tspan x="46.995" y="314">0</tspan>
                        </text>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
                            <tspan x="38.99" y="261">20</tspan>
                        </text>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
                            <tspan x="38.99" y="209">40</tspan>
                        </text>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
                            <tspan x="38.99" y="155">60</tspan>
                        </text>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
                            <tspan x="35.354" y="102"> 80</tspan>
                        </text>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
                            <tspan x="30.985" y="49">100</tspan>
                        </text>
                        <path fill="#E4C6DF" d="M92 87H118V309H92zM191 96H217V309H191zM291 141H317V309H291zM391 130H417V309H391zM491 127H517V309H491zM590 83H616V309H590zM689 73H715V309H689zM788 121H814V309H788z"/>
                        <path fill="#AF55A0" d="M120 178H146V309H120zM219 243H245V309H219zM319 182H345V309H319zM419 185H445V309H419zM519 198H545V309H519zM618 147H644V309H618zM717 205H743V309H717zM816 151H842V309H816z"/>
                        <circle cx="477" cy="11" r="9" fill="#E4C6DF"/>
                        <circle cx="715" cy="11" r="9" fill="#AF55A0"/>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="16">
                            <tspan x="493" y="17">Frauen mit Morbus Farby</tspan>
                        </text>
                        <text fill="#000" font-family="OpenSans, Open Sans" font-size="16">
                            <tspan x="731" y="17">Frauen allgemein</tspan>
                        </text>
                    </g>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="16" transform="translate(-290 -2562) translate(290 2562)">
                        <tspan x="33.591" y="395">Modifiziert nach </tspan> <tspan x="158" y="395">BAEHNER</tspan> <tspan x="230" y="395"> F. et al. 2003</tspan> <tspan x="329" y="390" font-size="11">5</tspan>
                    </text>
                </g>
            </g>
        </g>
      </svg> -->

    <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" width="860" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 861 400" style="enable-background:new 0 0 861 400;" xml:space="preserve">
      <style type="text/css">
        .st0{fill:none;stroke:#929897;stroke-width:2;}
        .st1{fill:none;stroke:#E3E1D9;stroke-linecap:square;}
        .st2{enable-background:new    ;}
        .st3{fill:#E4C6DF;}
        .st4{fill:#AF55A0;}
      </style>
      <title>chart</title>
      <g id="Page-1">
        <g id="chart">
          <g id="Group">
            <polyline id="Path" class="st0" points="851,310 62,310 62,36      "/>
            <path id="Shape" class="st1" d="M62.5,256.5h795.1 M62.5,204.5h795.1 M62.5,151.5h795.1 M62.5,98.5h795.1 M62.5,45.5h795.1"/>
            
              <g id="QoL-Scores-_x28_SF-36_x29_" transform="translate(12.000000, 188.500000) rotate(-90.000000) translate(-12.000000, -188.500000) translate(-58.000000, 177.000000)">
              <g class="st2">
                <path d="M12.4,11.8c0,1.5-0.3,2.7-0.9,3.6s-1.4,1.6-2.5,2l2.7,2.8H9.8l-2.2-2.6l-0.4,0c-1.7,0-3-0.5-3.9-1.5s-1.4-2.5-1.4-4.4
                  c0-1.9,0.5-3.3,1.4-4.3s2.2-1.5,3.9-1.5c1.6,0,2.9,0.5,3.8,1.6S12.4,9.9,12.4,11.8z M3.3,11.8c0,1.5,0.3,2.7,1,3.5
                  s1.6,1.2,2.9,1.2c1.3,0,2.2-0.4,2.9-1.2s1-2,1-3.5c0-1.5-0.3-2.7-1-3.5S8.4,7.1,7.2,7.1C5.9,7.1,5,7.5,4.3,8.3
                  S3.3,10.2,3.3,11.8z"/>
                <path d="M22.2,13.2c0,1.4-0.4,2.5-1.1,3.3s-1.7,1.2-2.9,1.2c-0.8,0-1.4-0.2-2-0.5c-0.6-0.4-1.1-0.9-1.4-1.5s-0.5-1.5-0.5-2.4
                  c0-1.4,0.3-2.5,1-3.3C16,9.2,17,8.8,18.2,8.8c1.2,0,2.1,0.4,2.9,1.2S22.2,11.9,22.2,13.2z M15.6,13.2c0,1.1,0.2,1.9,0.7,2.5
                  c0.4,0.6,1.1,0.9,1.9,0.9s1.5-0.3,1.9-0.9s0.7-1.4,0.7-2.5c0-1.1-0.2-1.9-0.7-2.5s-1.1-0.8-1.9-0.8c-0.8,0-1.5,0.3-1.9,0.8
                  C15.8,11.3,15.6,12.1,15.6,13.2z"/>
                <path d="M24.6,17.5V6.1H26v10.2h5v1.2H24.6z"/>
                <path d="M32,13.8v-1.2h3.8v1.2H32z"/>
                <path d="M44.5,14.5c0,1-0.4,1.8-1.1,2.4s-1.7,0.8-3,0.8c-1.4,0-2.4-0.2-3.1-0.5v-1.3c0.5,0.2,1,0.4,1.5,0.5s1.1,0.2,1.6,0.2
                  c0.9,0,1.6-0.2,2-0.5s0.7-0.8,0.7-1.4c0-0.4-0.1-0.7-0.2-1s-0.4-0.5-0.8-0.7s-0.9-0.5-1.7-0.7c-1.1-0.4-1.8-0.8-2.3-1.4
                  s-0.7-1.2-0.7-2c0-0.9,0.3-1.6,1-2.1s1.5-0.8,2.6-0.8c1.1,0,2.2,0.2,3.1,0.6l-0.4,1.2c-0.9-0.4-1.9-0.6-2.8-0.6
                  c-0.7,0-1.3,0.2-1.6,0.5s-0.6,0.7-0.6,1.3c0,0.4,0.1,0.7,0.2,1s0.4,0.5,0.7,0.7s0.9,0.4,1.6,0.7c1.2,0.4,2,0.9,2.5,1.4
                  S44.5,13.7,44.5,14.5z"/>
                <path d="M50.1,17.7c-1.2,0-2.2-0.4-2.9-1.1s-1-1.8-1-3.2c0-1.4,0.3-2.5,1-3.3s1.7-1.2,2.9-1.2c0.4,0,0.8,0,1.2,0.1
                  s0.7,0.2,1,0.3L52,10.3c-0.3-0.1-0.6-0.2-0.9-0.3s-0.6-0.1-0.9-0.1c-1.7,0-2.6,1.1-2.6,3.3c0,1.1,0.2,1.9,0.6,2.4
                  s1.1,0.8,1.9,0.8c0.7,0,1.4-0.2,2.2-0.5v1.1C51.7,17.5,51,17.7,50.1,17.7z"/>
                <path d="M61.7,13.2c0,1.4-0.4,2.5-1.1,3.3s-1.7,1.2-2.9,1.2c-0.8,0-1.4-0.2-2-0.5s-1.1-0.9-1.4-1.5s-0.5-1.5-0.5-2.4
                  c0-1.4,0.3-2.5,1-3.3s1.7-1.2,2.9-1.2c1.2,0,2.1,0.4,2.9,1.2C61.3,10.8,61.7,11.9,61.7,13.2z M55.1,13.2c0,1.1,0.2,1.9,0.7,2.5
                  s1.1,0.9,1.9,0.9s1.5-0.3,1.9-0.9c0.4-0.6,0.7-1.4,0.7-2.5c0-1.1-0.2-1.9-0.7-2.5c-0.4-0.6-1.1-0.8-1.9-0.8
                  c-0.8,0-1.5,0.3-1.9,0.8S55.1,12.1,55.1,13.2z"/>
                <path d="M67.9,8.8c0.4,0,0.7,0,1,0.1l-0.2,1.2C68.3,10,68,10,67.8,10c-0.7,0-1.3,0.3-1.8,0.8s-0.7,1.3-0.7,2.1v4.6h-1.3V8.9H65
                  l0.1,1.6h0.1c0.3-0.6,0.7-1,1.1-1.3C66.8,8.9,67.3,8.8,67.9,8.8z"/>
                <path d="M74.1,17.7c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8v0.8h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C75.2,17.6,74.7,17.7,74.1,17.7z M73.7,9.9c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  C74.9,10.1,74.4,9.9,73.7,9.9z"/>
                <path d="M85,15.2c0,0.8-0.3,1.4-0.9,1.8s-1.4,0.6-2.5,0.6c-1.1,0-2-0.2-2.7-0.5v-1.2c0.4,0.2,0.9,0.4,1.3,0.5
                  c0.5,0.1,0.9,0.2,1.4,0.2c0.7,0,1.2-0.1,1.6-0.3c0.4-0.2,0.5-0.5,0.5-1c0-0.3-0.1-0.6-0.4-0.9s-0.9-0.5-1.7-0.8
                  c-0.8-0.3-1.4-0.6-1.7-0.8s-0.6-0.5-0.8-0.8s-0.2-0.6-0.2-1c0-0.7,0.3-1.2,0.9-1.7c0.6-0.4,1.3-0.6,2.3-0.6
                  c0.9,0,1.8,0.2,2.7,0.6l-0.5,1.1C83.5,10,82.7,9.9,82,9.9c-0.6,0-1.1,0.1-1.4,0.3s-0.5,0.5-0.5,0.8c0,0.2,0.1,0.4,0.2,0.6
                  s0.3,0.3,0.6,0.5c0.3,0.1,0.8,0.4,1.5,0.6c1,0.4,1.7,0.7,2.1,1.1C84.8,14.1,85,14.6,85,15.2z"/>
                <path d="M90.5,13.1c0-1.4,0.2-2.7,0.6-3.9c0.4-1.2,1-2.3,1.7-3.2h1.3c-0.8,1-1.3,2.1-1.7,3.3c-0.4,1.2-0.6,2.4-0.6,3.7
                  c0,1.2,0.2,2.5,0.6,3.7c0.4,1.2,0.9,2.3,1.7,3.3h-1.2c-0.8-0.9-1.3-1.9-1.8-3.1C90.7,15.7,90.5,14.5,90.5,13.1z"/>
                <path d="M102.6,14.5c0,1-0.4,1.8-1.1,2.4c-0.7,0.6-1.7,0.8-3,0.8c-1.4,0-2.4-0.2-3.1-0.5v-1.3c0.5,0.2,1,0.4,1.5,0.5
                  c0.6,0.1,1.1,0.2,1.6,0.2c0.9,0,1.6-0.2,2-0.5c0.4-0.3,0.7-0.8,0.7-1.4c0-0.4-0.1-0.7-0.2-1c-0.2-0.3-0.4-0.5-0.8-0.7
                  c-0.4-0.2-0.9-0.5-1.7-0.7c-1.1-0.4-1.8-0.8-2.3-1.4c-0.5-0.5-0.7-1.2-0.7-2c0-0.9,0.3-1.6,1-2.1c0.7-0.5,1.5-0.8,2.6-0.8
                  c1.1,0,2.2,0.2,3.1,0.6l-0.4,1.2c-0.9-0.4-1.9-0.6-2.8-0.6c-0.7,0-1.3,0.2-1.6,0.5c-0.4,0.3-0.6,0.7-0.6,1.3
                  c0,0.4,0.1,0.7,0.2,1c0.1,0.3,0.4,0.5,0.7,0.7c0.3,0.2,0.9,0.4,1.6,0.7c1.2,0.4,2,0.9,2.5,1.4C102.4,13,102.6,13.7,102.6,14.5z
                  "/>
                <path d="M106.3,17.5H105V6.1h6.4v1.2h-5v4.2h4.7v1.2h-4.7V17.5z"/>
                <path d="M112.3,13.8v-1.2h3.8v1.2H112.3z"/>
                <path d="M124.6,8.8c0,0.7-0.2,1.3-0.6,1.8c-0.4,0.5-1,0.8-1.7,0.9v0.1c0.9,0.1,1.6,0.4,2,0.9c0.4,0.5,0.7,1.1,0.7,1.8
                  c0,1.1-0.4,1.9-1.1,2.5c-0.8,0.6-1.8,0.9-3.2,0.9c-0.6,0-1.2,0-1.7-0.1c-0.5-0.1-1-0.3-1.5-0.5v-1.2c0.5,0.2,1,0.4,1.6,0.6
                  c0.6,0.1,1.1,0.2,1.6,0.2c2,0,3-0.8,3-2.3c0-1.4-1.1-2.1-3.3-2.1h-1.1V11h1.1c0.9,0,1.6-0.2,2.1-0.6c0.5-0.4,0.8-0.9,0.8-1.6
                  c0-0.6-0.2-1-0.6-1.3S121.8,7,121.2,7c-0.5,0-1,0.1-1.4,0.2c-0.4,0.1-0.9,0.4-1.5,0.8l-0.7-0.9c0.5-0.4,1-0.7,1.6-0.9
                  c0.6-0.2,1.3-0.3,1.9-0.3c1.1,0,2,0.3,2.6,0.8C124.3,7.2,124.6,7.9,124.6,8.8z"/>
                <path d="M126.9,12.6c0-2.2,0.4-3.9,1.3-5c0.9-1.1,2.2-1.7,3.9-1.7c0.6,0,1.1,0,1.4,0.1v1.1C133,7,132.6,7,132,7
                  c-1.2,0-2.2,0.4-2.8,1.1c-0.6,0.8-1,2-1.1,3.6h0.1c0.6-0.9,1.5-1.3,2.7-1.3c1,0,1.8,0.3,2.4,0.9c0.6,0.6,0.9,1.5,0.9,2.5
                  c0,1.2-0.3,2.1-1,2.8s-1.5,1-2.6,1c-1.2,0-2.1-0.4-2.8-1.3C127.2,15.4,126.9,14.2,126.9,12.6z M130.7,16.6
                  c0.7,0,1.3-0.2,1.7-0.7c0.4-0.5,0.6-1.1,0.6-2c0-0.8-0.2-1.3-0.6-1.8c-0.4-0.4-0.9-0.6-1.7-0.6c-0.5,0-0.9,0.1-1.3,0.3
                  s-0.7,0.5-0.9,0.8c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.5,0.1,1,0.3,1.5c0.2,0.5,0.5,0.8,0.9,1.1S130.2,16.6,130.7,16.6z"/>
                <path d="M139.2,13.1c0,1.4-0.2,2.6-0.6,3.8c-0.4,1.2-1,2.2-1.7,3.1h-1.2c0.7-1,1.3-2.1,1.7-3.3c0.4-1.2,0.6-2.4,0.6-3.7
                  c0-1.3-0.2-2.5-0.6-3.7c-0.4-1.2-0.9-2.3-1.7-3.3h1.3c0.8,0.9,1.3,2,1.8,3.2C139,10.5,139.2,11.7,139.2,13.1z"/>
              </g>
            </g>
            <g transform="translate(43.000000, 317.000000)">
              <g class="st2">
                <path d="M35.3,17h-1.6l-4.2-5.5l-1.2,1.1V17h-1.3V5.6h1.3v5.7l5.2-5.7h1.6l-4.6,5L35.3,17z"/>
                <path d="M44.1,12.7c0,1.4-0.4,2.5-1.1,3.3c-0.7,0.8-1.7,1.2-2.9,1.2c-0.8,0-1.4-0.2-2-0.5s-1.1-0.9-1.4-1.5
                  c-0.3-0.7-0.5-1.5-0.5-2.4c0-1.4,0.3-2.5,1-3.3c0.7-0.8,1.7-1.2,2.9-1.2c1.2,0,2.1,0.4,2.9,1.2C43.7,10.3,44.1,11.4,44.1,12.7z
                   M37.6,12.7c0,1.1,0.2,1.9,0.7,2.5s1.1,0.9,1.9,0.9c0.8,0,1.5-0.3,1.9-0.9c0.4-0.6,0.7-1.4,0.7-2.5c0-1.1-0.2-1.9-0.7-2.5
                  c-0.4-0.6-1.1-0.8-1.9-0.8c-0.8,0-1.5,0.3-1.9,0.8C37.8,10.8,37.6,11.6,37.6,12.7z M37.9,6.1c0-0.3,0.1-0.5,0.2-0.6
                  c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.6c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.3,0.2-0.5,0.2
                  c-0.2,0-0.4-0.1-0.5-0.2C38,6.6,37.9,6.4,37.9,6.1z M40.9,6.1c0-0.3,0.1-0.5,0.2-0.6c0.1-0.1,0.3-0.2,0.5-0.2
                  c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.6c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2
                  C41,6.6,40.9,6.4,40.9,6.1z"/>
                <path d="M50.3,8.3c0.4,0,0.7,0,1,0.1l-0.2,1.2c-0.4-0.1-0.7-0.1-0.9-0.1c-0.7,0-1.3,0.3-1.8,0.8s-0.7,1.3-0.7,2.1V17h-1.3V8.4
                  h1.1l0.1,1.6h0.1c0.3-0.6,0.7-1,1.1-1.3C49.2,8.4,49.7,8.3,50.3,8.3z"/>
                <path d="M56.9,17.2c-0.6,0-1.1-0.1-1.5-0.3s-0.8-0.5-1.2-0.9h-0.1c0.1,0.5,0.1,1,0.1,1.4v3.5h-1.3V8.4h1.1l0.2,1.2h0.1
                  c0.3-0.5,0.7-0.8,1.2-1c0.4-0.2,1-0.3,1.5-0.3c1.1,0,2,0.4,2.6,1.2s0.9,1.9,0.9,3.3c0,1.4-0.3,2.5-0.9,3.3
                  C58.8,16.8,58,17.2,56.9,17.2z M56.7,9.4c-0.9,0-1.5,0.2-1.9,0.7s-0.6,1.3-0.6,2.3v0.3c0,1.2,0.2,2.1,0.6,2.6
                  c0.4,0.5,1,0.8,1.9,0.8c0.7,0,1.3-0.3,1.7-0.9c0.4-0.6,0.6-1.4,0.6-2.5c0-1.1-0.2-1.9-0.6-2.5C58,9.7,57.4,9.4,56.7,9.4z"/>
                <path d="M66.3,17.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V13h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C67.4,17.1,66.9,17.2,66.3,17.2z M65.9,9.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  C67.1,9.6,66.6,9.4,65.9,9.4z"/>
                <path d="M75.6,8.3c0.4,0,0.7,0,1,0.1l-0.2,1.2c-0.4-0.1-0.7-0.1-0.9-0.1c-0.7,0-1.3,0.3-1.8,0.8S73,11.6,73,12.4V17h-1.3V8.4
                  h1.1l0.1,1.6h0.1c0.3-0.6,0.7-1,1.1-1.3C74.5,8.4,75,8.3,75.6,8.3z"/>
                <path d="M79.5,17h-1.3V4.8h1.3V17z"/>
                <path d="M82.1,6.1c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.2,0.4,0.2,0.6
                  s-0.1,0.5-0.2,0.7C83.3,6.9,83.1,7,82.9,7c-0.2,0-0.4-0.1-0.5-0.2C82.2,6.6,82.1,6.4,82.1,6.1z M83.5,17h-1.3V8.4h1.3V17z"/>
                <path d="M89.7,17.2c-1.2,0-2.2-0.4-2.9-1.1c-0.7-0.8-1-1.8-1-3.2c0-1.4,0.3-2.5,1-3.3c0.7-0.8,1.7-1.2,2.9-1.2
                  c0.4,0,0.8,0,1.2,0.1c0.4,0.1,0.7,0.2,1,0.3l-0.4,1.1c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.6-0.1-0.9-0.1
                  c-1.7,0-2.6,1.1-2.6,3.3c0,1.1,0.2,1.9,0.6,2.4c0.4,0.6,1.1,0.8,1.9,0.8c0.7,0,1.4-0.2,2.2-0.5v1.1
                  C91.3,17,90.6,17.2,89.7,17.2z"/>
                <path d="M99.8,17v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7s-0.6,1.3-0.6,2.4V17h-1.3V4.8h1.3
                  v3.7c0,0.4,0,0.8-0.1,1.1h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.4,1.6-0.4c1,0,1.8,0.2,2.4,0.7s0.8,1.3,0.8,2.4V17H99.8z"/>
                <path d="M107.3,17.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2
                  c1.1,0,1.9,0.4,2.5,1.1s0.9,1.6,0.9,2.8V13h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2
                  c-0.5,0.2-0.9,0.3-1.3,0.4C108.4,17.1,107.9,17.2,107.3,17.2z M107,9.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.6,1.1-0.7,1.9h4.5
                  c0-0.8-0.2-1.4-0.5-1.9C108.2,9.6,107.7,9.4,107,9.4z"/>
              </g>
              <g class="st2">
                <path d="M2.9,36H1.6V24.6h6.4v1.2h-5v4.2h4.7v1.2H2.9V36z"/>
                <path d="M10.9,27.4V33c0,0.7,0.2,1.2,0.5,1.6c0.3,0.3,0.8,0.5,1.5,0.5c0.9,0,1.6-0.2,2-0.7s0.6-1.3,0.6-2.4v-4.5h1.3V36h-1.1
                  l-0.2-1.1h-0.1c-0.3,0.4-0.6,0.7-1.1,1c-0.5,0.2-1,0.3-1.6,0.3c-1,0-1.8-0.2-2.3-0.7c-0.5-0.5-0.8-1.3-0.8-2.4v-5.6H10.9z"/>
                <path d="M25.3,36v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7c-0.4,0.5-0.6,1.3-0.6,2.4V36h-1.3
                  v-8.6h1.1l0.2,1.2h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7c0.5,0.5,0.8,1.3,0.8,2.4V36H25.3z"/>
                <path d="M30.6,31.6c0.2-0.3,0.6-0.7,1-1.2l2.8-2.9h1.5l-3.5,3.6l3.7,4.9h-1.6l-3-4l-1,0.8V36h-1.3V23.8h1.3v6.4
                  C30.6,30.6,30.5,31,30.6,31.6L30.6,31.6z"/>
                <path d="M40.4,35.1c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1C41.5,36,41.3,36,41,36.1s-0.5,0.1-0.7,0.1
                  c-1.7,0-2.5-0.9-2.5-2.6v-5.1h-1.2v-0.6l1.2-0.5l0.5-1.8h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2
                  C39.7,34.9,40,35.1,40.4,35.1z"/>
                <path d="M43.2,25.1c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.2,0.4,0.2,0.6
                  s-0.1,0.5-0.2,0.7C44.4,25.9,44.2,26,44,26c-0.2,0-0.4-0.1-0.5-0.2C43.3,25.6,43.2,25.4,43.2,25.1z M44.6,36h-1.3v-8.6h1.3V36z
                  "/>
                <path d="M54.8,31.7c0,1.4-0.4,2.5-1.1,3.3s-1.7,1.2-2.9,1.2c-0.8,0-1.4-0.2-2-0.5s-1.1-0.9-1.4-1.5c-0.3-0.7-0.5-1.5-0.5-2.4
                  c0-1.4,0.3-2.5,1-3.3c0.7-0.8,1.7-1.2,2.9-1.2c1.2,0,2.1,0.4,2.9,1.2C54.4,29.3,54.8,30.4,54.8,31.7z M48.2,31.7
                  c0,1.1,0.2,1.9,0.7,2.5s1.1,0.9,1.9,0.9c0.8,0,1.5-0.3,1.9-0.9c0.4-0.6,0.7-1.4,0.7-2.5c0-1.1-0.2-1.9-0.7-2.5
                  c-0.4-0.6-1.1-0.8-1.9-0.8c-0.8,0-1.5,0.3-1.9,0.8C48.5,29.8,48.2,30.6,48.2,31.7z"/>
                <path d="M62.9,36v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7c-0.4,0.5-0.6,1.3-0.6,2.4V36H57
                  v-8.6h1.1l0.2,1.2h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7c0.5,0.5,0.8,1.3,0.8,2.4V36H62.9z"/>
                <path d="M72.4,33.7c0,0.8-0.3,1.4-0.9,1.8s-1.4,0.6-2.5,0.6c-1.1,0-2-0.2-2.7-0.5v-1.2c0.4,0.2,0.9,0.4,1.3,0.5
                  c0.5,0.1,0.9,0.2,1.4,0.2c0.7,0,1.2-0.1,1.6-0.3c0.4-0.2,0.5-0.5,0.5-1c0-0.3-0.1-0.6-0.4-0.9s-0.9-0.5-1.7-0.8
                  c-0.8-0.3-1.4-0.6-1.7-0.8s-0.6-0.5-0.8-0.8s-0.2-0.6-0.2-1c0-0.7,0.3-1.2,0.9-1.7c0.6-0.4,1.3-0.6,2.3-0.6
                  c0.9,0,1.8,0.2,2.7,0.6l-0.5,1.1c-0.9-0.4-1.6-0.5-2.3-0.5c-0.6,0-1.1,0.1-1.4,0.3s-0.5,0.5-0.5,0.8c0,0.2,0.1,0.4,0.2,0.6
                  s0.3,0.3,0.6,0.5c0.3,0.1,0.8,0.4,1.5,0.6c1,0.4,1.7,0.7,2.1,1.1C72.2,32.6,72.4,33.1,72.4,33.7z"/>
                <path d="M78.3,28.4h-2.2V36h-1.3v-7.6h-1.5v-0.6l1.5-0.5v-0.5c0-2.1,0.9-3.2,2.8-3.2c0.5,0,1,0.1,1.6,0.3l-0.3,1
                  c-0.5-0.2-0.9-0.2-1.3-0.2c-0.5,0-0.9,0.2-1.1,0.5s-0.4,0.8-0.4,1.6v0.6h2.2V28.4z"/>
                <path d="M85.2,36l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7
                  c-0.5-0.4-0.7-1.1-0.7-1.9c0-1.7,1.4-2.6,4.1-2.7l1.5,0v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7
                  l-0.4-1c0.4-0.2,0.9-0.4,1.4-0.5c0.5-0.1,1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2V36H85.2z M82.2,35.1
                  c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1
                  C81.3,35,81.7,35.1,82.2,35.1z"/>
                <path d="M94.7,36v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7s-0.6,1.3-0.6,2.4V36h-1.3V23.8
                  h1.3v3.7c0,0.4,0,0.8-0.1,1.1h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.4,1.6-0.4c1,0,1.8,0.2,2.4,0.7s0.8,1.3,0.8,2.4V36H94.7z"
                  />
                <path d="M98.5,25.1c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.2,0.4,0.2,0.6
                  s-0.1,0.5-0.2,0.7c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2C98.6,25.6,98.5,25.4,98.5,25.1z M99.9,36h-1.3v-8.6h1.3V36
                  z"/>
                <path d="M109.7,27.4v0.8l-1.6,0.2c0.1,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,1c0,0.8-0.3,1.5-0.9,2
                  c-0.6,0.5-1.4,0.8-2.4,0.8c-0.3,0-0.5,0-0.7-0.1c-0.6,0.3-0.8,0.7-0.8,1.1c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.5,0.2,1,0.2h1.5
                  c0.9,0,1.6,0.2,2.1,0.6c0.5,0.4,0.7,1,0.7,1.7c0,0.9-0.4,1.7-1.1,2.2c-0.8,0.5-1.9,0.7-3.3,0.7c-1.1,0-2-0.2-2.6-0.6
                  c-0.6-0.4-0.9-1-0.9-1.8c0-0.5,0.2-1,0.5-1.4c0.3-0.4,0.8-0.6,1.4-0.8c-0.2-0.1-0.4-0.3-0.6-0.5s-0.2-0.5-0.2-0.7
                  c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.4-0.5,0.8-0.7c-0.4-0.2-0.8-0.5-1.1-0.9c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.9,0.3-1.7,0.8-2.2
                  s1.4-0.8,2.4-0.8c0.4,0,0.9,0.1,1.2,0.2H109.7z M102.9,37.4c0,0.5,0.2,0.8,0.6,1.1s1,0.4,1.7,0.4c1.1,0,1.9-0.2,2.4-0.5
                  s0.8-0.8,0.8-1.3c0-0.5-0.1-0.8-0.4-1c-0.3-0.2-0.8-0.3-1.6-0.3h-1.6c-0.6,0-1,0.1-1.4,0.4S102.9,36.9,102.9,37.4z M103.6,30.2
                  c0,0.6,0.2,1.1,0.5,1.4c0.3,0.3,0.8,0.5,1.4,0.5c1.3,0,1.9-0.6,1.9-1.8c0-1.3-0.6-1.9-1.9-1.9c-0.6,0-1.1,0.2-1.4,0.5
                  S103.6,29.5,103.6,30.2z"/>
                <path d="M112.7,31.6c0.2-0.3,0.6-0.7,1-1.2l2.8-2.9h1.5l-3.5,3.6l3.7,4.9h-1.6l-3-4l-1,0.8V36h-1.3V23.8h1.3v6.4
                  C112.7,30.6,112.7,31,112.7,31.6L112.7,31.6z"/>
                <path d="M123.5,36.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2
                  c1.1,0,1.9,0.4,2.5,1.1s0.9,1.6,0.9,2.8V32h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2
                  c-0.5,0.2-0.9,0.3-1.3,0.4C124.5,36.1,124,36.2,123.5,36.2z M123.1,28.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.6,1.1-0.7,1.9h4.5
                  c0-0.8-0.2-1.4-0.5-1.9C124.3,28.6,123.8,28.4,123.1,28.4z"/>
                <path d="M128.7,25.1c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.2,0.4,0.2,0.6
                  s-0.1,0.5-0.2,0.7c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2C128.8,25.6,128.7,25.4,128.7,25.1z M130.1,36h-1.3v-8.6
                  h1.3V36z"/>
                <path d="M135.6,35.1c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2s-0.5,0.1-0.7,0.1
                  c-1.7,0-2.5-0.9-2.5-2.6v-5.1h-1.2v-0.6l1.2-0.5l0.5-1.8h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2
                  C134.9,34.9,135.2,35.1,135.6,35.1z"/>
              </g>
            </g>
            <g transform="translate(191.000000, 317.000000)">
              <g class="st2">
                <path d="M11.2,17l-1.4-3.6H5.2L3.8,17H2.5L7,5.5h1.1L12.6,17H11.2z M9.4,12.2L8.1,8.6C7.9,8.2,7.7,7.6,7.5,7
                  C7.4,7.5,7.2,8,7,8.6l-1.3,3.5H9.4z"/>
                <path d="M15.3,17H14V4.8h1.3V17z"/>
                <path d="M19.3,17H18V4.8h1.3V17z"/>
                <path d="M24.8,16.1c0.2,0,0.5,0,0.7-0.1s0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2s-0.5,0.1-0.7,0.1c-1.7,0-2.5-0.9-2.5-2.6
                  V9.4h-1.2V8.8l1.2-0.5l0.5-1.8h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2S24.4,16.1,24.8,16.1z"/>
                <path d="M33,17l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1s-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7s-0.7-1.1-0.7-1.9
                  c0-1.7,1.4-2.6,4.1-2.7l1.5,0v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7L28,9
                  c0.4-0.2,0.9-0.4,1.4-0.5s1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2V17H33z M30,16.1c0.8,0,1.4-0.2,1.9-0.7
                  s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5s-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1S29.5,16.1,30,16.1z"/>
                <path d="M43.6,8.4v0.8L42,9.4c0.1,0.2,0.3,0.4,0.4,0.7s0.2,0.6,0.2,1c0,0.8-0.3,1.5-0.9,2s-1.4,0.8-2.4,0.8
                  c-0.3,0-0.5,0-0.7-0.1c-0.6,0.3-0.8,0.7-0.8,1.1c0,0.2,0.1,0.4,0.3,0.5s0.5,0.2,1,0.2h1.5c0.9,0,1.6,0.2,2.1,0.6
                  c0.5,0.4,0.7,1,0.7,1.7c0,0.9-0.4,1.7-1.1,2.2s-1.9,0.7-3.3,0.7c-1.1,0-2-0.2-2.6-0.6s-0.9-1-0.9-1.8c0-0.5,0.2-1,0.5-1.4
                  s0.8-0.6,1.4-0.8c-0.2-0.1-0.4-0.3-0.6-0.5c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.3,0.1-0.6,0.2-0.8s0.4-0.5,0.8-0.7
                  c-0.4-0.2-0.8-0.5-1.1-0.9s-0.4-0.9-0.4-1.5c0-0.9,0.3-1.7,0.8-2.2s1.4-0.8,2.4-0.8c0.4,0,0.9,0.1,1.2,0.2H43.6z M36.8,18.4
                  c0,0.5,0.2,0.8,0.6,1.1s1,0.4,1.7,0.4c1.1,0,1.9-0.2,2.4-0.5s0.8-0.8,0.8-1.3c0-0.5-0.1-0.8-0.4-1s-0.8-0.3-1.6-0.3h-1.6
                  c-0.6,0-1,0.1-1.4,0.4S36.8,17.9,36.8,18.4z M37.5,11.2c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c1.3,0,1.9-0.6,1.9-1.8
                  c0-1.3-0.6-1.9-1.9-1.9c-0.6,0-1.1,0.2-1.4,0.5S37.5,10.5,37.5,11.2z"/>
                <path d="M50.9,14.7c0,0.8-0.3,1.4-0.9,1.8s-1.4,0.6-2.5,0.6c-1.1,0-2-0.2-2.7-0.5v-1.2c0.4,0.2,0.9,0.4,1.3,0.5
                  c0.5,0.1,0.9,0.2,1.4,0.2c0.7,0,1.2-0.1,1.6-0.3s0.5-0.5,0.5-1c0-0.3-0.1-0.6-0.4-0.9s-0.9-0.5-1.7-0.8
                  c-0.8-0.3-1.4-0.6-1.7-0.8s-0.6-0.5-0.8-0.8s-0.2-0.6-0.2-1c0-0.7,0.3-1.2,0.9-1.7S47,8.3,48,8.3c0.9,0,1.8,0.2,2.7,0.6
                  l-0.5,1.1c-0.9-0.4-1.6-0.5-2.3-0.5c-0.6,0-1.1,0.1-1.4,0.3s-0.5,0.5-0.5,0.8c0,0.2,0.1,0.4,0.2,0.6s0.3,0.3,0.6,0.5
                  s0.8,0.4,1.5,0.6c1,0.4,1.7,0.7,2.1,1.1S50.9,14.1,50.9,14.7z"/>
                <path d="M52.3,13.3v-1.2h3.8v1.2H52.3z"/>
              </g>
              <g class="st2">
                <path d="M6.6,36l-0.3-1.2H6.3c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7c-0.5-0.4-0.7-1.1-0.7-1.9
                  c0-1.7,1.4-2.6,4.1-2.7l1.5,0v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7l-0.4-1
                  c0.4-0.2,0.9-0.4,1.4-0.5c0.5-0.1,1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2V36H6.6z M3.7,35.1c0.8,0,1.4-0.2,1.9-0.7
                  s0.7-1.1,0.7-1.9v-0.8L5,31.8c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1C2.8,35,3.2,35.1,3.7,35.1z
                  "/>
                <path d="M11.6,31.6c0.2-0.3,0.6-0.7,1-1.2l2.8-2.9h1.5l-3.5,3.6l3.7,4.9h-1.6l-3-4l-1,0.8V36h-1.3V23.8h1.3v6.4
                  C11.6,30.6,11.5,31,11.6,31.6L11.6,31.6z"/>
                <path d="M21.4,35.1c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1C22.5,36,22.3,36,22,36.1s-0.5,0.1-0.7,0.1
                  c-1.7,0-2.5-0.9-2.5-2.6v-5.1h-1.2v-0.6l1.2-0.5l0.5-1.8h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2
                  C20.7,34.9,21,35.1,21.4,35.1z"/>
                <path d="M24.2,25.1c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.2,0.4,0.2,0.6
                  s-0.1,0.5-0.2,0.7C25.4,25.9,25.2,26,25,26c-0.2,0-0.4-0.1-0.5-0.2C24.3,25.6,24.2,25.4,24.2,25.1z M25.6,36h-1.3v-8.6h1.3V36z
                  "/>
                <path d="M30.2,36L27,27.4h1.4l1.8,5.1c0.4,1.2,0.7,2,0.7,2.3H31c0.1-0.3,0.2-0.8,0.5-1.7s1-2.8,2.1-5.7H35L31.8,36H30.2z"/>
                <path d="M36.3,25.1c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.2,0.4,0.2,0.6
                  s-0.1,0.5-0.2,0.7C37.4,25.9,37.2,26,37,26c-0.2,0-0.4-0.1-0.5-0.2C36.3,25.6,36.3,25.4,36.3,25.1z M37.7,36h-1.3v-8.6h1.3V36z
                  "/>
                <path d="M43.2,35.1c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1C44.2,36,44,36,43.7,36.1s-0.5,0.1-0.7,0.1
                  c-1.7,0-2.5-0.9-2.5-2.6v-5.1h-1.2v-0.6l1.2-0.5l0.5-1.8h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2
                  C42.4,34.9,42.8,35.1,43.2,35.1z"/>
                <path d="M51.3,36l-0.3-1.2H51c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7c-0.5-0.4-0.7-1.1-0.7-1.9
                  c0-1.7,1.4-2.6,4.1-2.7l1.5,0v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7l-0.4-1
                  c0.4-0.2,0.9-0.4,1.4-0.5c0.5-0.1,1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2V36H51.3z M48.4,35.1
                  c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1
                  C47.5,35,47.9,35.1,48.4,35.1z M46.9,25.1c0-0.3,0.1-0.5,0.2-0.6c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2
                  c0.1,0.1,0.2,0.3,0.2,0.6c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2C47,25.6,46.9,25.4,46.9,25.1
                  z M49.9,25.1c0-0.3,0.1-0.5,0.2-0.6c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.6
                  c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2C49.9,25.6,49.9,25.4,49.9,25.1z"/>
                <path d="M57.7,35.1c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2s-0.5,0.1-0.7,0.1
                  c-1.7,0-2.5-0.9-2.5-2.6v-5.1h-1.2v-0.6l1.2-0.5l0.5-1.8h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2
                  C57,34.9,57.3,35.1,57.7,35.1z"/>
              </g>
            </g>
            <g id="Schmerzen" transform="translate(278.000000, 317.000000)">
              <g class="st2">
                <path d="M8.5,14c0,1-0.4,1.8-1.1,2.4c-0.7,0.6-1.7,0.8-3,0.8c-1.4,0-2.4-0.2-3.1-0.5v-1.3c0.5,0.2,1,0.4,1.5,0.5
                  C3.4,15.9,4,16,4.5,16c0.9,0,1.6-0.2,2-0.5c0.4-0.3,0.7-0.8,0.7-1.4c0-0.4-0.1-0.7-0.2-1c-0.2-0.3-0.4-0.5-0.8-0.7
                  c-0.4-0.2-0.9-0.5-1.7-0.7c-1.1-0.4-1.8-0.8-2.3-1.4c-0.5-0.5-0.7-1.2-0.7-2c0-0.9,0.3-1.6,1-2.1C3.1,5.7,4,5.4,5.1,5.4
                  c1.1,0,2.2,0.2,3.1,0.6L7.8,7.2C6.9,6.8,6,6.6,5.1,6.6c-0.7,0-1.3,0.2-1.6,0.5C3,7.4,2.8,7.8,2.8,8.3c0,0.4,0.1,0.7,0.2,1
                  C3.2,9.5,3.4,9.8,3.8,10c0.3,0.2,0.9,0.4,1.6,0.7c1.2,0.4,2,0.9,2.5,1.4C8.3,12.5,8.5,13.2,8.5,14z"/>
                <path d="M14.1,17.2c-1.2,0-2.2-0.4-2.9-1.1c-0.7-0.8-1-1.8-1-3.2c0-1.4,0.3-2.5,1-3.3c0.7-0.8,1.7-1.2,2.9-1.2
                  c0.4,0,0.8,0,1.2,0.1c0.4,0.1,0.7,0.2,1,0.3L16,9.8c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.6-0.1-0.9-0.1c-1.7,0-2.6,1.1-2.6,3.3
                  c0,1.1,0.2,1.9,0.6,2.4c0.4,0.6,1.1,0.8,1.9,0.8c0.7,0,1.4-0.2,2.2-0.5v1.1C15.7,17,14.9,17.2,14.1,17.2z"/>
                <path d="M24.1,17v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7s-0.6,1.3-0.6,2.4V17h-1.3V4.8h1.3
                  v3.7c0,0.4,0,0.8-0.1,1.1h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.4,1.6-0.4c1,0,1.8,0.2,2.4,0.7s0.8,1.3,0.8,2.4V17H24.1z"/>
                <path d="M39,17v-5.6c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.3-0.7-0.5-1.4-0.5c-0.8,0-1.4,0.2-1.8,0.7c-0.4,0.5-0.6,1.2-0.6,2.1V17h-1.3
                  v-5.6c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.3-0.7-0.5-1.4-0.5c-0.8,0-1.4,0.2-1.8,0.7c-0.4,0.5-0.6,1.3-0.6,2.4V17h-1.3V8.4h1.1
                  l0.2,1.2h0.1c0.2-0.4,0.6-0.7,1-1s0.9-0.4,1.5-0.4c1.3,0,2.2,0.5,2.6,1.5h0.1c0.3-0.4,0.6-0.8,1.1-1.1s1-0.4,1.7-0.4
                  c1,0,1.7,0.2,2.2,0.7c0.5,0.5,0.7,1.3,0.7,2.4V17H39z"/>
                <path d="M46.6,17.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V13h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C47.7,17.1,47.2,17.2,46.6,17.2z M46.2,9.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  C47.4,9.6,46.9,9.4,46.2,9.4z"/>
                <path d="M55.9,8.3c0.4,0,0.7,0,1,0.1l-0.2,1.2c-0.4-0.1-0.7-0.1-0.9-0.1c-0.7,0-1.3,0.3-1.8,0.8s-0.7,1.3-0.7,2.1V17H52V8.4H53
                  l0.1,1.6h0.1c0.3-0.6,0.7-1,1.1-1.3C54.8,8.4,55.3,8.3,55.9,8.3z"/>
                <path d="M64,17h-6.2v-0.9l4.7-6.7H58v-1h5.8v1L59.2,16H64V17z"/>
                <path d="M69.6,17.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V13h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C70.7,17.1,70.2,17.2,69.6,17.2z M69.2,9.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  C70.4,9.6,69.9,9.4,69.2,9.4z"/>
                <path d="M80.8,17v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7c-0.4,0.5-0.6,1.3-0.6,2.4V17h-1.3
                  V8.4H76l0.2,1.2h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7c0.5,0.5,0.8,1.3,0.8,2.4V17H80.8z"/>
              </g>
            </g>
            <g transform="translate(376.000000, 317.000000)">
              <g class="st2">
                <path d="M10.5,17l-1.4-3.6H4.5L3.1,17H1.8L6.3,5.5h1.1L11.9,17H10.5z M8.7,12.2L7.4,8.6C7.2,8.2,7,7.6,6.8,7
                  C6.7,7.5,6.5,8,6.3,8.6L5,12.2H8.7z"/>
                <path d="M14.6,17h-1.3V4.8h1.3V17z"/>
                <path d="M18.6,17h-1.3V4.8h1.3V17z"/>
                <path d="M28.4,8.4v0.8l-1.6,0.2c0.1,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,1c0,0.8-0.3,1.5-0.9,2c-0.6,0.5-1.4,0.8-2.4,0.8
                  c-0.3,0-0.5,0-0.7-0.1c-0.6,0.3-0.8,0.7-0.8,1.1c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.5,0.2,1,0.2h1.5c0.9,0,1.6,0.2,2.1,0.6
                  c0.5,0.4,0.7,1,0.7,1.7c0,0.9-0.4,1.7-1.1,2.2c-0.8,0.5-1.9,0.7-3.3,0.7c-1.1,0-2-0.2-2.6-0.6c-0.6-0.4-0.9-1-0.9-1.8
                  c0-0.5,0.2-1,0.5-1.4c0.3-0.4,0.8-0.6,1.4-0.8c-0.2-0.1-0.4-0.3-0.6-0.5c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.3,0.1-0.6,0.2-0.8
                  c0.2-0.2,0.4-0.5,0.8-0.7c-0.4-0.2-0.8-0.5-1.1-0.9c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.9,0.3-1.7,0.8-2.2s1.4-0.8,2.4-0.8
                  c0.4,0,0.9,0.1,1.2,0.2H28.4z M21.5,18.4c0,0.5,0.2,0.8,0.6,1.1s1,0.4,1.7,0.4c1.1,0,1.9-0.2,2.4-0.5S27,18.6,27,18
                  c0-0.5-0.1-0.8-0.4-1c-0.3-0.2-0.8-0.3-1.6-0.3h-1.6c-0.6,0-1,0.1-1.4,0.4S21.5,17.9,21.5,18.4z M22.2,11.2
                  c0,0.6,0.2,1.1,0.5,1.4c0.3,0.3,0.8,0.5,1.4,0.5c1.3,0,1.9-0.6,1.9-1.8c0-1.3-0.6-1.9-1.9-1.9c-0.6,0-1.1,0.2-1.4,0.5
                  S22.2,10.5,22.2,11.2z"/>
                <path d="M33.7,17.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V13H31c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C34.8,17.1,34.3,17.2,33.7,17.2z M33.4,9.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  C34.6,9.6,34.1,9.4,33.4,9.4z"/>
                <path d="M50,17v-5.6c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.3-0.7-0.5-1.4-0.5c-0.8,0-1.4,0.2-1.8,0.7c-0.4,0.5-0.6,1.2-0.6,2.1V17h-1.3
                  v-5.6c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.3-0.7-0.5-1.4-0.5c-0.8,0-1.4,0.2-1.8,0.7c-0.4,0.5-0.6,1.3-0.6,2.4V17h-1.3V8.4h1.1
                  l0.2,1.2h0.1c0.2-0.4,0.6-0.7,1-1s0.9-0.4,1.5-0.4c1.3,0,2.2,0.5,2.6,1.5h0.1c0.3-0.4,0.6-0.8,1.1-1.1s1-0.4,1.7-0.4
                  c1,0,1.7,0.2,2.2,0.7c0.5,0.5,0.7,1.3,0.7,2.4V17H50z"/>
                <path d="M57.6,17.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3c0.7-0.8,1.6-1.2,2.7-1.2
                  c1.1,0,1.9,0.4,2.5,1.1s0.9,1.6,0.9,2.8V13h-5.9c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2
                  c-0.5,0.2-0.9,0.3-1.3,0.4S58.2,17.2,57.6,17.2z M57.2,9.4c-0.7,0-1.2,0.2-1.6,0.7s-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  S57.9,9.4,57.2,9.4z"/>
                <path d="M62.9,6.1c0-0.3,0.1-0.5,0.2-0.7s0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2s0.2,0.4,0.2,0.6s-0.1,0.5-0.2,0.7
                  S63.8,7,63.6,7c-0.2,0-0.4-0.1-0.5-0.2S62.9,6.4,62.9,6.1z M64.3,17H63V8.4h1.3V17z"/>
                <path d="M72.9,17v-5.5c0-0.7-0.2-1.2-0.5-1.6s-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7s-0.6,1.3-0.6,2.4V17H67V8.4h1.1l0.2,1.2
                  h0.1c0.3-0.4,0.6-0.7,1.1-1s1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7s0.8,1.3,0.8,2.4V17H72.9z"/>
                <path d="M80.4,17.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V13h-5.9c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  S81,17.2,80.4,17.2z M80.1,9.4c-0.7,0-1.2,0.2-1.6,0.7s-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9S80.8,9.4,80.1,9.4z"/>
              </g>
              <g class="st2">
                <path d="M6.6,30h3.9v5.6c-0.6,0.2-1.2,0.3-1.8,0.4s-1.3,0.1-2.2,0.1c-1.7,0-3.1-0.5-4-1.5c-1-1-1.4-2.5-1.4-4.3
                  c0-1.2,0.2-2.2,0.7-3.1s1.2-1.6,2.1-2c0.9-0.5,1.9-0.7,3.1-0.7c1.2,0,2.4,0.2,3.4,0.7l-0.5,1.2c-1-0.4-2-0.7-3-0.7
                  c-1.4,0-2.5,0.4-3.3,1.2s-1.2,2-1.2,3.4c0,1.5,0.4,2.7,1.1,3.5C4.3,34.6,5.4,35,6.8,35c0.8,0,1.6-0.1,2.3-0.3v-3.5H6.6V30z"/>
                <path d="M16.6,36.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V32h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C17.7,36.1,17.2,36.2,16.6,36.2z M16.3,28.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  C17.5,28.6,17,28.4,16.3,28.4z"/>
                <path d="M27.5,33.7c0,0.8-0.3,1.4-0.9,1.8s-1.4,0.6-2.5,0.6c-1.1,0-2-0.2-2.7-0.5v-1.2c0.4,0.2,0.9,0.4,1.3,0.5
                  c0.5,0.1,0.9,0.2,1.4,0.2c0.7,0,1.2-0.1,1.6-0.3c0.4-0.2,0.5-0.5,0.5-1c0-0.3-0.1-0.6-0.4-0.9s-0.9-0.5-1.7-0.8
                  c-0.8-0.3-1.4-0.6-1.7-0.8s-0.6-0.5-0.8-0.8s-0.2-0.6-0.2-1c0-0.7,0.3-1.2,0.9-1.7c0.6-0.4,1.3-0.6,2.3-0.6
                  c0.9,0,1.8,0.2,2.7,0.6l-0.5,1.1c-0.9-0.4-1.6-0.5-2.3-0.5c-0.6,0-1.1,0.1-1.4,0.3s-0.5,0.5-0.5,0.8c0,0.2,0.1,0.4,0.2,0.6
                  s0.3,0.3,0.6,0.5c0.3,0.1,0.8,0.4,1.5,0.6c1,0.4,1.7,0.7,2.1,1.1C27.3,32.6,27.5,33.1,27.5,33.7z"/>
                <path d="M30.9,27.4V33c0,0.7,0.2,1.2,0.5,1.6c0.3,0.3,0.8,0.5,1.5,0.5c0.9,0,1.6-0.2,2-0.7s0.6-1.3,0.6-2.4v-4.5h1.3V36h-1.1
                  l-0.2-1.1h-0.1c-0.3,0.4-0.6,0.7-1.1,1c-0.5,0.2-1,0.3-1.6,0.3c-1,0-1.8-0.2-2.3-0.7c-0.5-0.5-0.8-1.3-0.8-2.4v-5.6H30.9z"/>
                <path d="M45.3,36v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7c-0.4,0.5-0.6,1.3-0.6,2.4V36h-1.3
                  v-8.6h1.1l0.2,1.2h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7c0.5,0.5,0.8,1.3,0.8,2.4V36H45.3z"/>
                <path d="M55.1,34.9L55.1,34.9c-0.7,0.9-1.6,1.3-2.8,1.3c-1.1,0-2-0.4-2.6-1.1c-0.6-0.8-0.9-1.9-0.9-3.3s0.3-2.5,0.9-3.3
                  s1.5-1.2,2.6-1.2c1.2,0,2.1,0.4,2.7,1.3h0.1l-0.1-0.6l0-0.6v-3.5h1.3V36h-1.1L55.1,34.9z M52.5,35.1c0.9,0,1.5-0.2,1.9-0.7
                  S55,33.1,55,32v-0.3c0-1.2-0.2-2.1-0.6-2.6c-0.4-0.5-1-0.8-1.9-0.8c-0.8,0-1.3,0.3-1.7,0.9c-0.4,0.6-0.6,1.4-0.6,2.5
                  c0,1.1,0.2,1.9,0.6,2.5C51.1,34.8,51.7,35.1,52.5,35.1z"/>
                <path d="M64.9,36v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7s-0.6,1.3-0.6,2.4V36h-1.3V23.8
                  h1.3v3.7c0,0.4,0,0.8-0.1,1.1h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.4,1.6-0.4c1,0,1.8,0.2,2.4,0.7s0.8,1.3,0.8,2.4V36H64.9z"
                  />
                <path d="M72.5,36.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V32h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C73.6,36.1,73.1,36.2,72.5,36.2z M72.2,28.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  C73.4,28.6,72.8,28.4,72.2,28.4z"/>
                <path d="M77.8,25.1c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.2,0.4,0.2,0.6
                  s-0.1,0.5-0.2,0.7c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2C77.8,25.6,77.8,25.4,77.8,25.1z M79.2,36h-1.3v-8.6h1.3V36
                  z"/>
                <path d="M84.7,35.1c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2s-0.5,0.1-0.7,0.1
                  c-1.7,0-2.5-0.9-2.5-2.6v-5.1h-1.2v-0.6l1.2-0.5l0.5-1.8h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2
                  C83.9,34.9,84.3,35.1,84.7,35.1z"/>
              </g>
            </g>
            <g id="Vitalität" transform="translate(492.000000, 317.000000)">
              <g class="st2">
                <path d="M8.6,5.6H10L5.9,17H4.6L0.5,5.6h1.4L4.5,13c0.3,0.8,0.5,1.7,0.7,2.5c0.2-0.8,0.4-1.7,0.7-2.5L8.6,5.6z"/>
                <path d="M11.3,6.1c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.2,0.4,0.2,0.6
                  s-0.1,0.5-0.2,0.7C12.4,6.9,12.3,7,12.1,7c-0.2,0-0.4-0.1-0.5-0.2C11.4,6.6,11.3,6.4,11.3,6.1z M12.7,17h-1.3V8.4h1.3V17z"/>
                <path d="M18.2,16.1c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1C19.2,17,19,17,18.8,17.1s-0.5,0.1-0.7,0.1
                  c-1.7,0-2.5-0.9-2.5-2.6V9.4h-1.2V8.8l1.2-0.5l0.5-1.8h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2
                  C17.4,15.9,17.8,16.1,18.2,16.1z"/>
                <path d="M26.4,17l-0.3-1.2H26c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7c-0.5-0.4-0.7-1.1-0.7-1.9
                  c0-1.7,1.4-2.6,4.1-2.7l1.5,0v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7l-0.4-1
                  c0.4-0.2,0.9-0.4,1.4-0.5c0.5-0.1,1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2V17H26.4z M23.4,16.1
                  c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1
                  C22.5,16,22.9,16.1,23.4,16.1z"/>
                <path d="M31.3,17H30V4.8h1.3V17z"/>
                <path d="M33.9,6.1c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.2,0.4,0.2,0.6
                  s-0.1,0.5-0.2,0.7C35.1,6.9,34.9,7,34.7,7c-0.2,0-0.4-0.1-0.5-0.2C34,6.6,33.9,6.4,33.9,6.1z M35.3,17H34V8.4h1.3V17z"/>
                <path d="M40.9,16.1c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2s-0.5,0.1-0.7,0.1
                  c-1.7,0-2.5-0.9-2.5-2.6V9.4H37V8.8l1.2-0.5l0.5-1.8h0.8v2H42v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2C40.1,15.9,40.4,16.1,40.9,16.1z"
                  />
                <path d="M49,17l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7c-0.5-0.4-0.7-1.1-0.7-1.9
                  c0-1.7,1.4-2.6,4.1-2.7l1.5,0v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7l-0.4-1
                  c0.4-0.2,0.9-0.4,1.4-0.5c0.5-0.1,1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2V17H49z M46.1,16.1c0.8,0,1.4-0.2,1.9-0.7
                  s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1
                  C45.2,16,45.6,16.1,46.1,16.1z M44.5,6.1c0-0.3,0.1-0.5,0.2-0.6c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2
                  C45.9,5.7,46,5.8,46,6.1c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2C44.6,6.6,44.5,6.4,44.5,6.1z
                   M47.5,6.1c0-0.3,0.1-0.5,0.2-0.6c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.6
                  c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2C47.6,6.6,47.5,6.4,47.5,6.1z"/>
                <path d="M55.4,16.1c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2s-0.5,0.1-0.7,0.1
                  c-1.7,0-2.5-0.9-2.5-2.6V9.4h-1.2V8.8l1.2-0.5l0.5-1.8H54v2h2.5v1H54v5c0,0.5,0.1,0.9,0.4,1.2C54.6,15.9,55,16.1,55.4,16.1z"/>
              </g>
            </g>
            <g transform="translate(592.000000, 317.000000)">
              <g class="st2">
                <path d="M8.5,14c0,1-0.4,1.8-1.1,2.4c-0.7,0.6-1.7,0.8-3,0.8c-1.4,0-2.4-0.2-3.1-0.5v-1.3c0.5,0.2,1,0.4,1.5,0.5
                  C3.4,15.9,4,16,4.5,16c0.9,0,1.6-0.2,2-0.5c0.4-0.3,0.7-0.8,0.7-1.4c0-0.4-0.1-0.7-0.2-1c-0.2-0.3-0.4-0.5-0.8-0.7
                  c-0.4-0.2-0.9-0.5-1.7-0.7c-1.1-0.4-1.8-0.8-2.3-1.4c-0.5-0.5-0.7-1.2-0.7-2c0-0.9,0.3-1.6,1-2.1C3.1,5.7,4,5.4,5.1,5.4
                  c1.1,0,2.2,0.2,3.1,0.6L7.8,7.2C6.9,6.8,6,6.6,5.1,6.6c-0.7,0-1.3,0.2-1.6,0.5C3,7.4,2.8,7.8,2.8,8.3c0,0.4,0.1,0.7,0.2,1
                  C3.2,9.5,3.4,9.8,3.8,10c0.3,0.2,0.9,0.4,1.6,0.7c1.2,0.4,2,0.9,2.5,1.4C8.3,12.5,8.5,13.2,8.5,14z"/>
                <path d="M18,12.7c0,1.4-0.4,2.5-1.1,3.3c-0.7,0.8-1.7,1.2-2.9,1.2c-0.8,0-1.4-0.2-2-0.5s-1.1-0.9-1.4-1.5
                  c-0.3-0.7-0.5-1.5-0.5-2.4c0-1.4,0.3-2.5,1-3.3c0.7-0.8,1.7-1.2,2.9-1.2c1.2,0,2.1,0.4,2.9,1.2C17.7,10.3,18,11.4,18,12.7z
                   M11.5,12.7c0,1.1,0.2,1.9,0.7,2.5s1.1,0.9,1.9,0.9c0.8,0,1.5-0.3,1.9-0.9c0.4-0.6,0.7-1.4,0.7-2.5c0-1.1-0.2-1.9-0.7-2.5
                  c-0.4-0.6-1.1-0.8-1.9-0.8c-0.8,0-1.5,0.3-1.9,0.8C11.7,10.8,11.5,11.6,11.5,12.7z"/>
                <path d="M25.8,17h-6.2v-0.9l4.7-6.7h-4.4v-1h5.8v1L21.1,16h4.7V17z"/>
                <path d="M27.7,6.1c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.2,0.4,0.2,0.6
                  S29.2,6.6,29,6.8C28.8,6.9,28.7,7,28.5,7c-0.2,0-0.4-0.1-0.5-0.2C27.8,6.6,27.7,6.4,27.7,6.1z M29.1,17h-1.3V8.4h1.3V17z"/>
                <path d="M37.1,17l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7
                  c-0.5-0.4-0.7-1.1-0.7-1.9c0-1.7,1.4-2.6,4.1-2.7l1.5,0v-0.5c0-0.7-0.1-1.2-0.4-1.5S35.6,9.4,35,9.4c-0.7,0-1.5,0.2-2.4,0.7
                  l-0.4-1c0.4-0.2,0.9-0.4,1.4-0.5c0.5-0.1,1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2V17H37.1z M34.2,16.1
                  c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1
                  C33.3,16,33.7,16.1,34.2,16.1z"/>
                <path d="M42,17h-1.3V4.8H42V17z"/>
                <path d="M48.4,17.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V13h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C49.5,17.1,49,17.2,48.4,17.2z M48.1,9.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  C49.3,9.6,48.7,9.4,48.1,9.4z"/>
              </g>
              <g class="st2">
                <path d="M11,31.3V36H9.6V24.6h3.1c1.4,0,2.4,0.3,3.1,0.8s1,1.3,1,2.4c0,1.5-0.8,2.5-2.3,3.1l3.1,5.1h-1.6l-2.8-4.8H11z
                   M11,30.1h1.8c0.9,0,1.6-0.2,2.1-0.6s0.7-0.9,0.7-1.7c0-0.8-0.2-1.3-0.7-1.6s-1.2-0.5-2.1-0.5H11V30.1z"/>
                <path d="M26.7,31.7c0,1.4-0.4,2.5-1.1,3.3s-1.7,1.2-2.9,1.2c-0.8,0-1.4-0.2-2-0.5s-1.1-0.9-1.4-1.5s-0.5-1.5-0.5-2.4
                  c0-1.4,0.3-2.5,1-3.3s1.7-1.2,2.9-1.2c1.2,0,2.1,0.4,2.9,1.2S26.7,30.4,26.7,31.7z M20.2,31.7c0,1.1,0.2,1.9,0.7,2.5
                  s1.1,0.9,1.9,0.9s1.5-0.3,1.9-0.9s0.7-1.4,0.7-2.5c0-1.1-0.2-1.9-0.7-2.5s-1.1-0.8-1.9-0.8c-0.8,0-1.5,0.3-1.9,0.8
                  S20.2,30.6,20.2,31.7z"/>
                <path d="M30.3,36H29V23.8h1.3V36z"/>
                <path d="M34.3,36H33V23.8h1.3V36z"/>
                <path d="M40.7,36.2c-1.3,0-2.3-0.4-3-1.2s-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V32h-5.9c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  S41.3,36.2,40.7,36.2z M40.3,28.4c-0.7,0-1.2,0.2-1.6,0.7s-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9S41,28.4,40.3,28.4z"/>
              </g>
            </g>
            <g transform="translate(672.000000, 317.000000)">
              <g class="st2">
                <path d="M8.4,17H2.1V5.6h6.4v1.2h-5v3.7h4.7v1.2H3.4v4.2h5V17z"/>
                <path d="M21.7,17v-5.6c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.3-0.7-0.5-1.4-0.5c-0.8,0-1.4,0.2-1.8,0.7c-0.4,0.5-0.6,1.2-0.6,2.1V17
                  h-1.3v-5.6c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.3-0.7-0.5-1.4-0.5c-0.8,0-1.4,0.2-1.8,0.7c-0.4,0.5-0.6,1.3-0.6,2.4V17h-1.3V8.4h1.1
                  L12,9.6h0.1c0.2-0.4,0.6-0.7,1-1s0.9-0.4,1.5-0.4c1.3,0,2.2,0.5,2.6,1.5h0.1c0.3-0.4,0.6-0.8,1.1-1.1s1-0.4,1.7-0.4
                  c1,0,1.7,0.2,2.2,0.7c0.5,0.5,0.7,1.3,0.7,2.4V17H21.7z"/>
                <path d="M33,12.7c0,1.4-0.4,2.5-1.1,3.3c-0.7,0.8-1.7,1.2-2.9,1.2c-0.8,0-1.4-0.2-2-0.5s-1.1-0.9-1.4-1.5
                  c-0.3-0.7-0.5-1.5-0.5-2.4c0-1.4,0.3-2.5,1-3.3c0.7-0.8,1.7-1.2,2.9-1.2c1.2,0,2.1,0.4,2.9,1.2C32.7,10.3,33,11.4,33,12.7z
                   M26.5,12.7c0,1.1,0.2,1.9,0.7,2.5s1.1,0.9,1.9,0.9c0.8,0,1.5-0.3,1.9-0.9c0.4-0.6,0.7-1.4,0.7-2.5c0-1.1-0.2-1.9-0.7-2.5
                  c-0.4-0.6-1.1-0.8-1.9-0.8c-0.8,0-1.5,0.3-1.9,0.8C26.7,10.8,26.5,11.6,26.5,12.7z"/>
                <path d="M38.1,16.1c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2s-0.5,0.1-0.7,0.1
                  c-1.7,0-2.5-0.9-2.5-2.6V9.4h-1.2V8.8l1.2-0.5L36,6.5h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2C37.3,15.9,37.7,16.1,38.1,16.1
                  z"/>
                <path d="M40.9,6.1c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.2,0.4,0.2,0.6
                  s-0.1,0.5-0.2,0.7C42,6.9,41.8,7,41.6,7c-0.2,0-0.4-0.1-0.5-0.2C40.9,6.6,40.9,6.4,40.9,6.1z M42.3,17H41V8.4h1.3V17z"/>
                <path d="M52.4,12.7c0,1.4-0.4,2.5-1.1,3.3c-0.7,0.8-1.7,1.2-2.9,1.2c-0.8,0-1.4-0.2-2-0.5s-1.1-0.9-1.4-1.5
                  c-0.3-0.7-0.5-1.5-0.5-2.4c0-1.4,0.3-2.5,1-3.3c0.7-0.8,1.7-1.2,2.9-1.2c1.2,0,2.1,0.4,2.9,1.2C52.1,10.3,52.4,11.4,52.4,12.7z
                   M45.9,12.7c0,1.1,0.2,1.9,0.7,2.5s1.1,0.9,1.9,0.9c0.8,0,1.5-0.3,1.9-0.9c0.4-0.6,0.7-1.4,0.7-2.5c0-1.1-0.2-1.9-0.7-2.5
                  c-0.4-0.6-1.1-0.8-1.9-0.8c-0.8,0-1.5,0.3-1.9,0.8C46.1,10.8,45.9,11.6,45.9,12.7z"/>
                <path d="M60.5,17v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7c-0.4,0.5-0.6,1.3-0.6,2.4V17h-1.3
                  V8.4h1.1l0.2,1.2H56c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7c0.5,0.5,0.8,1.3,0.8,2.4V17H60.5z"/>
                <path d="M69.8,17l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7
                  c-0.5-0.4-0.7-1.1-0.7-1.9c0-1.7,1.4-2.6,4.1-2.7l1.5,0v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7
                  l-0.4-1c0.4-0.2,0.9-0.4,1.4-0.5c0.5-0.1,1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2V17H69.8z M66.8,16.1
                  c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1
                  C65.9,16,66.3,16.1,66.8,16.1z"/>
                <path d="M74.7,17h-1.3V4.8h1.3V17z"/>
                <path d="M81.1,17.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V13h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C82.1,17.1,81.6,17.2,81.1,17.2z M80.7,9.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  C81.9,9.6,81.4,9.4,80.7,9.4z"/>
              </g>
              <g class="st2">
                <path d="M27.4,31.3V36H26V24.6h3.1c1.4,0,2.4,0.3,3.1,0.8s1,1.3,1,2.4c0,1.5-0.8,2.5-2.3,3.1l3.1,5.1h-1.6l-2.8-4.8H27.4z
                   M27.4,30.1h1.8c0.9,0,1.6-0.2,2.1-0.6s0.7-0.9,0.7-1.7c0-0.8-0.2-1.3-0.7-1.6s-1.2-0.5-2.1-0.5h-1.7V30.1z"/>
                <path d="M43.1,31.7c0,1.4-0.4,2.5-1.1,3.3s-1.7,1.2-2.9,1.2c-0.8,0-1.4-0.2-2-0.5s-1.1-0.9-1.4-1.5s-0.5-1.5-0.5-2.4
                  c0-1.4,0.3-2.5,1-3.3s1.7-1.2,2.9-1.2c1.2,0,2.1,0.4,2.9,1.2S43.1,30.4,43.1,31.7z M36.6,31.7c0,1.1,0.2,1.9,0.7,2.5
                  s1.1,0.9,1.9,0.9s1.5-0.3,1.9-0.9s0.7-1.4,0.7-2.5c0-1.1-0.2-1.9-0.7-2.5s-1.1-0.8-1.9-0.8c-0.8,0-1.5,0.3-1.9,0.8
                  S36.6,30.6,36.6,31.7z"/>
                <path d="M46.7,36h-1.3V23.8h1.3V36z"/>
                <path d="M50.7,36h-1.3V23.8h1.3V36z"/>
                <path d="M57.1,36.2c-1.3,0-2.3-0.4-3-1.2S53,33.2,53,31.8c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V32h-5.9c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  S57.7,36.2,57.1,36.2z M56.8,28.4c-0.7,0-1.2,0.2-1.6,0.7s-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9S57.4,28.4,56.8,28.4z"/>
              </g>
            </g>
            <g transform="translate(773.000000, 317.000000)">
              <g class="st2">
                <path d="M19.8,17L15.9,6.9h-0.1C16,7.7,16,8.6,16,9.7V17h-1.2V5.6h2l3.6,9.4h0.1l3.6-9.4h2V17h-1.3V9.6c0-0.8,0-1.8,0.1-2.8
                  h-0.1L20.9,17H19.8z"/>
                <path d="M32.6,17.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V13h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C33.7,17.1,33.2,17.2,32.6,17.2z M32.3,9.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  C33.5,9.6,33,9.4,32.3,9.4z"/>
                <path d="M43.8,17v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7c-0.4,0.5-0.6,1.3-0.6,2.4V17H38
                  V8.4H39l0.2,1.2h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7c0.5,0.5,0.8,1.3,0.8,2.4V17H43.8z"/>
                <path d="M50.6,16.1c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2s-0.5,0.1-0.7,0.1
                  c-1.7,0-2.5-0.9-2.5-2.6V9.4h-1.2V8.8l1.2-0.5l0.5-1.8h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2
                  C49.8,15.9,50.1,16.1,50.6,16.1z"/>
                <path d="M58.7,17l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7
                  c-0.5-0.4-0.7-1.1-0.7-1.9c0-1.7,1.4-2.6,4.1-2.7l1.5,0v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7
                  l-0.4-1c0.4-0.2,0.9-0.4,1.4-0.5c0.5-0.1,1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2V17H58.7z M55.8,16.1
                  c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1
                  C54.9,16,55.3,16.1,55.8,16.1z"/>
                <path d="M63.7,17h-1.3V4.8h1.3V17z"/>
                <path d="M70,17.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V13h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C71.1,17.1,70.6,17.2,70,17.2z M69.7,9.4C69,9.4,68.4,9.6,68,10c-0.4,0.4-0.7,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  C70.9,9.6,70.3,9.4,69.7,9.4z"/>
              </g>
              <g class="st2">
                <path d="M7.1,30H11v5.6c-0.6,0.2-1.2,0.3-1.8,0.4S7.8,36.2,7,36.2c-1.7,0-3.1-0.5-4-1.5c-1-1-1.4-2.5-1.4-4.3
                  c0-1.2,0.2-2.2,0.7-3.1s1.2-1.6,2.1-2c0.9-0.5,1.9-0.7,3.1-0.7c1.2,0,2.4,0.2,3.4,0.7l-0.5,1.2c-1-0.4-2-0.7-3-0.7
                  c-1.4,0-2.5,0.4-3.3,1.2s-1.2,2-1.2,3.4c0,1.5,0.4,2.7,1.1,3.5C4.8,34.6,5.9,35,7.3,35c0.8,0,1.6-0.1,2.3-0.3v-3.5H7.1V30z"/>
                <path d="M17.1,36.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V32h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C18.2,36.1,17.7,36.2,17.1,36.2z M16.8,28.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  C18,28.6,17.5,28.4,16.8,28.4z"/>
                <path d="M28,33.7c0,0.8-0.3,1.4-0.9,1.8s-1.4,0.6-2.5,0.6c-1.1,0-2-0.2-2.7-0.5v-1.2c0.4,0.2,0.9,0.4,1.3,0.5
                  c0.5,0.1,0.9,0.2,1.4,0.2c0.7,0,1.2-0.1,1.6-0.3c0.4-0.2,0.5-0.5,0.5-1c0-0.3-0.1-0.6-0.4-0.9s-0.9-0.5-1.7-0.8
                  c-0.8-0.3-1.4-0.6-1.7-0.8s-0.6-0.5-0.8-0.8s-0.2-0.6-0.2-1c0-0.7,0.3-1.2,0.9-1.7c0.6-0.4,1.3-0.6,2.3-0.6
                  c0.9,0,1.8,0.2,2.7,0.6l-0.5,1.1c-0.9-0.4-1.6-0.5-2.3-0.5c-0.6,0-1.1,0.1-1.4,0.3s-0.5,0.5-0.5,0.8c0,0.2,0.1,0.4,0.2,0.6
                  s0.3,0.3,0.6,0.5c0.3,0.1,0.8,0.4,1.5,0.6c1,0.4,1.7,0.7,2.1,1.1C27.8,32.6,28,33.1,28,33.7z"/>
                <path d="M31.4,27.4V33c0,0.7,0.2,1.2,0.5,1.6c0.3,0.3,0.8,0.5,1.5,0.5c0.9,0,1.6-0.2,2-0.7s0.6-1.3,0.6-2.4v-4.5h1.3V36h-1.1
                  l-0.2-1.1h-0.1c-0.3,0.4-0.6,0.7-1.1,1c-0.5,0.2-1,0.3-1.6,0.3c-1,0-1.8-0.2-2.3-0.7C30.3,34.9,30,34.1,30,33v-5.6H31.4z"/>
                <path d="M45.8,36v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7c-0.4,0.5-0.6,1.3-0.6,2.4V36H40
                  v-8.6H41l0.2,1.2h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7c0.5,0.5,0.8,1.3,0.8,2.4V36H45.8z"/>
                <path d="M55.6,34.9L55.6,34.9c-0.7,0.9-1.6,1.3-2.8,1.3c-1.1,0-2-0.4-2.6-1.1c-0.6-0.8-0.9-1.9-0.9-3.3s0.3-2.5,0.9-3.3
                  s1.5-1.2,2.6-1.2c1.2,0,2.1,0.4,2.7,1.3h0.1l-0.1-0.6l0-0.6v-3.5h1.3V36h-1.1L55.6,34.9z M53,35.1c0.9,0,1.5-0.2,1.9-0.7
                  s0.6-1.3,0.6-2.3v-0.3c0-1.2-0.2-2.1-0.6-2.6c-0.4-0.5-1-0.8-1.9-0.8c-0.8,0-1.3,0.3-1.7,0.9c-0.4,0.6-0.6,1.4-0.6,2.5
                  c0,1.1,0.2,1.9,0.6,2.5C51.6,34.8,52.2,35.1,53,35.1z"/>
                <path d="M65.4,36v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7s-0.6,1.3-0.6,2.4V36h-1.3V23.8
                  h1.3v3.7c0,0.4,0,0.8-0.1,1.1h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.4,1.6-0.4c1,0,1.8,0.2,2.4,0.7s0.8,1.3,0.8,2.4V36H65.4z"
                  />
                <path d="M73,36.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V32h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C74.1,36.1,73.6,36.2,73,36.2z M72.7,28.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  C73.9,28.6,73.3,28.4,72.7,28.4z"/>
                <path d="M78.3,25.1c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.2,0.4,0.2,0.6
                  s-0.1,0.5-0.2,0.7C79.4,25.9,79.2,26,79,26c-0.2,0-0.4-0.1-0.5-0.2C78.3,25.6,78.3,25.4,78.3,25.1z M79.7,36h-1.3v-8.6h1.3V36z
                  "/>
                <path d="M85.2,35.1c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1C86.2,36,86,36,85.7,36.1s-0.5,0.1-0.7,0.1
                  c-1.7,0-2.5-0.9-2.5-2.6v-5.1h-1.2v-0.6l1.2-0.5l0.5-1.8h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2
                  C84.4,34.9,84.8,35.1,85.2,35.1z"/>
              </g>
            </g>
            <g id="_x30_" transform="translate(48.000000, 299.000000)">
              <g class="st2">
                <path d="M7.3,10c0,1.7-0.3,3-0.8,3.9S5.1,15.1,4,15.1c-1.1,0-1.9-0.4-2.5-1.3C1,13,0.7,11.7,0.7,10c0-1.7,0.3-3,0.8-3.9
                  S2.9,4.8,4,4.8c1.1,0,1.9,0.4,2.5,1.3S7.3,8.3,7.3,10z M1.8,10c0,1.5,0.2,2.5,0.5,3.2c0.3,0.7,0.9,1,1.6,1c0.8,0,1.3-0.3,1.6-1
                  C6,12.5,6.1,11.4,6.1,10S6,7.5,5.6,6.8c-0.3-0.7-0.9-1-1.6-1c-0.7,0-1.3,0.3-1.6,1C2,7.5,1.8,8.5,1.8,10z"/>
              </g>
            </g>
            <g id="_x32_0" transform="translate(39.000000, 246.000000)">
              <g class="st2">
                <path d="M8.2,15H1.7v-1l2.6-2.6c0.8-0.8,1.3-1.4,1.6-1.7s0.4-0.7,0.6-1s0.2-0.7,0.2-1.1c0-0.5-0.2-1-0.5-1.3
                  C5.8,6,5.4,5.8,4.8,5.8C4.4,5.8,4,5.9,3.6,6S2.9,6.4,2.4,6.8L1.8,6c0.9-0.8,1.9-1.1,3-1.1c0.9,0,1.7,0.2,2.2,0.7
                  c0.5,0.5,0.8,1.1,0.8,1.9c0,0.6-0.2,1.3-0.5,1.9s-1,1.4-2,2.4l-2.2,2.1v0.1h5.1V15z"/>
                <path d="M16.3,10c0,1.7-0.3,3-0.8,3.9s-1.4,1.3-2.5,1.3c-1.1,0-1.9-0.4-2.5-1.3C10,13,9.7,11.7,9.7,10c0-1.7,0.3-3,0.8-3.9
                  s1.4-1.3,2.5-1.3c1.1,0,1.9,0.4,2.5,1.3S16.3,8.3,16.3,10z M10.8,10c0,1.5,0.2,2.5,0.5,3.2c0.3,0.7,0.9,1,1.6,1
                  c0.8,0,1.3-0.3,1.6-1c0.3-0.7,0.5-1.7,0.5-3.2S15,7.5,14.6,6.8c-0.3-0.7-0.9-1-1.6-1c-0.7,0-1.3,0.3-1.6,1
                  C11,7.5,10.8,8.5,10.8,10z"/>
              </g>
            </g>
            <g id="_x34_0" transform="translate(39.000000, 194.000000)">
              <g class="st2">
                <path d="M8.7,12.7H7.2V15H6.1v-2.3H1.3v-1L6,5h1.2v6.7h1.5V12.7z M6.1,11.7V8.3c0-0.7,0-1.4,0.1-2.2H6.2
                  C5.9,6.6,5.7,6.9,5.5,7.2l-3.1,4.4H6.1z"/>
                <path d="M16.3,10c0,1.7-0.3,3-0.8,3.9s-1.4,1.3-2.5,1.3c-1.1,0-1.9-0.4-2.5-1.3C10,13,9.7,11.7,9.7,10c0-1.7,0.3-3,0.8-3.9
                  s1.4-1.3,2.5-1.3c1.1,0,1.9,0.4,2.5,1.3S16.3,8.3,16.3,10z M10.8,10c0,1.5,0.2,2.5,0.5,3.2c0.3,0.7,0.9,1,1.6,1
                  c0.8,0,1.3-0.3,1.6-1c0.3-0.7,0.5-1.7,0.5-3.2S15,7.5,14.6,6.8c-0.3-0.7-0.9-1-1.6-1c-0.7,0-1.3,0.3-1.6,1
                  C11,7.5,10.8,8.5,10.8,10z"/>
              </g>
            </g>
            <g id="_x36_0" transform="translate(39.000000, 140.000000)">
              <g class="st2">
                <path d="M1.8,10.7c0-2,0.4-3.4,1.1-4.4c0.8-1,1.9-1.5,3.4-1.5c0.5,0,0.9,0,1.2,0.1v1C7.2,5.9,6.8,5.8,6.3,5.8
                  c-1.1,0-1.9,0.3-2.5,1C3.3,7.5,3,8.5,3,9.9H3c0.5-0.8,1.3-1.2,2.4-1.2C6.3,8.8,7,9,7.5,9.6s0.8,1.3,0.8,2.2
                  c0,1-0.3,1.9-0.9,2.5c-0.6,0.6-1.3,0.9-2.3,0.9c-1,0-1.9-0.4-2.5-1.2C2.1,13.2,1.8,12.1,1.8,10.7z M5.1,14.2
                  c0.6,0,1.1-0.2,1.5-0.6c0.4-0.4,0.5-1,0.5-1.8c0-0.7-0.2-1.2-0.5-1.6S5.9,9.7,5.2,9.7c-0.4,0-0.8,0.1-1.1,0.3s-0.6,0.4-0.8,0.7
                  S3,11.2,3,11.5C3,12,3,12.4,3.2,12.9s0.4,0.7,0.8,1S4.7,14.2,5.1,14.2z"/>
                <path d="M16.3,10c0,1.7-0.3,3-0.8,3.9s-1.4,1.3-2.5,1.3c-1.1,0-1.9-0.4-2.5-1.3C10,13,9.7,11.7,9.7,10c0-1.7,0.3-3,0.8-3.9
                  s1.4-1.3,2.5-1.3c1.1,0,1.9,0.4,2.5,1.3S16.3,8.3,16.3,10z M10.8,10c0,1.5,0.2,2.5,0.5,3.2c0.3,0.7,0.9,1,1.6,1
                  c0.8,0,1.3-0.3,1.6-1c0.3-0.7,0.5-1.7,0.5-3.2S15,7.5,14.6,6.8c-0.3-0.7-0.9-1-1.6-1c-0.7,0-1.3,0.3-1.6,1
                  C11,7.5,10.8,8.5,10.8,10z"/>
              </g>
            </g>
            <g id="_x38_0" transform="translate(36.000000, 87.000000)">
              <g class="st2">
                <path d="M4.3,4.9c0.9,0,1.6,0.2,2.2,0.6s0.8,1,0.8,1.8c0,0.5-0.2,0.9-0.5,1.3S6.1,9.4,5.4,9.7c0.8,0.4,1.4,0.8,1.7,1.2
                  c0.3,0.4,0.5,0.9,0.5,1.5c0,0.8-0.3,1.5-0.9,2c-0.6,0.5-1.4,0.7-2.4,0.7c-1.1,0-1.9-0.2-2.5-0.7c-0.6-0.5-0.9-1.1-0.9-2
                  c0-1.1,0.7-2,2.1-2.7C2.5,9.4,2.1,9,1.8,8.6C1.5,8.2,1.4,7.8,1.4,7.2c0-0.7,0.3-1.3,0.8-1.7C2.7,5.1,3.4,4.9,4.3,4.9z
                   M2.2,12.5c0,0.5,0.2,1,0.6,1.3s0.9,0.5,1.6,0.5c0.7,0,1.2-0.2,1.6-0.5s0.6-0.8,0.6-1.3c0-0.4-0.2-0.8-0.5-1.2
                  c-0.4-0.3-1-0.7-1.9-1c-0.7,0.3-1.2,0.6-1.5,1C2.3,11.6,2.2,12,2.2,12.5z M4.3,5.8c-0.6,0-1,0.1-1.3,0.4S2.5,6.8,2.5,7.3
                  c0,0.4,0.1,0.8,0.4,1.1S3.7,9,4.4,9.3C5.1,9,5.5,8.7,5.8,8.4s0.4-0.7,0.4-1.1c0-0.5-0.2-0.8-0.5-1.1C5.4,5.9,4.9,5.8,4.3,5.8z"
                  />
                <path d="M15.7,10c0,1.7-0.3,3-0.8,3.9s-1.4,1.3-2.5,1.3c-1.1,0-1.9-0.4-2.5-1.3C9.3,13,9.1,11.7,9.1,10c0-1.7,0.3-3,0.8-3.9
                  s1.4-1.3,2.5-1.3c1.1,0,1.9,0.4,2.5,1.3S15.7,8.3,15.7,10z M10.2,10c0,1.5,0.2,2.5,0.5,3.2c0.3,0.7,0.9,1,1.6,1
                  c0.8,0,1.3-0.3,1.6-1c0.3-0.7,0.5-1.7,0.5-3.2S14.3,7.5,14,6.8c-0.3-0.7-0.9-1-1.6-1c-0.7,0-1.3,0.3-1.6,1
                  C10.4,7.5,10.2,8.5,10.2,10z"/>
              </g>
            </g>
            <g id="_x31_00" transform="translate(31.000000, 34.000000)">
              <g class="st2">
                <path d="M5.9,15H4.8V7.9c0-0.6,0-1.2,0.1-1.7C4.7,6.3,4.6,6.4,4.5,6.5S3.8,7,2.9,7.8L2.3,7l2.6-2h1V15z"/>
                <path d="M16.3,10c0,1.7-0.3,3-0.8,3.9s-1.4,1.3-2.5,1.3c-1.1,0-1.9-0.4-2.5-1.3C10,13,9.7,11.7,9.7,10c0-1.7,0.3-3,0.8-3.9
                  s1.4-1.3,2.5-1.3c1.1,0,1.9,0.4,2.5,1.3S16.3,8.3,16.3,10z M10.8,10c0,1.5,0.2,2.5,0.5,3.2c0.3,0.7,0.9,1,1.6,1
                  c0.8,0,1.3-0.3,1.6-1c0.3-0.7,0.5-1.7,0.5-3.2S15,7.5,14.6,6.8c-0.3-0.7-0.9-1-1.6-1c-0.7,0-1.3,0.3-1.6,1
                  C11,7.5,10.8,8.5,10.8,10z"/>
                <path d="M24.3,10c0,1.7-0.3,3-0.8,3.9s-1.4,1.3-2.5,1.3c-1.1,0-1.9-0.4-2.5-1.3C18,13,17.7,11.7,17.7,10c0-1.7,0.3-3,0.8-3.9
                  s1.4-1.3,2.5-1.3c1.1,0,1.9,0.4,2.5,1.3S24.3,8.3,24.3,10z M18.8,10c0,1.5,0.2,2.5,0.5,3.2c0.3,0.7,0.9,1,1.6,1
                  c0.8,0,1.3-0.3,1.6-1c0.3-0.7,0.5-1.7,0.5-3.2S23,7.5,22.6,6.8c-0.3-0.7-0.9-1-1.6-1c-0.7,0-1.3,0.3-1.6,1
                  C19,7.5,18.8,8.5,18.8,10z"/>
              </g>
            </g>
            <path id="Shape_1_" class="st3" d="M93,87h26v222H93V87z M192,96h26v213h-26V96z M292,141h26v168h-26V141z M392,130h26v179h-26
              V130z M492,127h26v182h-26V127z M591,83h26v226h-26V83z M690,73h26v236h-26V73z M789,121h26v188h-26V121z"/>
            <path id="Shape_2_" class="st4" d="M121,178h26v131h-26V178z M220,243h26v66h-26V243z M320,182h26v127h-26V182z M420,185h26v124
              h-26V185z M520,198h26v111h-26V198z M619,147h26v162h-26V147z M718,205h26v104h-26V205z M817,151h26v158h-26V151z"/>
            <circle id="Oval" class="st4" cx="478" cy="11" r="9"/>
            <circle id="Oval_1_" class="st3" cx="716" cy="11" r="9"/>
            <g id="Frauen-mit-Morbus-Fa" transform="translate(494.000000, 0.000000)">
              <g class="st2">
                <path d="M2.9,17H1.6V5.6h6.4v1.2h-5v4.2h4.7v1.2H2.9V17z"/>
                <path d="M13.5,8.3c0.4,0,0.7,0,1,0.1l-0.2,1.2c-0.4-0.1-0.7-0.1-0.9-0.1c-0.7,0-1.3,0.3-1.8,0.8s-0.7,1.3-0.7,2.1V17H9.6V8.4
                  h1.1l0.1,1.6h0.1c0.3-0.6,0.7-1,1.1-1.3C12.5,8.4,13,8.3,13.5,8.3z"/>
                <path d="M21.4,17l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7
                  c-0.5-0.4-0.7-1.1-0.7-1.9c0-1.7,1.4-2.6,4.1-2.7l1.5,0v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7
                  l-0.4-1c0.4-0.2,0.9-0.4,1.4-0.5c0.5-0.1,1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2V17H21.4z M18.5,16.1
                  c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1
                  C17.6,16,18,16.1,18.5,16.1z"/>
                <path d="M26.3,8.4V14c0,0.7,0.2,1.2,0.5,1.6c0.3,0.3,0.8,0.5,1.5,0.5c0.9,0,1.6-0.2,2-0.7s0.6-1.3,0.6-2.4V8.4h1.3V17h-1.1
                  l-0.2-1.1h-0.1c-0.3,0.4-0.6,0.7-1.1,1c-0.5,0.2-1,0.3-1.6,0.3c-1,0-1.8-0.2-2.3-0.7C25.2,15.9,25,15.1,25,14V8.4H26.3z"/>
                <path d="M38.5,17.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V13h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C39.6,17.1,39.1,17.2,38.5,17.2z M38.1,9.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  C39.3,9.6,38.8,9.4,38.1,9.4z"/>
                <path d="M49.7,17v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7c-0.4,0.5-0.6,1.3-0.6,2.4V17h-1.3
                  V8.4h1.1l0.2,1.2h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7c0.5,0.5,0.8,1.3,0.8,2.4V17H49.7z"/>
                <path d="M68.8,17v-5.6c0-0.7-0.1-1.2-0.4-1.5C68,9.6,67.6,9.4,67,9.4c-0.8,0-1.4,0.2-1.8,0.7c-0.4,0.5-0.6,1.2-0.6,2.1V17h-1.3
                  v-5.6c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.3-0.7-0.5-1.4-0.5c-0.8,0-1.4,0.2-1.8,0.7c-0.4,0.5-0.6,1.3-0.6,2.4V17h-1.3V8.4h1.1
                  l0.2,1.2h0.1c0.2-0.4,0.6-0.7,1-1s0.9-0.4,1.5-0.4c1.3,0,2.2,0.5,2.6,1.5h0.1c0.3-0.4,0.6-0.8,1.1-1.1s1-0.4,1.7-0.4
                  c1,0,1.7,0.2,2.2,0.7c0.5,0.5,0.7,1.3,0.7,2.4V17H68.8z"/>
                <path d="M72.6,6.1c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.2,0.4,0.2,0.6
                  s-0.1,0.5-0.2,0.7C73.8,6.9,73.6,7,73.4,7c-0.2,0-0.4-0.1-0.5-0.2C72.7,6.6,72.6,6.4,72.6,6.1z M74,17h-1.3V8.4H74V17z"/>
                <path d="M79.5,16.1c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2s-0.5,0.1-0.7,0.1
                  c-1.7,0-2.5-0.9-2.5-2.6V9.4h-1.2V8.8l1.2-0.5l0.5-1.8h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2
                  C78.8,15.9,79.1,16.1,79.5,16.1z"/>
                <path d="M91.8,17L87.9,6.9h-0.1C88,7.7,88,8.6,88,9.7V17h-1.2V5.6h2l3.6,9.4h0.1l3.6-9.4h2V17h-1.3V9.6c0-0.8,0-1.8,0.1-2.8
                  h-0.1L92.9,17H91.8z"/>
                <path d="M108.4,12.7c0,1.4-0.4,2.5-1.1,3.3c-0.7,0.8-1.7,1.2-2.9,1.2c-0.8,0-1.4-0.2-2-0.5s-1.1-0.9-1.4-1.5
                  c-0.3-0.7-0.5-1.5-0.5-2.4c0-1.4,0.3-2.5,1-3.3c0.7-0.8,1.7-1.2,2.9-1.2c1.2,0,2.1,0.4,2.9,1.2
                  C108.1,10.3,108.4,11.4,108.4,12.7z M101.9,12.7c0,1.1,0.2,1.9,0.7,2.5s1.1,0.9,1.9,0.9c0.8,0,1.5-0.3,1.9-0.9
                  c0.4-0.6,0.7-1.4,0.7-2.5c0-1.1-0.2-1.9-0.7-2.5c-0.4-0.6-1.1-0.8-1.9-0.8c-0.8,0-1.5,0.3-1.9,0.8
                  C102.1,10.8,101.9,11.6,101.9,12.7z"/>
                <path d="M114.6,8.3c0.4,0,0.7,0,1,0.1l-0.2,1.2c-0.4-0.1-0.7-0.1-0.9-0.1c-0.7,0-1.3,0.3-1.8,0.8s-0.7,1.3-0.7,2.1V17h-1.3V8.4
                  h1.1l0.1,1.6h0.1c0.3-0.6,0.7-1,1.1-1.3C113.6,8.4,114,8.3,114.6,8.3z"/>
                <path d="M121.2,8.3c1.1,0,2,0.4,2.6,1.2c0.6,0.8,0.9,1.9,0.9,3.3s-0.3,2.5-0.9,3.3c-0.6,0.8-1.5,1.2-2.6,1.2
                  c-0.6,0-1.1-0.1-1.5-0.3s-0.8-0.5-1.2-0.9h-0.1l-0.3,1.1h-0.9V4.8h1.3v3c0,0.7,0,1.3-0.1,1.8h0.1C119.1,8.7,120,8.3,121.2,8.3z
                   M121,9.4c-0.9,0-1.5,0.3-1.9,0.8s-0.6,1.4-0.6,2.6s0.2,2.1,0.6,2.6c0.4,0.5,1,0.8,1.9,0.8c0.8,0,1.4-0.3,1.8-0.9
                  s0.6-1.4,0.6-2.5c0-1.1-0.2-1.9-0.6-2.5S121.8,9.4,121,9.4z"/>
                <path d="M128.2,8.4V14c0,0.7,0.2,1.2,0.5,1.6c0.3,0.3,0.8,0.5,1.5,0.5c0.9,0,1.6-0.2,2-0.7s0.6-1.3,0.6-2.4V8.4h1.3V17H133
                  l-0.2-1.1h-0.1c-0.3,0.4-0.6,0.7-1.1,1c-0.5,0.2-1,0.3-1.6,0.3c-1,0-1.8-0.2-2.3-0.7c-0.5-0.5-0.8-1.3-0.8-2.4V8.4H128.2z"/>
                <path d="M142.4,14.7c0,0.8-0.3,1.4-0.9,1.8s-1.4,0.6-2.5,0.6c-1.1,0-2-0.2-2.7-0.5v-1.2c0.4,0.2,0.9,0.4,1.3,0.5
                  c0.5,0.1,0.9,0.2,1.4,0.2c0.7,0,1.2-0.1,1.6-0.3c0.4-0.2,0.5-0.5,0.5-1c0-0.3-0.1-0.6-0.4-0.9s-0.9-0.5-1.7-0.8
                  c-0.8-0.3-1.4-0.6-1.7-0.8s-0.6-0.5-0.8-0.8s-0.2-0.6-0.2-1c0-0.7,0.3-1.2,0.9-1.7c0.6-0.4,1.3-0.6,2.3-0.6
                  c0.9,0,1.8,0.2,2.7,0.6l-0.5,1.1c-0.9-0.4-1.6-0.5-2.3-0.5c-0.6,0-1.1,0.1-1.4,0.3s-0.5,0.5-0.5,0.8c0,0.2,0.1,0.4,0.2,0.6
                  s0.3,0.3,0.6,0.5c0.3,0.1,0.8,0.4,1.5,0.6c1,0.4,1.7,0.7,2.1,1.1C142.2,13.6,142.4,14.1,142.4,14.7z"/>
                <path d="M150.1,17h-1.3V5.6h6.4v1.2h-5v4.2h4.7v1.2h-4.7V17z"/>
                <path d="M162.1,17l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7
                  c-0.5-0.4-0.7-1.1-0.7-1.9c0-1.7,1.4-2.6,4.1-2.7l1.5,0v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7
                  l-0.4-1c0.4-0.2,0.9-0.4,1.4-0.5c0.5-0.1,1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2V17H162.1z M159.2,16.1
                  c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1
                  C158.3,16,158.7,16.1,159.2,16.1z"/>
                <path d="M169.8,8.3c1.1,0,2,0.4,2.6,1.2c0.6,0.8,0.9,1.9,0.9,3.3s-0.3,2.5-0.9,3.3c-0.6,0.8-1.5,1.2-2.6,1.2
                  c-0.6,0-1.1-0.1-1.5-0.3s-0.8-0.5-1.2-0.9H167l-0.3,1.1h-0.9V4.8h1.3v3c0,0.7,0,1.3-0.1,1.8h0.1C167.7,8.7,168.6,8.3,169.8,8.3
                  z M169.6,9.4c-0.9,0-1.5,0.3-1.9,0.8s-0.6,1.4-0.6,2.6s0.2,2.1,0.6,2.6c0.4,0.5,1,0.8,1.9,0.8c0.8,0,1.4-0.3,1.8-0.9
                  s0.6-1.4,0.6-2.5c0-1.1-0.2-1.9-0.6-2.5S170.4,9.4,169.6,9.4z"/>
                <path d="M179.5,8.3c0.4,0,0.7,0,1,0.1l-0.2,1.2c-0.4-0.1-0.7-0.1-0.9-0.1c-0.7,0-1.3,0.3-1.8,0.8s-0.7,1.3-0.7,2.1V17h-1.3V8.4
                  h1.1l0.1,1.6h0.1c0.3-0.6,0.7-1,1.1-1.3C178.5,8.4,179,8.3,179.5,8.3z"/>
                <path d="M180.8,8.4h1.4l1.9,4.9c0.4,1.1,0.7,1.9,0.8,2.4h0.1c0.1-0.3,0.2-0.7,0.4-1.4c0.2-0.6,0.9-2.6,2.1-5.9h1.4l-3.7,9.7
                  c-0.4,1-0.8,1.6-1.3,2.1c-0.5,0.4-1.1,0.6-1.8,0.6c-0.4,0-0.8,0-1.2-0.1v-1c0.3,0.1,0.6,0.1,1,0.1c0.9,0,1.5-0.5,1.9-1.5
                  l0.5-1.2L180.8,8.4z"/>
              </g>
            </g>
            <g id="Frauen-allgemein" transform="translate(732.000000, 0.000000)">
              <g class="st2">
                <path d="M2.9,17H1.6V5.6h6.4v1.2h-5v4.2h4.7v1.2H2.9V17z"/>
                <path d="M13.5,8.3c0.4,0,0.7,0,1,0.1l-0.2,1.2c-0.4-0.1-0.7-0.1-0.9-0.1c-0.7,0-1.3,0.3-1.8,0.8s-0.7,1.3-0.7,2.1V17H9.6V8.4
                  h1.1l0.1,1.6h0.1c0.3-0.6,0.7-1,1.1-1.3C12.5,8.4,13,8.3,13.5,8.3z"/>
                <path d="M21.4,17l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7
                  c-0.5-0.4-0.7-1.1-0.7-1.9c0-1.7,1.4-2.6,4.1-2.7l1.5,0v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7
                  l-0.4-1c0.4-0.2,0.9-0.4,1.4-0.5c0.5-0.1,1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2V17H21.4z M18.5,16.1
                  c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1
                  C17.6,16,18,16.1,18.5,16.1z"/>
                <path d="M26.3,8.4V14c0,0.7,0.2,1.2,0.5,1.6c0.3,0.3,0.8,0.5,1.5,0.5c0.9,0,1.6-0.2,2-0.7s0.6-1.3,0.6-2.4V8.4h1.3V17h-1.1
                  l-0.2-1.1h-0.1c-0.3,0.4-0.6,0.7-1.1,1c-0.5,0.2-1,0.3-1.6,0.3c-1,0-1.8-0.2-2.3-0.7C25.2,15.9,25,15.1,25,14V8.4H26.3z"/>
                <path d="M38.5,17.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V13h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C39.6,17.1,39.1,17.2,38.5,17.2z M38.1,9.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                  C39.3,9.6,38.8,9.4,38.1,9.4z"/>
                <path d="M49.7,17v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7c-0.4,0.5-0.6,1.3-0.6,2.4V17h-1.3
                  V8.4h1.1l0.2,1.2h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7c0.5,0.5,0.8,1.3,0.8,2.4V17H49.7z"/>
                <path d="M63.1,17l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7
                  c-0.5-0.4-0.7-1.1-0.7-1.9c0-1.7,1.4-2.6,4.1-2.7l1.5,0v-0.5c0-0.7-0.1-1.2-0.4-1.5S61.6,9.4,61,9.4c-0.7,0-1.5,0.2-2.4,0.7
                  l-0.4-1c0.4-0.2,0.9-0.4,1.4-0.5c0.5-0.1,1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2V17H63.1z M60.2,16.1
                  c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1
                  C59.3,16,59.7,16.1,60.2,16.1z"/>
                <path d="M68,17h-1.3V4.8H68V17z"/>
                <path d="M72.1,17h-1.3V4.8h1.3V17z"/>
                <path d="M81.8,8.4v0.8l-1.6,0.2c0.1,0.2,0.3,0.4,0.4,0.7c0.1,0.3,0.2,0.6,0.2,1c0,0.8-0.3,1.5-0.9,2c-0.6,0.5-1.4,0.8-2.4,0.8
                  c-0.3,0-0.5,0-0.7-0.1C76.3,14.1,76,14.5,76,15c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.5,0.2,1,0.2h1.5c0.9,0,1.6,0.2,2.1,0.6
                  c0.5,0.4,0.7,1,0.7,1.7c0,0.9-0.4,1.7-1.1,2.2c-0.8,0.5-1.9,0.7-3.3,0.7c-1.1,0-2-0.2-2.6-0.6c-0.6-0.4-0.9-1-0.9-1.8
                  c0-0.5,0.2-1,0.5-1.4c0.3-0.4,0.8-0.6,1.4-0.8c-0.2-0.1-0.4-0.3-0.6-0.5c-0.1-0.2-0.2-0.5-0.2-0.7c0-0.3,0.1-0.6,0.2-0.8
                  c0.2-0.2,0.4-0.5,0.8-0.7c-0.4-0.2-0.8-0.5-1.1-0.9c-0.3-0.4-0.4-0.9-0.4-1.5c0-0.9,0.3-1.7,0.8-2.2s1.4-0.8,2.4-0.8
                  c0.4,0,0.9,0.1,1.2,0.2H81.8z M75,18.4c0,0.5,0.2,0.8,0.6,1.1s1,0.4,1.7,0.4c1.1,0,1.9-0.2,2.4-0.5s0.8-0.8,0.8-1.3
                  c0-0.5-0.1-0.8-0.4-1c-0.3-0.2-0.8-0.3-1.6-0.3h-1.6c-0.6,0-1,0.1-1.4,0.4S75,17.9,75,18.4z M75.7,11.2c0,0.6,0.2,1.1,0.5,1.4
                  c0.3,0.3,0.8,0.5,1.4,0.5c1.3,0,1.9-0.6,1.9-1.8c0-1.3-0.6-1.9-1.9-1.9c-0.6,0-1.1,0.2-1.4,0.5S75.7,10.5,75.7,11.2z"/>
                <path d="M87.2,17.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
                  s0.9,1.6,0.9,2.8V13h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                  C88.3,17.1,87.8,17.2,87.2,17.2z M86.9,9.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9H89c0-0.8-0.2-1.4-0.5-1.9
                  C88.1,9.6,87.5,9.4,86.9,9.4z"/>
                <path d="M103.5,17v-5.6c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.3-0.7-0.5-1.4-0.5c-0.8,0-1.4,0.2-1.8,0.7c-0.4,0.5-0.6,1.2-0.6,2.1V17
                  H98v-5.6c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.3-0.7-0.5-1.4-0.5c-0.8,0-1.4,0.2-1.8,0.7c-0.4,0.5-0.6,1.3-0.6,2.4V17h-1.3V8.4h1.1
                  l0.2,1.2h0.1c0.2-0.4,0.6-0.7,1-1s0.9-0.4,1.5-0.4c1.3,0,2.2,0.5,2.6,1.5h0.1c0.3-0.4,0.6-0.8,1.1-1.1s1-0.4,1.7-0.4
                  c1,0,1.7,0.2,2.2,0.7c0.5,0.5,0.7,1.3,0.7,2.4V17H103.5z"/>
                <path d="M111.1,17.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2
                  c1.1,0,1.9,0.4,2.5,1.1s0.9,1.6,0.9,2.8V13h-5.9c0,1,0.3,1.8,0.8,2.3c0.5,0.5,1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2
                  c-0.5,0.2-0.9,0.3-1.3,0.4C112.1,17.1,111.6,17.2,111.1,17.2z M110.7,9.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9h4.5
                  c0-0.8-0.2-1.4-0.5-1.9C111.9,9.6,111.4,9.4,110.7,9.4z"/>
                <path d="M116.3,6.1c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2c0.2,0.1,0.2,0.4,0.2,0.6
                  s-0.1,0.5-0.2,0.7c-0.2,0.1-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2C116.4,6.6,116.3,6.4,116.3,6.1z M117.7,17h-1.3V8.4h1.3V17
                  z"/>
                <path d="M126.3,17v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7c-0.4,0.5-0.6,1.3-0.6,2.4V17
                  h-1.3V8.4h1.1l0.2,1.2h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7c0.5,0.5,0.8,1.3,0.8,2.4V17H126.3
                  z"/>
              </g>
            </g>
          </g>
          <g>
            <path d="M17.8,390.4l-1.4-3.6h-4.6l-1.4,3.6H9.1l4.5-11.5h1.1l4.5,11.5H17.8z M16,385.6l-1.3-3.5c-0.2-0.4-0.3-1-0.5-1.6
              c-0.1,0.5-0.3,1-0.5,1.6l-1.3,3.5H16z"/>
            <path d="M24.6,381.7c1.1,0,2,0.4,2.6,1.2s0.9,1.9,0.9,3.3s-0.3,2.5-0.9,3.3s-1.5,1.2-2.6,1.2c-0.6,0-1.1-0.1-1.5-0.3
              c-0.5-0.2-0.8-0.5-1.2-0.9h-0.1l-0.3,1.1h-0.9v-12.2h1.3v3c0,0.7,0,1.3-0.1,1.8h0.1C22.5,382.1,23.4,381.7,24.6,381.7z
               M24.4,382.8c-0.9,0-1.5,0.3-1.9,0.8s-0.6,1.4-0.6,2.6s0.2,2.1,0.6,2.6s1,0.8,1.9,0.8c0.8,0,1.4-0.3,1.8-0.9s0.6-1.4,0.6-2.5
              c0-1.1-0.2-1.9-0.6-2.5S25.2,382.8,24.4,382.8z"/>
            <path d="M34.4,381.7c1.1,0,2,0.4,2.6,1.2s0.9,1.9,0.9,3.3s-0.3,2.5-0.9,3.3s-1.5,1.2-2.6,1.2c-0.6,0-1.1-0.1-1.5-0.3
              c-0.5-0.2-0.8-0.5-1.2-0.9h-0.1l-0.3,1.1h-0.9v-12.2h1.3v3c0,0.7,0,1.3-0.1,1.8h0.1C32.3,382.1,33.2,381.7,34.4,381.7z
               M34.2,382.8c-0.9,0-1.5,0.3-1.9,0.8s-0.6,1.4-0.6,2.6s0.2,2.1,0.6,2.6s1,0.8,1.9,0.8c0.8,0,1.4-0.3,1.8-0.9s0.6-1.4,0.6-2.5
              c0-1.1-0.2-1.9-0.6-2.5S35,382.8,34.2,382.8z"/>
            <path d="M40,389.6c0-0.3,0.1-0.6,0.2-0.8s0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8
              c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.2S40,390,40,389.6z"/>
            <path d="M59.5,390.4v-5.6c0-0.7-0.1-1.2-0.4-1.5s-0.7-0.5-1.4-0.5c-0.8,0-1.4,0.2-1.8,0.7s-0.6,1.2-0.6,2.1v4.8h-1.3v-5.6
              c0-0.7-0.1-1.2-0.4-1.5s-0.7-0.5-1.4-0.5c-0.8,0-1.4,0.2-1.8,0.7s-0.6,1.3-0.6,2.4v4.5h-1.3v-8.6h1.1l0.2,1.2h0.1
              c0.2-0.4,0.6-0.7,1-1s0.9-0.4,1.5-0.4c1.3,0,2.2,0.5,2.6,1.5h0.1c0.3-0.4,0.6-0.8,1.1-1.1s1-0.4,1.7-0.4c1,0,1.7,0.2,2.2,0.7
              s0.7,1.3,0.7,2.4v5.6H59.5z"/>
            <path d="M70.9,386.1c0,1.4-0.4,2.5-1.1,3.3s-1.7,1.2-2.9,1.2c-0.8,0-1.4-0.2-2-0.5s-1.1-0.9-1.4-1.5S63,387,63,386.1
              c0-1.4,0.3-2.5,1-3.3s1.7-1.2,2.9-1.2c1.2,0,2.1,0.4,2.9,1.2S70.9,384.8,70.9,386.1z M64.4,386.1c0,1.1,0.2,1.9,0.7,2.5
              s1.1,0.9,1.9,0.9s1.5-0.3,1.9-0.9s0.7-1.4,0.7-2.5c0-1.1-0.2-1.9-0.7-2.5s-1.1-0.8-1.9-0.8c-0.8,0-1.5,0.3-1.9,0.8
              S64.4,385,64.4,386.1z"/>
            <path d="M79,389.3L79,389.3c-0.7,0.9-1.6,1.3-2.8,1.3c-1.1,0-2-0.4-2.6-1.1s-0.9-1.9-0.9-3.3s0.3-2.5,0.9-3.3s1.5-1.2,2.6-1.2
              c1.2,0,2.1,0.4,2.7,1.3H79l-0.1-0.6l0-0.6v-3.5h1.3v12.2h-1.1L79,389.3z M76.4,389.5c0.9,0,1.5-0.2,1.9-0.7s0.6-1.3,0.6-2.3v-0.3
              c0-1.2-0.2-2.1-0.6-2.6s-1-0.8-1.9-0.8c-0.8,0-1.3,0.3-1.7,0.9s-0.6,1.4-0.6,2.5c0,1.1,0.2,1.9,0.6,2.5S75.6,389.5,76.4,389.5z"
              />
            <path d="M82.9,379.5c0-0.3,0.1-0.5,0.2-0.7s0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2s0.2,0.4,0.2,0.6s-0.1,0.5-0.2,0.7
              s-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2S82.9,379.8,82.9,379.5z M84.3,390.4H83v-8.6h1.3V390.4z"/>
            <path d="M90.9,382.9h-2.2v7.6h-1.3v-7.6h-1.5v-0.6l1.5-0.5v-0.5c0-2.1,0.9-3.2,2.8-3.2c0.5,0,1,0.1,1.6,0.3l-0.3,1
              c-0.5-0.2-0.9-0.2-1.3-0.2c-0.5,0-0.9,0.2-1.1,0.5s-0.4,0.8-0.4,1.6v0.6h2.2V382.9z M92.3,379.5c0-0.3,0.1-0.5,0.2-0.7
              s0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2s0.2,0.4,0.2,0.6s-0.1,0.5-0.2,0.7s-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2
              S92.3,379.8,92.3,379.5z M93.7,390.4h-1.3v-8.6h1.3V390.4z"/>
            <path d="M102,390.4h-6.2v-0.9l4.7-6.7H96v-1h5.8v1l-4.6,6.5h4.7V390.4z"/>
            <path d="M103.9,379.5c0-0.3,0.1-0.5,0.2-0.7s0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2s0.2,0.4,0.2,0.6s-0.1,0.5-0.2,0.7
              s-0.3,0.2-0.5,0.2c-0.2,0-0.4-0.1-0.5-0.2S103.9,379.8,103.9,379.5z M105.3,390.4H104v-8.6h1.3V390.4z"/>
            <path d="M111.6,390.6c-1.3,0-2.3-0.4-3-1.2s-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
              s0.9,1.6,0.9,2.8v0.8h-5.9c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
              S112.2,390.6,111.6,390.6z M111.3,382.8c-0.7,0-1.2,0.2-1.6,0.7s-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
              S112,382.8,111.3,382.8z"/>
            <path d="M120.9,381.7c0.4,0,0.7,0,1,0.1l-0.2,1.2c-0.4-0.1-0.7-0.1-0.9-0.1c-0.7,0-1.3,0.3-1.8,0.8s-0.7,1.3-0.7,2.1v4.6H117
              v-8.6h1.1l0.1,1.6h0.1c0.3-0.6,0.7-1,1.1-1.3S120.4,381.7,120.9,381.7z"/>
            <path d="M126.3,389.5c0.2,0,0.5,0,0.7-0.1s0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2c-0.3,0-0.5,0.1-0.7,0.1
              c-1.7,0-2.5-0.9-2.5-2.6v-5.1h-1.2v-0.6l1.2-0.5l0.5-1.8h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2S125.9,389.5,126.3,389.5z"/>
            <path d="M139.2,390.4v-5.5c0-0.7-0.2-1.2-0.5-1.6s-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7s-0.6,1.3-0.6,2.4v4.5h-1.3v-8.6h1.1
              l0.2,1.2h0.1c0.3-0.4,0.6-0.7,1.1-1s1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7s0.8,1.3,0.8,2.4v5.6H139.2z"/>
            <path d="M148.4,390.4l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7s-0.7-1.1-0.7-1.9
              c0-1.7,1.4-2.6,4.1-2.7l1.5,0v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7l-0.4-1
              c0.4-0.2,0.9-0.4,1.4-0.5s1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2v5.8H148.4z M145.5,389.5c0.8,0,1.4-0.2,1.9-0.7
              s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5s-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1S145,389.5,145.5,389.5z"/>
            <path d="M155.5,390.6c-1.2,0-2.2-0.4-2.9-1.1s-1-1.8-1-3.2c0-1.4,0.3-2.5,1-3.3s1.7-1.2,2.9-1.2c0.4,0,0.8,0,1.2,0.1
              s0.7,0.2,1,0.3l-0.4,1.1c-0.3-0.1-0.6-0.2-0.9-0.3s-0.6-0.1-0.9-0.1c-1.7,0-2.6,1.1-2.6,3.3c0,1.1,0.2,1.9,0.6,2.4
              s1.1,0.8,1.9,0.8c0.7,0,1.4-0.2,2.2-0.5v1.1C157.1,390.4,156.3,390.6,155.5,390.6z"/>
            <path d="M165.5,390.4v-5.5c0-0.7-0.2-1.2-0.5-1.6s-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7s-0.6,1.3-0.6,2.4v4.5h-1.3v-12.2h1.3
              v3.7c0,0.4,0,0.8-0.1,1.1h0.1c0.3-0.4,0.6-0.7,1.1-1s1-0.4,1.6-0.4c1,0,1.8,0.2,2.4,0.7s0.8,1.3,0.8,2.4v5.6H165.5z"/>
            <path d="M173.8,379h3.2c1.5,0,2.6,0.2,3.3,0.7s1,1.2,1,2.1c0,0.7-0.2,1.2-0.6,1.7s-0.9,0.7-1.7,0.9v0.1c1.7,0.3,2.6,1.2,2.6,2.7
              c0,1-0.3,1.8-1,2.4s-1.7,0.9-2.9,0.9h-4V379z M175.2,383.9h2.2c0.9,0,1.6-0.1,2-0.4s0.6-0.8,0.6-1.5c0-0.6-0.2-1.1-0.7-1.4
              s-1.2-0.4-2.2-0.4h-2V383.9z M175.2,385v4.3h2.4c0.9,0,1.6-0.2,2.1-0.5s0.7-0.9,0.7-1.7c0-0.7-0.2-1.2-0.7-1.6s-1.2-0.5-2.2-0.5
              H175.2z"/>
            <path d="M191.4,390.4l-1.4-3.6h-4.6l-1.4,3.6h-1.3l4.5-11.5h1.1l4.5,11.5H191.4z M189.5,385.6l-1.3-3.5c-0.2-0.4-0.3-1-0.5-1.6
              c-0.1,0.5-0.3,1-0.5,1.6l-1.3,3.5H189.5z"/>
            <path d="M200.7,390.4h-6.4V379h6.4v1.2h-5v3.7h4.7v1.2h-4.7v4.2h5V390.4z"/>
            <path d="M211.9,390.4h-1.3V385h-6v5.4h-1.3V379h1.3v4.9h6V379h1.3V390.4z"/>
            <path d="M223.9,390.4h-1.5l-6.2-9.6h-0.1c0.1,1.1,0.1,2.2,0.1,3.1v6.5H215V379h1.5l6.2,9.5h0.1c0-0.1,0-0.6-0.1-1.4s0-1.3,0-1.6
              V379h1.2V390.4z"/>
            <path d="M233.5,390.4h-6.4V379h6.4v1.2h-5v3.7h4.7v1.2h-4.7v4.2h5V390.4z"/>
            <path d="M237.3,385.7v4.8H236V379h3.1c1.4,0,2.4,0.3,3.1,0.8s1,1.3,1,2.4c0,1.5-0.8,2.5-2.3,3.1l3.1,5.1h-1.6l-2.8-4.8H237.3z
               M237.3,384.5h1.8c0.9,0,1.6-0.2,2.1-0.6s0.7-0.9,0.7-1.7c0-0.8-0.2-1.3-0.7-1.6s-1.2-0.5-2.1-0.5h-1.7V384.5z"/>
            <path d="M247,388.6l0.1,0.2c-0.1,0.5-0.3,1.1-0.6,1.8c-0.3,0.7-0.5,1.3-0.8,1.9h-1c0.1-0.5,0.3-1.2,0.5-2s0.3-1.4,0.4-1.9H247z"
              />
            <path d="M255.3,390.4H254V379h6.4v1.2h-5v4.2h4.7v1.2h-4.7V390.4z"/>
            <path d="M261.8,389.6c0-0.3,0.1-0.6,0.2-0.8s0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8
              c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.2S261.8,390,261.8,389.6z"/>
            <path d="M274,390.6c-1.3,0-2.3-0.4-3-1.2s-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2c1.1,0,1.9,0.4,2.5,1.1
              s0.9,1.6,0.9,2.8v0.8h-5.9c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
              S274.6,390.6,274,390.6z M273.7,382.8c-0.7,0-1.2,0.2-1.6,0.7s-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
              S274.4,382.8,273.7,382.8z"/>
            <path d="M282.2,389.5c0.2,0,0.5,0,0.7-0.1s0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2c-0.3,0-0.5,0.1-0.7,0.1
              c-1.7,0-2.5-0.9-2.5-2.6v-5.1h-1.2v-0.6l1.2-0.5l0.5-1.8h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2S281.7,389.5,282.2,389.5z"/>
            <path d="M294.5,390.4l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7s-0.7-1.1-0.7-1.9
              c0-1.7,1.4-2.6,4.1-2.7l1.5,0v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7l-0.4-1
              c0.4-0.2,0.9-0.4,1.4-0.5s1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2v5.8H294.5z M291.5,389.5c0.8,0,1.4-0.2,1.9-0.7
              s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5s-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1S291,389.5,291.5,389.5z"/>
            <path d="M299.4,390.4h-1.3v-12.2h1.3V390.4z"/>
            <path d="M302,389.6c0-0.3,0.1-0.6,0.2-0.8s0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8
              c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.2S302,390,302,389.6z"/>
            <path d="M317.5,390.4H310v-1.1l3-3c0.9-0.9,1.5-1.6,1.8-2s0.5-0.8,0.7-1.2s0.2-0.8,0.2-1.2c0-0.6-0.2-1.1-0.6-1.4
              s-0.9-0.5-1.5-0.5c-0.5,0-0.9,0.1-1.3,0.2s-0.9,0.4-1.4,0.9l-0.7-0.9c1.1-0.9,2.2-1.3,3.4-1.3c1.1,0,1.9,0.3,2.5,0.8
              s0.9,1.3,0.9,2.2c0,0.7-0.2,1.4-0.6,2.1s-1.2,1.6-2.3,2.7l-2.5,2.4v0.1h5.9V390.4z"/>
            <path d="M326.7,384.7c0,2-0.3,3.4-0.9,4.4s-1.6,1.5-2.9,1.5c-1.2,0-2.2-0.5-2.8-1.5s-1-2.5-1-4.4c0-2,0.3-3.5,0.9-4.4
              s1.6-1.4,2.8-1.4c1.2,0,2.2,0.5,2.8,1.5S326.7,382.8,326.7,384.7z M320.5,384.7c0,1.7,0.2,2.9,0.6,3.6s1,1.1,1.9,1.1
              c0.9,0,1.5-0.4,1.9-1.2s0.6-2,0.6-3.6s-0.2-2.8-0.6-3.6s-1-1.1-1.9-1.1c-0.9,0-1.5,0.4-1.9,1.1S320.5,383,320.5,384.7z"/>
            <path d="M335.8,384.7c0,2-0.3,3.4-0.9,4.4s-1.6,1.5-2.9,1.5c-1.2,0-2.2-0.5-2.8-1.5s-1-2.5-1-4.4c0-2,0.3-3.5,0.9-4.4
              s1.6-1.4,2.8-1.4c1.2,0,2.2,0.5,2.8,1.5S335.8,382.8,335.8,384.7z M329.6,384.7c0,1.7,0.2,2.9,0.6,3.6s1,1.1,1.9,1.1
              c0.9,0,1.5-0.4,1.9-1.2s0.6-2,0.6-3.6s-0.2-2.8-0.6-3.6s-1-1.1-1.9-1.1c-0.9,0-1.5,0.4-1.9,1.1S329.6,383,329.6,384.7z"/>
            <path d="M344.5,381.7c0,0.7-0.2,1.3-0.6,1.8s-1,0.8-1.7,0.9v0.1c0.9,0.1,1.6,0.4,2,0.9s0.7,1.1,0.7,1.8c0,1.1-0.4,1.9-1.1,2.5
              s-1.8,0.9-3.2,0.9c-0.6,0-1.2,0-1.7-0.1s-1-0.3-1.5-0.5v-1.2c0.5,0.2,1,0.4,1.6,0.6s1.1,0.2,1.6,0.2c2,0,3-0.8,3-2.3
              c0-1.4-1.1-2.1-3.3-2.1h-1.1V384h1.1c0.9,0,1.6-0.2,2.1-0.6s0.8-0.9,0.8-1.6c0-0.6-0.2-1-0.6-1.3s-0.9-0.5-1.6-0.5
              c-0.5,0-1,0.1-1.4,0.2s-0.9,0.4-1.5,0.8l-0.7-0.9c0.5-0.4,1-0.7,1.6-0.9s1.3-0.3,1.9-0.3c1.1,0,2,0.3,2.6,0.8
              S344.5,380.8,344.5,381.7z"/>
            <path d="M348.3,381c0.7,0,1.3,0.2,1.7,0.5s0.6,0.8,0.6,1.4c0,0.7-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.6-1.8,0.6c-0.8,0-1.3-0.1-1.7-0.4
              v-0.7c0.2,0.1,0.5,0.2,0.8,0.3c0.3,0.1,0.6,0.1,0.9,0.1c0.5,0,0.9-0.1,1.2-0.4c0.3-0.3,0.4-0.6,0.4-1.1c0-0.9-0.6-1.4-1.7-1.4
              c-0.3,0-0.7,0-1.2,0.1l-0.4-0.3l0.2-3.1h3.3v0.7h-2.7l-0.2,2C347.6,381.1,348,381,348.3,381z"/>
          </g>
        </g>
      </g>
    </svg>
  </ng-container>

  <ng-container *ngIf="currentIdentifier === 'therapie-chart-1'">
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="450" height="252" viewBox="0 0 450 252">
      <g fill="none" fill-rule="evenodd">
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="40.99" y="194">40</tspan>
        </text>
        <text fill="#000" font-family="OpenSans-Bold, Open Sans" font-size="14" font-weight="bold">
            <tspan x="189.313" y="15">(nur Männer n=279)</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="40.99" y="173">50</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="40.99" y="152">50</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="40.99" y="130">70</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="40.99" y="109">80</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="40.99" y="87">90</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="32.985" y="64">100</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="65.498" y="213">0</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="151.995" y="213">20</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="240.495" y="213">40</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="330.495" y="213">60</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="420.495" y="213">80</tspan>
        </text>
        <path stroke="#929897" stroke-linecap="square" stroke-width="2" d="M71 58.771V192.5M67 124h4m-4-23h4m-4 45h4m-4 22h4m-4-87h4m-4-22h4M448.75 189.5L66.5 189.5M159 189.5L159 193.5M249 189.5L249 193.5M338 189.5L338 193.5M428 189.5L428 193.5"/>
        <path stroke="#007BB6" stroke-width="2" d="M71.5 59L225.819 59 225.819 60.85 251.002 60.85 251.002 62.748 260.053 62.748 260.053 64.094 262.439 64.094 262.439 66.308 274.175 66.308 274.175 68.553 276.577 68.553 276.577 71.407 281.119 71.407 281.119 74.332 296.747 74.332 296.747 85.566 298.099 85.566 298.099 93.61 301.134 93.61 301.134 102.601 323.887 102.601 323.887 109.991 329.281 109.991 329.281 119.038 331.464 119.038 331.464 128.616 334.813 128.616 334.813 140.07 337.285 140.07 337.285 189"/>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="rotate(-90 9.5 125.5)">
            <tspan x="-77.792" y="131">Geschätztes Überleben (%)</tspan>
        </text>
        <text fill="#000" font-family="OpenSans-Semibold, Open Sans" font-size="10" font-weight="500">
            <tspan x="126" y="174">Männer </tspan>
        </text>
        <path stroke="#007BB6" stroke-width="2" d="M95 171.5L122 171.5"/>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="207.198" y="248">Alter (in Jahren)</tspan>
        </text>
      </g>
    </svg> -->
    <!-- <img src="/assets/img/template/group-14@2x.png" > -->
    <svg xmlns="http://www.w3.org/2000/svg" width="448" height="245" viewBox="0 0 448 245">
        <g fill="none" fill-rule="evenodd">
            <g>
                <g>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-261 -9904) translate(261 9904)">
                        <tspan x="38.99" y="186">40</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans-Bold, Open Sans" font-size="16" font-weight="bold" transform="translate(-261 -9904) translate(261 9904)">
                        <tspan x="177.5" y="17">(nur Männer n = 279)</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-261 -9904) translate(261 9904)">
                        <tspan x="38.99" y="165">50</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-261 -9904) translate(261 9904)">
                        <tspan x="38.99" y="144">50</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-261 -9904) translate(261 9904)">
                        <tspan x="38.99" y="122">70</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-261 -9904) translate(261 9904)">
                        <tspan x="38.99" y="101">80</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-261 -9904) translate(261 9904)">
                        <tspan x="38.99" y="79">90</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-261 -9904) translate(261 9904)">
                        <tspan x="30.985" y="56">100</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-261 -9904) translate(261 9904)">
                        <tspan x="63.498" y="205">0</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-261 -9904) translate(261 9904)">
                        <tspan x="149.995" y="205">20</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-261 -9904) translate(261 9904)">
                        <tspan x="238.495" y="205">40</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-261 -9904) translate(261 9904)">
                        <tspan x="328.495" y="205">60</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-261 -9904) translate(261 9904)">
                        <tspan x="418.495" y="205">80</tspan>
                    </text>
                    <path stroke="#929897" stroke-linecap="square" stroke-width="2" d="M69 50.771V184.5M65 116h4m-4-23h4m-4 45h4m-4 22h4m-4-87h4m-4-22h4M446.75 181.5L64.5 181.5M157 181.5L157 185.5M247 181.5L247 185.5M336 181.5L336 185.5M426 181.5L426 185.5" transform="translate(-261 -9904) translate(261 9904)"/>
                    <path stroke="#007BB6" stroke-width="2" d="M69.5 51L223.819 51 223.819 52.85 249.002 52.85 249.002 54.748 258.053 54.748 258.053 56.094 260.439 56.094 260.439 58.308 272.175 58.308 272.175 60.553 274.577 60.553 274.577 63.407 279.119 63.407 279.119 66.332 294.747 66.332 294.747 77.566 296.099 77.566 296.099 85.61 299.134 85.61 299.134 94.601 321.887 94.601 321.887 101.991 327.281 101.991 327.281 111.038 329.464 111.038 329.464 120.616 332.813 120.616 332.813 132.07 335.285 132.07 335.285 181" transform="translate(-261 -9904) translate(261 9904)"/>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="16" transform="translate(-261 -9904) translate(261 9904) rotate(-90 11 129)">
                        <tspan x="-88.762" y="135">Geschätztes Überleben (%)</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans-Semibold, Open Sans" font-size="12" font-weight="500" transform="translate(-261 -9904) translate(261 9904)">
                        <tspan x="130" y="166">Männer </tspan>
                    </text>
                    <path stroke="#007BB6" stroke-width="2" d="M93 161.5L120 161.5" transform="translate(-261 -9904) translate(261 9904)"/>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="16" transform="translate(-261 -9904) translate(261 9904)">
                        <tspan x="198.012" y="240">Alter (in Jahren)</tspan>
                    </text>
                </g>
            </g>
        </g>
    </svg>

  </ng-container>

  <ng-container *ngIf="currentIdentifier === 'therapie-chart-2'">
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="447" height="252" viewBox="0 0 447 252">
      <g fill="none" fill-rule="evenodd">
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="37.99" y="194">40</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="37.99" y="173">50</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="37.99" y="152">50</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="37.99" y="130">70</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="37.99" y="109">80</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="37.99" y="87">90</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="29.985" y="64">100</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="62.498" y="213">0</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="148.995" y="213">20</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="239.495" y="213">40</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="330.495" y="213">60</tspan>
        </text>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="421.495" y="214">80</tspan>
        </text>
        <path stroke="#929897" stroke-linecap="square" stroke-width="2" d="M68 58.771V192.5M64 124h4m-4-23h4m-4 45h4m-4 22h4m-4-87h4m-4-22h4M445.75 189.5L63.5 189.5M157 189.5L157 193.5M249 189.5L249 193.5M338 189.5L338 193.5M429 189.5L429 193.5"/>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="rotate(-90 9.5 129.5)">
            <tspan x="-77.792" y="135">Geschätztes Überleben (%)</tspan>
        </text>
        <text fill="#000" font-family="OpenSans-Semibold, Open Sans" font-size="10" font-weight="500">
            <tspan x="123" y="174">Männer </tspan>
        </text>
        <text fill="#000" font-family="OpenSans-Semibold, Open Sans" font-size="10" font-weight="500">
            <tspan x="123" y="155">Frauen </tspan>
        </text>
        <path stroke="#007BB6" stroke-width="2" d="M92 171.5L119 171.5"/>
        <path stroke="#D8137D" stroke-width="2" d="M92 152.5L119 152.5"/>
        <text fill="#000" font-family="OpenSans, Open Sans" font-size="14">
            <tspan x="204.198" y="248">Alter (in Jahren)</tspan>
        </text>
        <text fill="#000" font-family="OpenSans-Bold, Open Sans" font-size="14" font-weight="bold">
            <tspan x="149.315" y="15">(Männer n=360; Frauen n=317)</tspan>
        </text>
        <path stroke="#007BB6" stroke-width="2" d="M68.5 59L185.53 59 185.53 60.947 252.632 60.947 252.632 62.853 281.403 62.853 281.403 64.205 283.407 64.205 283.407 65.739 287.854 65.739 287.854 67.242 306.804 67.242 306.804 69.072 310.422 69.072 310.422 74.597 315.149 74.597 315.149 78.041 319.995 78.041 319.995 83.108 323.801 83.108 323.801 86.667 351.873 86.667 351.873 94.938 368.833 94.938 368.833 105.672 413.758 105.672 413.758 147.448 419.007 147.448 419.007 189.5 442.545 189.5"/>
        <path stroke="#D8137D" stroke-width="2" d="M68 58.771L355.878 58.771 355.878 63.611 384.258 63.611 384.258 70.023 389.927 70.023 389.927 77.39 392.216 77.39 392.216 85.181 396.655 85.181 396.655 94.745 411.394 94.745 411.394 118.038 417.128 118.038 417.128 144.645 426 144.645 426 189.771"/>
      </g>
    </svg> -->
    <!-- <img src="/assets/img/template/group-15@2x.png" > -->
    <svg xmlns="http://www.w3.org/2000/svg" width="448" height="244" viewBox="0 0 448 244">
        <g fill="none" fill-rule="evenodd">
            <g>
                <g>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="38.99" y="185">40</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="38.99" y="164">50</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="38.99" y="143">50</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="38.99" y="121">70</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="38.99" y="100">80</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="38.99" y="78">90</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="30.985" y="55">100</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="63.498" y="204">0</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="149.995" y="204">20</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="240.495" y="204">40</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="331.495" y="204">60</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="14" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="422.495" y="205">80</tspan>
                    </text>
                    <path stroke="#929897" stroke-linecap="square" stroke-width="2" d="M69 49.771V183.5M65 115h4m-4-23h4m-4 45h4m-4 22h4m-4-87h4m-4-22h4M446.75 180.5L64.5 180.5M158 180.5L158 184.5M250 180.5L250 184.5M339 180.5L339 184.5M430 180.5L430 184.5" transform="translate(-776 -9905) translate(776 9905)"/>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="16" transform="translate(-776 -9905) translate(776 9905) rotate(-90 11 122)">
                        <tspan x="-88.762" y="128">Geschätztes Überleben (%)</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans-Semibold, Open Sans" font-size="12" font-weight="500" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="130" y="165">Männer </tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans-Semibold, Open Sans" font-size="12" font-weight="500" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="130" y="141">Frauen </tspan>
                    </text>
                    <path stroke="#007BB6" stroke-width="2" d="M93 160.5L120 160.5" transform="translate(-776 -9905) translate(776 9905)"/>
                    <path stroke="#D8137D" stroke-width="2" d="M93 136.5L120 136.5" transform="translate(-776 -9905) translate(776 9905)"/>
                    <text fill="#000" font-family="OpenSans, Open Sans" font-size="16" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="198.012" y="239">Alter (in Jahren)</tspan>
                    </text>
                    <text fill="#000" font-family="OpenSans-Bold, Open Sans" font-size="16" font-weight="bold" transform="translate(-776 -9905) translate(776 9905)">
                        <tspan x="135.289" y="17">(Männer n = 360; Frauen n = 317)</tspan>
                    </text>
                    <path stroke="#007BB6" stroke-width="2" d="M69.5 50L186.53 50 186.53 51.947 253.632 51.947 253.632 53.853 282.403 53.853 282.403 55.205 284.407 55.205 284.407 56.739 288.854 56.739 288.854 58.242 307.804 58.242 307.804 60.072 311.422 60.072 311.422 65.597 316.149 65.597 316.149 69.041 320.995 69.041 320.995 74.108 324.801 74.108 324.801 77.667 352.873 77.667 352.873 85.938 369.833 85.938 369.833 96.672 414.758 96.672 414.758 138.448 420.007 138.448 420.007 180.5 443.545 180.5" transform="translate(-776 -9905) translate(776 9905)"/>
                    <path stroke="#D8137D" stroke-width="2" d="M69 49.771L356.878 49.771 356.878 54.611 385.258 54.611 385.258 61.023 390.927 61.023 390.927 68.39 393.216 68.39 393.216 76.181 397.655 76.181 397.655 85.745 412.394 85.745 412.394 109.038 418.128 109.038 418.128 135.645 427 135.645 427 180.771" transform="translate(-776 -9905) translate(776 9905)"/>
                </g>
            </g>
        </g>
    </svg>
  </ng-container>

</div>
