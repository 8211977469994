import { Component, Input, OnInit, ViewEncapsulation, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient } from '@angular/common/http';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {

  @Input() colorClass: string;
  @Input() zone: string;


  username = '';
  password = '';

  constructor(
    private _httpClient: HttpClient,
    ) { }

  ngOnInit(): void {
  }

  tryLogin(){

    

  }

}
