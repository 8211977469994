import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { ImageDialogComponent } from '../partials/image-dialog/image-dialog.component';

@Component({
  selector: 'app-therapie',
  templateUrl: './therapie.component.html',
  styleUrls: ['./therapie.component.scss']
})
export class TherapieComponent implements OnInit {

  lastClickedLink = "";

  constructor(
    private _router: Router,
    public _matDialog: MatDialog
    ) {
    
  }

  ngOnInit(): void {

    window.scrollTo(0, 0);

    const specialistLinks = document.querySelectorAll('.specialist-link');

    for (var i = 0; i < specialistLinks.length; i++) {
      specialistLinks[i].addEventListener('click', this.openDetailsPanel.bind(this));
    }

    const closeSpecialistIcons = document.querySelectorAll('.close-specialist-desc');

    for (var i = 0; i < closeSpecialistIcons.length; i++) {
      closeSpecialistIcons[i].addEventListener('click', this.closePanel.bind(this));
    }

    const supTags = document.querySelectorAll('sup');

    for (var i = 0; i < supTags.length; i++) {
      supTags[i].addEventListener('click', this.goToReferences.bind(this));
      supTags[i].setAttribute('style', 'cursor: pointer;');
    }

  }

  openDetailsPanel(event): void {

    var t = 'specialist-desc';
    var n = 'specialist-box';

    var i = "id";

    if(event.currentTarget.hasAttribute("data-open-desc")) {
      i = "data-open-desc";
    }

    var o = event.currentTarget.getAttribute(i);

    console.log("currentTarget", o);

    if(this.lastClickedLink == o) return;
    
    this.lastClickedLink = o;

    var s = document.querySelector("." + t + "[data-desc-box='" + o + "']");
    var a = document.querySelector("." + t + ".desc_show");

    if(a){
      a.classList.remove("desc_show");
      a.setAttribute('style', 'display: none;');
    }

    if((s != null && a == null ) || (s.getAttribute("data-desc-box") != a.getAttribute("data-desc-box"))) {
      s.classList.add("desc_show");
      s.removeAttribute('style');

      const y = s.getBoundingClientRect().top + window.pageYOffset - 132;
      window.scrollTo({top: y, behavior: 'smooth'});
    } else {
      // f(t, n);
    }

  }

  closePanel(event): void {

    var t = 'specialist-desc';
    var n = 'specialist-box';

    var a = document.querySelector("." + t + ".desc_show");
    var s = document.querySelector("." + n);

    if(a){

      a.classList.remove("desc_show");
      a.setAttribute('style', 'display: none;');

      const y = s.getBoundingClientRect().top + window.pageYOffset - 132;
      window.scrollTo({top: y, behavior: 'smooth'});

    }
  }

  goToReferences(event): void {
    this.navigateTo('/referenzen');
  }

  navigateTo(url){
    this._router.navigate([url], { fragment: 'therapie' });
  }

  openDialog(id) {
    this._matDialog.open(ImageDialogComponent, {
      data: {
        identifier: id
      },
      panelClass: 'image-dialog-container'
    });

  }

}
