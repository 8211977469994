<div id="education" class="page-wrapper">

  <app-menu>
  </app-menu>

  <!--<div class="subheader-bar-wrapper">
    <div class="subheader-content">
      <a href="https://www.lsd-diagnostik-partner.de/" target="_blank">
        <div class="subheader-content-text">
          Ihr kostenfreies Testset!
        </div>
      </a>
    </div>
  </div>-->

  <div class="content-icon">
    <img src="/assets/img/template/icon-file-alt.png" />
  </div>

  <div class="title-wrapper">

    <h1>
      Referenzen
    </h1>

  </div>

  <div class="info-section info-section-1">

    <!-- <div class="info-section-subtitle info-section-subtitle-4">
      Webinare und zertifizierte CME-Fortbildung für Ärzte 
    </div> -->

    <div class="info-section-container">

      <div class="info-section-block info-section-block-1">

        <div class="info-section-entry" (click)="showReferences('morbus-fabry')">
          <div class="info-section-entry-bar info-section-entry-bar-1"
            [ngClass]="selectedReferenceGroupName == 'morbus-fabry' ? 'info-section-entry-bar-active-' + selectedReferenceGroupName : ''"
            >
          </div>
          <div class="info-section-entry-title">
            Morbus Fabry
          </div>
        </div>

        <div class="info-section-entry" (click)="showReferences('diagnose')">
          <div class="info-section-entry-bar info-section-entry-bar-2"
            [ngClass]="selectedReferenceGroupName == 'diagnose' ? 'info-section-entry-bar-active-' + selectedReferenceGroupName : ''"
            >
          </div>
          <div class="info-section-entry-title">
            Diagnose
          </div>
        </div>

        <div class="info-section-entry" (click)="showReferences('therapie')">
          <div class="info-section-entry-bar info-section-entry-bar-3"
            [ngClass]="selectedReferenceGroupName == 'therapie' ? 'info-section-entry-bar-active-' + selectedReferenceGroupName : ''"
            >
          </div>
          <div class="info-section-entry-title">
            Therapie
          </div>
        </div>

        <div class="info-section-entry" (click)="showReferences('info-center')">
          <div class="info-section-entry-bar info-section-entry-bar-4"
            [ngClass]="selectedReferenceGroupName == 'info-center' ? 'info-section-entry-bar-active-' + selectedReferenceGroupName : ''"
            >
          </div>
          <div class="info-section-entry-title">
            Infocenter
          </div>
        </div>

        <div class="info-section-entry" (click)="showReferences('education')">
          <div class="info-section-entry-bar info-section-entry-bar-5"
            [ngClass]="selectedReferenceGroupName == 'education' ? 'info-section-entry-bar-active-' + selectedReferenceGroupName : ''"
            >
          </div>
          <div class="info-section-entry-title">
            Education
          </div>
        </div>

        <div class="info-section-entry" (click)="showReferences('fachgebiete')">
          <div class="info-section-entry-bar info-section-entry-bar-6"
            [ngClass]="selectedReferenceGroupName == 'fachgebiete' ? 'info-section-entry-bar-active-' + selectedReferenceGroupName : ''"
            >
          </div>
          <div class="info-section-entry-title">
            Fachgebiete
          </div>
        </div>

      </div>

      <div class="info-section-block info-section-block-2" [ngClass]="'info-section-block-' + selectedReferenceGroupName">
        <div *ngFor="let reference of selectedReferences; let i = index" class="info-section-block-text">
          <sup class="info-section-text-ref"
            [ngClass]="'info-section-text-ref-' + selectedReferenceGroupName"
            >
            {{ reference.sup }}
          </sup>
          <div class="info-section-text-content" [innerHTML]="reference.text ">
            <!-- {{ reference.text }} -->
          </div>
        </div>
      </div>

    </div>

  </div>

  <app-footer>
  </app-footer>

</div>
<style>
    .info-section-entry {
        margin-bottom: 64px;
    }
    .info-section-block-text {
	font-size: 14px;
	line-height: 1.61;
	display: flex;
    }
    .info-section-text-ref {
        font-size: 10px;
        flex: 0 0 22px;
    }
</style>