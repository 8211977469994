<div id="footer" class="footer-bar">

  <div class="footer-separator">
  </div>

  <div class="footer-links">

    <a class="footer-link" href="https://www.takeda.com/de-de/impressum/" target="_blank">
      Impressum
    </a>
    <!-- <div class="footer-link">
      Impressum
    </div> -->

    <a class="footer-link" href="https://www.takeda.com/de-de/privacy-policy/" target="_blank">
      Datenschutzbestimmung
    </a>
    <!-- <div class="footer-link">
      Datenschutzbestimmung
    </div> -->

    <a class="footer-link" href="https://www.takeda.com/de-de/terms-of-use/" target="_blank">
      Nutzungsbedingungen
    </a>
    <!-- <div class="footer-link">
      Unternehmen
    </div> -->

    <a class="footer-link" href="https://www.takeda.com/de-de/kontakt/" target="_blank">
      Kontakt
    </a>
    <!-- <div class="footer-link">
      Kontakt
    </div> -->
    
  </div>

  <div class="footer-address">
    Diese Webseite ist nur für die Nutzung von Personen mit Wohnsitz in Deutschland bestimmt.<br>
    Seite zuletzt aktualisiert: Oktober 2020 C-APROM/DE/FAB/002<br>
    ©️ 2020 Takeda Pharma Vertrieb GmbH & Co. KG<br>
    Jägerstr. 27 | 10117 Berlin<br>
    <!--Diese Webseite ist nur für die Nutzung von Personen mit Wohnsitz in Deutschland bestimmt.<br>-->
    <!-- Seite zuletzt aktualisiert: Mai 2020 C-ANPROM/DE//1440_05/2020<br> -->
    <!-- © 2020 Shire Deutschland GmbH, jetzt Teil der Takeda Group<br> -->
    <!-- Friedrichstraße 149 | 10117 Berlin -->
  </div>

  <div class="footer-logo">
    <img src="/assets/img/template/logo-takeda.svg" />
  </div>

</div>

<div *ngIf="showCookieBar" id="cookie-bar" class="cookie-bar">
  <div class="cookie-bar-wrapper">
    <div class="cookie-bar-text">
      Diese Webseite verwendet Cookies, um Ihnen den bestmöglichen 
      Service zu bieten. Durch die Nutzung dieser Webseite erklären 
      Sie sich mit unserer Verwendung von Cookies einverstanden. 
      Die Details zur Nutzung von Cookies finden Sie in unserer 
      <a href="https://www.shire.de/impressum/privacy-notice" target="_blank">Datenschutzerklärung</a>.
    </div>
    <div class="cookie-bar-button-wrapper">
      <button class="cookie-bar-button" (click)="saveCookie()">
        Ich habe verstanden
      </button>
    </div>
  </div>
</div>
