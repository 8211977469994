<div id="education" class="page-wrapper">

  <app-menu [activeMenuIndex]="5">
  </app-menu>

  <!--<div class="subheader-bar-wrapper">
    <div class="subheader-content">
      <a href="https://www.lsd-diagnostik-partner.de/" target="_blank">
        <div class="subheader-content-text">
          Ihr kostenfreies Testset!
        </div>
      </a>
    </div>
  </div>-->

  <div class="floating-chameleon">
  </div>

  <div class="content-icon">
    <img src="/assets/img/template/icon-education.svg" />
  </div>

  <div class="title-wrapper">

    <h1>
      Education
    </h1>

  </div>

  <!--<div class="info-section info-section-1">

    <div class="info-section-subtitle info-section-subtitle-1">
      Morbus Fabry im Blick:<br>
      Aktuelles aus der Literatur
    </div>

    <div class="info-section-text info-section-text-1">
      Bleiben Sie auf dem Laufenden. Wir senden Ihnen regelmäßig kurz zusammengefasste<br>
      Forschungs- und Studienergebnisse zum Thema Morbus Fabry zu.
    </div>

    <div class="info-section-text info-section-text-italic info-section-text-2">
      Von Dr. Charlotte Nejad &amp; Dr. Martina Kralewski
    </div>

  </div>-->

<!--  <app-login zone="education">-->
<!--  <app-login zone="2000000014820"> <!-- Development -->
<!--  <app-login zone="2000000016670"> <!-- Takeda Server -->
  <app-login zone="2000000014985"> <!-- Production -->
  </app-login>

  <app-footer>
  </app-footer>

</div>