<div id="education" class="page-wrapper">

  <app-menu [activeMenuIndex]="5">
  </app-menu>

  <!--<div class="subheader-bar-wrapper">
    <div class="subheader-content">
      <a href="https://www.lsd-diagnostik-partner.de/" target="_blank">
        <div class="subheader-content-text">
          Ihr kostenfreies Testset!
        </div>
      </a>
    </div>
  </div>-->

  <div class="floating-chameleon">
  </div>

  <div class="content-icon">
    <img src="/assets/img/template/icon-education.svg" />
  </div>

  <!-- <span class="icon-psycho ico page-intro apple"></span> -->

  <div class="title-wrapper">

    <h1>
      Education
    </h1>

  </div>

  <!-- <div class="info-section info-section-1">

    <div class="info-section-subtitle info-section-subtitle-1">
      <h2>
        Morbus Fabry im Blick:<br>
        Aktuelles aus der Literatur
      </h2>
    </div>

    <div class="info-section-text info-section-text-1">
      Bleiben Sie auf dem Laufenden. Wir senden Ihnen regelmäßig kurz zusammengefasste<br>
      Forschungs- und Studienergebnisse zum Thema Morbus Fabry zu.
    </div>

    <div class="info-section-text info-section-text-italic info-section-text-2">
      Von Dr. Charlotte Nejad &amp; Dr. Martina Kralewski
    </div>

  </div> -->

  <!-- <div class="info-section info-section-2">

    <div class="info-section-subtitle info-section-subtitle-2">
      <h2>
        Die Themen im aktuellen Newsletter
      </h2>
    </div> -->

    <!-- <div class="info-section-container"> -->

      <!-- <div class="info-section-block">
        <div class="info-section-block-title">
          01
        </div>
        <div class="info-section-block-bar">
        </div>
        <div class="info-section-block-text">
          Teaser Thema 1 Lorem Ipsum Dolor Sit Amet  Kontinentia Amogis
        </div>
      </div> -->

      <!-- <div class="info-section-block">
        <div class="info-section-block-title">
          02
        </div>
        <div class="info-section-block-bar">
        </div>
        <div>
          Teaser Thema 2 Lorem Ipsum Dolor Sit Amet  Kontinentia Amogis
        </div>
      </div> -->

      <!-- <div class="info-section-block">
        <div class="info-section-block-title">
          03
        </div>
        <div class="info-section-block-bar">
        </div>
        <div class="info-section-block-text">
          Teaser Thema 3 Lorem Ipsum Dolor Sit Amet  Kontinentia Amogis
        </div>
      </div> -->

    <!-- </div> -->

  <!-- </div> -->

  <!-- 
    formControlName="lastName" 
    [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"  
  -->

  <!-- <div class="info-section info-section-3">

    <div class="info-section-subtitle info-section-subtitle-2">
      <h2>
        Melden Sie sich jetzt an:
      </h2>
    </div>

    <div>
      <form>
        <div class="info-section-form-container">
          <div class="info-section-form-column">

            <div class="info-section-form-input-container">
              <div class="info-section-form-label">
                Anrede*
              </div>
              
              <input type="text" 
                class="info-section-form-input-text"
              />
            </div>

            <div class="info-section-form-input-container">
              <div class="info-section-form-label">
                Titel
              </div>
              <input type="text" 
                class="info-section-form-input-text"
              />
            </div>

            <div class="info-section-form-input-container">
              <div class="info-section-form-label">
                Vorname
              </div>
              <input type="text" 
                class="info-section-form-input-text"
              />
            </div>

            <div class="info-section-form-input-container">
              <div class="info-section-form-label">
                Nachname*
              </div>
              <input type="text" 
                class="info-section-form-input-text"
              />
            </div>

            <div class="info-section-form-input-container">
              <div class="info-section-form-label">
                Beruf
              </div>
              <input type="text" 
                class="info-section-form-input-text"
              />
            </div>

            <div class="info-section-form-input-container">
              <div class="info-section-form-label">
                PLZ*
              </div>
              <input type="text" 
                class="info-section-form-input-text"
              />
            </div>

            <div class="info-section-form-input-container">
              <div class="info-section-form-label">
                Ort*
              </div>
              <input type="text" 
                class="info-section-form-input-text"
              />
            </div>

            <div class="info-section-form-input-container">
              <div class="info-section-form-label">
                Institution*
              </div>
              <input type="text" 
                class="info-section-form-input-text"
              />
            </div>

            <div class="info-section-form-input-container">
              <div class="info-section-form-label">
                E-Mail-Adresse *
              </div>
              <input type="text" 
                class="info-section-form-input-text"
              />
            </div>

            <div class="info-section-form-input-container">
              <input type="checkbox" 
                class="info-section-form-input-checkbox"
                id="education-checkbox"
                />
              <label for="education-checkbox" class="info-section-form-label-2">
                Ja, ich möchte mich für den Morbus Fabry-Newsletter von Takeda anmelden
              </label>
            </div>

            <div class="info-section-form-label-3">
              * Pflichtfeld
            </div>

          </div>
        </div>
      </form>
    </div>

    <div class="info-section-form-button">
      Anfordern
    </div>

  </div> -->

  <!-- <iframe class="mj-w-res-iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://app.mailjet.com/widget/iframe/1Hev/Epw" width="100%"></iframe> -->

  <div class="info-section info-section-4">

    <div class="info-section-subtitle info-section-subtitle-3">
      <h2>
        Mediathek
      </h2>
    </div>

    <div class="info-section-container">

      <div class="info-section-block">

        <div class="info-section-block-image" style="height: auto">
          <!-- width="560" height="315" -->
          <iframe width="300" height="165" src="https://www.youtube.com/embed/36EinEUEoiY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen  style="border: 1px solid #84be43;"></iframe>
          <!--<a href="https://www.youtube.com/embed/36EinEUEoiY" target="_blank">
            <img src="/assets/img/template/thumbnail-kardiologie-3.png" />
          </a>-->
        </div>

        <div class="info-section-block-title">
          Wann sollte der Kardiologe an Morbus Fabry denken?
        </div>

        <div class="info-section-block-text">
          EKG und Echokardiografie können bereits erste Hinweise 
          auf eine seltene Ursache, wie die genetisch-bedingte 
          Speicherkrankheit Morbus Fabry, geben. 
          Gezielte Fragen an den Patienten können die Verdachtsdiagnose erhärten.
        </div>
        
      </div>

      <div class="info-section-block">

        <div class="info-section-block-image" style="height: auto">
          <!-- width="560" height="315" -->
          <iframe width="300" height="165" src="https://www.youtube.com/embed/e2ftoowxRtc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen  style="border: 1px solid #84be43;"></iframe>
          <!--<a href="https://www.youtube.com/embed/e2ftoowxRtc" target="_blank">
            <img src="/assets/img/template/thumbnail-neurologie-2.png" />
          </a>-->
        </div>

        <div class="info-section-block-title">
          Wann sollte der Neurologe an Morbus Fabry denken? 
        </div>

        <div class="info-section-block-text">
          Die Speicherkrankheit Morbus Fabry zeichnet sich 
          durch die Vielfalt an Symptomen aus. 
          Dies ist eine Herausforderung für den Arzt, 
          bei bestimmten Auffälligkeiten an die seltene genetische Erkrankung zu denken.
        </div>
        
      </div>

      <!-- <div class="info-section-block">

        <div class="info-section-block-image">
          <img src="/assets/img/template/icon_20_temp.png" />
        </div>

        <div class="info-section-block-title">
          Wir unterstützen Sie bei der Diagnose
        </div>

        <div class="info-section-block-text">
          Ein einfacher Bluttest ermöglicht eine frühzeitige und eindeutige Diagnose von Morbus Fabry. Um Sie im Verdachtsfall zu unterstützen, bietet Takeda ein kostenfreies Trockenblut-Testset an. 
        </div>
        
      </div> -->

      <!-- <div class="info-section-block">

        <div class="info-section-block-image">
          <img src="/assets/img/template/icon_20_temp.png" />
        </div>

        <div class="info-section-block-title">
          Die Wirkweise von Replagal<sup class="info-section-text-ref">®</sup>
        </div>

        <div class="info-section-block-text">
          Informationen zur Wirkweise der Enzymersatztherapie mit Replagal<sup class="info-section-text-ref">®</sup> (Agalsidase alfa) erhalten Sie in diesem Video.
        </div>
        
      </div> -->

      <div class="info-section-block">

        <div class="info-section-block-image" style="height: auto">
          <!-- <img src="/assets/img/template/icon_20_temp.png" /> -->
          <iframe width="300" height="168" src="https://www.youtube.com/embed/oJjld0je6po" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen  style="border: 1px solid #84be43;"></iframe>
        </div>

        <div class="info-section-block-title">
            Von der Bildgebung zur Diagnose Morbus Fabry<br><br>
        </div>

        <div class="info-section-block-text">
          Die hypertrophe Kardiomyopathie (HCM) ist eine 
          mögliche Ursache einer linksventrikulären Hypertrophie (LVH). 
          Speicherkrankheiten, wie Amyloidose oder Morbus Fabry, 
          sollten ebenso als mögliche Differenzialdiagnose 
          in Betracht gezogen werden.
        </div>
        
      </div>

      <div class="info-section-block">

        <div class="info-section-block-image" style="height: auto">
          <!-- <img src="/assets/img/template/icon_20_temp.png" /> -->
          <iframe width="300" height="168" src="https://www.youtube.com/embed/-w3ez-RdYzo" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen  style="border: 1px solid #84be43;"></iframe>
        </div>

        <div class="info-section-block-title">
          Diagnosen müssen nicht selten sein
        </div>

        <div class="info-section-block-text">
          Die lysosomalen Speicherkrankheiten (LSD) Morbus Fabry, 
          Morbus Gaucher und Morbus Hunter sind genetische Stoffwechselerkrankungen, 
          die aufgrund der stark variierenden Symptome leicht zu übersehen sind.
        </div>
        
      </div>

      <!-- <div class="info-section-block">

        <div class="info-section-block-image">
          <img src="/assets/img/template/icon_20_temp.png" />
        </div>

        <div class="info-section-block-title">
          Neurologie-Expertenvideo Teaser
        </div>

        <div class="info-section-block-text">
          Informationen zur Wirkweise der Enzymersatztherapie mit Replagal<sup class="info-section-text-ref">®</sup> (Agalsidase alfa) erhalten Sie in diesem Video.
        </div>
        
      </div> -->

      <!-- <div class="info-section-block">

        <div class="info-section-block-image">
          <img src="/assets/img/template/icon_20_temp.png" />
        </div>

        <div class="info-section-block-title">
          Nephrologie-Expertenvideo Teaser
        </div>

        <div class="info-section-block-text">
          Informationen zur Wirkweise der Enzymersatztherapie mit Replagal<sup class="info-section-text-ref">®</sup> (Agalsidase alfa) erhalten Sie in diesem Video.
        </div>
        
      </div> -->

      <div class="info-section-block">

        <div class="info-section-block-image" style="height: auto">
          <!-- width="560" height="315" -->
          <iframe width="300" height="168" src="https://www.youtube.com/embed/MPZPZJLktac" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="border: 1px solid #84be43;"></iframe>
          <!-- <iframe width="300" height="168" src="https://www.youtube.com/embed/36EinEUEoiY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
          <!--<a href="https://youtu.be/MPZPZJLktac" target="_blank">
            <img src="/assets/img/template/thumbnail-MorbusFabry.png" />
          </a>-->
        </div>

        <div class="info-section-block-title">
          Der lange Weg zur Diagnose Morbus Fabry
        </div>

        <div class="info-section-block-text">
          Max hat es geschafft. Endlich hat er eine Diagnose. 
          Hinter ihm liegen viele Jahre, in denen er bei zahlreichen Ärzten war. 
          Denn Max leidet bereits seit seiner Kindheit unter brennenden Schmerzen in den Händen und Füßen, Bauchschmerzen und hat rote Punkte auf der Haut. 
          Der Film gibt einen kurzen Einblick, warum es so schwierig ist, eine seltene Erkrankung wie Morbus Fabry zu diagnostizieren.
        </div>
        
      </div>
      
      <div class="info-section-block">

        <div class="info-section-block-image" style="height: auto">
          <!-- width="560" height="315" -->
          <iframe width="300" height="168" src="https://www.youtube.com/embed/qwX_U0wNsAU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="border: 1px solid #84be43;"></iframe>
          <!-- <iframe width="300" height="168" src="https://www.youtube.com/embed/36EinEUEoiY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
          <!--<a href="https://youtu.be/MPZPZJLktac" target="_blank">
            <img src="/assets/img/template/thumbnail-MorbusFabry.png" />
          </a>-->
        </div>

        <div class="info-section-block-title">
          Wann sollte in der Nephrologie an Morbus Fabry gedacht werden
        </div>

        <div class="info-section-block-text">
          Albuminurie/Proteinurie, chronische Niereninsuffizienz. Das sind klassische Themen in der Nephrologie. Sie bringen gleichermaßen eine Herausforderung mit sich. Denn hinter eine unklaren Proteinurie könnte als Ursache auch die seltene Erkrankung Morbus Fabry stecken. 
        </div>
        
      </div>
      
      <div class="info-section-block">

        <div class="info-section-block-image" style="height: auto">
          <!-- width="560" height="315" -->
          <iframe width="300" height="168" src="https://www.youtube.com/embed/K0pYqJo1TxY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="border: 1px solid #84be43;"></iframe>
          <!-- <iframe width="300" height="168" src="https://www.youtube.com/embed/36EinEUEoiY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
          <!--<a href="https://youtu.be/MPZPZJLktac" target="_blank">
            <img src="/assets/img/template/thumbnail-MorbusFabry.png" />
          </a>-->
        </div>

        <div class="info-section-block-title">
          Wann sollte in der Pädiatrie an Morbus Fabry gedacht werden
        </div>

        <div class="info-section-block-text">
          Je früher die Diagnose einer seltenen Erkrankung gestellt wird, desto eher können Maßnahmen zum Schutz der Organe und Organsysteme eingeleitet werden. Eine ganz entscheidende Rolle spielt dabei die Kinderheilkunde.
        </div>
        
      </div>
      
      <div class="info-section-block">

        <div class="info-section-block-image" style="height: auto">
          <a href="https://www.rarediseasesacademy.de/rarethek/" target="_blank">
            <img src="/assets/img/template/rare-diseases-academy.png" style="border: 1px solid #84be43;" />
          </a>
        </div>

        <div class="info-section-block-title">
          Rare Diseases Academy
        </div>

        <div class="info-section-block-text">
          In der RARETHEK der RARE DISEASES ACADEMY finden Sie weitere Fortbildungsvideos zu Morbus Fabry und anderen Seltenen Erkrankungen.
        </div>
        
      </div>
      
      
        <div class="info-section-block">
        </div>

    </div>

  </div>

  <div class="info-section info-section-5">

    <div class="info-section-subtitle info-section-subtitle-4">
      <h2>
        Webinare für Ärzte
      </h2>
    </div>

    <div class="info-section-container">

      <div class="info-section-block">

        <div class="info-section-block-image" style="height: auto">
          <!--<iframe src="https://player.vimeo.com/video/496952971" width="300" height="168" frameborder="0" allow="autoplay; fullscreen" allowfullscreen  style="border: 1px solid #84be43;"></iframe>-->
          <a href="https://vimeo.com/496952971/c7bd87839a" target="_blank">
            <img src="/assets/img/template/Webinar_Paediatrie_Diagnostik_Startslide.jpg" style="border: 1px solid #84be43;" />
          </a>
        </div>

        <div class="info-section-block-title">
          Die Nadel im Heuhaufen: effiziente Diagnostik von Stoffwechselerkrankungen
        </div>

        <div class="info-section-block-text">
          Sind Ihnen in der Praxis schon einmal Patienten begegnet, bei denen die Diagnose lange Zeit unklar war? Vielleicht waren darunter auch Patienten mit einer seltenen Erkrankung, bei denen die Diagnose erst durch Umwege gestellt werden konnte. 
        </div>
        
      </div>
      <div class="info-section-block">
        <div class="info-section-block-image" style="height: auto">
          <!--<iframe src="https://player.vimeo.com/video/496946255" width="300" height="168" frameborder="0" allow="autoplay; fullscreen" allowfullscreen  style="border: 1px solid #84be43;"></iframe>-->
          <a href="https://vimeo.com/496946255/a5f7a02e62" target="_blank">
            <img src="/assets/img/template/Webinar_Neurologie_Golsari_Startslide.jpg" style="border: 1px solid #84be43;" />
          </a>
        </div>

        <div class="info-section-block-title">
          Neurologische und neuroradiologische Merkmale des Morbus Fabry
        </div>

        <div class="info-section-block-text">
          
        </div>          
      </div>
      <div class="info-section-block">
      </div>
        

    </div>

  </div>
  
  <app-footer>
  </app-footer>

</div>