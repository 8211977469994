<div id="login" class="login-wrapper">

    <!-- NEWEST -->
    <iframe align="left" 
          frameborder="0" 
          scrolling="no" 
          width="467" 
          height="231" 
          name="dc_login_iframe" 
          id="dc_login_iframe" 
          [src]="'https://login.doccheck.com/code/de/'+zone+'/login_xl/' + zone | safe">
        <a [href]="'https://login.doccheck.com/code/de/'+zone+'/login_xl/' + zone | safe" target="_blank">LOGIN</a>
    </iframe>
    
    <!-- NEW -->
<!--    <iframe align="left" 
          frameborder="0" 
          scrolling="no" 
          width="424" 
          height="215" 
          name="dc_login_iframe" 
          id="dc_login_iframe" 
          [src]="'https://login.doccheck.com/code/de/'+zone+'/login_l/' + zone | safe">
        <a [href]="'https://login.doccheck.com/code/de/'+zone+'/login_l/' + zone | safe" target="_blank">LOGIN</a>
    </iframe>-->
    
    <!-- NEW - TESTING CREATING DIRECTLY THE IFRAME -->
    <!--<iframe align="left" 
            frameborder="0" 
            scrolling="no" 
            width="424" 
            height="215" 
            name="dc_login_iframe" 
            id="dc_login_iframe" 
            src="https://login.doccheck.com/code/de/2000000014925/login_l/" >
        <a href="https://login.doccheck.com/code/de/2000000014925/login_l/" target="_blank">LOGIN</a></iframe>-->
    
    <!-- OLD IFRAME -->
    <!--<iframe align="left" 
          frameborder="0" 
          scrolling="no" 
          width="468" 
          height="196" 
          name="dc_login_iframe" 
          id="dc_login_iframe" 
          [src]="'https://login.doccheck.com/code/de/2000000014820/login_xl/zone=' + zone | safe">
    <a [href]="'https://login.doccheck.com/code/de/2000000014820/login_xl/zone=' + zone | safe" target="_blank">LOGIN</a>
  </iframe>--->
  
</div>