import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { StartComponent } from './start/start.component';
import { MorbusFabryComponent } from './morbus-fabry/morbus-fabry.component';
import { DiagnoseComponent } from './diagnose/diagnose.component';
import { TherapieComponent } from './therapie/therapie.component';
import { InfoCenterComponent } from './info-center/info-center.component';
import { EducationComponent } from './education/education.component';
import { FachgebieteComponent } from './fachgebiete/fachgebiete.component';
import { ReferencesComponent } from './references/references.component';
import { MainLoginComponent } from './main-login/main-login.component';
import { DocCheckHandlerComponent } from './doc-check-handler/doc-check-handler.component';
import { DocCheckHandlerService } from './doc-check-handler/doc-check-handler.service';

import { TherapieLoginComponent } from './therapie-login/therapie-login.component';
import { EducationLoginComponent } from './education-login/education-login.component';
import { InfoCenterLoginComponent } from './info-center-login/info-center-login.component';

// auth
import { AuthGuardService } from './shared/guards/auth-guard.service';


//  { path: 'therapie', component: TherapieComponent, data: {title: 'Morbus Fabry Therapie » Fabry-Wissen.de', description: 'Wie wird Morbus Fabry behandelt? Erfahren Sie mehr unter » Fabry-Wissen.de - Ihr Wissensportal zu Morbus Fabry'}, canActivate: [AuthGuardService] },
//  { path: 'infocenter', component: InfoCenterComponent, data: {title: 'Morbus Fabry Infocenter » Fabry-Wissen.de', description: 'Finden Sie Fachinformationen zur Erberkrankung im Infocenter unter » Fabry-Wissen.de » Ihr Wissensportal zu Morbus Fab'}, canActivate: [AuthGuardService] },
//  { path: 'education', component: EducationComponent, data: {title: 'Morbus Fabry Education » Fabry-Wissen.de', description: 'In unserer Mediathek finden Sie weiterführende Informationen zur Erberkrankung unter » Fabry-Wissen.de '}, canActivate: [AuthGuardService] }, 


const routes: Routes = [
  { path: '', redirectTo: 'start', pathMatch: 'full'},
  { path: 'start', component: StartComponent, data: {title: 'Morbus Fabry » erfahren Sie mehr über diese Krankheit', description: 'Erfahren Sie mehr über die seltene Erkrankung Morbus Fabry unter » Fabry-Wissen.de – Ihr Ansprechpartner und Wissensportal zur Erberkrankung ✓'} },
  { path: 'morbus-fabry', component: MorbusFabryComponent, data: {title: 'Morbus Fabry » erfahren Sie mehr über diese Krankheit', description: ''} },
  { path: 'diagnose', component: DiagnoseComponent, data: {title: 'Morbus Fabry Diagnose » Fabry-Wissen.de', description: 'Wie wird Morbus Fabry diagnostiziert? Erfahren Sie mehr unter » Fabry-Wissen.de - Ihr Wissensportal zu Morbus Fabry'} },
  { path: 'therapie', component: TherapieComponent, data: {title: 'Morbus Fabry Therapie » Fabry-Wissen.de', description: 'Wie wird Morbus Fabry behandelt? Erfahren Sie mehr unter » Fabry-Wissen.de - Ihr Wissensportal zu Morbus Fabry'}, canActivate: [AuthGuardService] },
  { path: 'infocenter', component: InfoCenterComponent, data: {title: 'Morbus Fabry Infocenter » Fabry-Wissen.de', description: 'Finden Sie Fachinformationen zur Erberkrankung im Infocenter unter » Fabry-Wissen.de » Ihr Wissensportal zu Morbus Fab'}, canActivate: [AuthGuardService] },
  { path: 'education', component: EducationComponent, data: {title: 'Morbus Fabry Education » Fabry-Wissen.de', description: 'In unserer Mediathek finden Sie weiterführende Informationen zur Erberkrankung unter » Fabry-Wissen.de '}, canActivate: [AuthGuardService] }, 
  { path: 'fachgebiete', component: FachgebieteComponent, data: {title: 'Morbus Fabry Fachgebiete » Fabry-Wissen.de', description: ''} },
  { path: 'referenzen', component: ReferencesComponent, data: {title: 'Morbus Fabry » erfahren Sie mehr über diese Krankheit', description: 'Erfahren Sie mehr über die seltene Erkrankung Morbus Fabry unter » Fabry-Wissen.de – Ihr Ansprechpartner und Wissensportal zur Erberkrankung ✓'} },

  { path: 'login', component: MainLoginComponent, data: {title: 'Morbus Fabry » erfahren Sie mehr über diese Krankheit', description: 'Erfahren Sie mehr über die seltene Erkrankung Morbus Fabry unter » Fabry-Wissen.de – Ihr Ansprechpartner und Wissensportal zur Erberkrankung ✓'} },
  { 
    path: 'doccheck/redirect', 
    component: DocCheckHandlerComponent,
    resolve  : {
      serviceData: DocCheckHandlerService
    }
  },

  { path: 'therapie-login', component: TherapieLoginComponent, data: {title: 'DocCheck-Login Therapie – Fabry-Wissen.de', description: ''} },
  { path: 'education-login', component: EducationLoginComponent, data: {title: 'DocCheck-Login Morbus Fabry Infocenter – Fabry-Wissen.de', description: ''} },
  { path: 'infocenter-login', component: InfoCenterLoginComponent, data: {title: 'DocCheck-Login Education – fabry-wissen.de', description: ''} },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'disabled',
    anchorScrolling: 'enabled',
    // scrollOffset: [0, 64]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
