import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {

  showCookieBar: boolean;

  constructor() { }

  ngOnInit(): void {

    this.showCookieBar = !(decodeURIComponent(document.cookie).indexOf('accept_cookies=1') > -1);
  }

  saveCookie() {
    var t = new Date;
    t.setTime(t.getTime() + 2592e6);
    var e = "expires=" + t.toUTCString();
    document.cookie = "accept_cookies=1;path=/;" + e;

    this.showCookieBar = false;
  }

}
