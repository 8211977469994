import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class StartComponent implements OnInit {

  constructor(
    private _router: Router,
    ) { }

  ngOnInit(): void {
  }

  navigateTo(url){

    this._router.navigate([url]);

  }

}
