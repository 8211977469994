import { Component, Input, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MenuComponent implements OnInit {

  @Input() activeMenuIndex: number;

  isSticky: boolean = false;

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 2;
  }

  isMenuOpen: boolean;

  constructor(
    private _router: Router,
    ) { }

  ngOnInit(): void {
    this.isMenuOpen = false;
  }

  navigateTo(url){

    this._router.navigate([url]);

  }

  openMenu(){
    this.isMenuOpen = true;
  }

  closeMenu(){
    this.isMenuOpen = false;
  }

}
