// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  firebase: {
    apiKey: 'AIzaSyDziFGI23bEpplIz_dLRZayUjtoFXPX5Vk',
    // authDomain: 'fir-express-mayuscula.firebaseapp.com',
    // projectId: 'fir-express-mayuscula',
    // databaseURL: "https://fir-express-mayuscula.firebaseio.com",
    
    // storageBucket: "fir-express-mayuscula.appspot.com",
    // messagingSenderId: "70428804291",
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
