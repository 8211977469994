import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';  
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';  
import { filter } from 'rxjs/operators';  
import { SeoService } from './seo.service/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit  {  
    
  constructor(private router: Router,  
              private activatedRoute: ActivatedRoute,  
              private seoService: SeoService,
              private titleService: Title, private meta: Meta) {  
  }  

  ngOnInit() {  
    this.createLinkForCanonicalURL();
    this.router.events.pipe(  
        filter(event => event instanceof NavigationEnd),  
      ).subscribe(() => {  
        const rt = this.getChild(this.activatedRoute);  
        rt.data.subscribe(data => {  
          console.log(data);
            this.titleService.setTitle(data.title);
            this.meta.updateTag({ name: 'description', content: data.description })
            this.meta.updateTag({ name: 'keywords', content:  '' })
            this.meta.updateTag({ name: 'og:title', content: data.title })
            this.meta.updateTag({ name: 'og:description', content: data.description })
        });  
      });  
  }  
  
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 

  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  
  }
  
}  

/*export class AppComponent {
  title = 'morbus-fabry';
}*/
