import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-info-center',
  templateUrl: './info-center.component.html',
  styleUrls: ['./info-center.component.scss']
})
export class InfoCenterComponent implements OnInit, AfterViewInit {

  currentPanelId = ""; 

  private fragment: string;
  
  constructor(
    private route: ActivatedRoute,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {

    window.scrollTo(0, 0);

    const cityButtons = document.querySelectorAll('.st2');

    for (var i = 0; i < cityButtons.length; i++) {
      cityButtons[i].addEventListener('click', this.switchAddressPanel.bind(this));
    }

    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    
    if(this.fragment=="mapImage") {
        this._activatedRoute.fragment.subscribe(
          (fragments) => {
//            var s = document.querySelector("#mapImage");
            var s = document.querySelector(".content-icon");
//            var s = document.querySelector(".floating-chameleon");
            s.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
          }
        ); // update on all changes
    }

  }

  switchAddressPanel(event): void {

    if(this.currentPanelId) {
      document.querySelectorAll(this.currentPanelId)[0].classList.add("hidden");
    }

    var newPanelId = "#center_" + event.srcElement['id'].split("_")[1];


    if(newPanelId) {
      document.querySelectorAll(newPanelId)[0].classList.remove("hidden");
    }

    this.currentPanelId = newPanelId;
  }

  ngAfterViewInit(): void {
/*    try {
        var s = document.querySelector('#' + this.fragment);
        s.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
//      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }*/
    
  }

}
