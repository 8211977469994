<div id="start" class="page-wrapper">

  <div class="header-bar">

    <div class="rectangle rectangle-1">
    </div>
    <div class="rectangle rectangle-2">
    </div>
    <div class="rectangle rectangle-3">
    </div>
    <div class="rectangle rectangle-4">
    </div>
    <div class="rectangle rectangle-5">
    </div>
    <div class="rectangle rectangle-6">
    </div>

  </div>

  <div class="title-wrapper">

    <h1 class="message-title">
      Willkommen auf der Morbus Fabry Webseite
    </h1>
    
    <div class="message-text">
      Auf den folgenden Seiten finden Sie grundlegende Informationen zu Morbus Fabry. 
      Sie erhalten einen Überblick zur Erkrankung, Wissenswertes zur Diagnose, aktuelle 
      Therapiemöglichkeiten sowie unterstützende Infomaterialien zum Download.
    </div>

  </div>

  <div class="buttons-wrapper">

    <h3>
      <!--<a href="https://www.fabry-wissen.de/allgemein/" target="_blank" -->
      <a href="/allgemein" target="_blank" 
        class="button-wrapper button-wrapper-1" 
        title="mehr für Fabry Interessierte">
      </a>
    </h3>

    <h3>
      <a routerLink="/morbus-fabry" 
        class="button-wrapper button-wrapper-2"
        title="mehr für Fabry Fachkreise">

      </a>
    </h3>

  </div>

</div>

<app-footer style="font-family: 'OpenSans-Regular';">
</app-footer>