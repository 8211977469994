export const references = {

  "morbus-fabry": [
    {
      "sup": 1,
      "text": "MEIKLE, P. et al. JAMA. 1999: 249–254.",
    },
    {
      "sup": 2,
      "text": "SPADA, M. et al. Am J Hum Genet. 2006: 31–40.",
    },
    {
      "sup": 3,
      "text": "HOPKINS, P. et al. J Pediatr. 2015: 172–177.",
    },
    {
      "sup": 4,
      "text": "WITTMANN J et al. JIMD Rep. 2012: 117–125.",
    },
    {
      "sup": 5,
      "text": "HWU, WL. et al. Hum Mutat. 2009: 1397–1405.",
    },
    {
      "sup": 6,
      "text": "MACDERMOT, KD. et al. J Med Genet. 2001: 750–760.",
    },
    {
      "sup": 7,
      "text": "MACDERMOT, KD. et al. J Med Genet. 2001: 769–775.",
    },
    {
      "sup": 8,
      "text": "KÖPING, M. et al. Orphanet J Rare Dis. 2018: 137.",
    },
    {
      "sup": 9,
      "text": "CARMONA, S. et al. Audiol Res. 2017: 176.",
    },
    {
      "sup": 10,
      "text": "HEGEMANN, S. et al. Eur J Clin Invest. 2006: 654–662.",
    },
    {
      "sup": 11,
      "text": "HOFFMANN, B. et al. Clin Gastroenterol Hepathol. 2007: 1447–1453.",
    },
    {
      "sup": 12,
      "text": "WEIDEMANN, F. et al. Int J Cardiol. 2010: 3–10.",
    },
    {
      "sup": 13,
      "text": "WALDEK, S. et al. Genet Med. 2009: 790–796.",
    },
    {
      "sup": 14,
      "text": "MORRISSEY, RP. et al. Cardiovasc J Afr. 2011: 38–44.",
    },
    {
      "sup": 15,
      "text": "HOFFMANN, B. et al. Hepatology. 2007: 1447–1453.",
    },
    {
      "sup": 16,
      "text": "HOPKIN, RJ. et al. Pediatr Res. 2008: 550–555.",
    },
    {
      "sup": 17,
      "text": "RAMASWAMI, U. et al. Acta Paediatrica. 2006: 86–92.",
    },
    {
      "sup": 18,
      "text": "PENSABENE, L. et al. Gastroenterol Res Pract. 2016: 9712831.",
    },
    {
      "sup": 19,
      "text": "HOFFMANN, B. et al. Clin J Pain. 2007: 535–542.",
    },
    {
      "sup": 20,
      "text": "ÜÇEYLER, N. et al. Clin J Pain. 2014: 915–920.",
    },
    {
      "sup": 21,
      "text": "MEHTA, A. et al. Q J Med. 2010: 641–649.",
    },
    {
      "sup": 22,
      "text": "KOLODNY, E. & PASTORES, G. Am Soc Nephrol. 2002: S150–S513.",
    },
    {
      "sup": 23,
      "text": "LAO, LM. et al. J Dermatol Sci. 1998: 109–117.",
    },
    {
      "sup": 24,
      "text": "MØLLER, AT. et al. J Peripher Nerv Syst. 2009: 159–164.",
    },
    {
      "sup": 25,
      "text": "SIMS, K. et al. Stroke. 2009: 788–794.",
    },
    {
      "sup": 26,
      "text": "SODI, A. et al. Br J Ophthalmol. 2007: 210–214.",
    },
    {
      "sup": 27,
      "text": "PITZ, S. et al. PLoS One. 2015: e0120814.",
    },
    {
      "sup": 28,
      "text": "KALKUM, G. et al. BMC Ophtalmol. 2016: 202.",
    },
    {
      "sup": 29,
      "text": "WANNER, C. et al. Clin J Am Soc Nephrol. 2010: 2220–2228.",
    },
    {
      "sup": 30,
      "text": "PISANI, A. et al. Nephrol Dial Transplant. 2018: 318–323.",
    },
    {
      "sup": 31,
      "text": "RIES, M. et al. Kidney Int. 2004: 978–982.",
    },
    {
      "sup": 32,
      "text": "BECK, M. In: MEHTA, A. et al. (Hrsg.) Fabry Disease: Perspectives from\n 5 Years of FOS. Oxford   PharmaGenesis Ltd; 2006; Chapter 16.\n Verfügbar unter: https://www.ncbi.nlm.nih.gov/pubmed/21290683\n [letzter Zugriff: 02.03.2020].",
    },
    {
      "sup": 33,
      "text": "NAKAO, S. et al. Kidney Int. 2003: 801–807.",
    },
    {
      "sup": 34,
      "text": "KOTANKO, P. et al. J Am Soc Nephrol. 2004: 1323–1329.",
    },
    {
      "sup": 35,
      "text": "TANAKA, M. et al. Clin Nephrol. 2005: 281–287.",
    },
    {
      "sup": 36,
      "text": "GASPAR, P. et al. BMC Med Genet. 2010: 19.",
    },
    {
      "sup": 37,
      "text": "KLEINERT, J. et al. Transpl Int. 2009: 287–292.",
    },
    {
      "sup": 38,
      "text": "LIDOVE, O. et al. In: MEHTA, A. et al. (Hrsg.). Fabry Disease Perspectives\n from 5 Years of FOS. Oxford PharmaGenesis Ltd; 2006; Chapter 24.\n Verfügbar unter: https:// www.ncbi.nlm.nih.gov/pubmed/21290683/\n [letzter Zugriff: 02.03.2020].",
    },
    {
      "sup": 39,
      "text": "LARRALDE, M. et al. Arch Dermatol. 2004: 1440–1446.",
    },
    {
      "sup": 40,
      "text": "BARBEY, F. et al. Curr Med Chem Cardiovasc Haematol Agents. 2004;\n 277–286.",
    },
    {
      "sup": 41,
      "text": "Openstax College, Rice University. Biology (2013). ISBN–10\n 1938168399; ISBN–13; 978–1–938168–39–0; Revision C–1–000–DW. Verfügbar unter: https://openstax.org/",
    },
    {
      "sup": 42,
      "text": "SCHÄFER, E. et al. Hum Mutat. 2005: 412.",
    },
    {
      "sup": 43,
      "text": "BARBA–ROMERO, M–Á. et al. Int J Clin Pract. 201: 903–910.",
    },
    {
      "sup": 44,
      "text": "Human Gene Mutation Database. Verfügbar unter:\n http://www.hgmd.cf.ac.uk/ac/gene.php?gene=GLA [letzter Zugriff: 29.09.2020]",
    },
    {
      "sup": 45,
      "text": "SMID, BE. et al. J Med Genet. 2015: 262–268.",
    },
    {
      "sup": 46,
      "text": "DESNICK, RJ. et al. a–Galactosidase A deficiency: Fabry disease. In:\n SCRIVER, CR. et al. (Hrsg.) The metabolic and molecular bases\n of inherited disease, 8th ed. New York: McGraw-Hill, 2001: 3733–3774.",
    },
    {
      "sup": 47,
      "text": "WHYBRA, C. et al. Klinische Manifestation des Morbus Fabry bei\n Kindern. In: Neuropädiatrie. 2;2005: 44–48.",
    },
    {
      "sup": 48,
      "text": "ENG, CM. et al. J Inherit Metab Dis. 2007: 184–192.",
    },
    {
      "sup": 49,
      "text": "REISIN, R. et al. Int J Clin Pract. 2017: e12914.",
    },
    {
      "sup": 50,
      "text": "MEHTA, A. et al. Eur J Clin Invest. 2004: 236–242.",
    },
    {
      "sup": 51,
      "text": "DOBYNS, WB. et al. Am J Med Genet A. 2004: 136–143.",
    },
    {
      "sup": 52,
      "text": "WANG, RY. et al. Genet Med. 2007: 34–45.",
    },
    {
      "sup": 53,
      "text": "ORTIZ, A. et al. Mol Genet Metab. 2018: 416–427.",
    },
    {
      "sup": 54,
      "text": "LYON, MF. Nature. 1961: 372–373.",
    },
    {
      "sup": 55,
      "text": "LU, YH. et al. J Hum Genet. 2018: 1–8.",
    },
    {
      "sup": 56,
      "text": "RUDOLPH, C. et al. Kinderärztliche Praxis (Sonderheft Morbus Fabry).\n 2018: 3–5.",
    },
    {
      "sup": 57,
      "text": "SVARSTAD, E. et al. Clin Nephrol. 2005: 394–401.",
    },
    {
      "sup": 58,
      "text": "BÖTTCHER, T. et al. PLOS One. 2013: e71894.",
    },
    {
      "sup": 59,
      "text": "TANISLAV, C. et al. Eur J Neurol. 2011: 631–636.",
    },
    {
      "sup": 60,
      "text": "LIDOVE, O. et al. Joint Bone Spine. 2016: 421–426.",
    },



  ],

  "diagnose": [
    {
      "sup": 1,
      "text": "BECK, M. In: MEHTA, A. et al. (Hrsg.) Fabry Disease Perspectives from 5 Years of FOS. Oxford PharmaGenesis Ltd; 2006; Chapter\n16. Verfügbar unter: https://pubmed.ncbi.nlm.nih.gov/21290683/ [letzter Zugriff: 23.06.2020].",
    },
    {
      "sup": 2,
      "text": "MEHTA, A. et al. Eur J Clin Invest. 2004: 236–242.",
    },
    {
      "sup": 3,
      "text": "MACDERMOT, KD. et al. J Med Genet. 2001: 750–760.",
    },
    {
      "sup": 4,
      "text": "MACDERMOT, KD. et al. J Med Genet. 2001: 769–775.",
    },
    {
      "sup": 5,
      "text": "BAEHNER, F. et al. J Inherit Metab Dis. 2003: 617–627.",
    },
    {
      "sup": 6,
      "text": "LANEY, DA. & FERNHOFF, PM. J Genet Couns. 2008: 79–83.",
    },
    {
      "sup": 7,
      "text": "LANEY, DA. et al. J Genet Couns. 2013: 555–564.",
    },
    {
      "sup": 8,
      "text": "GAL, A. et al. Eur J Hum Genet. 2012; 20.",
    },
    {
      "sup": 9,
      "text": "SMID, BE. et al. J Med Genet. 2015: 262–268.",
    },
    {
      "sup": 10,
      "text": "TALBOT, A. et al. Mol Genet Metab. 2017: 121–125.",
    },
    {
      "sup": 11,
      "text": "AERTS, JM. et al. Proc Natl Acad Sci USA. 2008: 2812–2817.",
    },
    {
      "sup": 12,
      "text": "NOWAK, A. et al. Mol Genet Metab. 2017: 57–61.",
    },
    {
      "sup": 13,
      "text": "DUPONT, FO. et al. Curr Med Chem. 2013: 280–288.",
    },
    {
      "sup": 14,
      "text": "NIEMANN, M. et al. Circ Cardiovasc Genet. 2014: 8–16.",
    },
    {
      "sup": 15,
      "text": "LUKAS, J. et al. Hum Mutat. 2016: 43–51.",
    },
    {
      "sup": 16,
      "text": "FERRAZ, MJ. et al. Biochim Biophys Acta. 2014: 811–825.",
    },
    {
      "sup": 17,
      "text": "ARENDS, M. et al. Mol Genet Metab. 2017: 157–161.",
    },
    {
      "sup": 18,
      "text": "SAKURABA, H. et al. Clin Exp Nephrol. 2018: 843–849.",
    },
    {
      "sup": 19,
      "text": "ROMBACH, SM. et al. PLoS One. 2012: e47805.",
    },
    {
      "sup": 20,
      "text": "LENDERS, M. et al. J Am Soc Nephrol. 2016: 256–264.",
    },
    {
      "sup": 21,
      "text": "LENDERS, M. et al. J Allergy Clin Immunol. 2018: 2289–2292.",
    },
    {
      "sup": 22,
      "text": "TSUBOI, K. et al. BMC Pharmacol Toxicol. 2017: 43.",
    },
    {
      "sup": 23,
      "text": "FERRAZ, MJ. et al. FEBS Lett. 2016: 716–725.",
    },
    {
      "sup": 24,
      "text": "POLO, G. et al. Clin Chem Lab Med. 2017: 403–414.",
    },
    {
      "sup": 25,
      "text": "VAN BREEMEN, MJ. et al. Biochim Biophys Acta. 2011: 70–76.",
    },
    {
      "sup": 26,
      "text": "GERMAIN, DP. et al. N Engl J Med. 2016: 545–555.",
    },
    {
      "sup": 27,
      "text": "LIU, HC. et al. Orphanet J Rare Dis. 2014: 111.",
    },
    {
      "sup": 28,
      "text": "BENJAMIN, ER. et al. Genet Med. 2017: 430–438 (und ergänzende Materialien).",
    },
  ],
  
  "therapie": [
    {
      "sup": 1,
      "text": "AWMF-Leitlinien-Register Nr. 030/134: Interdisziplinäre Leitlinie für die Diagnose und Therapie des Morbus Fabry; Verfügbar unter: https://dgn.org/wp-content/uploads/2015/10/030-134l_S2k_Morbus_Fabry_Diagnose_Therapie_2013-verlaengert_archiviert.pdf [letzter Zugriff: 16.10.2020].",
    },
    {
      "sup": 2,
      "text": "HUGHES, D. et al. In: MEHTA, A. et al. (Hrsg.) Fabry Disease: Perspectives from 5 Years of FOS. Oxford PharmaGenesis Ltd; 2006; Chapter 35. Verfügbar unter: https://www.ncbi.nlm.nih.gov/pubmed/21290683 [Letzter Zugriff: 23.06.2020].",
    },
    {
      "sup": 3,
      "text": "LANEY, DA. et al. J Genet Couns. 203: 555–564.",
    },
    {
      "sup": 4,
      "text": "GAL, A. et al. Eur J Hum Genet. 2012: 20.",
    },
    {
      "sup": 5,
      "text": "VAN DER TOL, L. et al. J Med Genet. 2014: 1–9.",
    },
    {
      "sup": 6,
      "text": "BROUNS, R. et al. Stroke. 2010: 863–868",
    },
    {
      "sup": 7,
      "text": "ROLFS, A. et al. Stroke. 2013: 340–349.",
    },
    {
      "sup": 8,
      "text": "DU MOULIN, M. et al. Clin Genet. 2017: 528–533.",
    },
    /*{
      "sup": 1,
      "text": "Fachinformation Galafold<sup>®</sup>, Stand März 2019.",
    },
    {
      "sup": 2,
      "text": "Fachinformation REPLAGAL<sup>®</sup>, Stand Juli 2016.",
    },
    {
      "sup": 3,
      "text": "Fachinformation Fabrazyme<sup>®</sup>, Stand November 2018.",
    },
    {
      "sup": 4,
      "text": "AWMF-Leitlinien-Register Nr. 030/134: Interdisziplinäre Leitlinie für die Diagnose und Therapie des Morbus Fabry. 2013.\nVerfügbar unter: https://www.dgn.org/images/red_leitlinien/LL_2013/\nPDFs_Download/030134l_S2k_Morbus_Fabry_Diagnose_Therapie_2013-verlaengert.pdf [letzter Zugriff 12.06.2020].",
    },
    {
      "sup": 5,
      "text": "WANNER, C. et al. Mol Genet Metab. 2018: 189–203.",
    },
    {
      "sup": 6,
      "text": "HUGHES, D. et al. In: MEHTA, A. et al. (Hrsg.) Fabry Disease:\nPerspectives from 5 Years of FOS. Oxford PharmaGenesis Ltd; 2006;\nChapter 35. Verfügbar unter: https://pubmed.ncbi.nlm.nih.gov/\n21290683/ [letzter Zugriff: 23.06.2020].",
    },
    {
      "sup": 7,
      "text": "LANEY, DA. et al. J Genet Couns. 2013: 555–64.",
    },
    {
      "sup": 8,
      "text": "GAL, A. et al. J Inherit Metab Dis. 2011: 509–514.",
    },
    {
      "sup": 9,
      "text": "LANEY, DA. & FERNHOFF, PM. J Genet Couns. 2008: 79–83.",
    },
    {
      "sup": 10,
      "text": "GAL, A. et al. Eur J Hum Genet. 2012: 20.",
    },
    {
      "sup": 11,
      "text": "VAN DER TOL, L. et al. J Med Genet. 2014: 1–9.",
    },
    {
      "sup": 12,
      "text": "BROUNS, R. et al. Stroke. 2010: 863–838.",
    },
    {
      "sup": 13,
      "text": "ROLFS, A. et al. Stroke. 2013: 340–349.",
    },
    {
      "sup": 14,
      "text": "DU MOULIN, M. et al. Clin Genet. 2017: 528–533.",
    },
    {
      "sup": 15,
      "text": "MACDERMOT, KD. et al. J Med Genet. 2001: 750–760.",
    },
    {
      "sup": 16,
      "text": "MACDERMOT, KD. et al. J Med Genet. 2001: 769–775.",
    },
    {
      "sup": 17,
      "text": "SCHIFFMANN, R. et al. Nephrol Dial Transplant. 2009: 2102–2111.",
    },
    {
      "sup": 18,
      "text": "BECK, M. et al. Mol Genet Metabol Reports. 2015: 21–27.",
    },*/

  ]

}