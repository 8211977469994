import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  identifier: '', 
  url: ''
}

@Component({
  selector: 'app-banner-dialog',
  templateUrl: './banner-dialog.component.html',
  styleUrls: ['./banner-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BannerDialogComponent implements OnInit {

  currentIdentifier: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    console.log("new dialog data: ", data);

    this.currentIdentifier = data.identifier;
    
  }

  ngOnInit(): void {

  }

}
