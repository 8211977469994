import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { StartComponent } from './start/start.component';
import { MorbusFabryComponent } from './morbus-fabry/morbus-fabry.component';
import { DiagnoseComponent } from './diagnose/diagnose.component';
import { TherapieComponent } from './therapie/therapie.component';
import { InfoCenterComponent } from './info-center/info-center.component';
import { EducationComponent } from './education/education.component';
import { FachgebieteComponent } from './fachgebiete/fachgebiete.component';
import { MenuComponent } from './partials/menu/menu.component';
import { FooterComponent } from './partials/footer/footer.component';
import { ReferencesComponent } from './references/references.component';
import { LoginComponent, SafePipe } from './partials/login/login.component';
import { TherapieLoginComponent } from './therapie-login/therapie-login.component';
import { EducationLoginComponent } from './education-login/education-login.component';
import { InfoCenterLoginComponent } from './info-center-login/info-center-login.component';
import { ImageDialogComponent } from './partials/image-dialog/image-dialog.component';
import { BannerDialogComponent } from './partials/banner-dialog/banner-dialog.component';
import { DocCheckHandlerComponent } from './doc-check-handler/doc-check-handler.component';
import { DocCheckHandlerService } from './doc-check-handler/doc-check-handler.service';
import { MainLoginComponent } from './main-login/main-login.component';

// auth
import { AuthGuardService } from './shared/guards/auth-guard.service';
import { ApiLoginService } from './shared/services/api-login.service';
import { NgxScrollTopModule } from 'ngx-scrolltop';


@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    MorbusFabryComponent,
    DiagnoseComponent,
    TherapieComponent,
    InfoCenterComponent,
    EducationComponent,
    FachgebieteComponent,
    MenuComponent,
    FooterComponent,
    ReferencesComponent,
    LoginComponent,
    SafePipe,
    TherapieLoginComponent,
    EducationLoginComponent,
    ImageDialogComponent,
    BannerDialogComponent,
    MainLoginComponent,
    DocCheckHandlerComponent,
    InfoCenterLoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,

    FormsModule,
    HttpClientModule,

    MatTooltipModule,
    MatDialogModule,
    NgxScrollTopModule,
  ],
  providers: [
    AuthGuardService,
    ApiLoginService,
    DocCheckHandlerService,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
