<div id="morbus-fabry" class="page-wrapper">
<!--<div id="morbus-fabry" class="page-wrapper" (load)="openBanner('morbus-fabry-banner')">-->

    <app-menu [activeMenuIndex]="1">
    </app-menu>

    <!--<div class="subheader-bar-wrapper">
      <div class="subheader-content">
        <a href="https://www.lsd-diagnostik-partner.de/" target="_blank">
          <div class="subheader-content-text">
            Ihr kostenfreies Testset!
          </div>
        </a>
      </div>
    </div>-->

    <div class="floating-chameleon">
    </div>

    <!-- <div class="content-icon">
      <img src="/assets/img/template/icon-home-btn.svg" />
    </div> -->

    <span class="icon-book ico page-intro pink"></span>

    <div class="title-wrapper">

        <h1>
            Was ist <span class="no-lb">Morbus Fabry</span>?
        </h1>

        <div class="title-description">
            <span class="title-description-b"><span class="no-lb">Morbus Fabry</span></span> ist eine seltene, progrediente Multisystemerkrankung, 
            die durch genetische Varianten im <span class="title-description-i">GLA</span>-Gen verursacht wird.
            Die <span class="title-description-sb">Symptome</span> der Erkrankung können sich im Laufe des Lebens verändern. 
        </div>

    </div>

    <div class="info-section-1">
        <div class="info-section info-section-1-a">
            <div class="section-icon">
                <img src="/assets/img/template/chromosome.svg" />
            </div>
            <div class="info-section-text">
                <span class="info-section-text-b">X</span>-chromosomal vererbte <span class="info-section-text-b">lysosomale Speicher-erkrankung</span> (Sphingolipidose)
            </div>
        </div>

        <div class="info-section info-section-1-b">
            <div class="section-icon">
                <img src="/assets/img/template/alpha.svg" />
                <!-- <span id="DescDoctor" class="badge specialist-link achnorpulse specialist-doctor">
                  <span class="icon-man ico"></span>
                </span> -->
            </div>
            <div class="info-section-text">
                Defekt am <span class="info-section-text-bi">GLA</span> <span class="info-section-text-b">-Gen</span> führt zu <span class="info-section-text-b">verminderter <br>Aktivität</span> oder <span class="info-section-text-b">Mangel</span> an 
                <br><span class="info-section-text-b">α-Galaktosidase-A</span> (α-GAL)
            </div>
        </div>

        <div class="info-section info-section-1-c">
            <div class="info-section-text">
                Prävalenz des 
                klassischen Phänotyps:<sup class="info-section-text-ref">1</sup>
            </div>
            <div class="info-section-icons">
                <div class="section-icon">
                    <img src="/assets/img/template/Piktogramme_Frau_Badge.svg" />
                    <div class="section-icon-text pink">
                        <div class="info-section-text-b">
                            1:20.000
                        </div>
                    </div>
                </div>
                <div class="section-icon">
                    <img src="/assets/img/template/Piktogramme_Mann_Badge.svg" />
                    <div class="section-icon-text blue-two">
                        <div class="info-section-text-b">
                            1:40.000
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="info-section info-section-1-d">
            <div class="info-section-text">
                Neugeborenen-Screenings zeigten teilweise erheblich höhere Inzidenzen von:
            </div>
            <div class="info-section-icons">
                <div class="section-icon">
                    <img src="/assets/img/template/Piktogramme_Kind_Badge.svg" />
                    <div class="section-icon-text purple">
                        <div class="info-section-text-b">1:13.000</div> 
                        bis 
                        <div class="info-section-text-b">
                            1:1.250<sup class="info-section-text-ref">2&ndash;5</sup>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>

    <div class="info-section-ribbon info-section-2">

        <div class="info-section-ribbon-left">
        </div>

        <div class="info-section-ribbon-right">
        </div>

        <div class="info-section-title">
            <h2>
                Bei <span class="no-lb">Morbus Fabry</span> ist eine frühe Diagnose wichtig!
            </h2>
        </div>

        <div class="info-section-text info-section-text-last-2">
            Ohne Therapie kann es bei Betroffenen zu einer deutlich reduzierten
            Lebenserwartung kommen (Median: Männer <span class="no-lb">50 Jahre</span> und Frauen <span class="no-lb">70 Jahre</span>).<sup class="info-section-text-ref">6,7</sup>
            Deshalb ist eine frühzeitige Diagnose wichtig.
        </div>

        <div class="info-section-block">
            <!-- <div class="section-link-icon">
              <img src="/assets/img/template/icon_3_temp.png" alt="ico />
            </div> -->

            <a href="#" class="section-link" routerLink="/diagnose">
                <span class="link-arrow-right icon-arrow-right ico white"></span>
                <span class="section-link-text">
                    Zur Diagnose von <span class="no-lb">Morbus Fabry</span>
                </span>
            </a>
        </div>

    </div>

    <div class="info-section info-section-3">

        <div class="info-section-title">
            <h2>
                Typische Symptome und Auffälligkeiten
            </h2>
        </div>

        <!-- <div class="grid-container">
          <div class="grid-x align-center"> -->
        <!-- <div class="cell small-12 text-center"> -->
        <div class="info-section-symptom-box">
            <div class="badge bg-pink symptom-short-desc">              
                <!-- ShortDesc -->
                <div class="symptom-short-desc-content DescDefault show" data-desc-short="DescDefault">
                    <span class="symptom-short-desc-title">Anleitung</span>
                    <span class="symptom-short-desc-text">
                        Bitte fahren Sie mit der Maus über einen der Punkte, um die Symptome anzuzeigen.
                    </span>
                </div>
                <!-- ShortDesc -->
                <div class="symptom-short-desc-content DescBrain" data-desc-short="DescBrain">
                    <span class="symptom-short-desc-title first">ZNS</span>
                    <span class="symptom-short-desc-text">
                        Schlaganfall, TIA, Depressionen, White Matter Lesions
                    </span>
                    <!-- <span class="symptom-short-desc-title">Psychische Beschwerden</span> -->
                    <!-- <span class="symptom-short-desc-text">
                      Müdigkeit, Antriebslosigkeit, Verwirrtheit, Depressionen
                    </span> -->
                    <span class="symptom-icon badge blue"><span class="icon-brain ico"></span></span>
                    <span class="symptom-short-desc-cta">Jetzt klicken </span>
                </div>
                <!-- ShortDesc -->
                <div class="symptom-short-desc-content DescEye" data-desc-short="DescEye">
                    <span class="symptom-short-desc-title">Augen</span>
                    <span class="symptom-short-desc-text">
                        Cornea verticillata, Tortuositas vasorum
                    </span>
                    <span class="symptom-icon badge green"><span class="icon-eye ico"></span></span>
                    <span class="symptom-short-desc-cta">Jetzt klicken </span>
                </div>
                <!-- ShortDesc -->
                <div class="symptom-short-desc-content DescEar" data-desc-short="DescEar">
                    <span class="symptom-short-desc-title">Ohren</span>
                    <span class="symptom-short-desc-text">
                        Tinnitus, Vertigo, Hörstörungen
                    </span>
                    <span class="symptom-icon badge apple"><span class="icon-ear ico"></span></span>
                    <span class="symptom-short-desc-cta">Jetzt klicken </span>
                </div>
                <!-- ShortDesc -->
                <div class="symptom-short-desc-content DescLung" data-desc-short="DescLung">
                    <span class="symptom-short-desc-title">Lunge</span>
                    <span class="symptom-short-desc-text">
                        Atemnot, <br>Pfeifende Atemgeräusche
                    </span>
                    <span class="symptom-icon badge ocean"><span class="icon-lung ico"></span></span>
                    <span class="symptom-short-desc-cta">Jetzt klicken </span>
                </div>
                <!-- ShortDesc -->
                <div class="symptom-short-desc-content DescHeart" data-desc-short="DescHeart">
                    <span class="symptom-short-desc-title">Herz</span>
                    <span class="symptom-short-desc-text">
                        Linksventrikuläre Hypertrophie, prominenter Papillarmuskel, Arrhythmien
                    </span>
                    <span class="symptom-icon badge red"><span class="icon-heart ico"></span></span>
                    <span class="symptom-short-desc-cta">Jetzt klicken </span>
                </div>
                <!-- ShortDesc -->
                <div class="symptom-short-desc-content DescKidney" data-desc-short="DescKidney">
                    <span class="symptom-short-desc-title">Nieren</span>
                    <span class="symptom-short-desc-text">
                        Proteinurie/Albuminurie, Niereninsuffizienz, parapelvine Zysten
                    </span>
                    <span class="symptom-icon badge orange"><span class="icon-kidney ico"></span></span>
                    <span class="symptom-short-desc-cta">Jetzt klicken </span>
                </div>
                <!-- ShortDesc -->
                <div class="symptom-short-desc-content DescBowel" data-desc-short="DescBowel">
                    <span class="symptom-short-desc-title">Verdauungstrakt</span>
                    <span class="symptom-short-desc-text">
                        Bauchschmerzen, Diarrhö, Völlegefühl, Übelkeit, DD zu Diarrhö-prädominanter Form des Reizdarmsyndroms
                    </span>
                    <span class="symptom-icon badge purple"><span class="icon-bowel ico"></span></span>
                    <span class="symptom-short-desc-cta">Jetzt klicken </span>
                </div>
                <!-- ShortDesc -->
                <div class="symptom-short-desc-content DescSkin" data-desc-short="DescSkin">
                    <span class="symptom-short-desc-title">Haut</span>
                    <span class="symptom-short-desc-text">
                        Angiokeratome und Lymphödeme
                    </span>
                    <span class="symptom-icon badge pink"><span class="icon-skin ico"></span></span>
                    <span class="symptom-short-desc-cta">Jetzt klicken </span>
                </div>
                <!-- ShortDesc -->
                <!-- <div class="symptom-short-desc-content DescVeins" data-desc-short="DescVeins">
                  <span class="symptom-short-desc-title">Blutgefäße</span>
                  <span class="symptom-short-desc-text">
                    Weiß- oder Blaufärbung der Finger und manchmal der Zehen  
                  </span>
                  <span class="symptom-icon badge red"><span class="icon-veins ico"></span></span>
                  <span class="symptom-short-desc-cta">Jetzt klicken </span>
                </div> -->
                <!-- ShortDesc -->
                <div class="symptom-short-desc-content DescNerves" data-desc-short="DescNerves" style="padding-top: 10px">
                    <span class="symptom-short-desc-title">Peripheres Nervensystem</span>
                    <span class="symptom-short-desc-text">
                        Neuropathische Schmerzen (<span class="no-lb">Small-Fiber-Neuropathie</span>),<br>Anhidrose/Hypohidrose
                    </span>
                    <span class="symptom-icon badge blue"><span class="icon-nerves ico"></span></span>
                    <span class="symptom-short-desc-cta">Jetzt klicken </span>
                </div>
                <!-- ShortDesc -->
                <div class="symptom-short-desc-content DescNerves" data-desc-short="DescNervesFoot" style="padding-top: 10px">
                    <span class="symptom-short-desc-title">Peripheres Nervensystem</span>
                    <span class="symptom-short-desc-text">
                        Neuropathische Schmerzen (<span class="no-lb">Small-Fiber-Neuropathie</span>),<br>Anhidrose/Hypohidrose
                    </span>
                    <span class="symptom-icon badge blue"><span class="icon-nerves ico"></span></span>
                    <span class="symptom-short-desc-cta">Jetzt klicken </span>
                </div>
                <!-- ShortDesc -->
                <div class="symptom-short-desc-content DescMove" data-desc-short="DescMove">
                    <span class="symptom-short-desc-title">Bewegungs-<br>apparat</span>
                    <span class="symptom-short-desc-text">
                        Unkoordinierter Gang, <br>Bewegungsstörungen
                    </span>
                    <span class="symptom-icon badge green"><span class="icon-foot ico"></span></span>
                    <span class="symptom-short-desc-cta">Jetzt klicken </span>    
                </div>              
            </div>
            <img class="symptom-person" src="/assets/img/template/symptom-person.svg" alt="">
            <span id="DescBrain" class="badge bg-blue symptom-link achnorpulse symptom-brain"></span>
            <span id="DescEye" class="badge bg-green symptom-link achnorpulse symptom-eye"></span>
            <span id="DescEar" class="badge bg-apple symptom-link achnorpulse symptom-ear"></span>
            <!-- <span id="DescLung" class="badge bg-ocean symptom-link achnorpulse symptom-lung"></span> -->
            <span id="DescHeart" class="badge bg-red symptom-link achnorpulse symptom-heart"></span>
            <span id="DescKidney" class="badge bg-orange symptom-link achnorpulse symptom-kidney"></span>
            <span id="DescBowel" class="badge bg-purple symptom-link achnorpulse symptom-bowel"></span>
            <span id="DescSkin" class="badge bg-pink symptom-link achnorpulse symptom-skin"></span>
            <!-- <span id="DescVeins" class="badge bg-red symptom-link achnorpulse symptom-veins"></span> -->
            <span id="DescNerves" class="badge bg-blue symptom-link achnorpulse symptom-hand"></span>
            <!-- <span id="DescMove" class="badge bg-green symptom-link achnorpulse symptom-move"></span> -->
            <span id="DescNervesFoot" class="badge bg-blue symptom-link achnorpulse symptom-foot"></span>
        </div>
        <!-- </div> -->
        <!-- </div>
      </div> -->

        <div class="info-section-symptom-description cell small-12 large-8 text-center bg-pink">

            <!-- Desc -->
            <div class="symptom-desc" data-desc-box="DescBrain" style="display: none">
                <span class="symptom-icon badge blue"><span class="icon-brain ico"></span></span>
                <div class="h3 symptom-desc-title">ZNS</div>

                <ol class="symptom-desc-list">
                    <!-- <li>
                      <div class="symptom-desc-list-title">Schlaganfall</div>
          
                      <ul>
                        <li>
                          Sensorineurale Hörminderung im 
                          Hochfrequenzbereich (Innenohrhörstörung): 
                          bei bis zu 74 % der Patienten<sup class="symptom-short-desc-ref">8–10</sup>
                        </li>
                        <li>
                          Schalleitungsschwerhörigkeit
                        </li>
                      </ul>
          
                    </li> -->
                    <li>
                        <div class="symptom-desc-list-title">Schlaganfall/transitorische Attacke (TIA) (<span class="no-lb">18-55 Jahre</span>)</div>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">White Matter Lesions (WML)</div>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">Depressive Störungen</div>
                    </li>


                    <div class="symptom-bar-separator symptom-bar-separator-top"></div>

                    <div class="symptom-badge-wrapper">

                        <div class="symptom-block-left">
                            <img src="/assets/img/template/alert-triangle.png" alt="Alert Triangle">
                        </div>


                        <div class="symptom-block-right">
                            Schlaganfall und TIA sind bei Fabry-Patienten häufiger und früher als in der Allgemeinbevölkerung.<sup class="symptom-short-desc-ref">25</sup> 
                        </div>

                    </div>

                    <div class="symptom-bar-separator symptom-bar-separator-bottom"></div>

                </ol>



                <!-- <p class="symptom-desc-subtitle">Mehr Informationen finden Sie im Fachbereich Neurologie</p>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Wann sollten Neurologen an <span class="no-lb">Morbus Fabry</span> denken?
                  </a>
                </div>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Welche Hinweise liefern bildgebende Verfahren?
                  </a>
                </div>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Typische neurologische Fehldiagnosen
                  </a>
                </div> -->

                <span class="close-symptom-desc"><span class="icon-up ico"></span></span>
            </div>

            <!-- Desc -->
            <div class="symptom-desc" data-desc-box="DescEye" style="display: none">
                <span class="symptom-icon badge green"><span class="icon-eye ico"></span></span>
                <div class="h3 symptom-desc-title">Augen</div>

                <ol class="symptom-desc-list">
                    <li>
                        <div class="symptom-desc-list-title">Cornea verticillata</div>
                        <p>
                            Stellt ein beinahe pathognomonisches Merkmal des 
                            <span class="no-lb">Morbus Fabry</span> dar und kann bei > 50 % der Fabry-Patienten 
                            diagnostiziert werden<sup class="symptom-short-desc-ref">26</sup>
                        </p>

                    </li>
                    <li>
                        <div class="symptom-desc-list-title">Gefäß-Tortuositas</div>
                        <p>
                            Kann in ungefähr der Hälfte der Patienten dargestellt 
                            werden und ist mit Veränderungen der makulären 
                            Mikroperfusion und Gefäßveränderungen assoziiert
                        </p>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">Speichenförmige Cataracta subcapsularis posterior </div>
                    </li>
                    
                    
                    <div class="symptom-bar-separator symptom-bar-separator-top"></div>
                    
                    <div class="symptom-badge-wrapper">

                        <div class="symptom-block-left">
                            <img src="/assets/img/template/alert-triangle.png">
                        </div>

                        <div class="symptom-block-right">
                            Das Vorliegen einer Cornea verticillata und/oder einer retinalen Gefäß-Tortuositas korreliert hochgradig mit dem Schweregrad der systemischen Erkrankung.<sup class="symptom-short-desc-ref">27,28</sup> 
                        </div>

                        <!-- <span class="symptom-icon pink-light"> -->
                        <!-- <span class="symptom-icon-info">
                          <div>
                            Das Vorliegen einer Cornea verticillata und/oder einer retinalen Gefäß-Tortuositas korreliert hochgradig mit dem Schweregrad der systemischen Erkrankung.<sup class="symptom-short-desc-ref">27,28</sup> 
                          </div>
                        </span> -->
                        <!-- </span> -->
                    </div>
                    
                    <div class="symptom-bar-separator symptom-bar-separator-bottom"></div>
                    
                </ol>


                <!-- <p class="symptom-desc-subtitle">Mehr Informationen finden Sie in den Fachbereichen</p>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Mögliche Fehldiagnosen
                  </a>
                </div>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link"> 
                    Ophthalmologische Auffälligkeiten
                  </a>
                </div> -->

                <span class="close-symptom-desc"><span class="icon-up ico"></span></span>
            </div>

            <!-- Desc -->
            <div class="symptom-desc" data-desc-box="DescEar" style="display: none">
                <span class="symptom-icon badge apple"><span class="icon-ear ico"></span></span>
                <div class="h3 symptom-desc-title">Ohren</div>

                <ol class="symptom-desc-list">
                    <li>
                        <div class="symptom-desc-list-title">Hörstörung</div>
                        <p>
                            Sensorineurale Hörminderung im 
                            Hochfrequenzbereich (Innenohrhörstörung): 
                            bei bis zu 74 % der Patienten<sup class="symptom-short-desc-ref">8–10</sup>
                        </p>
                        <!-- Schalleitungsschwerhörigkeit -->
                        <p>
                            Schallleitungsgeschwindigkeit
                        </p>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">Vertigo</div>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">Tinnitus</div>
                        <p>
                            Tritt bei bis zu 43,9 % der Patienten auf <sup class="symptom-short-desc-ref">8,9</sup>
                        </p>
                        <!-- <li>
                          Sensorineurale Hörminderung im 
                          Hochfrequenzbereich (Innenohrhörstörung): 
                          bei bis zu 74 % der Patienten<sup class="symptom-short-desc-ref">8–10</sup>
                        </li>
                        <li>
                          Schalleitungsschwerhörigkeit
                        </li> -->
                    </li>
                    
                    <div class="symptom-bar-separator symptom-bar-separator-top"></div>
                    
                    <div class="symptom-badge-wrapper">

                        <div class="symptom-block-left">
                            <img src="/assets/img/template/alert-triangle.png">
                        </div>

                        <div class="symptom-block-right">
                            Häufig treten Symptome kombiniert auf.<sup class="symptom-short-desc-ref">8,9</sup> 
                            <br>
                            <br>
                            Häufig Episoden mit plötzlichem Hörverlust.<sup class="symptom-short-desc-ref">8,10</sup> 
                        </div>

                    </div>
                    
                    <div class="symptom-bar-separator symptom-bar-separator-bottom"></div>
                    
                </ol>


                <!-- <p class="symptom-desc-subtitle">Mehr Informationen finden Sie bei den Fachbereichen</p>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Diagnostische Hinweise
                  </a>
                </div>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Häufigkeit der beiden Hörstörungen bei Patienten mit <span class="no-lb">Morbus Fabry</span>
                  </a>
                </div> -->

                <span class="close-symptom-desc"><span class="icon-up ico"></span></span>
            </div>

            <!-- Desc -->
            <div class="symptom-desc" data-desc-box="DescLung" style="display: none;">
                <span class="symptom-icon badge ocean"><span class="icon-lung ico"></span></span>
                <div class="h3 symptom-desc-title">Wie fühlen sich Symptome der Lunge an?</div>
                <p class="symptom-desc-text">Atemnot kann auf eine Schädigung der Lunge hinweisen, kann aber auch auf Herzprobleme hindeuten. Sie tritt zumeist bei und nach körperlicher Anstrengung auf. Manchmal sind dabei auch pfeifende Atemgeräusche zu hören.</p>
                <span class="close-symptom-desc"><span class="icon-up ico"></span></span>
            </div>

            <!-- Desc -->
            <div class="symptom-desc" data-desc-box="DescHeart" style="display: none">
                <span class="symptom-icon badge red"><span class="icon-heart ico"></span></span>
                <div class="h3 symptom-desc-title">Herz</div>

                <ol class="symptom-desc-list">
                    <li>
                        <div class="symptom-desc-list-title">
                            Linksventrikuläre Hypertrophie (vorwiegend konzentrisch)<sup class="symptom-short-desc-ref">11</sup>
                        </div>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">
                            Prominenter Papillarmuskel
                        </div>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">
                            Myokardiale Fibrose
                        </div>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">Arrhythmien</div>
                    </li>
                    
                    <div class="symptom-bar-separator symptom-bar-separator-top"></div>
                    
                    <div class="symptom-badge-wrapper">

                        <div class="symptom-block-left">
                            <img src="/assets/img/template/alert-triangle.png">
                        </div>

                        <div class="symptom-block-right">
                            Jeder zweite Fabry-Patient stirbt 
                            an kardiovaskulären Komplikationen. 
                            Damit sind Herzerkrankungen die häufigste 
                            Todesursache bei <span class="no-lb">Morbus Fabry</span>.<sup class="symptom-short-desc-ref">13</sup> 
                            <br>
                            <br>
                            Bei ca. 60 % der Patienten liegt 
                            eine Form der kardialen Beteiligung vor.<sup class="symptom-short-desc-ref">14</sup> 
                        </div>

                    </div>
                    
                    <div class="symptom-bar-separator symptom-bar-separator-bottom"></div>
                    
                </ol>


                <!-- <p class="symptom-desc-subtitle">Mehr Informationen finden Sie im Fachbereich Kardiologie</p>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Diagnostische Fragen und Hinweise
                  </a>
                </div>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Welche Hinweise liefern die bildgebenden Verfahren?
                  </a>
                </div> -->

                <span class="close-symptom-desc"><span class="icon-up ico"></span></span>
            </div>

            <!-- Desc -->
            <div class="symptom-desc" data-desc-box="DescKidney" style="display: none">
                <span class="symptom-icon badge orange"><span class="icon-kidney ico"></span></span>
                <div class="h3 symptom-desc-title">Nieren</div>

                <ol class="symptom-desc-list">
                    <li>
                        <div class="symptom-desc-list-title">Proteinurie</div>
                        <p>
                            Die Proteinurie ist ein unabhängiger 
                            Risikofaktor für eine renale Progression<sup class="symptom-short-desc-ref">29</sup>
                        </p>
                        <p>
                            Proteinurie kann auch erst im 
                            Laufe der Erkrankung auftreten, selbst 
                            wenn die Niere bereits Gb3-Ablagerungen hat
                        </p>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">Progrediente Niereninsuffizienz</div>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">Parapelvine Zysten</div>
                        <p>
                            Parapelvine Zysten treten in 30,<span class="no-lb">8–50</span> % des 
                            <span class="no-lb">Morbus Fabry</span> mit Nierenbeteiligung auf. 
                            Gerade bei Patienten mit unklarer Familienanamnese 
                            liefern parapelvine Zysten einen diagnostischen 
                            Hinweis auf <span class="no-lb">Morbus Fabry</span><sup class="symptom-short-desc-ref">30,31</sup>
                        </p>
                    </li>
                    
                    <div class="symptom-bar-separator symptom-bar-separator-top"></div>
                    
                    <div class="symptom-badge-wrapper">

                        <div class="symptom-block-left">
                            <img src="/assets/img/template/alert-triangle.png">
                        </div>

                        <div class="symptom-block-right">
                            Bei ca. 40 % der weiblichen und ca. 60 % der männlichen 
                            Fabry-Patienten liegt eine Nierenbeteiligung vor.<sup class="symptom-short-desc-ref">32</sup> 
                            Screeninguntersuchungen zeigten eine Prävalenz des 
                            <span class="no-lb">Morbus Fabry</span> unter Patienten mit terminaler 
                            Niereninsuffizienz von 0,2–1,2 %<sup class="symptom-short-desc-ref">33–36</sup> 
                            und bei männlichen Nierentransplantierten von <span class="no-lb">0,36–0,37 %</span>.<sup class="symptom-short-desc-ref">37</sup> 
                        </div>

                    </div>
                    
                    <div class="symptom-bar-separator symptom-bar-separator-bottom"></div>

                </ol>

                <!-- <p class="symptom-desc-subtitle">Mehr Informationen finden Sie im Fachbereich Nephrologie</p>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Wann sollten Nephrologen an <span class="no-lb">Morbus Fabry</span> denken?
                  </a>
                </div>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Welche Hinweise liefert die Biopsie?
                  </a>
                </div> -->

                <span class="close-symptom-desc"><span class="icon-up ico"></span></span>
            </div>

            <!-- Desc -->
            <div class="symptom-desc" data-desc-box="DescBowel" style="display: none">
                <span class="symptom-icon badge purple"><span class="icon-bowel ico"></span></span>
                <div class="h3 symptom-desc-title">
                    Verdauungstrakt<br>
                    <div class="symptom-desc-subtitle">
                        Symptome treten häufig kurz nach der Mahlzeit auf <sup class="symptom-short-desc-ref">15</sup> 
                    </div>
                </div>

                <ol class="symptom-desc-list">
                    <li>
                        <div class="symptom-desc-list-title">
                            Abdominale Krämpfe
                        </div>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">
                            Koliken
                        </div>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">
                            Diarrhö
                        </div>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">
                            Obstipation
                        </div>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">
                            Völlegefühl
                        </div>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">
                            Übelkeit
                        </div>
                    </li>
                    <!--          <li>
                                <div class="symptom-desc-list-title">
                                  Erbrechen häufig kurz nach der Mahlzeit<sup class="symptom-short-desc-ref">15 </sup></div>
                              </li>-->
                    <li>
                        <div class="symptom-desc-list-title">
                            Häufig Gewichtsverlust
                        </div>
                    </li>
                    
                    <div class="symptom-bar-separator symptom-bar-separator-top"></div>
                    
                    <div class="symptom-badge-wrapper">

                        <div class="symptom-block-left">
                            <img src="/assets/img/template/alert-triangle.png">
                        </div>

                        <div class="symptom-block-right">
                            Gastroenterologische Symptome treten früh im Krankheitsverlauf auf.
                            Medianes Alter: <span class="no-lb">5 Jahre</span> bei Jungen und <span class="no-lb">9,5 Jahre</span> bei Mädchen.<sup class="symptom-short-desc-ref">16</sup> 
                            <br>
                            <br>
                            Die Prävalenz liegt bei 18-60 %.<sup class="symptom-short-desc-ref">15–18</sup> 
                        </div>

                    </div>
                    
                    <div class="symptom-bar-separator symptom-bar-separator-bottom"></div>

                </ol>


                <!-- <p class="symptom-desc-subtitle">Mehr Informationen finden Sie bei den Fachbereichen</p>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Diagnostische Fragen
                  </a>
                </div>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Mögliche Ursachen
                  </a>
                </div> -->

                <span class="close-symptom-desc"><span class="icon-up ico"></span></span>
            </div>
            <!-- Desc -->
            <div class="symptom-desc" data-desc-box="DescSkin" style="display: none">
                <span class="symptom-icon badge pink"><span class="icon-skin ico"></span></span>
                <div class="h3 symptom-desc-title">Haut</div>

                <ol class="symptom-desc-list">
                    <li>
                        <div class="symptom-desc-list-title">Angiokeratome<sup class="symptom-short-desc-ref">38</sup></div>
                        <p>
                            Gutartige Hautläsionen (rötliche Papeln)
                        </p>
                        <p>
                            Einzeln oder gruppiert
                        </p>
                        <p>
                            Im Alter zunehmend
                        </p>
                        <p>
                            Prädilektionsstellen: periumbilikal, skrotal, Flanken, „Bikinizone“, Mukosa 
                        </p>
                        <p>
                            83 % der männlichen und 80 % der weiblichen Fabry-Patienten betroffen<sup class="symptom-short-desc-ref">39</sup>
                        </p>
                    </li>
                    <li>
                        <div class="symptom-desc-list-title">Lymphödeme</div>
                    </li>
                </ol>


                <!-- <p class="symptom-desc-subtitle">Mehr Informationen finden Sie in den Fachbereichen</p>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Typische Veränderungen der Haut bei <span class="no-lb">Morbus Fabry</span>
                  </a>
                </div>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Ophthalmologische Auffälligkeiten
                  </a>
                </div> -->

                <span class="close-symptom-desc"><span class="icon-up ico"></span></span>
            </div>

            <!-- Desc -->
            <div class="symptom-desc" data-desc-box="DescVeins" style="display: none;">
                <span class="symptom-icon badge red"><span class="icon-veins ico"></span></span>
                <div class="h3 symptom-desc-title">Auf welche Weise können die Blutgefäße betroffen sein?</div>
                <p class="symptom-desc-text">Durch Ablagerungen in den Blutgefäßen wird der Blutkreislauf behindert. Infolgedessen werden vor allem Finger und Zehen nicht optimal durchblutet. Sie fühlen sich dann kalt an und sind weiß oder bläulich.</p>
                <span class="close-symptom-desc"><span class="icon-up ico"></span></span>
            </div>

            <!-- Desc -->
            <div class="symptom-desc" data-desc-box="DescNerves" style="display: none">
                <span class="symptom-icon badge blue"><span class="icon-nerves ico"></span></span>
                <div class="h3 symptom-desc-title">Peripheres Nervensystem</div>

                <ol class="symptom-desc-list">
                    <li>
                        <div class="symptom-desc-list-title">
                            Neuropathische Schmerzen<br>
                        </div>
                        <p>
                            > 80 % der männlichen und 
                            > 65 % der weiblichen Fabry-Patienten sind betroffen<sup class="symptom-short-desc-ref">19</sup>
                        </p>
                        <p>
                            Typisch sind initial brennende Schmerzen, vor allem in Händen und Füßen
                        </p>
                        <p>
                            Es kann jede Körperregion betroffen sein<sup class="symptom-short-desc-ref">20</sup>
                        </p>
                        <p>
                            Mit fortschreitender Erkrankung können Schmerzattacken seltener und weniger gravierend werden<sup class="symptom-short-desc-ref">21</sup>
                        </p>
                    </li>

                    <div class="symptom-bar-separator symptom-bar-separator-top"></div>

                    <div class="symptom-badge-wrapper">

                        <div class="symptom-block-left">
                            <img src="/assets/img/template/alert-triangle.png">
                        </div>

                        <div class="symptom-block-right">
                            Neuropathische Schmerzen sind ein sehr frühes Symptom. Schon Kinder sind betroffen. Erstmanifestation im Mittel: <span class="no-lb">9 Jahre</span> bei Jungen und <span class="no-lb">16 Jahre</span> bei Mädchen.<sup class="symptom-short-desc-ref">21</sup> 
                        </div>

                    </div>

                    <div class="symptom-bar-separator symptom-bar-separator-bottom"></div>

                    <li>
                        <div class="symptom-desc-list-title">
                            Autonome Störungen
                        </div>
                        <p>
                            Symptome durch Beteiligung des autonomen Nervensystems<sup class="symptom-short-desc-ref">22</sup>
                        </p>
                        <p>
                            Hypo-/Anhidrose mit reduzierter Wärmetoleranz
                        </p>
                        <p>
                            Gastrointestinale Motilitätsstörungen 
                        </p>
                        <p>
                            Gestörte Herzfrequenzvariabilität 
                        </p>
                        <p>
                            Verminderte Tränen-/Speichelproduktion
                        </p>
                    </li>
                    <!--<li>
                      <div class="symptom-desc-list-title">
                        Hypo- und Anhidrose
                      </div>
                      <p>
                        z. T. auch als dermatologische Manifestation aufzufassen 
                        (Gb3-Akkumulation in den Schweißdrüsen)<sup class="symptom-short-desc-ref">23,24</sup>
                      </p>
                    <!--</li>-->

                </ol>

                <!-- <p class="symptom-desc-subtitle">
                  Weitere Informationen finden Sie im Fachbereich Neurologie
                </p>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Wann sollten Neurologen an <span class="no-lb">Morbus Fabry</span> denken?
                  </a>
                </div>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Schmerztypen 
                  </a>
                </div>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Schmerzhäufigkeit 
                  </a>
                </div>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Schmerzlokalisation
                  </a>
                </div> -->

                <span class="close-symptom-desc"><span class="icon-up ico"></span></span>
            </div>

            <!-- Desc -->
            <div class="symptom-desc" data-desc-box="DescNervesFoot" style="display: none">
                <span class="symptom-icon badge blue"><span class="icon-nerves ico"></span></span>
                <div class="h3 symptom-desc-title">Peripheres Nervensystem</div>

                <ol class="symptom-desc-list">
                    <li>
                        <div class="symptom-desc-list-title">
                            Neuropathische Schmerzen
                        </div>
                        <p>
                            > 80 % der männlichen und 
                            > 65 % der weiblichen <span class="no-lb">Morbus Fabry</span> Patienten sind betroffen<sup class="symptom-short-desc-ref">19</sup>
                        </p>
                        <p>
                            Typisch sind initial brennende Schmerzen, vor allem in Händen und Füßen
                        </p>
                        <p>
                            Es kann jede Körperregion betroffen sein<sup class="symptom-short-desc-ref">20</sup>
                        </p>
                        <p>
                            Mit fortschreitender Erkrankung können Schmerzattacken seltener und weniger gravierend werden<sup class="symptom-short-desc-ref">21</sup>
                        </p>
                    </li>

                    <div class="symptom-bar-separator symptom-bar-separator-top"></div>

                    <div class="symptom-badge-wrapper">

                        <div class="symptom-block-left">
                            <img src="/assets/img/template/alert-triangle.png">
                        </div>

                        <div class="symptom-block-right">
                            Neuropathische Schmerzen sind ein sehr frühes Symptom. Schon Kinder sind betroffen. Erstmanifestation im Mittel: <span class="no-lb">9 Jahre</span> bei Jungen und <span class="no-lb">16 Jahre</span> bei Mädchen.<sup class="symptom-short-desc-ref">21</sup> 
                        </div>

                    </div>

                    <div class="symptom-bar-separator symptom-bar-separator-bottom"></div>

                    <li>
                        <div class="symptom-desc-list-title">
                            Autonome Störungen
                        </div>
                        <p>
                            Symptome durch Beteiligung des autonomen Nervensystems:<sup class="symptom-short-desc-ref">22</sup>
                        </p>
                        <p>
                            Hypo-/Anhidrose mit reduzierter Wärmetoleranz
                        </p>
                        <p>
                            Gastrointestinale Motilitätsstörungen 
                        </p>
                        <p>
                            Gestörte Herzfrequenzvariabilität 
                        </p>
                        <p>
                            Verminderte Tränen-/Speichelproduktion
                        </p>
                    </li>
                    <!--<li>
                      <div class="symptom-desc-list-title">
                        Hypo- Und Anhidrose
                      </div>
                      <br>
                      z. T. auch als dermatologische Manifestation aufzufassen 
                      (Gb3-Akkumulation in den Schweißdrüsen)<sup class="symptom-short-desc-ref">23,24</sup>
                    </li>-->

                </ol>


                <!-- <p class="symptom-desc-subtitle">
                  Weitere Informationen zu neuropathischen Schmerzen bei <span class="no-lb">Morbus Fabry</span> finden Sie im Fachbereich Neurologie</p>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Wann sollten Neurologen an <span class="no-lb">Morbus Fabry</span> denken?
                  </a>
                </div>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Schmerztypen 
                  </a>
                </div>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Schmerztypen 
                  </a>
                </div>
        
                <div class="symptom-desc-link-wrapper">
                  <span class="link-arrow-right icon-arrow-right ico white"></span>
                  <a href="#" class="symptom-desc-link">
                    Schmerzlokalisation
                  </a>
                </div> -->

                <span class="close-symptom-desc"><span class="icon-up ico"></span></span>
            </div>

            <!-- Desc -->
            <div class="symptom-desc" data-desc-box="DescMove" style="display: none">
                <span class="symptom-icon badge green"><span class="icon-foot ico"></span></span>
                <div class="h3 symptom-desc-title">Welche Symptome zeigen sich am Bewegungsapparat?</div>
                <p class="symptom-desc-text">Wichtigstes Symptom des Bewegungsapparates sind Schmerzen in den Extremitäten. Ferner können Patienten eine Osteoporose entwickeln, besonders wenn sie häufig Kortisonpräparate einnehmen mussten.</p>
                <span class="close-symptom-desc"><span class="icon-up ico"></span></span>
            </div>
        </div>
        <!-- </div>
      </div> -->

        <!-- <div class="info-section-graphics">
    
          <div class="info-section-graphic">
            <img src="/assets/img/template/icon_7_temp.png" />
          </div>
          <div class="info-section-graphic">
            <img src="/assets/img/template/icon_6_temp.png" />
          </div>
    
        </div> -->

        <div class="info-section-title info-section-title-2">
            <h2>
                Pathophysiologie und Genetik
            </h2>
        </div>

        <div class="info-section-text">
            Bei <span class="no-lb">Morbus Fabry</span> führt ein Defekt im <span class="info-section-text-i">GLA</span>-Gen zu einer verminderten 
            Aktivität des 
            lysosomalen Enzyms <span class="no-lb">α-Galaktosidase-A</span> (α-Gal). Aufgrund des Mangels oder des Fehlens von 
            <span class="no-lb">α-Galaktosidase-A</span> kommt es zur Akkumulation von Globotriaosylceramid (Gb3) und 
            Globotriaosylsphingosin (Lyso-Gb3) im Gewebe verschiedener Organe und zum konsekutiven 
            progredienten Funktionsverlust der Zellen.<sup class="info-section-text-ref">40</sup>
        </div>

        <div class="info-section-chart">

            <!-- <img src="/assets/img/template/path-genetik-flow.png" /> -->


            <div class="info-section-chart-box">
                α-Gal-Mangel
            </div>
            <div class="info-section-chart-box-separator">
                <img src="/assets/img/template/icon_4_temp.png" />
            </div>
            <div class="info-section-chart-box">
                Akkumulation von Gb3/Lyso-Gb3
            </div>
            <div class="info-section-chart-box-separator">
                <img src="/assets/img/template/icon_4_temp.png" />
            </div>
            <div class="info-section-chart-box">
                Progrediente Organschädigung
            </div>

        </div>

    </div>

    <div class="info-section-ribbon info-section-4">

        <div class="info-section-ribbon-left">
        </div>

        <div class="info-section-ribbon-right">
        </div>

        <div class="info-section-title">
            <h2>
                <span class="no-lb">Morbus Fabry</span> ist behandelbar!
            </h2>
        </div>

        <div class="info-section-text">
            Eine kausale Therapie für Morbus Fabry, die das fehlende Enzym ersetzt, ist seit 2001 verfügbar. Seit 2016 gibt es darüber hinaus eine Chaperon-Therapie, diese ist allerdings mutationsspezifisch, sie kommt daher nicht für alle Patienten in Frage.
        </div>

        <!--<div class="info-section-text">
          Seit 2016 gibt es die mutationsspezifische Chaperon-Therapie mit Migalastat.
        </div>
    
        <div class="info-section-text info-section-text-last">
          Bereits seit 2001 existiert die kausale Therapie durch Enzymersatz mit Agalsidase beta bzw. Agalsidase alfa.
        </div>-->

        <div class="info-section-block">
            <!-- <div class="section-link-icon">
              <img src="/assets/img/template/icon_3_temp.png" />
            </div> -->
            <!-- <span class="link-arrow-right icon-arrow-right ico white"></span>
            <a href="#" class="section-link">
              Zur aktuellen interdisziplinären Leitlinie
            </a> -->

            <!--<a href="https://dgn.org/leitlinien/3153-interdisziplinaere-leitlinie-fuer-die-diagnose-und-therapie-des-morbus-fabry" 
              target="_blank"
              class="section-link">
              <span class="link-arrow-right icon-arrow-right ico white"></span>
              <span class="section-link-text">
                Zur interdisziplinären Leitlinie
              </span>
            </a>-->
        </div>

    </div>

    <div class="info-section info-section-5">

        <div class="info-section-title">
            <h2>
                Genetische Grundlagen von <span class="no-lb">Morbus Fabry</span>
            </h2>
        </div>

        <div class="info-section-text extra">
            Das α-Gal-Gen (<span class="info-section-text-i">GLA</span>) befindet sich auf dem <span class="no-lb">X-Chromosom</span>. Daher wird <br><span class="no-lb">Morbus Fabry</span> geschlechtsspezifisch vererbt.<sup class="info-section-text-ref"><span class="no-lb" style="font-size: 10px">41–45</span></sup>
        </div>

        <div class="info-section-imgs">

            <img src="/assets/img/template/betroffene-ansicht-1_2x.webp" alt="Stammbaum der Vererbung bei Mann mit Morbus Fabry" title="Vererbung erkrankter Vater" />

            <img class="info-section-imgs-man" src="/assets/img/template/betroffene-ansicht-2_2x.webp" alt="Stammbaum der Vererbung bei Frau mit Morbus Fabry" title="Vererbung erkrankter Mutter" />

        </div>

    </div>

    <div class="info-section-ribbon info-section-6">

        <div class="info-section-ribbon-left">
        </div>

        <div class="info-section-ribbon-right">
        </div>

        <div class="info-section-title">
            <h2>
                Genetische Beratung
            </h2>
        </div>

        <div class="info-section-text">
            Nach der Diagnosestellung des <span class="no-lb">Morbus Fabry</span> sollte Patienten eine genetische 
            Beratung angeboten werden. Dabei werden genetische Zusammenhänge erklärt, 
            damit Betroffene Informationen und Wahrscheinlichkeiten besser verstehen und 
            ihre persönliche Situation besser einschätzen können.
        </div>

    </div>

    <div class="info-section info-section-7">

        <div class="info-section-title">
            <h2>
                Progredienz der Speicherkrankheit
            </h2>
        </div>

        <div class="info-section-subtitle">
            <span class="no-lb">Morbus Fabry</span> zeigt zumeist einen progredienten Verlauf 
        </div>

        <div class="info-section-graphics-1">

            <div class="info-section-graphic info-section-graphic-first">
                <div class="info-section-graphic-icon">
                    <!-- 1 -->
                    <img src="/assets/img/template/Piktogramme_Kind_Badge.svg" />
                </div>
                <!-- <br /> -->
                Erste Symptome können bereits im<br>
                Kindesalter auftreten<sup class="info-section-text-ref">46</sup>
            </div>
            <div class="info-section-graphic">
                <div class="info-section-graphic-icon">
                    <!-- 2 -->
                    <!-- <img src="/assets/img/template/Piktogramme_Kind_Badge.svg" /> -->
                    <span class="symptom-icon badge purple-light"><span class="ico-symptoms-purple ico-custom"></span></span>
                </div>
                <!-- <br /> -->
                Im klinischen Verlauf entwickelt sich meist<br>
                ein Spektrum unterschiedlicher, häufig<br>
                auch unspezifischer Symptome<br>
                verschiedener Organsysteme<sup class="info-section-text-ref">47</sup>
            </div>

        </div>

        <div class="info-section-graphics-2">
            <div class="info-section-graphic-chart-wrapper zoom-in-container" (click)="openDialog('morbus-fabry-chart-1')">
                <!-- 3 -->
                <!-- <img src="/assets/img/template/chart_1_temp.png" /> -->
                <!-- <?xml version="1.0" encoding="utf-8"?> -->
                <!-- Generator: Adobe Illustrator 24.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                     viewBox="0 0 903 368" style="enable-background:new 0 0 903 368;" xml:space="preserve" alt="Zunehmende Symptomlast über die Zeit" title="Krankheitslast im Verlauf der Erkrankung">
                <style type="text/css">
                    .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#E3E1D9;}
                    .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#F5C4DF;}
                    .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#D8137D;}
                    .st3{fill:none;stroke:#E3E1D9;stroke-width:4;}
                    .st4{enable-background:new    ;}
                    .st5{fill:#FFFFFF;}
                    .st6{fill:none;stroke:#E3E1D9;stroke-width:5;}
                </style>
                <g>
                <g>
                <g>
                <g>
                <path class="st0" d="M874,286V5L518,286H874z"/>
                <path class="st1" d="M874,286V126L285,286H874z"/>
                <path class="st2" d="M184,286h690v-68L184,286z"/>
                <path class="st3" d="M895,286H38V5"/>
                </g>
                <g class="st4">
                <path d="M461.6,319h-7.9v-1l6.1-9.2h-5.9v-1.2h7.5v1l-6.1,9.2h6.2L461.6,319L461.6,319z"/>
                <path d="M467.2,319.2c-1.3,0-2.3-0.4-3-1.2s-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2s1.9,0.4,2.5,1.1
                      s0.9,1.6,0.9,2.8v0.8h-5.9c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                      S467.8,319.2,467.2,319.2z M466.9,311.4c-0.7,0-1.2,0.2-1.6,0.7s-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                      S467.5,311.4,466.9,311.4z"/>
                <path d="M472.5,308.1c0-0.3,0.1-0.5,0.2-0.7s0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2s0.2,0.4,0.2,0.6s-0.1,0.5-0.2,0.7
                      s-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2S472.5,308.4,472.5,308.1z M473.9,319h-1.3v-8.6h1.3V319z"/>
                <path d="M479.4,318.1c0.2,0,0.5,0,0.7-0.1s0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2s-0.5,0.1-0.7,0.1c-1.7,0-2.5-0.9-2.5-2.6
                      v-5.1h-1.2v-0.6l1.2-0.5l0.5-1.8h0.8v2h2.5v1H478v5c0,0.5,0.1,0.9,0.4,1.2S479,318.1,479.4,318.1z"/>
                </g>
                <g class="st4">
                <path d="M798.3,94c0,1.7-0.4,2.9-1.2,3.8s-2,1.3-3.5,1.3s-2.7-0.4-3.5-1.3s-1.2-2.2-1.2-3.8s0.4-2.9,1.2-3.8s2-1.3,3.5-1.3
                      s2.7,0.4,3.5,1.3C797.9,91.1,798.3,92.3,798.3,94z M791,94c0,1.1,0.2,2,0.6,2.5s1.1,0.8,1.9,0.8c1.7,0,2.5-1.1,2.5-3.4
                      s-0.8-3.4-2.5-3.4c-0.8,0-1.5,0.3-1.9,0.9C791.2,92,791,92.9,791,94z"/>
                <path d="M804.5,91.2c0.3,0,0.5,0,0.7,0.1l-0.2,2c-0.2,0-0.4-0.1-0.6-0.1c-0.7,0-1.2,0.2-1.6,0.5c-0.4,0.3-0.6,0.8-0.6,1.4V99
                      h-2.1v-7.6h1.6l0.3,1.3h0.1c0.2-0.4,0.6-0.8,1-1C803.6,91.3,804,91.2,804.5,91.2z"/>
                <path d="M813.2,91.4v1.1l-1.2,0.3c0.2,0.3,0.3,0.7,0.3,1.1c0,0.8-0.3,1.5-0.9,1.9c-0.6,0.5-1.4,0.7-2.4,0.7h-0.4h-0.3
                      c-0.2,0.2-0.3,0.3-0.3,0.5c0,0.3,0.4,0.5,1.1,0.5h1.3c0.8,0,1.5,0.2,1.9,0.5c0.4,0.4,0.7,0.9,0.7,1.6c0,0.9-0.4,1.6-1.1,2.1
                      c-0.7,0.5-1.8,0.7-3.2,0.7c-1.1,0-1.9-0.2-2.4-0.6c-0.6-0.4-0.8-0.9-0.8-1.6c0-0.5,0.1-0.8,0.4-1.2s0.7-0.5,1.3-0.7
                      c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.3,0.1-0.5,0.3-0.7s0.4-0.4,0.7-0.6c-0.4-0.2-0.7-0.5-1-0.8
                      c-0.2-0.4-0.4-0.8-0.4-1.4c0-0.8,0.3-1.5,0.8-1.9s1.3-0.7,2.3-0.7c0.2,0,0.5,0,0.8,0.1c0.3,0,0.5,0.1,0.6,0.1L813.2,91.4
                      L813.2,91.4z M807.3,100.1c0,0.3,0.1,0.5,0.4,0.7c0.3,0.2,0.7,0.2,1.2,0.2c0.7,0,1.3-0.1,1.8-0.3s0.6-0.5,0.6-0.8
                      s-0.1-0.5-0.4-0.6s-0.6-0.2-1.2-0.2h-1.1c-0.4,0-0.7,0.1-1,0.3C807.5,99.5,807.3,99.8,807.3,100.1z M808.1,93.9
                      c0,0.4,0.1,0.7,0.3,1c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.4s0.3-0.6,0.3-1c0-0.9-0.4-1.4-1.1-1.4
                      C808.5,92.5,808.1,92.9,808.1,93.9z"/>
                <path d="M819.4,99l-0.4-1h-0.1c-0.4,0.4-0.7,0.7-1.1,0.9c-0.4,0.2-0.9,0.3-1.5,0.3c-0.7,0-1.3-0.2-1.7-0.6s-0.6-1-0.6-1.8
                      s0.3-1.4,0.9-1.8c0.6-0.4,1.4-0.6,2.6-0.6h1.3v-0.3c0-0.8-0.4-1.2-1.2-1.2c-0.6,0-1.3,0.2-2.2,0.6l-0.7-1.4
                      c0.9-0.5,1.9-0.7,2.9-0.7s1.8,0.2,2.4,0.7s0.8,1.1,0.8,2V99H819.4z M818.7,95.5h-0.8c-0.6,0-1.1,0.1-1.4,0.3s-0.4,0.5-0.4,0.9
                      c0,0.6,0.3,0.9,1,0.9c0.5,0,0.9-0.1,1.2-0.4s0.4-0.6,0.4-1.1V95.5z"/>
                <path d="M830,99h-2.1v-4.5c0-0.6-0.1-1-0.3-1.2c-0.2-0.3-0.5-0.4-0.9-0.4c-0.6,0-1,0.2-1.3,0.6s-0.4,1-0.4,1.9V99h-2v-7.6h1.6
                      l0.3,1h0.1c0.2-0.4,0.6-0.6,1-0.8c0.4-0.2,0.9-0.3,1.4-0.3c0.9,0,1.6,0.2,2,0.7c0.5,0.5,0.7,1.2,0.7,2.1L830,99L830,99z"/>
                <path d="M831.5,96.1v-1.7h3.7v1.7H831.5z"/>
                </g>
                <g class="st4">
                <path d="M790.9,118l-2.9-7.6h2.2l1.5,4.4c0.2,0.6,0.3,1.1,0.3,1.6l0,0c0-0.4,0.1-1,0.3-1.6l1.5-4.4h2.2l-2.9,7.6H790.9z"/>
                <path d="M800.5,118.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9s0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1c1.1,0,1.9,0.3,2.5,0.9
                      s0.9,1.5,0.9,2.5v1h-4.9c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                      c-0.4,0.2-0.8,0.3-1.2,0.4C801.6,118.1,801.1,118.1,800.5,118.1z M800.2,111.7c-0.4,0-0.8,0.1-1,0.4s-0.4,0.7-0.4,1.2h2.9
                      c0-0.5-0.1-0.9-0.4-1.2C801,111.8,800.7,111.7,800.2,111.7z"/>
                <path d="M809.6,110.2c0.3,0,0.5,0,0.7,0.1l-0.2,2c-0.2,0-0.4-0.1-0.6-0.1c-0.7,0-1.2,0.2-1.6,0.5c-0.4,0.3-0.6,0.8-0.6,1.4v3.9
                      h-2.1v-7.6h1.6l0.3,1.3h0.1c0.2-0.4,0.6-0.8,1-1C808.7,110.3,809.1,110.2,809.6,110.2z"/>
                <path d="M817,115.7c0,0.8-0.3,1.4-0.8,1.8s-1.4,0.6-2.4,0.6c-0.6,0-1,0-1.4-0.1s-0.8-0.2-1.1-0.3V116c0.4,0.2,0.8,0.3,1.3,0.5
                      c0.5,0.1,0.9,0.2,1.3,0.2c0.8,0,1.1-0.2,1.1-0.7c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.5-0.3c-0.2-0.1-0.6-0.3-1-0.5
                      c-0.6-0.2-1-0.5-1.3-0.7s-0.5-0.5-0.6-0.7c-0.1-0.3-0.2-0.6-0.2-1c0-0.7,0.3-1.2,0.8-1.6s1.3-0.6,2.2-0.6s1.8,0.2,2.7,0.6
                      l-0.6,1.5c-0.4-0.2-0.7-0.3-1.1-0.4s-0.7-0.2-1-0.2c-0.6,0-0.9,0.2-0.9,0.5c0,0.2,0.1,0.3,0.3,0.5c0.2,0.1,0.6,0.3,1.3,0.6
                      c0.6,0.2,1,0.5,1.3,0.7s0.5,0.5,0.6,0.7S817,115.4,817,115.7z"/>
                <path d="M823.5,118l-0.4-1H823c-0.4,0.4-0.7,0.7-1.1,0.9c-0.4,0.2-0.9,0.3-1.5,0.3c-0.7,0-1.3-0.2-1.7-0.6s-0.6-1-0.6-1.8
                      s0.3-1.4,0.9-1.8c0.6-0.4,1.4-0.6,2.6-0.6h1.3v-0.3c0-0.8-0.4-1.2-1.2-1.2c-0.6,0-1.3,0.2-2.2,0.6l-0.7-1.4
                      c0.9-0.5,1.9-0.7,2.9-0.7s1.8,0.2,2.4,0.7s0.8,1.1,0.8,2v5.1h-1.4V118z M822.9,114.5h-0.8c-0.6,0-1.1,0.1-1.4,0.3
                      s-0.4,0.5-0.4,0.9c0,0.6,0.3,0.9,1,0.9c0.5,0,0.9-0.1,1.2-0.4s0.4-0.6,0.4-1.1V114.5z"/>
                <path d="M833.8,110.4v1.1l-1.2,0.3c0.2,0.3,0.3,0.7,0.3,1.1c0,0.8-0.3,1.5-0.9,1.9c-0.6,0.5-1.4,0.7-2.4,0.7h-0.4h-0.3
                      c-0.2,0.2-0.3,0.3-0.3,0.5c0,0.3,0.4,0.5,1.1,0.5h1.3c0.8,0,1.5,0.2,1.9,0.5c0.4,0.4,0.7,0.9,0.7,1.6c0,0.9-0.4,1.6-1.1,2.1
                      c-0.7,0.5-1.8,0.7-3.2,0.7c-1.1,0-1.9-0.2-2.4-0.6c-0.6-0.4-0.8-0.9-0.8-1.6c0-0.5,0.1-0.8,0.4-1.2s0.7-0.5,1.3-0.7
                      c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.3,0.1-0.5,0.3-0.7s0.4-0.4,0.7-0.6c-0.4-0.2-0.7-0.5-1-0.8
                      c-0.2-0.4-0.4-0.8-0.4-1.4c0-0.8,0.3-1.5,0.8-1.9s1.3-0.7,2.3-0.7c0.2,0,0.5,0,0.8,0.1c0.3,0,0.5,0.1,0.6,0.1L833.8,110.4
                      L833.8,110.4z M827.9,119.1c0,0.3,0.1,0.5,0.4,0.7c0.3,0.2,0.7,0.2,1.2,0.2c0.7,0,1.3-0.1,1.8-0.3s0.6-0.5,0.6-0.8
                      s-0.1-0.5-0.4-0.6s-0.6-0.2-1.2-0.2h-1.1c-0.4,0-0.7,0.1-1,0.3C828,118.5,827.9,118.8,827.9,119.1z M828.6,112.9
                      c0,0.4,0.1,0.7,0.3,1c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.4s0.3-0.6,0.3-1c0-0.9-0.4-1.4-1.1-1.4
                      C829,111.5,828.6,111.9,828.6,112.9z"/>
                <path d="M838.5,118.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9s0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1c1.1,0,1.9,0.3,2.5,0.9
                      s0.9,1.5,0.9,2.5v1h-4.9c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                      c-0.4,0.2-0.8,0.3-1.2,0.4C839.6,118.1,839.1,118.1,838.5,118.1z M838.2,111.7c-0.4,0-0.8,0.1-1,0.4s-0.4,0.7-0.4,1.2h2.9
                      c0-0.5-0.1-0.9-0.4-1.2C839,111.8,838.6,111.7,838.2,111.7z"/>
                <path d="M850.3,118h-2.1v-4.5c0-0.6-0.1-1-0.3-1.2c-0.2-0.3-0.5-0.4-0.9-0.4c-0.6,0-1,0.2-1.3,0.6s-0.4,1-0.4,1.9v3.6h-2.1v-7.6
                      h1.6l0.3,1h0.1c0.2-0.4,0.6-0.6,1-0.8c0.4-0.2,0.9-0.3,1.4-0.3c0.9,0,1.6,0.2,2,0.7c0.5,0.5,0.7,1.2,0.7,2.1V118z"/>
                </g>
                <g class="st4">
                <path class="st5" d="M735.1,191.4h4v5.2c-0.6,0.2-1.2,0.4-1.8,0.4c-0.6,0.1-1.1,0.1-1.7,0.1c-1.5,0-2.7-0.4-3.5-1.3
                      c-0.8-0.9-1.2-2.2-1.2-3.8s0.5-2.9,1.4-3.8c0.9-0.9,2.2-1.4,3.8-1.4c1,0,2,0.2,3,0.6l-0.7,1.7c-0.7-0.4-1.5-0.5-2.3-0.5
                      c-0.9,0-1.6,0.3-2.2,0.9S733,191,733,192c0,1.1,0.2,1.9,0.7,2.5c0.4,0.6,1.1,0.9,1.9,0.9c0.4,0,0.9,0,1.3-0.1v-2.1H735
                      L735.1,191.4L735.1,191.4z"/>
                <path class="st5" d="M744.7,197.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9c0-1.3,0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1
                      c1.1,0,1.9,0.3,2.5,0.9s0.9,1.5,0.9,2.5v1H743c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                      c-0.4,0.2-0.8,0.3-1.2,0.4C745.8,197.1,745.3,197.1,744.7,197.1z M744.4,190.7c-0.4,0-0.8,0.1-1,0.4c-0.2,0.3-0.4,0.7-0.4,1.2
                      h2.9c0-0.5-0.1-0.9-0.4-1.2C745.2,190.8,744.8,190.7,744.4,190.7z"/>
                <path class="st5" d="M755.8,197l-0.6-2.7l-0.8-3.4l0,0l-1.4,6h-2.2l-2.2-7.6h2.1l0.9,3.4c0.1,0.6,0.3,1.4,0.4,2.5l0,0
                      c0-0.3,0.1-0.9,0.2-1.6l0.1-0.6l0.9-3.7h2.3l0.9,3.7c0,0.1,0,0.2,0.1,0.4c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.1,0.6
                      c0,0.2,0,0.4,0.1,0.5l0,0c0-0.3,0.1-0.8,0.2-1.3c0.1-0.6,0.2-1,0.2-1.2l0.9-3.4h2l-2.2,7.6h-2.1V197z"/>
                <path class="st5" d="M764.9,197.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9c0-1.3,0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1
                      c1.1,0,1.9,0.3,2.5,0.9s0.9,1.5,0.9,2.5v1h-4.9c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                      c-0.4,0.2-0.8,0.3-1.2,0.4C766,197.1,765.5,197.1,764.9,197.1z M764.7,190.7c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.7-0.4,1.2h2.9
                      c0-0.5-0.1-0.9-0.4-1.2C765.4,190.8,765.1,190.7,764.7,190.7z"/>
                <path class="st5" d="M774,189.2c0.9,0,1.6,0.4,2.1,1.1c0.5,0.7,0.8,1.7,0.8,2.9c0,1.3-0.3,2.2-0.8,2.9s-1.2,1-2.2,1
                      c-0.9,0-1.6-0.3-2.1-1h-0.1l-0.3,0.8h-1.6v-10.6h2.1v2.5c0,0.3,0,0.8-0.1,1.5h0.1C772.3,189.6,773.1,189.2,774,189.2z
                      M773.3,190.9c-0.5,0-0.9,0.2-1.1,0.5c-0.2,0.3-0.4,0.8-0.4,1.6v0.2c0,0.8,0.1,1.4,0.4,1.8c0.2,0.4,0.6,0.5,1.2,0.5
                      c0.4,0,0.8-0.2,1-0.6c0.3-0.4,0.4-1,0.4-1.7s-0.1-1.3-0.4-1.7S773.8,190.9,773.3,190.9z"/>
                <path class="st5" d="M782.1,197.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9c0-1.3,0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1
                      c1.1,0,1.9,0.3,2.5,0.9s0.9,1.5,0.9,2.5v1h-4.9c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                      c-0.4,0.2-0.8,0.3-1.2,0.4C783.2,197.1,782.7,197.1,782.1,197.1z M781.8,190.7c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.7-0.4,1.2
                      h2.9c0-0.5-0.1-0.9-0.4-1.2C782.6,190.8,782.2,190.7,781.8,190.7z"/>
                <path class="st5" d="M791.1,189.2c0.9,0,1.6,0.4,2.1,1.1c0.5,0.7,0.8,1.7,0.8,2.9c0,1.3-0.3,2.2-0.8,2.9s-1.2,1-2.2,1
                      c-0.9,0-1.6-0.3-2.1-1h-0.1l-0.3,0.8h-1.6v-10.6h2.1v2.5c0,0.3,0,0.8-0.1,1.5h0.1C789.5,189.6,790.2,189.2,791.1,189.2z
                      M790.5,190.9c-0.5,0-0.9,0.2-1.1,0.5c-0.2,0.3-0.4,0.8-0.4,1.6v0.2c0,0.8,0.1,1.4,0.4,1.8c0.2,0.4,0.6,0.5,1.2,0.5
                      c0.4,0,0.8-0.2,1-0.6c0.3-0.4,0.4-1,0.4-1.7s-0.1-1.3-0.4-1.7S790.9,190.9,790.5,190.9z"/>
                <path class="st5" d="M799.2,197.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9c0-1.3,0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1
                      c1.1,0,1.9,0.3,2.5,0.9s0.9,1.5,0.9,2.5v1h-4.9c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                      c-0.4,0.2-0.8,0.3-1.2,0.4C800.3,197.1,799.8,197.1,799.2,197.1z M798.9,190.7c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.7-0.4,1.2
                      h2.9c0-0.5-0.1-0.9-0.4-1.2C799.7,190.8,799.4,190.7,798.9,190.7z"/>
                <path class="st5" d="M807.2,195.5c0.4,0,0.8-0.1,1.3-0.2v1.6c-0.5,0.2-1.2,0.3-1.9,0.3c-0.8,0-1.4-0.2-1.8-0.6
                      c-0.4-0.4-0.6-1.1-0.6-1.9V191h-1v-1l1.1-0.7l0.6-1.6h1.3v1.6h2.1v1.6h-2.1v3.7c0,0.3,0.1,0.5,0.2,0.7
                      C806.7,195.4,807,195.5,807.2,195.5z"/>
                <path class="st5" d="M813.6,197.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9c0-1.3,0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1
                      c1.1,0,1.9,0.3,2.5,0.9s0.9,1.5,0.9,2.5v1h-4.9c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                      c-0.4,0.2-0.8,0.3-1.2,0.4C814.7,197.1,814.2,197.1,813.6,197.1z M813.3,190.7c-0.4,0-0.8,0.1-1,0.4c-0.2,0.3-0.4,0.7-0.4,1.2
                      h2.9c0-0.5-0.1-0.9-0.4-1.2C814.1,190.8,813.7,190.7,813.3,190.7z"/>
                <path class="st5" d="M818.3,187.4c0-0.7,0.4-1,1.1-1s1.1,0.3,1.1,1c0,0.3-0.1,0.6-0.3,0.8s-0.5,0.3-0.9,0.3
                      C818.7,188.4,818.3,188.1,818.3,187.4z M820.5,197h-2.1v-7.6h2.1V197z"/>
                <path class="st5" d="M824.7,197h-2.1v-10.6h2.1V197z"/>
                <path class="st5" d="M826.8,187.4c0-0.7,0.4-1,1.1-1s1.1,0.3,1.1,1c0,0.3-0.1,0.6-0.3,0.8s-0.5,0.3-0.9,0.3
                      C827.2,188.4,826.8,188.1,826.8,187.4z M829,197h-2.1v-7.6h2.1V197z"/>
                <path class="st5" d="M837.8,189.4v1.1l-1.2,0.3c0.2,0.3,0.3,0.7,0.3,1.1c0,0.8-0.3,1.5-0.9,1.9c-0.6,0.5-1.4,0.7-2.4,0.7h-0.4
                      h-0.3c-0.2,0.2-0.3,0.3-0.3,0.5c0,0.3,0.4,0.5,1.1,0.5h1.3c0.8,0,1.5,0.2,1.9,0.5c0.4,0.4,0.7,0.9,0.7,1.6
                      c0,0.9-0.4,1.6-1.1,2.1c-0.7,0.5-1.8,0.7-3.2,0.7c-1.1,0-1.9-0.2-2.4-0.6c-0.6-0.4-0.8-0.9-0.8-1.6c0-0.5,0.1-0.8,0.4-1.2
                      s0.7-0.5,1.3-0.7c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.2-0.4-0.2-0.7s0.1-0.5,0.3-0.7s0.4-0.4,0.7-0.6c-0.4-0.2-0.7-0.5-1-0.8
                      c-0.2-0.4-0.4-0.8-0.4-1.4c0-0.8,0.3-1.5,0.8-1.9s1.3-0.7,2.3-0.7c0.2,0,0.5,0,0.8,0.1c0.3,0,0.5,0.1,0.6,0.1L837.8,189.4
                      L837.8,189.4z M831.9,198.1c0,0.3,0.1,0.5,0.4,0.7c0.3,0.2,0.7,0.2,1.2,0.2c0.7,0,1.3-0.1,1.8-0.3s0.6-0.5,0.6-0.8
                      s-0.1-0.5-0.4-0.6s-0.6-0.2-1.2-0.2h-1.1c-0.4,0-0.7,0.1-1,0.3C832.1,197.5,831.9,197.8,831.9,198.1z M832.7,191.9
                      c0,0.4,0.1,0.7,0.3,1c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.4s0.3-0.6,0.3-1c0-0.9-0.4-1.4-1.1-1.4
                      C833.1,190.5,832.7,190.9,832.7,191.9z"/>
                <path class="st5" d="M844.5,197l-0.3-1h-0.1c-0.2,0.4-0.5,0.6-1,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.9,0-1.6-0.2-2-0.7
                      c-0.5-0.5-0.7-1.2-0.7-2.1v-5h2.1v4.5c0,0.6,0.1,1,0.3,1.2c0.2,0.3,0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.6c0.3-0.4,0.4-1,0.4-1.9
                      v-3.6h2.1v7.6h-1.6V197z"/>
                <path class="st5" d="M855.4,197h-2.1v-4.5c0-0.6-0.1-1-0.3-1.2c-0.2-0.3-0.5-0.4-0.9-0.4c-0.6,0-1,0.2-1.3,0.6
                      c-0.3,0.4-0.4,1-0.4,1.9v3.6h-2.1v-7.6h1.6l0.3,1h0.1c0.2-0.4,0.6-0.6,1-0.8s0.9-0.3,1.4-0.3c0.9,0,1.6,0.2,2,0.7
                      c0.5,0.5,0.7,1.2,0.7,2.1V197z"/>
                <path class="st5" d="M864.1,189.4v1.1l-1.2,0.3c0.2,0.3,0.3,0.7,0.3,1.1c0,0.8-0.3,1.5-0.9,1.9c-0.6,0.5-1.4,0.7-2.4,0.7h-0.4
                      h-0.3c-0.2,0.2-0.3,0.3-0.3,0.5c0,0.3,0.4,0.5,1.1,0.5h1.3c0.8,0,1.5,0.2,1.9,0.5c0.4,0.4,0.7,0.9,0.7,1.6
                      c0,0.9-0.4,1.6-1.1,2.1c-0.7,0.5-1.8,0.7-3.2,0.7c-1.1,0-1.9-0.2-2.4-0.6c-0.6-0.4-0.8-0.9-0.8-1.6c0-0.5,0.1-0.8,0.4-1.2
                      s0.7-0.5,1.3-0.7c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.2-0.4-0.2-0.7s0.1-0.5,0.3-0.7s0.4-0.4,0.7-0.6c-0.4-0.2-0.7-0.5-1-0.8
                      c-0.2-0.4-0.4-0.8-0.4-1.4c0-0.8,0.3-1.5,0.8-1.9s1.3-0.7,2.3-0.7c0.2,0,0.5,0,0.8,0.1c0.3,0,0.5,0.1,0.6,0.1L864.1,189.4
                      L864.1,189.4z M858.2,198.1c0,0.3,0.1,0.5,0.4,0.7c0.3,0.2,0.7,0.2,1.2,0.2c0.7,0,1.3-0.1,1.8-0.3s0.6-0.5,0.6-0.8
                      s-0.1-0.5-0.4-0.6s-0.6-0.2-1.2-0.2h-1.1c-0.4,0-0.7,0.1-1,0.3C858.4,197.5,858.2,197.8,858.2,198.1z M859,191.9
                      c0,0.4,0.1,0.7,0.3,1c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.4s0.3-0.6,0.3-1c0-0.9-0.4-1.4-1.1-1.4
                      C859.4,190.5,859,190.9,859,191.9z"/>
                </g>
                <g class="st4">
                <path class="st5" d="M691.8,264h-7.4v-1.4l4.8-6.9h-4.7V254h7.2v1.4l-4.8,6.9h4.9V264z"/>
                <path class="st5" d="M696.7,264.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9s0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1c1.1,0,1.9,0.3,2.5,0.9
                      s0.9,1.5,0.9,2.5v1H695c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                      c-0.4,0.2-0.8,0.3-1.2,0.4C697.8,264.1,697.3,264.1,696.7,264.1z M696.4,257.7c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.7-0.4,1.2
                      h2.9c0-0.5-0.1-0.9-0.4-1.2C697.2,257.8,696.8,257.7,696.4,257.7z"/>
                <path class="st5" d="M703.6,264h-2.1v-10.6h2.1V264z"/>
                <path class="st5" d="M707.8,264h-2.1v-10.6h2.1V264z"/>
                <path class="st5" d="M715.4,264l-0.3-1H715c-0.2,0.4-0.5,0.6-1,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.9,0-1.6-0.2-2-0.7
                      c-0.5-0.5-0.7-1.2-0.7-2.1v-5h2.1v4.5c0,0.6,0.1,1,0.3,1.2c0.2,0.3,0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.6s0.4-1,0.4-1.9v-3.6h2.1
                      v7.6h-1.6V264z"/>
                <path class="st5" d="M721.3,264h-2.1v-10.6h2.1V264z"/>
                <path class="st5" d="M728.3,264l-0.4-1h-0.1c-0.4,0.4-0.7,0.7-1.1,0.9c-0.4,0.2-0.9,0.3-1.5,0.3c-0.7,0-1.3-0.2-1.7-0.6
                      s-0.6-1-0.6-1.8s0.3-1.4,0.9-1.8c0.6-0.4,1.4-0.6,2.6-0.6h1.3v-0.3c0-0.8-0.4-1.2-1.2-1.2c-0.6,0-1.3,0.2-2.2,0.6l-0.7-1.4
                      c0.9-0.5,1.9-0.7,2.9-0.7s1.8,0.2,2.4,0.7s0.8,1.1,0.8,2v5.1h-1.4V264z M724.3,254.4c0-0.3,0.1-0.5,0.3-0.7s0.4-0.2,0.7-0.2
                      s0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7s-0.4,0.2-0.7,0.2s-0.5-0.1-0.7-0.2C724.4,254.9,724.3,254.7,724.3,254.4z
                      M727.7,260.5h-0.8c-0.6,0-1.1,0.1-1.4,0.3s-0.4,0.5-0.4,0.9c0,0.6,0.3,0.9,1,0.9c0.5,0,0.9-0.1,1.2-0.4s0.4-0.6,0.4-1.1V260.5z
                      M727.1,254.4c0-0.3,0.1-0.6,0.3-0.7c0.2-0.1,0.4-0.2,0.7-0.2s0.5,0.1,0.7,0.2c0.2,0.2,0.3,0.4,0.3,0.7s-0.1,0.5-0.3,0.7
                      s-0.4,0.2-0.7,0.2s-0.5-0.1-0.7-0.2C727.2,254.9,727.1,254.7,727.1,254.4z"/>
                <path class="st5" d="M736.2,256.2c0.3,0,0.5,0,0.7,0.1l-0.2,2c-0.2,0-0.4-0.1-0.6-0.1c-0.7,0-1.2,0.2-1.6,0.5
                      c-0.4,0.3-0.6,0.8-0.6,1.4v3.9h-2.1v-7.6h1.6l0.3,1.3h0.1c0.2-0.4,0.6-0.8,1-1C735.3,256.3,735.7,256.2,736.2,256.2z"/>
                <path class="st5" d="M741.8,264.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9s0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1c1.1,0,1.9,0.3,2.5,0.9
                      s0.9,1.5,0.9,2.5v1h-5c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                      c-0.4,0.2-0.8,0.3-1.2,0.4C742.9,264.1,742.4,264.1,741.8,264.1z M741.5,257.7c-0.4,0-0.8,0.1-1,0.4c-0.3,0.3-0.4,0.7-0.4,1.2
                      h2.9c0-0.5-0.1-0.9-0.4-1.2C742.3,257.8,741.9,257.7,741.5,257.7z"/>
                <path class="st5" d="M754.2,258.4h4v5.2c-0.6,0.2-1.2,0.4-1.8,0.4c-0.6,0.1-1.1,0.1-1.7,0.1c-1.5,0-2.7-0.4-3.5-1.3
                      s-1.2-2.2-1.2-3.8c0-1.6,0.5-2.9,1.4-3.8c0.9-0.9,2.2-1.4,3.8-1.4c1,0,2,0.2,3,0.6l-0.7,1.7c-0.7-0.4-1.5-0.5-2.3-0.5
                      c-0.9,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.8,1.4-0.8,2.5s0.2,1.9,0.7,2.5c0.4,0.6,1.1,0.9,1.9,0.9c0.4,0,0.9,0,1.3-0.1v-2.1h-1.9
                      V258.4z"/>
                <path class="st5" d="M764.6,256.2c0.9,0,1.6,0.4,2.1,1.1s0.8,1.7,0.8,2.9c0,1.3-0.3,2.2-0.8,2.9c-0.5,0.7-1.2,1-2.1,1
                      c-0.9,0-1.6-0.3-2.1-1h-0.1l-0.3,0.8h-1.6v-10.6h2.1v2.5c0,0.3,0,0.8-0.1,1.5h0.1C762.9,256.6,763.6,256.2,764.6,256.2z
                      M763.9,257.9c-0.5,0-0.9,0.2-1.1,0.5c-0.2,0.3-0.4,0.8-0.4,1.6v0.2c0,0.8,0.1,1.4,0.4,1.8c0.2,0.4,0.6,0.5,1.2,0.5
                      c0.4,0,0.8-0.2,1-0.6c0.3-0.4,0.4-1,0.4-1.7s-0.1-1.3-0.4-1.7C764.7,258.1,764.4,257.9,763.9,257.9z"/>
                <path class="st5" d="M775.3,256.2c0,0.6-0.2,1.2-0.6,1.6s-0.9,0.7-1.6,0.9l0,0c0.8,0.1,1.4,0.3,1.8,0.7c0.4,0.4,0.6,0.9,0.6,1.6
                      c0,1-0.3,1.7-1,2.2s-1.7,0.8-3,0.8c-1.1,0-2-0.2-2.9-0.5v-1.8c0.4,0.2,0.8,0.4,1.3,0.5s0.9,0.2,1.4,0.2c0.7,0,1.2-0.1,1.5-0.4
                      s0.5-0.6,0.5-1.1s-0.2-0.8-0.6-1s-1-0.3-1.8-0.3h-0.8V258h0.8c0.8,0,1.3-0.1,1.7-0.3c0.4-0.2,0.5-0.5,0.5-1
                      c0-0.8-0.5-1.1-1.4-1.1c-0.3,0-0.7,0.1-1,0.2c-0.3,0.1-0.7,0.3-1.1,0.6l-1-1.5c0.9-0.7,2-1,3.3-1c1,0,1.9,0.2,2.5,0.6
                      C775,254.9,775.3,255.5,775.3,256.2z"/>
                <path class="st5" d="M776.5,261.1v-1.7h3.7v1.7H776.5z"/>
                <path class="st5" d="M788,264l-0.7-2.4h-3.6L783,264h-2.3l3.5-10h2.6l3.5,10H788z M786.8,259.8c-0.7-2.2-1-3.4-1.1-3.7
                      c-0.1-0.3-0.1-0.5-0.2-0.7c-0.2,0.6-0.6,2-1.3,4.3h2.6V259.8z"/>
                <path class="st5" d="M795.6,256.2c0.9,0,1.6,0.4,2.1,1.1s0.8,1.7,0.8,2.9c0,1.3-0.3,2.2-0.8,2.9c-0.5,0.7-1.2,1-2.2,1
                      c-0.9,0-1.6-0.3-2.1-1h-0.1L793,264h-1.6v-10.6h2.1v2.5c0,0.3,0,0.8-0.1,1.5h0.1C793.9,256.6,794.7,256.2,795.6,256.2z
                      M794.9,257.9c-0.5,0-0.9,0.2-1.1,0.5c-0.2,0.3-0.4,0.8-0.4,1.6v0.2c0,0.8,0.1,1.4,0.4,1.8c0.2,0.4,0.6,0.5,1.2,0.5
                      c0.4,0,0.8-0.2,1-0.6c0.3-0.4,0.4-1,0.4-1.7s-0.1-1.3-0.4-1.7C795.7,258.1,795.4,257.9,794.9,257.9z"/>
                <path class="st5" d="M802.3,264h-2.1v-10.6h2.1V264z"/>
                <path class="st5" d="M809.4,264l-0.4-1h-0.1c-0.4,0.4-0.7,0.7-1.1,0.9c-0.4,0.2-0.9,0.3-1.5,0.3c-0.7,0-1.3-0.2-1.7-0.6
                      s-0.6-1-0.6-1.8s0.3-1.4,0.9-1.8c0.6-0.4,1.4-0.6,2.6-0.6h1.3v-0.3c0-0.8-0.4-1.2-1.2-1.2c-0.6,0-1.3,0.2-2.2,0.6l-0.7-1.4
                      c0.9-0.5,1.9-0.7,2.9-0.7s1.8,0.2,2.4,0.7s0.8,1.1,0.8,2v5.1h-1.4V264z M808.7,260.5h-0.8c-0.6,0-1.1,0.1-1.4,0.3
                      s-0.4,0.5-0.4,0.9c0,0.6,0.3,0.9,1,0.9c0.5,0,0.9-0.1,1.2-0.4s0.4-0.6,0.4-1.1V260.5z"/>
                <path class="st5" d="M819.6,256.4v1.1l-1.2,0.3c0.2,0.3,0.3,0.7,0.3,1.1c0,0.8-0.3,1.5-0.9,1.9c-0.6,0.5-1.4,0.7-2.4,0.7H815
                      h-0.3c-0.2,0.2-0.3,0.3-0.3,0.5c0,0.3,0.4,0.5,1.1,0.5h1.3c0.8,0,1.5,0.2,1.9,0.5c0.4,0.4,0.7,0.9,0.7,1.6
                      c0,0.9-0.4,1.6-1.1,2.1c-0.7,0.5-1.8,0.7-3.2,0.7c-1.1,0-1.9-0.2-2.4-0.6c-0.6-0.4-0.8-0.9-0.8-1.6c0-0.5,0.1-0.8,0.4-1.2
                      s0.7-0.5,1.3-0.7c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.3,0.1-0.5,0.3-0.7s0.4-0.4,0.7-0.6
                      c-0.4-0.2-0.7-0.5-1-0.8c-0.2-0.4-0.4-0.8-0.4-1.4c0-0.8,0.3-1.5,0.8-1.9s1.3-0.7,2.3-0.7c0.2,0,0.5,0,0.8,0.1
                      c0.3,0,0.5,0.1,0.6,0.1h2.7V256.4z M813.7,265.1c0,0.3,0.1,0.5,0.4,0.7c0.3,0.2,0.7,0.2,1.2,0.2c0.7,0,1.3-0.1,1.8-0.3
                      s0.6-0.5,0.6-0.8s-0.1-0.5-0.4-0.6s-0.6-0.2-1.2-0.2H815c-0.4,0-0.7,0.1-1,0.3C813.8,264.5,813.7,264.8,813.7,265.1z
                      M814.5,258.9c0,0.4,0.1,0.7,0.3,1c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.4s0.3-0.6,0.3-1c0-0.9-0.4-1.4-1.1-1.4
                      C814.8,257.5,814.5,257.9,814.5,258.9z"/>
                <path class="st5" d="M824.3,264.1c-1.2,0-2.2-0.3-2.9-1s-1-1.6-1-2.9s0.3-2.3,1-3c0.6-0.7,1.5-1,2.7-1c1.1,0,1.9,0.3,2.5,0.9
                      s0.9,1.5,0.9,2.5v1h-4.9c0,0.6,0.2,1.1,0.5,1.4s0.8,0.5,1.4,0.5c0.5,0,0.9,0,1.3-0.1s0.8-0.2,1.3-0.5v1.6
                      c-0.4,0.2-0.8,0.3-1.2,0.4C825.4,264.1,824.9,264.1,824.3,264.1z M824,257.7c-0.4,0-0.8,0.1-1,0.4s-0.4,0.7-0.4,1.2h2.9
                      c0-0.5-0.1-0.9-0.4-1.2C824.8,257.8,824.5,257.7,824,257.7z"/>
                <path class="st5" d="M833.4,256.2c0.3,0,0.5,0,0.7,0.1l-0.2,2c-0.2,0-0.4-0.1-0.6-0.1c-0.7,0-1.2,0.2-1.6,0.5
                      c-0.4,0.3-0.6,0.8-0.6,1.4v3.9H829v-7.6h1.6l0.3,1.3h0.1c0.2-0.4,0.6-0.8,1-1C832.5,256.3,832.9,256.2,833.4,256.2z"/>
                <path class="st5" d="M840.9,264l-0.3-1h-0.1c-0.2,0.4-0.5,0.6-1,0.8c-0.4,0.2-0.9,0.3-1.4,0.3c-0.9,0-1.6-0.2-2-0.7
                      c-0.5-0.5-0.7-1.2-0.7-2.1v-5h2.1v4.5c0,0.6,0.1,1,0.3,1.2c0.2,0.3,0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.6s0.4-1,0.4-1.9v-3.6h2.1
                      v7.6h-1.6V264z"/>
                <path class="st5" d="M851.7,264h-2.1v-4.5c0-0.6-0.1-1-0.3-1.2c-0.2-0.3-0.5-0.4-0.9-0.4c-0.6,0-1,0.2-1.3,0.6s-0.4,1-0.4,1.9
                      v3.6h-2.1v-7.6h1.6l0.3,1h0.1c0.2-0.4,0.6-0.6,1-0.8c0.4-0.2,0.9-0.3,1.4-0.3c0.9,0,1.6,0.2,2,0.7c0.5,0.5,0.7,1.2,0.7,2.1V264z
                      "/>
                <path class="st5" d="M860.5,256.4v1.1l-1.2,0.3c0.2,0.3,0.3,0.7,0.3,1.1c0,0.8-0.3,1.5-0.9,1.9c-0.6,0.5-1.4,0.7-2.4,0.7h-0.4
                      h-0.3c-0.2,0.2-0.3,0.3-0.3,0.5c0,0.3,0.4,0.5,1.1,0.5h1.3c0.8,0,1.5,0.2,1.9,0.5c0.4,0.4,0.7,0.9,0.7,1.6
                      c0,0.9-0.4,1.6-1.1,2.1c-0.7,0.5-1.8,0.7-3.2,0.7c-1.1,0-1.9-0.2-2.4-0.6c-0.6-0.4-0.8-0.9-0.8-1.6c0-0.5,0.1-0.8,0.4-1.2
                      s0.7-0.5,1.3-0.7c-0.2-0.1-0.4-0.2-0.6-0.4c-0.2-0.2-0.2-0.4-0.2-0.7c0-0.3,0.1-0.5,0.3-0.7s0.4-0.4,0.7-0.6
                      c-0.4-0.2-0.7-0.5-1-0.8c-0.2-0.4-0.4-0.8-0.4-1.4c0-0.8,0.3-1.5,0.8-1.9s1.3-0.7,2.3-0.7c0.2,0,0.5,0,0.8,0.1
                      c0.3,0,0.5,0.1,0.6,0.1h2.7V256.4z M854.6,265.1c0,0.3,0.1,0.5,0.4,0.7c0.3,0.2,0.7,0.2,1.2,0.2c0.7,0,1.3-0.1,1.8-0.3
                      s0.6-0.5,0.6-0.8s-0.1-0.5-0.4-0.6s-0.6-0.2-1.2-0.2h-1c-0.4,0-0.7,0.1-1,0.3C854.8,264.5,854.6,264.8,854.6,265.1z
                      M855.4,258.9c0,0.4,0.1,0.7,0.3,1c0.2,0.2,0.5,0.4,0.9,0.4s0.7-0.1,0.9-0.4s0.3-0.6,0.3-1c0-0.9-0.4-1.4-1.1-1.4
                      C855.8,257.5,855.4,257.9,855.4,258.9z"/>
                </g>
                <g class="st4">
                <path d="M730.9,310.3c0,0.7-0.2,1.3-0.6,1.8c-0.4,0.5-1,0.8-1.7,0.9v0.1c0.9,0.1,1.6,0.4,2,0.9s0.7,1.1,0.7,1.8
                      c0,1.1-0.4,1.9-1.1,2.5c-0.8,0.6-1.8,0.9-3.2,0.9c-0.6,0-1.2,0-1.7-0.1s-1-0.3-1.5-0.5v-1.2c0.5,0.2,1,0.4,1.6,0.6
                      c0.6,0.1,1.1,0.2,1.6,0.2c2,0,3-0.8,3-2.3c0-1.4-1.1-2.1-3.3-2.1h-1.1v-1.1h1.1c0.9,0,1.6-0.2,2.1-0.6s0.8-0.9,0.8-1.6
                      c0-0.6-0.2-1-0.6-1.3s-0.9-0.5-1.6-0.5c-0.5,0-1,0.1-1.4,0.2s-0.9,0.4-1.5,0.8l-0.7-0.9c0.5-0.4,1-0.7,1.6-0.9
                      c0.6-0.2,1.3-0.3,1.9-0.3c1.1,0,2,0.3,2.6,0.8C730.6,308.7,730.9,309.4,730.9,310.3z"/>
                <path d="M733.3,318.2c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.8
                      c0,0.3-0.1,0.6-0.3,0.8s-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.2C733.4,318.8,733.3,318.5,733.3,318.2z"/>
                <path d="M741.2,315.3v-1.2h3.8v1.2H741.2z"/>
                <path d="M758.7,316.4H757v2.6h-1.2v-2.6h-5.6v-1.1l5.4-7.7h1.4v7.7h1.7L758.7,316.4L758.7,316.4z M755.8,315.2v-3.8
                      c0-0.7,0-1.6,0.1-2.5h-0.1c-0.2,0.5-0.5,0.9-0.7,1.2l-3.6,5.1L755.8,315.2L755.8,315.2z"/>
                <path d="M760.2,318.2c0-0.3,0.1-0.6,0.2-0.8c0.2-0.2,0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.8
                      c0,0.3-0.1,0.6-0.3,0.8s-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.2C760.3,318.8,760.2,318.5,760.2,318.2z"/>
                <path d="M769,319v-11.4h1.3v10.2h5v1.2L769,319L769,319z"/>
                <path d="M780.7,319.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2
                      c1.1,0,1.9,0.4,2.5,1.1s0.9,1.6,0.9,2.8v0.8H778c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2
                      c-0.5,0.2-0.9,0.3-1.3,0.4C781.8,319.1,781.3,319.2,780.7,319.2z M780.4,311.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9
                      h4.5c0-0.8-0.2-1.4-0.5-1.9C781.6,311.6,781.1,311.4,780.4,311.4z"/>
                <path d="M790.1,310.3c1.1,0,2,0.4,2.6,1.2c0.6,0.8,0.9,1.9,0.9,3.3s-0.3,2.5-0.9,3.3c-0.6,0.8-1.5,1.2-2.6,1.2
                      c-0.6,0-1.1-0.1-1.5-0.3s-0.8-0.5-1.2-0.9h-0.1L787,319h-0.9v-12.2h1.3v3c0,0.7,0,1.3-0.1,1.8h0.1
                      C788,310.7,788.9,310.3,790.1,310.3z M789.9,311.4c-0.9,0-1.5,0.3-1.9,0.8s-0.6,1.4-0.6,2.6s0.2,2.1,0.6,2.6
                      c0.4,0.5,1,0.8,1.9,0.8c0.8,0,1.4-0.3,1.8-0.9s0.6-1.4,0.6-2.5s-0.2-1.9-0.6-2.5S790.7,311.4,789.9,311.4z"/>
                <path d="M799.5,319.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2
                      c1.1,0,1.9,0.4,2.5,1.1s0.9,1.6,0.9,2.8v0.8h-5.9c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2
                      c-0.5,0.2-0.9,0.3-1.3,0.4C800.6,319.1,800.1,319.2,799.5,319.2z M799.2,311.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9
                      h4.5c0-0.8-0.2-1.4-0.5-1.9C800.4,311.6,799.8,311.4,799.2,311.4z"/>
                <path d="M810.7,319v-5.5c0-0.7-0.2-1.2-0.5-1.6c-0.3-0.3-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7s-0.6,1.3-0.6,2.4v4.5h-1.3v-8.6
                      h1.1l0.2,1.2h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7s0.8,1.3,0.8,2.4v5.6L810.7,319L810.7,319z"
                      />
                <path d="M820.2,316.7c0,0.8-0.3,1.4-0.9,1.8s-1.4,0.6-2.5,0.6s-2-0.2-2.7-0.5v-1.2c0.4,0.2,0.9,0.4,1.3,0.5
                      c0.5,0.1,0.9,0.2,1.4,0.2c0.7,0,1.2-0.1,1.6-0.3c0.4-0.2,0.5-0.5,0.5-1c0-0.3-0.1-0.6-0.4-0.9s-0.9-0.5-1.7-0.8
                      s-1.4-0.6-1.7-0.8s-0.6-0.5-0.8-0.8s-0.2-0.6-0.2-1c0-0.7,0.3-1.2,0.9-1.7c0.6-0.4,1.3-0.6,2.3-0.6c0.9,0,1.8,0.2,2.7,0.6
                      l-0.5,1.1c-0.9-0.4-1.6-0.5-2.3-0.5c-0.6,0-1.1,0.1-1.4,0.3s-0.5,0.5-0.5,0.8c0,0.2,0.1,0.4,0.2,0.6s0.3,0.3,0.6,0.5
                      c0.3,0.1,0.8,0.4,1.5,0.6c1,0.4,1.7,0.7,2.1,1.1C820,315.6,820.2,316.1,820.2,316.7z"/>
                <path d="M828.1,317.9L828.1,317.9c-0.7,0.9-1.6,1.3-2.8,1.3c-1.1,0-2-0.4-2.6-1.1c-0.6-0.8-0.9-1.9-0.9-3.3s0.3-2.5,0.9-3.3
                      s1.5-1.2,2.6-1.2c1.2,0,2.1,0.4,2.7,1.3h0.1L828,311v-0.6v-3.5h1.3V319h-1.1L828.1,317.9z M825.6,318.1c0.9,0,1.5-0.2,1.9-0.7
                      s0.6-1.3,0.6-2.3v-0.3c0-1.2-0.2-2.1-0.6-2.6c-0.4-0.5-1-0.8-1.9-0.8c-0.8,0-1.3,0.3-1.7,0.9s-0.6,1.4-0.6,2.5s0.2,1.9,0.6,2.5
                      C824.2,317.8,824.8,318.1,825.6,318.1z"/>
                <path d="M835.7,319.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2
                      c1.1,0,1.9,0.4,2.5,1.1s0.9,1.6,0.9,2.8v0.8H833c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2
                      c-0.5,0.2-0.9,0.3-1.3,0.4C836.8,319.1,836.3,319.2,835.7,319.2z M835.4,311.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9
                      h4.5c0-0.8-0.2-1.4-0.5-1.9C836.6,311.6,836.1,311.4,835.4,311.4z"/>
                <path d="M842.4,314.6c0.2-0.3,0.6-0.7,1-1.2l2.8-2.9h1.5l-3.5,3.6l3.7,4.9h-1.6l-3-4l-1,0.8v3.2H841v-12.2h1.3v6.4
                      C842.4,313.6,842.4,314,842.4,314.6L842.4,314.6z"/>
                <path d="M854.8,319l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7
                      c-0.5-0.4-0.7-1.1-0.7-1.9c0-1.7,1.4-2.6,4.1-2.7h1.5v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7
                      l-0.4-1c0.4-0.2,0.9-0.4,1.4-0.5s1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2v5.8L854.8,319L854.8,319z M851.8,318.1
                      c0.8,0,1.4-0.2,1.9-0.7s0.7-1.1,0.7-1.9v-0.8l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3c0,0.5,0.1,0.8,0.4,1.1
                      C850.9,318,851.3,318.1,851.8,318.1z"/>
                <path d="M864.2,317.9L864.2,317.9c-0.7,0.9-1.6,1.3-2.8,1.3c-1.1,0-2-0.4-2.6-1.1c-0.6-0.8-0.9-1.9-0.9-3.3s0.3-2.5,0.9-3.3
                      s1.5-1.2,2.6-1.2c1.2,0,2.1,0.4,2.7,1.3h0.1l-0.1-0.6v-0.6v-3.5h1.3V319h-1.1L864.2,317.9z M861.6,318.1c0.9,0,1.5-0.2,1.9-0.7
                      s0.6-1.3,0.6-2.3v-0.3c0-1.2-0.2-2.1-0.6-2.6c-0.4-0.5-1-0.8-1.9-0.8c-0.8,0-1.3,0.3-1.7,0.9s-0.6,1.4-0.6,2.5s0.2,1.9,0.6,2.5
                      C860.3,317.8,860.9,318.1,861.6,318.1z"/>
                <path d="M871.8,319.2c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2
                      c1.1,0,1.9,0.4,2.5,1.1s0.9,1.6,0.9,2.8v0.8h-5.9c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2
                      c-0.5,0.2-0.9,0.3-1.3,0.4C872.9,319.1,872.4,319.2,871.8,319.2z M871.5,311.4c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.7,1.1-0.7,1.9
                      h4.5c0-0.8-0.2-1.4-0.5-1.9C872.7,311.6,872.1,311.4,871.5,311.4z"/>
                </g>
                <g class="st4">
                <path d="M17.5,191v1.6l-5.5,4.2l1.1,1.2h4.5v1.3H6.1V198h5.7l-5.7-5.2v-1.6l5,4.6L17.5,191z"/>
                <path d="M8.8,185.7c0-0.4,0-0.7,0.1-1l1.2,0.2c-0.1,0.4-0.1,0.7-0.1,0.9c0,0.7,0.3,1.3,0.8,1.8s1.3,0.7,2.1,0.7h4.6v1.3H8.9
                      v-1.1l1.6-0.1v-0.1c-0.6-0.3-1-0.7-1.3-1.1S8.8,186.2,8.8,185.7z"/>
                <path d="M17.5,177.8l-1.2,0.3v0.1c0.5,0.4,0.9,0.9,1.1,1.3c0.2,0.4,0.3,1,0.3,1.6c0,0.8-0.2,1.5-0.7,2s-1.1,0.7-1.9,0.7
                      c-1.7,0-2.6-1.4-2.7-4.1v-1.5h-0.5c-0.7,0-1.2,0.1-1.5,0.4s-0.5,0.8-0.5,1.4c0,0.7,0.2,1.5,0.7,2.4l-1,0.4
                      c-0.2-0.4-0.4-0.9-0.5-1.4s-0.2-1-0.2-1.5c0-1,0.2-1.8,0.7-2.3s1.2-0.7,2.2-0.7h5.8L17.5,177.8L17.5,177.8z M16.6,180.7
                      c0-0.8-0.2-1.4-0.7-1.9s-1.1-0.7-1.9-0.7h-0.8l0.1,1.3c0,1,0.2,1.8,0.5,2.2s0.7,0.7,1.3,0.7c0.5,0,0.8-0.1,1.1-0.4
                      S16.6,181.2,16.6,180.7z"/>
                <path d="M17.5,168.3H12c-0.7,0-1.2,0.2-1.6,0.5c-0.3,0.3-0.5,0.8-0.5,1.5c0,0.9,0.2,1.6,0.7,2s1.3,0.6,2.4,0.6h4.5v1.3H8.9v-1.1
                      l1.2-0.2v-0.1c-0.4-0.3-0.7-0.6-1-1.1c-0.2-0.5-0.3-1-0.3-1.6c0-1,0.2-1.8,0.7-2.3s1.3-0.8,2.4-0.8h5.6V168.3z"/>
                <path d="M13.1,163.1c-0.3-0.2-0.7-0.6-1.2-1l-3-2.8v-1.5l3.6,3.5l4.9-3.7v1.6l-4,3l0.8,1h3.2v1.3H5.3v-1.3h6.4
                      C12.1,163.1,12.5,163.1,13.1,163.1L13.1,163.1z"/>
                <path d="M17.5,150H12c-0.7,0-1.2,0.2-1.6,0.5c-0.3,0.3-0.5,0.8-0.5,1.5c0,0.9,0.2,1.6,0.7,2s1.3,0.6,2.4,0.6h4.5v1.3H5.3v-1.3H9
                      c0.4,0,0.8,0,1.1,0.1v-0.1c-0.4-0.3-0.7-0.6-1-1.1c-0.2-0.5-0.4-1-0.4-1.6c0-1,0.2-1.8,0.7-2.4s1.3-0.8,2.4-0.8h5.6L17.5,150
                      L17.5,150z"/>
                <path d="M17.7,142.4c0,1.3-0.4,2.3-1.2,3c-0.8,0.7-1.8,1.1-3.2,1.1s-2.5-0.3-3.3-1s-1.2-1.6-1.2-2.7c0-1.1,0.4-1.9,1.1-2.5
                      c0.7-0.6,1.6-0.9,2.8-0.9h0.8v5.9c1,0,1.8-0.3,2.3-0.8s0.8-1.2,0.8-2c0-0.9-0.2-1.8-0.6-2.7h1.2c0.2,0.5,0.3,0.9,0.4,1.3
                      C17.6,141.4,17.7,141.8,17.7,142.4z M9.9,142.8c0,0.7,0.2,1.2,0.7,1.6c0.4,0.4,1.1,0.6,1.9,0.7v-4.5c-0.8,0-1.4,0.2-1.9,0.5
                      C10.1,141.6,9.9,142.1,9.9,142.8z"/>
                <path d="M6.6,137.2c-0.3,0-0.5-0.1-0.7-0.2c-0.1-0.1-0.2-0.3-0.2-0.5s0.1-0.4,0.2-0.5c0.1-0.2,0.4-0.2,0.6-0.2s0.5,0.1,0.7,0.2
                      c0.1,0.2,0.2,0.3,0.2,0.5s-0.1,0.4-0.2,0.5C7.1,137.1,6.9,137.2,6.6,137.2z M17.5,135.8v1.3H8.9v-1.3H17.5z"/>
                <path d="M16.6,130.3c0-0.2,0-0.5-0.1-0.7c0-0.2-0.1-0.4-0.1-0.5h1c0.1,0.1,0.1,0.3,0.2,0.6c0.1,0.3,0.1,0.5,0.1,0.7
                      c0,1.7-0.9,2.5-2.6,2.5H9.9v1.2H9.3l-0.5-1.2L7,132.4v-0.8h2v-2.5h1v2.5h5c0.5,0,0.9-0.1,1.2-0.4
                      C16.4,131,16.6,130.7,16.6,130.3z"/>
                <path d="M15.2,121.8c0.8,0,1.4,0.3,1.8,0.9s0.6,1.4,0.6,2.5s-0.2,2-0.5,2.7h-1.2c0.2-0.4,0.4-0.9,0.5-1.3
                      c0.1-0.5,0.2-0.9,0.2-1.4c0-0.7-0.1-1.2-0.3-1.6c-0.2-0.4-0.5-0.5-1-0.5c-0.3,0-0.6,0.1-0.9,0.4s-0.5,0.9-0.8,1.7
                      c-0.3,0.8-0.6,1.4-0.8,1.7s-0.5,0.6-0.8,0.8s-0.6,0.2-1,0.2c-0.7,0-1.2-0.3-1.7-0.9c-0.4-0.6-0.6-1.3-0.6-2.3
                      c0-0.9,0.2-1.8,0.6-2.7l1.1,0.5c-0.4,0.9-0.5,1.6-0.5,2.3c0,0.6,0.1,1.1,0.3,1.4s0.5,0.5,0.8,0.5c0.2,0,0.4-0.1,0.6-0.2
                      s0.3-0.3,0.5-0.6c0.1-0.3,0.4-0.8,0.6-1.5c0.4-1,0.7-1.7,1.1-2.1C14.1,122,14.6,121.8,15.2,121.8z"/>
                <path d="M17.5,118.4v1.3H5.3v-1.3H17.5z"/>
                <path d="M17.5,110.4l-1.2,0.3v0.1c0.5,0.4,0.9,0.9,1.1,1.3s0.3,1,0.3,1.6c0,0.8-0.2,1.5-0.7,2c-0.4,0.5-1.1,0.7-1.9,0.7
                      c-1.7,0-2.6-1.4-2.7-4.1v-1.5h-0.5c-0.7,0-1.2,0.1-1.5,0.4s-0.5,0.8-0.5,1.4c0,0.7,0.2,1.5,0.7,2.4l-1,0.4
                      c-0.3-0.5-0.5-0.9-0.6-1.4s-0.2-1-0.2-1.5c0-1,0.2-1.8,0.7-2.3s1.2-0.7,2.2-0.7h5.8V110.4z M16.6,113.4c0-0.8-0.2-1.4-0.7-1.9
                      s-1.1-0.7-1.9-0.7h-0.8l0.1,1.3c0,1,0.2,1.8,0.5,2.2c0.3,0.5,0.7,0.7,1.3,0.7c0.5,0,0.8-0.1,1.1-0.4
                      C16.5,114.3,16.6,113.9,16.6,113.4z"/>
                <path d="M15.2,101.3c0.8,0,1.4,0.3,1.8,0.9s0.6,1.4,0.6,2.5s-0.2,2-0.5,2.7h-1.2c0.2-0.4,0.4-0.9,0.5-1.3
                      c0.1-0.5,0.2-0.9,0.2-1.4c0-0.7-0.1-1.2-0.3-1.6c-0.2-0.4-0.5-0.5-1-0.5c-0.3,0-0.6,0.1-0.9,0.4s-0.5,0.9-0.8,1.7
                      c-0.3,0.8-0.6,1.4-0.8,1.7s-0.5,0.6-0.8,0.8s-0.6,0.2-1,0.2c-0.7,0-1.2-0.3-1.7-0.9c-0.4-0.6-0.6-1.3-0.6-2.3
                      c0-0.9,0.2-1.8,0.6-2.7l1.1,0.5c-0.4,0.9-0.5,1.6-0.5,2.3c0,0.6,0.1,1.1,0.3,1.4s0.5,0.5,0.8,0.5c0.2,0,0.4-0.1,0.6-0.2
                      s0.3-0.3,0.5-0.6c0.1-0.3,0.4-0.8,0.6-1.5c0.4-1,0.7-1.7,1.1-2.1C14.1,101.4,14.6,101.3,15.2,101.3z"/>
                <path d="M16.6,96.4c0-0.2,0-0.5-0.1-0.7c0-0.2-0.1-0.4-0.1-0.5h1c0.1,0.1,0.1,0.3,0.2,0.6s0.1,0.5,0.1,0.7
                      c0,1.7-0.9,2.5-2.6,2.5H9.9v1.2H9.3L8.8,99L7,98.5v-0.8h2v-2.5h1v2.5h5c0.5,0,0.9-0.1,1.2-0.4C16.4,97.2,16.6,96.8,16.6,96.4z"
                      />
                </g>
                <path class="st6" d="M27,21L38.2,5L49,21"/>
                <path class="st6" d="M882,275l16,11.2L882,297"/>
                <g>
                <path d="M17.1,352.2l-1.4-3.6h-4.6l-1.4,3.6H8.3l4.5-11.5H14l4.5,11.5H17.1z M15.2,347.4l-1.3-3.5c-0.2-0.4-0.3-1-0.5-1.6
                      c-0.1,0.5-0.3,1-0.5,1.6l-1.3,3.5H15.2z"/>
                <path d="M23.8,343.5c1.1,0,2,0.4,2.6,1.2c0.6,0.8,0.9,1.9,0.9,3.3s-0.3,2.5-0.9,3.3s-1.5,1.2-2.6,1.2c-0.6,0-1.1-0.1-1.5-0.3
                      c-0.5-0.2-0.8-0.5-1.2-0.9H21l-0.3,1.1h-0.9v-12.2h1.3v3c0,0.7,0,1.3-0.1,1.8h0.1C21.7,344,22.6,343.5,23.8,343.5z M23.6,344.6
                      c-0.9,0-1.5,0.3-1.9,0.8s-0.6,1.4-0.6,2.6s0.2,2.1,0.6,2.6s1,0.8,1.9,0.8c0.8,0,1.4-0.3,1.8-0.9s0.6-1.4,0.6-2.5
                      s-0.2-1.9-0.6-2.5S24.4,344.6,23.6,344.6z"/>
                <path d="M33.6,343.5c1.1,0,2,0.4,2.6,1.2c0.6,0.8,0.9,1.9,0.9,3.3s-0.3,2.5-0.9,3.3c-0.6,0.8-1.5,1.2-2.6,1.2
                      c-0.6,0-1.1-0.1-1.5-0.3c-0.5-0.2-0.8-0.5-1.2-0.9h-0.1l-0.3,1.1h-0.9v-12.2h1.3v3c0,0.7,0,1.3-0.1,1.8h0.1
                      C31.5,344,32.4,343.5,33.6,343.5z M33.4,344.6c-0.9,0-1.5,0.3-1.9,0.8s-0.6,1.4-0.6,2.6s0.2,2.1,0.6,2.6s1,0.8,1.9,0.8
                      c0.8,0,1.4-0.3,1.8-0.9c0.4-0.6,0.6-1.4,0.6-2.5s-0.2-1.9-0.6-2.5S34.2,344.6,33.4,344.6z"/>
                <path d="M39.3,351.4c0-0.3,0.1-0.6,0.2-0.8s0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8
                      c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.2S39.3,351.8,39.3,351.4z"/>
                <path d="M58.8,352.2v-5.6c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.3-0.7-0.5-1.4-0.5c-0.8,0-1.4,0.2-1.8,0.7c-0.4,0.5-0.6,1.2-0.6,2.1v4.8
                      h-1.3v-5.6c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.3-0.7-0.5-1.4-0.5c-0.8,0-1.4,0.2-1.8,0.7c-0.4,0.5-0.6,1.3-0.6,2.4v4.5h-1.3v-8.6h1.1
                      l0.2,1.2h0.1c0.2-0.4,0.6-0.7,1-1s0.9-0.4,1.5-0.4c1.3,0,2.2,0.5,2.6,1.5h0.1c0.3-0.4,0.6-0.8,1.1-1.1c0.5-0.3,1-0.4,1.7-0.4
                      c1,0,1.7,0.2,2.2,0.7s0.7,1.3,0.7,2.4v5.6L58.8,352.2L58.8,352.2z"/>
                <path d="M70.1,348c0,1.4-0.4,2.5-1.1,3.3s-1.7,1.2-2.9,1.2c-0.8,0-1.4-0.2-2-0.5s-1.1-0.9-1.4-1.5s-0.5-1.5-0.5-2.4
                      c0-1.4,0.3-2.5,1-3.3s1.7-1.2,2.9-1.2c1.2,0,2.1,0.4,2.9,1.2S70.1,346.6,70.1,348z M63.6,348c0,1.1,0.2,1.9,0.7,2.5
                      c0.4,0.6,1.1,0.9,1.9,0.9s1.5-0.3,1.9-0.9s0.7-1.4,0.7-2.5s-0.2-1.9-0.7-2.5c-0.4-0.6-1.1-0.8-1.9-0.8s-1.5,0.3-1.9,0.8
                      C63.8,346,63.6,346.8,63.6,348z"/>
                <path d="M78.2,351.1L78.2,351.1c-0.7,0.9-1.6,1.3-2.8,1.3c-1.1,0-2-0.4-2.6-1.1s-0.9-1.9-0.9-3.3s0.3-2.5,0.9-3.3
                      s1.5-1.2,2.6-1.2c1.2,0,2.1,0.4,2.7,1.3h0.1l-0.1-0.6v-0.6v-3.5h1.3v12.2h-1.1L78.2,351.1z M75.6,351.3c0.9,0,1.5-0.2,1.9-0.7
                      s0.6-1.3,0.6-2.3V348c0-1.2-0.2-2.1-0.6-2.6s-1-0.8-1.9-0.8c-0.8,0-1.3,0.3-1.7,0.9c-0.4,0.6-0.6,1.4-0.6,2.5s0.2,1.9,0.6,2.5
                      C74.3,351,74.9,351.3,75.6,351.3z"/>
                <path d="M82.1,341.4c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2s0.2,0.4,0.2,0.6c0,0.3-0.1,0.5-0.2,0.7
                      c-0.2,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2S82.1,341.7,82.1,341.4z M83.5,352.2h-1.3v-8.6h1.3V352.2z"/>
                <path d="M90.1,344.7h-2.2v7.6h-1.3v-7.6h-1.5v-0.6l1.5-0.5v-0.5c0-2.1,0.9-3.2,2.8-3.2c0.5,0,1,0.1,1.6,0.3l-0.3,1
                      c-0.5-0.2-0.9-0.2-1.3-0.2c-0.5,0-0.9,0.2-1.1,0.5s-0.4,0.8-0.4,1.6v0.6h2.2C90.1,343.7,90.1,344.7,90.1,344.7z M91.6,341.4
                      c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2s0.2,0.4,0.2,0.6c0,0.3-0.1,0.5-0.2,0.7
                      c-0.2,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2C91.6,341.9,91.6,341.7,91.6,341.4z M93,352.2h-1.3v-8.6H93V352.2z"/>
                <path d="M101.2,352.2H95v-0.9l4.7-6.7h-4.4v-1h5.8v1l-4.6,6.5h4.7V352.2z"/>
                <path d="M103.1,341.4c0-0.3,0.1-0.5,0.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2s0.4,0.1,0.5,0.2s0.2,0.4,0.2,0.6c0,0.3-0.1,0.5-0.2,0.7
                      c-0.2,0.1-0.3,0.2-0.5,0.2s-0.4-0.1-0.5-0.2S103.1,341.7,103.1,341.4z M104.5,352.2h-1.3v-8.6h1.3V352.2z"/>
                <path d="M110.9,352.4c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2s1.9,0.4,2.5,1.1
                      s0.9,1.6,0.9,2.8v0.8H108c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2c-0.5,0.2-0.9,0.3-1.3,0.4
                      S111.4,352.4,110.9,352.4z M110.5,344.6c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.6,1.1-0.7,1.9h4.5c0-0.8-0.2-1.4-0.5-1.9
                      C111.7,344.8,111.2,344.6,110.5,344.6z"/>
                <path d="M120.1,343.5c0.4,0,0.7,0,1,0.1l-0.2,1.2c-0.4-0.1-0.7-0.1-0.9-0.1c-0.7,0-1.3,0.3-1.8,0.8s-0.7,1.3-0.7,2.1v4.6h-1.3
                      v-8.6h1.1l0.1,1.6h0.1c0.3-0.6,0.7-1,1.1-1.3C119.1,343.7,119.6,343.5,120.1,343.5z"/>
                <path d="M125.5,351.3c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2s-0.5,0.1-0.7,0.1
                      c-1.7,0-2.5-0.9-2.5-2.6v-5.1h-1.2v-0.6l1.2-0.5l0.5-1.8h0.8v2h2.5v1h-2.5v5c0,0.5,0.1,0.9,0.4,1.2
                      C124.8,351.2,125.1,351.3,125.5,351.3z"/>
                <path d="M138.4,352.2v-5.5c0-0.7-0.2-1.2-0.5-1.6s-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7s-0.6,1.3-0.6,2.4v4.5h-1.3v-8.6h1.1
                      l0.2,1.2h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.3,1.6-0.3c1,0,1.8,0.2,2.3,0.7s0.8,1.3,0.8,2.4v5.6L138.4,352.2L138.4,352.2z"
                      />
                <path d="M147.7,352.2l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7s-0.7-1.1-0.7-1.9
                      c0-1.7,1.4-2.6,4.1-2.7h1.5v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7l-0.4-1
                      c0.4-0.2,0.9-0.4,1.4-0.5s1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2v5.8L147.7,352.2L147.7,352.2z M144.7,351.3
                      c0.8,0,1.4-0.2,1.9-0.7c0.5-0.4,0.7-1.1,0.7-1.9V348l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3
                      c0,0.5,0.1,0.8,0.4,1.1C143.8,351.2,144.2,351.3,144.7,351.3z"/>
                <path d="M154.7,352.4c-1.2,0-2.2-0.4-2.9-1.1c-0.7-0.8-1-1.8-1-3.2c0-1.4,0.3-2.5,1-3.3s1.7-1.2,2.9-1.2c0.4,0,0.8,0,1.2,0.1
                      s0.7,0.2,1,0.3l-0.4,1.1c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.6-0.1-0.9-0.1c-1.7,0-2.6,1.1-2.6,3.3c0,1.1,0.2,1.9,0.6,2.4
                      s1.1,0.8,1.9,0.8c0.7,0,1.4-0.2,2.2-0.5v1.1C156.3,352.2,155.6,352.4,154.7,352.4z"/>
                <path d="M164.8,352.2v-5.5c0-0.7-0.2-1.2-0.5-1.6s-0.8-0.5-1.5-0.5c-0.9,0-1.6,0.2-2,0.7s-0.6,1.3-0.6,2.4v4.5h-1.3V340h1.3v3.7
                      c0,0.4,0,0.8-0.1,1.1h0.1c0.3-0.4,0.6-0.7,1.1-1c0.5-0.2,1-0.4,1.6-0.4c1,0,1.8,0.2,2.4,0.7c0.5,0.5,0.8,1.3,0.8,2.4v5.6
                      L164.8,352.2L164.8,352.2z"/>
                <path d="M179.4,352.2H173v-11.4h6.4v1.2h-5v3.7h4.7v1.2h-4.7v4.2h5V352.2z"/>
                <path d="M190.9,352.2h-1.5l-6.2-9.6h-0.1c0.1,1.1,0.1,2.2,0.1,3.1v6.5H182v-11.4h1.5l6.2,9.5h0.1c0-0.1,0-0.6-0.1-1.4
                      c0-0.8,0-1.3,0-1.6v-6.6h1.2V352.2z"/>
                <path d="M199.1,346.3h3.9v5.6c-0.6,0.2-1.2,0.3-1.8,0.4s-1.3,0.1-2.2,0.1c-1.7,0-3.1-0.5-4-1.5c-1-1-1.4-2.5-1.4-4.3
                      c0-1.2,0.2-2.2,0.7-3.1s1.2-1.6,2.1-2c0.9-0.5,1.9-0.7,3.1-0.7c1.2,0,2.4,0.2,3.4,0.7l-0.5,1.2c-1-0.4-2-0.7-3-0.7
                      c-1.4,0-2.5,0.4-3.3,1.2s-1.2,2-1.2,3.4c0,1.5,0.4,2.7,1.1,3.5s1.9,1.2,3.3,1.2c0.8,0,1.6-0.1,2.3-0.3v-3.5H199L199.1,346.3
                      L199.1,346.3z"/>
                <path d="M206.8,350.4l0.1,0.2c-0.1,0.5-0.3,1.1-0.6,1.8c-0.3,0.7-0.5,1.3-0.8,1.9h-1c0.1-0.5,0.3-1.2,0.5-2s0.3-1.4,0.4-1.9
                      H206.8z"/>
                <path d="M218.7,341.8c-1.3,0-2.2,0.4-3,1.3s-1.1,2-1.1,3.4c0,1.5,0.4,2.7,1.1,3.5c0.7,0.8,1.7,1.2,3,1.2c0.8,0,1.7-0.1,2.7-0.4
                      v1.2c-0.8,0.3-1.8,0.4-2.9,0.4c-1.7,0-3-0.5-3.9-1.5s-1.4-2.5-1.4-4.4c0-1.2,0.2-2.2,0.7-3.1c0.4-0.9,1.1-1.6,1.9-2
                      c0.8-0.5,1.8-0.7,2.9-0.7c1.2,0,2.2,0.2,3.1,0.7l-0.6,1.1C220.4,342,219.5,341.8,218.7,341.8z"/>
                <path d="M228.9,352.2l-3.9-10.1l0,0c0.1,0.8,0.1,1.8,0.1,2.9v7.3h-1.2v-11.4h2l3.6,9.4h0.1l3.6-9.4h2v11.4h-1.3v-7.4
                      c0-0.8,0-1.8,0.1-2.8h-0.1l-3.9,10.1H228.9z"/>
                <path d="M237.9,351.4c0-0.3,0.1-0.6,0.2-0.8s0.4-0.3,0.7-0.3s0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8
                      c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.2S237.9,351.8,237.9,351.4z"/>
                <path d="M250.1,352.4c-1.3,0-2.3-0.4-3-1.2c-0.7-0.8-1.1-1.8-1.1-3.2c0-1.4,0.3-2.5,1-3.3s1.6-1.2,2.7-1.2
                      c1.1,0,1.9,0.4,2.5,1.1s0.9,1.6,0.9,2.8v0.8h-5.9c0,1,0.3,1.8,0.8,2.3s1.2,0.8,2,0.8c0.9,0,1.8-0.2,2.7-0.6v1.2
                      c-0.5,0.2-0.9,0.3-1.3,0.4S250.7,352.4,250.1,352.4z M249.8,344.6c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.4-0.6,1.1-0.7,1.9h4.5
                      c0-0.8-0.2-1.4-0.5-1.9C251,344.8,250.5,344.6,249.8,344.6z"/>
                <path d="M258.3,351.3c0.2,0,0.5,0,0.7-0.1c0.2,0,0.4-0.1,0.5-0.1v1c-0.1,0.1-0.3,0.1-0.6,0.2s-0.5,0.1-0.7,0.1
                      c-1.7,0-2.5-0.9-2.5-2.6v-5.1h-1.2v-0.6l1.2-0.5l0.5-1.8h0.8v2h2.5v1H257v5c0,0.5,0.1,0.9,0.4,1.2
                      C257.5,351.2,257.8,351.3,258.3,351.3z"/>
                <path d="M270.6,352.2l-0.3-1.2h-0.1c-0.4,0.5-0.9,0.9-1.3,1.1s-1,0.3-1.6,0.3c-0.8,0-1.5-0.2-2-0.7s-0.7-1.1-0.7-1.9
                      c0-1.7,1.4-2.6,4.1-2.7h1.5v-0.5c0-0.7-0.1-1.2-0.4-1.5s-0.8-0.5-1.4-0.5c-0.7,0-1.5,0.2-2.4,0.7l-0.4-1
                      c0.4-0.2,0.9-0.4,1.4-0.5s1-0.2,1.5-0.2c1,0,1.8,0.2,2.3,0.7s0.7,1.2,0.7,2.2v5.8L270.6,352.2L270.6,352.2z M267.6,351.3
                      c0.8,0,1.4-0.2,1.9-0.7c0.5-0.4,0.7-1.1,0.7-1.9V348l-1.3,0.1c-1,0-1.8,0.2-2.2,0.5c-0.5,0.3-0.7,0.7-0.7,1.3
                      c0,0.5,0.1,0.8,0.4,1.1C266.7,351.2,267.1,351.3,267.6,351.3z"/>
                <path d="M275.5,352.2h-1.3V340h1.3V352.2z"/>
                <path d="M278.1,351.4c0-0.3,0.1-0.6,0.2-0.8s0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.8c0,0.3-0.1,0.6-0.3,0.8
                      c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.2S278.1,351.8,278.1,351.4z"/>
                <path d="M293.6,352.2h-7.5v-1.1l3-3c0.9-0.9,1.5-1.6,1.8-2s0.5-0.8,0.7-1.2s0.2-0.8,0.2-1.2c0-0.6-0.2-1.1-0.6-1.4
                      c-0.4-0.4-0.9-0.5-1.5-0.5c-0.5,0-0.9,0.1-1.3,0.2s-0.9,0.4-1.4,0.9l-0.7-0.9c1.1-0.9,2.2-1.3,3.4-1.3c1.1,0,1.9,0.3,2.5,0.8
                      s0.9,1.3,0.9,2.2c0,0.7-0.2,1.4-0.6,2.1s-1.2,1.6-2.3,2.7l-2.5,2.4v0.1h5.9V352.2z"/>
                <path d="M302.8,346.5c0,2-0.3,3.4-0.9,4.4s-1.6,1.5-2.9,1.5c-1.2,0-2.2-0.5-2.8-1.5c-0.6-1-1-2.5-1-4.4c0-2,0.3-3.5,0.9-4.4
                      c0.6-1,1.6-1.4,2.8-1.4c1.2,0,2.2,0.5,2.8,1.5C302.5,343.2,302.8,344.6,302.8,346.5z M296.5,346.5c0,1.7,0.2,2.9,0.6,3.6
                      s1,1.1,1.9,1.1s1.5-0.4,1.9-1.2c0.4-0.8,0.6-2,0.6-3.6s-0.2-2.8-0.6-3.6c-0.4-0.8-1-1.1-1.9-1.1s-1.5,0.4-1.9,1.1
                      C296.7,343.6,296.5,344.9,296.5,346.5z"/>
                <path d="M311.9,346.5c0,2-0.3,3.4-0.9,4.4s-1.6,1.5-2.9,1.5c-1.2,0-2.2-0.5-2.8-1.5c-0.6-1-1-2.5-1-4.4c0-2,0.3-3.5,0.9-4.4
                      c0.6-1,1.6-1.4,2.8-1.4c1.2,0,2.2,0.5,2.8,1.5C311.6,343.2,311.9,344.6,311.9,346.5z M305.7,346.5c0,1.7,0.2,2.9,0.6,3.6
                      s1,1.1,1.9,1.1s1.5-0.4,1.9-1.2c0.4-0.8,0.6-2,0.6-3.6s-0.2-2.8-0.6-3.6c-0.4-0.8-1-1.1-1.9-1.1s-1.5,0.4-1.9,1.1
                      C305.9,343.6,305.7,344.9,305.7,346.5z"/>
                <path d="M315,352.2l4.7-10.2h-6.2v-1.2h7.6v1l-4.7,10.4L315,352.2L315,352.2z"/>
                <path d="M327,345.4h-1v1.5h-0.7v-1.5h-3.2v-0.7l3.2-4.5h0.8v4.5h1L327,345.4L327,345.4z M325.3,344.7v-2.2c0-0.4,0-0.9,0-1.5
                      l0,0c-0.1,0.3-0.3,0.5-0.4,0.7l-2.1,3H325.3z"/>
                <path d="M329.9,340.2c0.6,0,1.1,0.1,1.4,0.4s0.5,0.7,0.5,1.2c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.3-0.5,0.5-1,0.7
                      c0.5,0.3,0.9,0.5,1.2,0.8c0.2,0.3,0.3,0.6,0.3,1c0,0.6-0.2,1-0.6,1.3s-0.9,0.5-1.6,0.5s-1.3-0.2-1.6-0.5
                      c-0.4-0.3-0.6-0.8-0.6-1.3c0-0.8,0.5-1.4,1.4-1.8c-0.4-0.2-0.7-0.5-0.9-0.8c-0.2-0.3-0.3-0.6-0.3-0.9c0-0.5,0.2-0.9,0.5-1.2
                      S329.3,340.2,329.9,340.2z M328.4,345.2c0,0.4,0.1,0.6,0.4,0.9c0.3,0.2,0.6,0.3,1.1,0.3s0.8-0.1,1.1-0.3
                      c0.3-0.2,0.4-0.5,0.4-0.9c0-0.3-0.1-0.6-0.4-0.8c-0.2-0.2-0.6-0.5-1.2-0.7c-0.5,0.2-0.8,0.4-1,0.6
                      C328.5,344.6,328.4,344.9,328.4,345.2z M329.9,340.8c-0.4,0-0.7,0.1-0.9,0.3s-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7
                      c0.2,0.2,0.5,0.4,1,0.6c0.4-0.2,0.7-0.4,0.9-0.6c0.2-0.2,0.3-0.5,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7
                      C330.6,340.9,330.2,340.8,329.9,340.8z"/>
                </g>
                </g>
                </g>
                </g>
                </svg>



            </div>
            <!-- <div class="info-section-graphics-legend"> -->
            <!-- Abbildung modifiziert nach Eng CM et al. 2007 -->
            <!-- Abb. modifiziert nach ENG, CM. et al. 2007<sup class="info-section-text-ref">48</sup> -->
            <!-- </div> -->
        </div>

        <div class="info-section-columns">

            <div class="info-section-column info-section-column-first">
                <div class="info-section-column-bar">
                </div>

                <div class="info-section-column-title">
                    <h3>
                        Symptome ab Kindheit
                    </h3>
                </div>

                <div class="info-section-list">

                    <ul>
                        <li>
                            Angiokeratome
                        </li>
                        <li>
                            Neuropathische Schmerzen
                        </li>
                        <li>
                            Gastrointestinale Beschwerden
                        </li>
                        <li>
                            Hypohidrose
                        </li>
                        <li class="last-item">
                            Hitze- und Kälteintoleranz
                        </li>
                    </ul>

                </div>

                <!-- <div class="info-section-column-text">
                  Angiokeratome
                </div>
                <div class="info-section-column-text">
                  Neuropathische Schmerzen
                </div>
                <div class="info-section-column-text">
                  Gastrointestinale Beschwerden
                </div>
                <div class="info-section-column-text">
                  Hypohidrose
                </div>
                <div class="info-section-column-text">
                  Hitze- und Kälte-Intoleranz
                </div> -->

            </div>
            <div class="info-section-column">
                <div class="info-section-column-bar">
                </div>

                <div class="info-section-column-title">
                    <h3>
                        Zunehmende<br />Globotriaosylceramid-Akkumulation
                    </h3>
                </div>

                <div class="info-section-list">

                    <ul>
                        <li>
                            Weitere Angiokeratome
                        </li>
                        <li>
                            Lymphödeme an den unteren Extremitäten
                        </li>
                        <li>
                            Proteinurie und fortschreitende Niereninsuffizienz
                        </li>
                        <li>
                            Linksventrikuläre Hypertrophie, <span class="no-lb">Angina-pectoris-Beschwerden</span>, Arrhythmien
                        </li>
                        <li>
                            <span matTooltip='Transitorische ischämische Attacken' matTooltipPosition="below">TIA</span>, Schlaganfall
                        </li>
                        <li class="last-item">
                            Depressionen
                        </li>
                    </ul>

                </div>
                <!-- <div class="info-section-column-text">
                  Weitere Angiokeratome
                </div>
                <div class="info-section-column-text">
                  Lymphödeme an den unteren Extremitäten
                </div>
                <div class="info-section-column-text">
                  Proteinurie und fortschreitende Niereninsuffizienz
                </div>
                <div class="info-section-column-text">
                  Hypertrophe Kardiomyopathie und LVH, Angina pectoris, Arrhythmien
                </div>
                <div class="info-section-column-text">
                  ZNS: TIA, Schlaganfall
                </div>
                <div class="info-section-column-text">
                  Depressionen
                </div> -->

            </div>
            <div class="info-section-column">
                <div class="info-section-column-bar">
                </div>

                <div class="info-section-column-title">
                    <h3>
                        Spätere Komplikationen
                    </h3>
                </div>

                <div class="info-section-list">

                    <ul>
                        <li>
                            Nierenversagen
                        </li>
                        <li>
                            Kardiovaskuläre  Komplikationen
                        </li>
                        <li>
                            ZNS-Komplikationen
                        </li>
                        <li class="last-item">
                            Tod
                        </li>
                    </ul>

                </div>
                <!-- <div class="info-section-column-text">
                  Nierenversagen
                </div>
                <div class="info-section-column-text">
                  Kardiovaskuläre  Komplikationen
                </div>
                <div class="info-section-column-text">
                  ZNS-Komplikationen
                </div>
                <div class="info-section-column-text">
                  Tod
                </div> -->

            </div>

        </div>

        <div class="info-section-title info-section-title-2">
            <h2>
                Die <a routerLink="/diagnose">Diagnose</a> wird oft lange nach den ersten Symptomen gestellt.<sup class="info-section-text-ref">49</sup> 
            </h2>
        </div>

        <div class="info-section-graphics-3">

            <!-- <div class="info-section-graphics-title">
              Im Durchschnitt vergehen:<sup class="info-section-text-ref">50</sup>
            </div> -->

            <div class="info-section-graphics-sub">

                <div class="info-section-graphic">
                    <div class="info-section-graphics-icon">
                        <img src="/assets/img/template/Piktogramme_Frau_Badge.svg" />
                    </div>
                    <div class="info-section-graphics-legend-1">
                        16,3 Jahre bei Frauen
                    </div>
                    <div class="info-section-graphics-legend-3">
                        (bis Diagnosestellung<sup class="info-section-text-ref">50</sup>)
                    </div>
                </div>
                <div class="info-section-graphic">
                    <div class="info-section-graphics-icon">
                        <img src="/assets/img/template/Piktogramme_Mann_Badge.svg" />
                    </div>
                    <div class="info-section-graphics-legend-2">
                        13,7 Jahre bei Männern
                    </div>
                    <div class="info-section-graphics-legend-3">
                        (bis Diagnosestellung<sup class="info-section-text-ref">50</sup>)
                    </div>
                </div>

            </div>

        </div>


    </div>

    <!-- <div class="info-section-ribbon info-section-8">
  
      <div class="info-section-ribbon-left">
      </div>
  
      <div class="info-section-ribbon-right">
      </div>
  
      <div class="info-section-title">
        Eine frühe Diagnose kann Lebensqualität bewahren
      </div>
  
      <div class="info-section-text">
        <span class="no-lb">Morbus Fabry</span> geht unbehandelt mit einer deutlich reduzierten Lebenserwartung und verminderten Lebensqualität einher. 
        Daher sind eine frühe Diagnose und angemessene Therapiemaßnahmen wichtig.
      </div>
  
    </div> -->

    <div class="info-section info-section-9">

        <div class="info-section-title">
            <!-- Progredienz der Speicherkrankheit -->
            <h2>
                <span class="no-lb">Morbus Fabry</span> bei Frauen
            </h2>
        </div>


        <div class="info-section-container">

            <div class="info-section-block info-section-block-1 info-section-special">

                <div class="info-section-icon">

                    <!-- <img src="/assets/img/template/Piktogramme_Frau_Badge.svg" /> -->
                    <!-- <img src="/assets/img/template/icon-woman-head.svg" /> -->
                    <span class="ico-woman-head-purple ico-custom"></span>
                </div>
            </div>
            <div class="info-section-block info-section-block-2">

                <div class="info-section-block-title info-section-block-title-md">
                    70 % der weiblichen (heterozygoten) Mutationsträger entwickeln Symptome.<sup class="info-section-text-ref"><span style="font-size: 16px !important">51</span></sup>
                </div>

                <div class="info-section-block-text">
                    Im Gegensatz zu vielen anderen <span class="no-lb">X-chromosomalen</span> Erkrankungen 
                    haben Frauen mit <span class="no-lb">Morbus Fabry</span> häufig Krankheitssymptome und 
                    können genauso schwer betroffen sein wie Männer, auch wenn der 
                    Verlauf der Erkrankung deutlich langsamer ist.<sup class="info-section-text-ref">7,52</sup>
                </div>

            </div>

            <div class="info-section-block info-section-block-3">

                <div class="info-section-block-title">
                    Die Diagnose bei Frauen
                </div>

                <div class="info-section-block-text">
                    Die Bestimmung der Enzymaktivität ist zur 
                    Diagnosestellung bei Frauen unzureichend, da nur 
                    bei ca. 20 % eine reduzierte Enzymaktivität 
                    vorliegt.<sup class="info-section-text-ref" style="font-size: 10px">55</sup> Die Sequenzierung des <span class="no-lb">α-Galaktosidase-A-Gens</span> (<span class="title-description-i">GLA</span>) 
                    ist zur Diagnosestellung bei Morbus 
                    Fabry unerlässlich.
                </div>
                <div class="info-section-block-link">
                    <!-- <div>
                      >
                    </div> -->
                    <span class="link-arrow-right icon-arrow-right ico white"></span>
                    <!-- <div>
                       Zur Diagnose von Morbus Fabry
                    </div> -->
                    <a href="#" class="section-link" routerLink="/diagnose">
                        Zur Diagnose von <span class="no-lb">Morbus Fabry</span>
                    </a>
                </div>

            </div>

            <div class="info-section-block info-section-block-3">

                <div>
                    <ul class="info-section-list">
                        <li>
                            Schwere klinische Manifestationen sind in bis zu <span class="no-lb">43 %</span> beschrieben<sup class="info-section-text-ref" style="font-size: 10px !important">53</sup>
                        </li>
                        <li>
                            Symptome treten im Durchschnitt ca. <span class="no-lb">6 Jahre</span> später auf als bei Männern<sup class="info-section-text-ref" style="font-size: 10px !important">32</sup>
                        </li>
                        <li>
                            Ursache ist die zufällige Inaktivierung eines <span class="no-lb">X-Chromosoms</span> jeder Zelle während der frühen Embryonalentwicklung (sog. Lyonisationseffekt)<sup class="info-section-text-ref" style="font-size: 10px !important">54</sup>
                        </li>
                    </ul>
                </div>

            </div>
            <div class="info-section-block-separator">
            </div>
            <div class="info-section-block info-section-block-1 info-section-block-optional-mobile info-section-special">
                <div class="info-section-icon">
                    <!-- <img src="/assets/img/template/Piktogramme_Kind_Badge.svg" /> -->
                    <span class="ico-baby-head-purple ico-custom"></span>
                </div>
            </div>
            <div class="info-section-block info-section-block-2 info-section-block-2-down">

                <div class="info-section-block-title">
                    <span class="no-lb">Morbus Fabry</span> bei Kindern
                </div>

                <div>
                    <ul class="info-section-list">
                        <li>Die pathologische Akkumulation von Globotriaosylceramid beginnt bereits während der fetalen Entwicklung<sup class="info-section-text-ref">47</sup>
                        </li>
                        <li>Symptome des <span class="no-lb">Morbus Fabry</span> können daher bereits in den ersten Lebensjahren manifest werden<sup class="info-section-text-ref">50</sup>
                        </li>
                        <li>Frühe Symptome sind neuropathische Schmerzen und gastrointestinale Beschwerden<sup class="info-section-text-ref">56</sup>
                        </li>
                    </ul>
                </div>

            </div>
            <div class="info-section-block info-section-block-1 info-section-block-optional">
                <div class="info-section-icon">
                    <!-- <img src="/assets/img/template/Piktogramme_Kind_Badge.svg" /> -->
                    <span class="ico-baby-head-purple ico-custom"></span>
                </div>
            </div>

        </div>

        <!-- <div class="info-section-title info-section-title-1">
          Weitere Informationen finden Sie im<br>
          Fachbereich Pädiatrie
        </div> -->

        <!-- <div class="info-section-link">
          <span class="link-arrow-right icon-arrow-right ico pink"></span>
          <div>
            Wann sollten Pädiater an <span class="no-lb">Morbus Fabry</span> denken?
          </div>
        </div> -->
        <!-- <div class="info-section-link">
          <span class="link-arrow-right icon-arrow-right ico pink"></span>
          <div>
            Häufige Fehldiagnosen bei Kindern
          </div>
        </div> -->
        <!-- <div class="info-section-link info-section-link-last">
          <span class="link-arrow-right icon-arrow-right ico pink"></span>
          <div>
            Spezielle Anforderungen in der Kommunikation mit Kindern
          </div>
        </div> -->

        <div class="info-section-title info-section-title-2" style="margin-bottom: 32px;">
            <h2>
                Häufige Fehldiagnosen bei <span class="no-lb">Morbus Fabry</span>
            </h2>
        </div>

        <div class="info-section-subtitle info-section-subtitle-1">
            Alter und Leitsymptom prägen die Fehldiagnose
        </div>

        <div class="info-section-columns">

            <div class="info-section-column">
                <div class="info-section-icons">
                    <div class="info-section-icon">
                        <!-- <img src="/assets/img/template/icon_1_temp.png" /> -->
                        <span class="symptom-icon badge pink-light-2"><span class="ico-girl-pink ico-custom"></span></span>
                    </div>
                    <div class="info-section-icon">
                        <!-- <img src="/assets/img/template/icon_1_temp.png" /> -->
                        <span class="symptom-icon badge blue-light-2"><span class="ico-boy ico-custom"></span></span>
                    </div>
                </div>
                <div class="info-section-column-title">
                    <h3>
                        Kindheit und Adoleszenz
                    </h3>
                </div>
                <div class="info-section-column-subtitle">
                    Neuropathische Symptome, inkl. Schmerz:
                </div>
                <div class="info-section-column-list">
                    <ul>
                        <li>Juvenile rheumatoide Arthritis</li>
                        <li>Raynaud-Syndrom</li>
                        <li>Rheumatisches Fieber</li>
                        <li>Wachstumsschmerz</li>
                        <li>Erythromelalgie</li>
                        <li>Neurose</li>
                    </ul>
                </div>
                <div class="info-section-column-subtitle">
                    Angiokeratome:
                </div>
                <div class="info-section-column-list">
                    <ul>
                        <li>Petechien</li>
                        <li>Lupus erythematodes</li>
                    </ul>
                </div>
                <div class="info-section-column-subtitle">
                    Gastrointestinale Symptome:
                </div>
                <div class="info-section-column-list">
                    <ul>
                        <li>Nahrungsmittelallergien</li>
                        <li>Reizdarmsyndrom</li>
                        <li>Akutes inflammatorisches Darmsyndrom</li>
                        <li>Appendizitis</li>
                        <li>Depression</li>
                        <li>Isolation</li>
                        <li>Schulvermeidungstaktik</li>
                    </ul>
                </div>

            </div>

            <div class="info-section-column">
                <div class="info-section-icons">
                    <div class="info-section-icon">
                        <!-- <img src="/assets/img/template/icon_1_temp.png" /> -->
                        <span class="symptom-icon badge pink-light-2"><span class="ico-young-woman-pink ico-custom"></span></span>
                    </div>
                    <div class="info-section-icon">
                        <!-- <img src="/assets/img/template/icon_1_temp.png" /> -->
                        <span class="symptom-icon badge blue-light-2"><span class="ico-young-man ico-custom"></span></span>
                    </div>
                </div>
                <div class="info-section-column-title">
                    <h3>
                        Frühes Erwachsenenalter
                    </h3>
                </div>
                <div class="info-section-column-subtitle">
                    Neuropathische Symptome, inkl. Schmerz:
                </div>
                <div class="info-section-column-list">
                    <ul>
                        <li>Rheuma</li>
                        <li>Arthritis</li>
                        <li>Neuropsychologische Erkrankung</li>
                        <li>Fibromyalgie</li>
                        <li>Erythromelalgie</li>
                    </ul>
                </div>
                <div class="info-section-column-subtitle">
                    Angiokeratome:
                </div>
                <div class="info-section-column-list">
                    <ul>
                        <li>Morbus Osler</li>
                        <li>Dermatomyositis</li>
                    </ul>
                </div>
                <div class="info-section-column-subtitle">
                    Audio-vestibuläre Symptome:
                </div>
                <div class="info-section-column-list">
                    <ul>
                        <li>Morbus Menière</li>
                    </ul>
                </div>

            </div>


            <div class="info-section-column info-section-column-last">
                <div class="info-section-icons">
                    <div class="info-section-icon">
                        <!-- <img src="/assets/img/template/icon_1_temp.png" /> -->
                        <span class="symptom-icon badge pink-light-2"><span class="ico-woman-pink ico-custom"></span></span>
                    </div>
                    <div class="info-section-icon">
                        <!-- <img src="/assets/img/template/icon_1_temp.png" /> -->
                        <span class="symptom-icon badge blue-light-2"><span class="ico-man ico-custom"></span></span>
                    </div>
                </div>
                <div class="info-section-column-title">
                    <h3>
                        Spätes Erwachsenenalter
                    </h3>
                </div>
                <div class="info-section-column-subtitle">
                    Kardiale Symptome:
                </div>
                <div class="info-section-column-list">
                    <ul>
                        <li>Idiopathische Kardiomyopathie</li>
                        <li>Nichtobstruktive Kardiomyopathie</li>
                        <li>Amyloidose</li>
                        <li>Sarkoidose</li>
                    </ul>
                </div>
                <div class="info-section-column-subtitle">
                    Nierenbeteiligung:
                </div>
                <div class="info-section-column-list">
                    <ul>
                        <li>Idiopathische Niereninsuffizienz</li>
                        <li>Fokale segmentale Glomerulosklerose<sup class="info-section-text-ref">57</sup></li>
                    </ul>
                </div>
                <div class="info-section-column-subtitle">
                    ZNS-Beteiligung:
                </div>
                <div class="info-section-column-list">
                    <ul>
                        <li>Kryptogener juveniler Schlaganfall</li>
                        <li>CADASIL</li>
                        <li>Multiple Sklerose<sup class="info-section-text-ref">58</sup></li>
                    </ul>
                </div>
                <div class="info-section-column-subtitle">
                    Neuropathische Symptome, inkl. Schmerz:
                </div>
                <div class="info-section-column-list">
                    <ul>
                        <li>Kryptogene <span class="no-lb">Small-Fiber-Neuropathie</span><sup class="info-section-text-ref">59</sup></li>
                        <li>Rheuma</li>
                        <li>Somatoforme Schmerzstörung</li>
                        <li>Sjögren-Syndrom<sup class="info-section-text-ref">60</sup></li>
                        <li>Sklerodermie<sup class="info-section-text-ref">60</sup></li>
                    </ul>
                </div>

            </div>

        </div>






    </div>

    <app-footer>
    </app-footer>


</div>
<style>
    .title-description {
        font-size: 16px; line-height: 1.6; text-align: center;
    }
    .info-section-1-a, .info-section-1-b {
        flex: 0 1 50%; display: flex; flex-direction: row; align-items: center; margin-bottom: 80px;
    }
    .symptom-desc-list li {
        margin-bottom: 40px
    }
    .symptom-desc-list {
        list-style: none;
        counter-reset: my-awesome-counter;
    }
    .symptom-desc-list li {
        counter-increment: my-awesome-counter;
    }
    .symptom-desc-list li::before {
        content: counter(my-awesome-counter) ". ";
        font-size: 24px;
        margin-left: -30px;
    }
    .symptom-desc-list-title {
        display: inline;
    }
    .extra {
        font-size: 16px;
        line-height: 1.4;
        text-align: center;
        color: #000;
        width: 600px;
        margin: 0 auto 120px;
    }
    /*.symptom-desc-list li::marker { 
        font-size:40px; 
    }*/
    /*.symptom-short-desc-content.DescNerves {
        padding-top: 20px
    }*/
    .info-section-subtitle {
        font-family: OpenSans-SemiBold;
        font-size: 22px;
        font-weight: 600;
        line-height: 1.39;
        text-align: center;
        color: #000;
        margin-bottom: 60px;
    }
    .info-section-container {
        display: flex;
        flex-wrap: wrap;
        width: 1200px;
        margin-bottom: 120px;        
    }
    @media only screen and (max-width: 768px) {
        .info-section-container {
            width: calc(100% + 64px);
            margin-bottom: 8px;
        }
    } 
    /*    .info-section-text-ref {
            font-size: 18px;
        }*/
    sup {
        font-size: 10px;
    }
    /*    .info-section-text-ref[_ngcontent-ksm-c78] {
            font-size: 10px;        
        }*/
</style>