import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { references } from './references'

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss']
})
export class ReferencesComponent implements OnInit {

  selectedReferences: any[];
  selectedReferenceGroupName: any;

  constructor(
    private _activatedRoute: ActivatedRoute
    ) { }

  ngOnInit(): void {

    window.scrollTo(0, 0);

    this.showReferences('morbus-fabry');

    this._activatedRoute.fragment.subscribe(
      (fragments) => {
        this.showReferences(fragments);
        var s = document.querySelector(".info-section-1");
        s.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      }
    ); // update on all changes



  }

  showReferences(newActiveBlock){
    this.selectedReferenceGroupName = newActiveBlock;
    this.selectedReferences = references[this.selectedReferenceGroupName];
  }

}
