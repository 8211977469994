import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { ImageDialogComponent } from '../partials/image-dialog/image-dialog.component';
import { BannerDialogComponent } from '../partials/banner-dialog/banner-dialog.component';


@Component({
  selector: 'app-morbus-fabry',
  templateUrl: './morbus-fabry.component.html',
  styleUrls: ['./morbus-fabry.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class MorbusFabryComponent implements OnInit {

  lastClickedLink = "";

  constructor(
    private _router: Router,
    public _matDialog: MatDialog
    ) { }

  ngOnInit(): void {

    const symptomLinks = document.querySelectorAll('.symptom-link');

    for (var i = 0; i < symptomLinks.length; i++) {
      symptomLinks[i].addEventListener('click', this.openDetailsPanel.bind(this));

      symptomLinks[i].addEventListener('mouseover', this.showBadgeInfo.bind(this));
      symptomLinks[i].addEventListener('mouseout', this.hideBadgeInfo.bind(this));
    }

    const closeSymptomIcons = document.querySelectorAll('.close-symptom-desc');

    for (var i = 0; i < closeSymptomIcons.length; i++) {
      closeSymptomIcons[i].addEventListener('click', this.closePanel.bind(this));
    }

    const supTags = document.querySelectorAll('sup');

    for (var i = 0; i < supTags.length; i++) {
      supTags[i].addEventListener('click', this.goToReferences.bind(this));
      supTags[i].setAttribute('style', 'cursor: pointer;');
    }

    

  }

  openDetailsPanel(event): void {

    var t = 'symptom-desc';
    var n = 'symptom-person';

    var i = "id";

    if(event.currentTarget.hasAttribute("data-open-desc")) {
      i = "data-open-desc";
    }

    var o = event.currentTarget.getAttribute(i);

    if(this.lastClickedLink == o) return;
    
    this.lastClickedLink = o;

    var s = document.querySelector("." + t + "[data-desc-box='" + o + "']");
    var a = document.querySelector("." + t + ".desc_show");

    if(a){
      a.classList.remove("desc_show");
      a.setAttribute('style', 'display: none;');
    }

    if((s != null && a == null ) || (s.getAttribute("data-desc-box") != a.getAttribute("data-desc-box"))) {
      s.classList.add("desc_show");
      s.removeAttribute('style');

      const y = s.getBoundingClientRect().top + window.pageYOffset - 132;

      window.scrollTo({top: y, behavior: 'smooth'});
    } else {
      if(s!= null){
        const y = s.getBoundingClientRect().top + window.pageYOffset - 132;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
    }

  }

  showBadgeInfo(event): void {
    var t = event.target.getAttribute("id");
    document.querySelector(".symptom-short-desc-content.show").classList.remove("show");
    document.querySelector(".symptom-short-desc-content[data-desc-short='" + t + "']").classList.add("show");

    var anchors = document.querySelectorAll(".achnorpulse");

    for (var i = 0; i < anchors.length; i++) {
      if(anchors[i] != event.target) {
        anchors[i].classList.add("animation-stop");
      }
    }

    
  }

  hideBadgeInfo(event): void {
    var anchors = document.querySelectorAll(".achnorpulse");
    
    for (var i = 0; i < anchors.length; i++) {
      if(anchors[i] != event.target) {
        anchors[i].classList.remove("animation-stop");
      }
    }

    document.querySelector(".symptom-short-desc-content.show").classList.remove("show");
    document.querySelector(".symptom-short-desc-content[data-desc-short='DescDefault']").classList.add("show");
  }

  closePanel(event): void {
    // close-specialist-desc

    console.log("closePanel: ");

    var t = 'symptom-desc';
    var n = 'symptom-person';

    var a = document.querySelector("." + t + ".desc_show");
    var s = document.querySelector("." + n);

    if(a){
      a.classList.remove("desc_show");
      a.setAttribute('style', 'display: none;');

      const y = s.getBoundingClientRect().top + window.pageYOffset - 132;
      window.scrollTo({top: y, behavior: 'smooth'});
    }
  }

  goToReferences(event): void {
    this.navigateTo('/referenzen');
  }

  navigateTo(url){
    this._router.navigate([url], { fragment: 'morbus-fabry' });
  }

  openDialog(id) {
    this._matDialog.open(ImageDialogComponent, {
      data: {
        identifier: id
      },
      panelClass: 'image-dialog-container'
    });

  }
  
  openBanner(id) {
    this._matDialog.open(BannerDialogComponent, {
      data: {
        identifier: id
      },
      panelClass: 'banner-dialog-container'
    });

  }

}
