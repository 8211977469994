import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject } from "rxjs";
import { Subject } from "rxjs";
import { Observable } from 'rxjs';
// import { CookieService } from 'ngx-cookie-service';

import { ApiLoginService } from '../shared/services/api-login.service';

@Injectable({
  providedIn: 'root'
})
export class DocCheckHandlerService {


  constructor(
    // private _httpClient: HttpClient,
    // private _competitorsSelectorHeaderService: CompetitorsSelectorHeaderService,
    // private _cookieService: CookieService,
    private _apiLoginService: ApiLoginService,
    private _router: Router
    ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
  {
    return new Promise((resolve, reject) => {

      console.log("Params: ", route.queryParams);
      // console.log("Cookie: ", this._cookieService.get('doccheck_scu_data'));
      // console.log("Cookie: ", this._cookieService.get('expires'));
      // console.log("Cookie: ", this._cookieService.get('domain'));
      // console.log("Cookies: ", this._cookieService.getAll());
      console.log("referrer: ", document.referrer);

      

      

      // doccheck/redirect?dc=1&dc_timestamp=1600918487

  // Set-Cookie: doccheck_scu_data=00eca2ce6a99303d55b746fb954ea613536455c347cbf23c3cd250e7b6724583a6914e4685d8798b9cc1eff8c6cec57c02583d39bf9e9c474c4e83d98cff9b20; expires=Fri, 24-Sep-2021 04:45:47 GMT; Max-Age=31536000; path=/; domain=.doccheck.com

      // let vehicleId = route.params.vehicleId;

      Promise.all([
        // this._competitorsSelectorHeaderService.getBenchmark(vehicleId),
        // this._competitorsSelectorHeaderService.getCompetitors(vehicleId),
        this._apiLoginService.tryLogin(route.queryParams)
      ]).then(
        (data) => {
            resolve(data);
            this._router.navigate(['/morbus-fabry']);
        },
        reject
      );
    });
  }
}
